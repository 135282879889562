export const translation_9_2_A = {
  en: {},
  ko: {
    v1: {
      typography_0: `함께 다뤘던 자동적 사고를 다시 살펴볼게요.`,
      typography_1: `우리는 살면서 위와 같은 부정적인 생각과 감정을 자주 만나곤 했을 거예요.`,
      typography_2: `이제부터 우리가 그동안 위와 같은 생각과 감정에 어떻게 대처했는지, 그것은 얼마나 효과적이었는지 살펴보고자 해요.`,
      typography_3: `이해하기`,
      typography_4: `우선 우리는 부정적인 생각과 감정을 어떻게든 이해해보고자 많은 노력을 하는데요.`,
      typography_5: `과거의 기억을 끊임없이 떠올리며 다시 생각해보기도 하고, 다가올 미래를 끊임없이 걱정하기도 하죠.`,
      typography_6: `예를 들면 이렇게요.`,
      typography_6_1: `지난 이별에 대해 '그 일이 나에게 어떠한 의미인지', '난 왜 그렇게 행동했는지' 답을 찾으려 함`,
      typography_6_2: `다가올 발표에 대해 '잘 안 되면 어떻게 하지', '잘 할 수 있을까', '어떻게 해야 하지'하며 대비하려 함`,
      typography_7: `나는 부정적인 생각과 감정을 이해하고자 어떤 노력들을 했나요?`,
      typography_8: `많은 사람들이 부정적인 생각과 감정을 스스로의 마음에 드는 방식으로 납득하려 시도하곤 해요.`,
      typography_9: `흔히 그러한 생각들을 통해 현재의 상황에 대해 나름의 통찰을 얻는다고 생각하죠.`,
      typography_10: `하지만 안타깝게도 연구 결과는 그 방법이 그다지 효과가 없다는 것을 말해주고 있어요.`,
      typography_11: `나의 경우를 한번 살펴볼까요? 내가 부정적인 생각과 감정을 이해하기 위해 했던 노력들은 얼마나 효과적이었나요?`,
      typography_12: `그것이 옳았냐 틀렸냐를 따지는 것이 아닙니다. 위에 내가 적은 노력들은 우리 삶에 얼마나 많은 도움이 되었나요?`,
      typography_13: `당장 조금 기분이 완화된 것이 아니라 이를 통해 내가 겪는 문제로부터 자유로워지고, 그로 인해 나의 삶은 내가 원하는 모습으로 나아가고 있나요?`,
      typography_14: `그러한 노력으로 나는 괴로움으로부터 충분히 자유로워졌나요?`,
      typography_15: `제거하기`,
      typography_16: `또 다른 방법도 있어요. 몇몇 사람들은 부정적인 생각이나 감정을 마음에서 없애거나 다른 좋은 것으로 대체하기 위해 노력하곤 해요.`,
      typography_17: `예를 들면 이렇게요.`,
      typography_17_1: `"이런 생각하지 말자"며, 우울한 생각을 의식적으로 안하려 함`,
      typography_17_2: `다른 좋은 일로 관심을 돌리며, 스스로를 비하하는 생각을 덮으려 함`,
      typography_18: `나는 부정적인 생각과 감정을 제거하거나 다른 것으로 대체하기 위해 어떤 노력들을 했나요?`,
      typography_19: `좋아요. 이번에도 그러한 노력이 얼마나 효과적이었는지 적어볼까요?`,
      typography_20: `그러한 노력으로 나는 괴로움으로부터 충분히 자유로워졌나요?`,
      typography_21: `통제하기`,
      typography_22: `또 많은 사람들은 흔히 부정적인 생각과 감정을 통제하기 위해 어떤 행동을 하기도 해요.`,
      typography_23: `술을 많이 마시기도 하고, 처방받은 약을 정해진 용량 이상으로 복용하기도 해요.`,
      typography_24: `폭식, 과소비, 게임 몰두, 성적인 행동에 대한 집착 등과 같이 특정한 '중독 행동'을 보이기도 하죠.`,
      typography_25: `예를 들면 이렇게요.`,
      typography_25_1: `폭식을 하며 입시에 대한 불안감을 제어하려고 함`,
      typography_25_2: `성적인 행동에 집착하며 외로움을 해소하려고 함`,
      typography_26: `나는 부정적인 생각과 감정을 통제하기 위해 어떤 노력들을 했나요?`,
      typography_27: `좋아요. 이번에도 그러한 노력이 얼마나 효과적이었는지 적어볼까요?`,
      typography_28: `그러한 노력으로 나는 괴로움으로부터 충분히 자유로워졌나요?`,
      typography_29: `회피하기`,
      typography_30: `마지막으로 우리는 문제 해결을 위해 가장 쉬운 방법을 떠올리기도 하는데요.`,
      typography_31: `회피하는 것이지요.`,
      typography_32: `많은 사람들은 부정적인 생각과 감정을 불러일으키는 상황을 피하곤 해요.`,
      typography_33: `예를 들면 이렇게요.`,
      typography_33_1: `어렸을 때 미술을 전공하지 못했어서 그 후 전시회나 미술 작품은 쳐다보지도 않음`,
      typography_33_2: `상사에게 나의 부족함을 들킬 것만 같아 부장님과 함께 있는 상황을 최대한 피함`,
      typography_34: `나는 부정적인 생각과 감정을 회피하기 위해 어떤 노력들을 했나요?`,
      typography_35: `좋아요. 이번에도 그러한 노력이 얼마나 효과적이었는지 적어볼까요?`,
      typography_36: `그러한 노력으로 나는 괴로움으로부터 충분히 자유로워졌나요?`,
      typography_37: `지난 노력을 돌아보며`,
      typography_38: `좋습니다. 요약해볼까요?`,
      typography_39: `나의 생각과 감정을 이해하고자 했던 노력과 그 결과예요.`,
      typography_40: `제거하고자 했던 노력과 그 결과예요.`,
      typography_41: `통제하고자 했던 노력과 그 결과예요.`,
      typography_42: `회피하고자 했던 노력과 그 결과예요.`,
      typography_43: `지금까지 나의 노력들을 떠올려보면 어떤 생각이 드나요?`,
      typography_44: `앞으로 비슷한 노력들을 지속하면 나는 괴로움으로부터 충분히 자유로워질 수 있을까요?`,
      typography_45: `그 과정에서 나의 삶은 내가 원하는 방향으로 나아갈 수 있을까요?`,
      typography_46: `이렇게 과거를 돌아보면 흔히 “애썼다", “시간을 허비했다", “바보 같았다"와 같이 생각하기도 하는데요.`,
      typography_47: `사실 이 노력들은 모두 자연스러운 방법이에요.`,
      typography_48: `우리가 어리석고 부족하기 때문에 위와 같이 노력하고 행동한 게 아니랍니다.`,
      typography_49: `그러한 노력들은 현재 삶의 맥락이나 상황에서 충분히 그럴 만하고 이해할 수 있는 반응이었어요.`,
      typography_50: `누구든 비슷한 경험들을 해오고, 비슷한 상황 속에 놓였다면, 위와 같이 노력했을 거예요.`,
      typography_51: `그 시간이 잘못된 것도, 낭비였던 것도 아니랍니다.`,
      typography_52: `다만 이제는 우리가 경험한 것, 그리고 디스턴싱을 통해 배운 것을 종합하여 다음 단계로 나아갈 때입니다.`,
      typography_53: `이제 이런 내면의 문제를 대하는 효과적인 방법에 대해서 알아볼게요.`,
    },
  },
};
