import { Button, Option, Select, Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import useFetch from "hooks/useFetch";
import { useNavigate } from "react-router-dom";

export default function ParticipationInfo() {
  const navigate = useNavigate();
  const jobList = [
    "R&D",
    "영업 마케팅",
    "기획",
    "홍보",
    "인사",
    "재무",
    "기타",
  ];
  const companyData = [
    { corporation: "LG전자", jobList: jobList },
    { corporation: "LG디스플레이" },
    { corporation: "LG이노텍" },
    { corporation: "LG화학" },
    { corporation: "LG에너지솔루션" },
    { corporation: "LG생활건강" },
    { corporation: "LG유플러스" },
    { corporation: "LG헬로비전" },
    { corporation: "HS애드" },
    { corporation: "LG CNS" },
    { corporation: "LG경영개발원" },
    { corporation: "LG스포츠" },

    { corporation: "삼성전자" },
    { corporation: "삼성생명보험" },
    { corporation: "삼성디스플레이" },
    { corporation: "삼성물산" },
    { corporation: "삼성화재해상보험" },
    { corporation: "삼성SDI" },
    { corporation: "삼성증권" },
    { corporation: "삼성전기" },
    { corporation: "삼성엔지니어링" },
    { corporation: "삼성중공업" },

    { corporation: "기타" },
  ];
  const [corporation, setCorporation] = useState<string | undefined>(undefined);
  const [job, setJob] = useState<string | undefined>(undefined);

  const [submitResult, submit] = useFetch({
    url: `/user/participation-info/submit`,
    requestBody: JSON.stringify({
      type: !corporation ? "individual" : "corporate",
      ...(corporation && { corporation: corporation }),
      ...(job && { job: job }),
    }),
  });
  useEffect(() => {
    if (submitResult) {
      navigate("/onboarding/2");
    }
  }, [submitResult]);

  return (
    <Stack
      spacing={5}
      justifyContent={"center"}
      alignItems="center"
      sx={{ width: "100%" }}
    >
      <Stack
        spacing={2}
        justifyContent={"center"}
        // alignItems="center"
        sx={{ width: "100%" }}
      >
        <Typography level="h3">기관의 지원을 받아 참여하나요?</Typography>
        <Stack
          spacing={1}
          justifyContent={"center"}
          alignItems="center"
          sx={{ width: "100%" }}
        >
          {[
            {
              placeholder: "기관 선택",
              data: companyData.map(({ corporation }) => corporation),
            },
            {
              placeholder: "직군 선택",
              data:
                companyData.find((each) => each.corporation === corporation)
                  ?.jobList || jobList,
            },
          ].map(({ placeholder, data }) =>
            data ? (
              <Select
                key={placeholder}
                placeholder={placeholder}
                sx={{ width: "100%" }}
                onChange={(e) => {
                  const selected = (e?.target as HTMLElement).innerText;
                  if (placeholder === "기관 선택") {
                    setCorporation(selected);
                  } else {
                    setJob(selected);
                  }
                }}
              >
                {data.map((each) => (
                  <Option key={each} value={each} sx={{ width: "100%" }}>
                    {each}
                  </Option>
                ))}
              </Select>
            ) : null
          )}
        </Stack>
      </Stack>
      <Stack spacing={1} sx={{ width: "100%" }}>
        <Button
          sx={{ width: "100%" }}
          disabled={!(corporation && job)}
          onClick={submit}
        >
          다음
        </Button>
        <Button variant="plain" onClick={submit}>
          지원 없이 개인으로 참여해요
        </Button>
      </Stack>
    </Stack>
  );
}
