import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithImage,
  InstructionWithTextarea,
} from "../BlockComponent";

import { AutomaticThoughtTemplate, LastCard } from "./common";

export const Content_4_1_A = [
  InstructionWithImage(`typography_0_0_0`, "0"),
  Instruction(`typography_1_0_0`),
  Instruction(`typography_2_0_0`),
  InstructionWithImage(`typography_3_0_0`, "1"),
  Instruction(`typography_4_0_0`),
  Instruction(`typography_5_0_0`),
  InstructionWithImage(`typography_6_0_0`, "2"),
  Instruction(`typography_7_0_0`),
  InstructionWithImage(`typography_8_0_0`, "3"),
  Instruction(`typography_9_0_0`),
  Instruction(`typography_10_0_0`),

  Header1(`typography_11_0_0`),
  Instruction(`typography_12_0_0`),
  Instruction(`typography_13_0_0`),
  Instruction(`typography_14_0_0`),
  Instruction(`typography_15_0_0`),
  Instruction(`typography_16_0_0`),
  InstructionWithContent(`typography_17_0_0`, AutomaticThoughtTemplate("1")),
  Instruction(`typography_18_0_0`),
  Instruction(`typography_19_0_0`),
  Instruction(`typography_20_0_0`),
  Instruction(`typography_21_0_0`),
  InstructionWithTextarea("typography_22_0_0", { id: "별명" }),
  InstructionWithTextarea("typography_23_0_0"),
  InstructionWithTextarea("typography_24_0_0"),
  InstructionWithTextarea("typography_25_0_0"),
  InstructionWithTextarea("typography_26_0_0"),
  InstructionWithTextarea("typography_27_0_0"),
  InstructionWithTextarea("typography_28_0_0"),
  InstructionWithTextarea("typography_29_0_0"),
  Instruction(`typography_30_0_0`),
  InstructionWithTextarea("typography_31_0_0"),
  Instruction(`typography_32_0_0`),
  Instruction(`typography_33_0_0`),

  Header1(`typography_34_0_0`),
  Instruction(`typography_35_0_0`),
  Instruction(`typography_36_0_0`),
  Instruction(`typography_37_0_0`),
  Instruction(`typography_38_0_0`),
  // InstructionWithImage(`typography_39_0_0`, "4"),
  // InstructionWithImage(`typography_40_0_0`, "5"),
  Instruction(`typography_39`),
  Instruction(`typography_41_0_0`),
  Instruction(`typography_42_0_0`),

  Header1(`typography_43_0_0`),
  InstructionWithImage(`typography_44_0_0`, "6"),
  InstructionWithImage(`typography_45_0_0`, "7"),
  Instruction(`typography_46_0_0`),
  Instruction(`typography_47_0_0`),
  Instruction(`typography_48_0_0`),
  Instruction(`typography_49_0_0`),

  ...LastCard,
];
