import { Stack, Textarea, Typography } from "@mui/joy";
import Link from "components/common/Link";
import ButtonWithModal from "components/common/ButtonWithModal";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import ButtonWithOptionalModal from "components/common/ButtonWithOptionalModal";
import { useState } from "react";

export default function SavePresetWithReasonButton({
  initialValue,
  value,
  updateAction,
  modificationReason,
  setModificationReason,
  isRequireModificationReason,
}: {
  initialValue?: string;
  value?: string;
  updateAction: () => void;
  modificationReason: string;
  setModificationReason: React.Dispatch<React.SetStateAction<string>>;
  isRequireModificationReason?: boolean;
}) {
  const user = useRecoilValue(userAtom);
  const isCoach = user?.accessToken && user?.role !== "patient";

  const [openModal, setOpenModal] = useState<boolean>(false);
  const resetModalState = () => {
    setOpenModal(false);
  };

  return isCoach ? (
    <Stack spacing={0.5} alignItems="end">
      <ButtonWithOptionalModal
        openModal={openModal}
        buttonText=""
        render={<Link text="저장" opacity={1} textColor="primary.solid" />}
        onClick={() => {
          if (isRequireModificationReason) {
            setOpenModal(true);
          } else {
            updateAction();
          }
        }}
        content={
          <>
            <Stack>
              {(initialValue || "").length > 0 && (
                <>
                  <Typography sx={{ opacity: 0.5 }} level="body-xs">
                    AI가 추천한 문구
                  </Typography>
                  <Typography level="body-md" sx={{ mb: "10px" }}>
                    {initialValue}
                  </Typography>
                </>
              )}
              <Typography sx={{ opacity: 0.5 }} level="body-xs">
                코치가 수정한 문구
              </Typography>
              <Typography level="body-md">{value}</Typography>
            </Stack>
            <Textarea
              value={modificationReason}
              onChange={(e) => {
                setModificationReason(e.target.value);
              }}
              sx={{
                borderColor: "background.level3",
                borderWidth: "1px",
                "--joy-focus-thickness": "0px",
                boxShadow: "0px 0px 0px transparent",
              }}
              placeholder={"(수정 이유를 적어주세요)"}
            ></Textarea>
          </>
        }
        actionText={"저장하기"}
        action={updateAction}
      />
    </Stack>
  ) : (
    <></>
  );
}
