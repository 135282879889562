import Box from "@mui/joy/Box";
import List from "@mui/joy/List";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { CircularProgress, ListDivider, Stack } from "@mui/joy";
import {
  patientApi,
  PatientType,
  PATIENT_DOMAIN,
  PATIENT_LIST_ENDPOINT,
} from "api/patientApi";
import { useRecoilValue } from "recoil";
import CoachFilter from "./CoachFilter";
import { userAtom } from "recoil/userAtom";
import * as _ from "lodash";
import PatientSubList from "./PatientSubList";
import { axiosInstance } from "api";
import { patientListMenuKey } from "recoil/menuAtom";
import { SidebarMenuType } from "components/sidebar/SideBar";

export default function PatientList({
  setCurrentMenu,
}: {
  setCurrentMenu?: React.Dispatch<React.SetStateAction<SidebarMenuType>>;
}) {
  const user = useRecoilValue(userAtom);

  const [coachList, setCoachList] = useState<undefined | string[]>();
  const [selectedCoach, setSelectedCoach] = useState<string | undefined>(
    undefined
  );

  // const mock: PatientType[] = Array.from({ length: 20 }, (v, i) => ({
  //   patientId: "129",
  //   nickname: "ss",
  //   isTaskUpdateRequired: false,
  //   isRemindRequired: false,
  //   dayNumber: 10,
  //   doneAt: "2023-07-19",
  //   profileImageUrl: "",
  //   coachName: "세원",
  // }));

  const { isLoading } = useQuery(
    [PATIENT_DOMAIN, PATIENT_LIST_ENDPOINT],
    () => patientApi.getPatientList(),
    {
      enabled: !!axiosInstance.defaults.headers["X-Auth-Token"],
      refetchInterval: 10000,
      onSuccess: (data) => {
        if (data && data.result) {
          const list = data.result;
          const coachSet = new Set(list.map((each) => each.coachName || ""));
          const coachArray = Array.from(coachSet);
          setCoachList([...coachArray]);
          const currentList = list.sort(function (a, b) {
            return (a.nickname || "") > (b.nickname || "") ? 1 : -1;
          });
          const now = new Date();

          const humanCheckList = currentList
            .filter((element) => element.isHumanCheckRequired)
            .sort(function (a, b) {
              return +new Date(a.doneAt || now) - +new Date(b.doneAt || now);
            });

          const riskyList = currentList
            .filter((element) => element.isRiskCheckRequired)
            .sort(function (a, b) {
              return +new Date(a.doneAt || now) - +new Date(b.doneAt || now);
            });
          const chatList = currentList
            .filter((element) => (element.unreadMessageCount || 0) > 0)
            .sort(function (a, b) {
              return +new Date(a.doneAt || now) - +new Date(b.doneAt || now);
            });

          const updateList = currentList
            .filter(
              (element) =>
                element.isTaskUpdateRequired &&
                !element.isHumanCheckRequired &&
                !element.isRiskCheckRequired &&
                !((element.unreadMessageCount || 0) > 0) &&
                !element.isInAutoProcessingStage
            )
            .sort(function (a, b) {
              return +new Date(a.doneAt || now) - +new Date(b.doneAt || now);
            })
            .sort(function (a, b) {
              // a가 새로운 아이템이고 b가 아닌 경우, a를 앞에 위치시킴
              if (a.isNew && !b.isNew) {
                return -1;
              }

              // b가 새로운 아이템이고 a가 아닌 경우, b를 앞에 위치시킴
              if (b.isNew && !a.isNew) {
                return 1;
              }

              // 둘 다 새로운 아이템이거나 둘 다 아닌 경우, 순서를 변경하지 않음
              return 0;
            });
          const remindList = currentList
            .filter((element) => element.isRemindRequired)
            .sort(function (a, b) {
              return +new Date(a.doneAt || now) - +new Date(b.doneAt || now);
            });

          setTotalListData([
            {
              listTitle: "HUMAN CHECK 필요",
              patientList: humanCheckList,
              menuKey: "patientList_humanCheckRequired",
            },
            {
              listTitle: "위험 체크 필요",
              patientList: riskyList,
              menuKey: "patientList_isRiskCheckRequired",
            },
            {
              listTitle: "채팅 확인 필요",
              patientList: chatList,
              menuKey: "patientList_isChatCheckRequired",
            },
            {
              listTitle: "업데이트 필요",
              patientList: updateList,
              menuKey: "patientList_taskOpenRequired",
            },
            {
              listTitle: "리마인드 필요",
              patientList: remindList,
              menuKey: "patientList_isRemindRequired",
            },
            {
              listTitle: "전체",
              patientList: currentList,
              menuKey: "patientList_all",
            },
          ]);
        }
      },
    }
  );

  const [totalListData, setTotalListData] = useState<
    | {
        listTitle: string;
        patientList: PatientType[];
        menuKey: patientListMenuKey;
      }[]
    | undefined
  >();
  const [listData, setListData] = useState<
    | {
        listTitle: string;
        patientList: PatientType[];
        menuKey: patientListMenuKey;
      }[]
    | undefined
  >();

  useEffect(() => {
    if (totalListData) {
      if (selectedCoach) {
        setListData(
          totalListData?.map((each) => ({
            ...each,
            patientList: each.patientList.filter((element) =>
              !selectedCoach ? true : element.coachName === selectedCoach
            ),
          }))
        );
      } else {
        setListData(totalListData);
      }
    }
  }, [selectedCoach, totalListData]);

  const [selected, setSelected] = useState<string | undefined>();

  useEffect(() => {
    if (selected && listData) {
      const selectedTitle = selected.split("_")[0];
      const selectedPid = selected.split("_")[1];
      if (
        !listData
          .find((element) => element.listTitle === selectedTitle)
          ?.patientList.map((each) => each.patientId)
          .includes(selectedPid)
      ) {
        setSelected(`전체_${selectedPid}`);
      }
    }
  }, [listData]);

  return isLoading ? (
    <Sheet sx={{ backgroundColor: "transparent", height: "100%" }}>
      <Stack
        alignItems={"center"}
        justifyContent="center"
        sx={{ height: "100%" }}
      >
        <CircularProgress color="neutral" />
      </Stack>
    </Sheet>
  ) : (
    <Sheet sx={{ backgroundColor: "transparent" }}>
      <Box
        sx={{
          height: "var(--StickyHeader-height)",
          position: "sticky",
          top: 0,
          display: "flex",
          alignSelf: "center",
          backgroundColor: "background.level1",
          borderBottom: "1px solid",
          borderColor: "background.level3",
          zIndex: 10,
        }}
      >
        <Stack direction={"row"} alignItems="center">
          {user?.role === "admin" && coachList ? (
            <Box sx={{ p: 0, mr: 1, height: "32px" }}>
              <CoachFilter
                coachList={coachList || []}
                selectedCoach={selectedCoach}
                setSelectedCoach={setSelectedCoach}
              />
            </Box>
          ) : (
            <Typography level="title-lg">나</Typography>
          )}
          <Typography level="title-lg">의 내담자</Typography>
        </Stack>
      </Box>

      {listData && (
        <Box
          sx={{
            minHeight: 0,
            overflow: "visible",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <List
            sx={{
              "--ListItem-radius": "8px",
            }}
          >
            {listData.map(
              ({ menuKey, listTitle, patientList }, index) =>
                patientList &&
                patientList.length > 0 && (
                  <Box key={listTitle}>
                    <PatientSubList
                      menuKey={menuKey}
                      title={listTitle}
                      patientList={patientList}
                      selected={selected}
                      setSelected={setSelected}
                      setCurrentMenu={setCurrentMenu}
                    />
                    {index < listData.length - 1 && (
                      <ListDivider
                        sx={{
                          backgroundColor: "background.level3",
                        }}
                      />
                    )}
                  </Box>
                )
            )}
          </List>
        </Box>
      )}
    </Sheet>
  );
}
