import { atom, selectorFamily } from "recoil";

export type menuKey =
  | "taskList_open"
  | "taskList_close"
  | "thoughtRecordList_open"
  | "thoughtRecordList_close"
  | "meditationList_open"
  | "meditationList_close"
  | patientListMenuKey
  | "progress";

export type patientListMenuKey =
  | "patientList_humanCheckRequired"
  | "patientList_isRiskCheckRequired"
  | "patientList_isChatCheckRequired"
  | "patientList_taskOpenRequired"
  | "patientList_isRemindRequired"
  | "patientList_all";

export const alwaysOpenPatientListMenyKey: patientListMenuKey[] = [
  "patientList_humanCheckRequired",
  "patientList_isRiskCheckRequired",
  "patientList_isChatCheckRequired",
  "patientList_taskOpenRequired",
];

type MenuState = {
  [key in menuKey]?: boolean;
};

export const menuAtom = atom<MenuState>({
  key: "menuState",
  default: {
    taskList_open: true,
    taskList_close: false,
    thoughtRecordList_open: true,
    thoughtRecordList_close: true,
    meditationList_open: true,
    meditationList_close: true,
    patientList_humanCheckRequired: true,
    patientList_isRiskCheckRequired: true,
    patientList_isChatCheckRequired: true,
    patientList_taskOpenRequired: true,
    patientList_isRemindRequired: true,
    patientList_all: true,
    progress: true,
  },
  effects: [
    ({ setSelf, onSet }) => {
      const savedData = localStorage.getItem("menuState");
      // setSelf: atom 값을 설정 혹은 재설정
      if (savedData) setSelf(JSON.parse(savedData));

      // atom이 변화가 감지될 때 작동, Storage에 데이터 저장
      // setSelf에 의해서는 작동하지 않음
      onSet((newValue, _, isReset) => {
        isReset
          ? localStorage.removeItem("menuState")
          : localStorage.setItem("menuState", JSON.stringify(newValue));
      });
    },
  ],
});

export const menuSelector = selectorFamily<boolean | undefined, menuKey>({
  key: "menuStateSelector",
  get:
    (params: menuKey) =>
    ({ get }) =>
      get(menuAtom)[params],
  set:
    (params) =>
    ({ get, set }, newValue) => {
      set(menuAtom, { ...get(menuAtom), [params]: newValue });
    },
});
