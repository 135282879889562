import { ProgramContentType } from "data/BlockComponent";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import {
  CellType,
  ElementType,
  PieChartType,
  SingleSelectionType,
} from "data/CellComponent";
import { arraySum } from "logic/logics";
import { useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import { Box } from "@mui/joy";
import {
  blueberryTwilightPalette,
  mangoFusionPalette,
  cheerfulFiestaPalette,
} from "@mui/x-charts/colorPalettes";
import { theme } from "styles/theme";
import { useTranslation } from "react-i18next";

export default function PieChartCell({
  data,
  setData,
  lineIndex,
  cellIndex,
  blockIndex,
  pieChartId,
}: {
  data: ProgramContentType[];
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
  blockIndex: number;
  pieChartId: string;
}) {
  const { t: t_common } = useTranslation("translation", {
    keyPrefix: "task.activity.common",
  });

  function groupByLabel(
    objectArray: {
      label: string;
      labelTranslationKey?: string;
      value: string;
    }[]
  ) {
    return objectArray.reduce(function (acc: any, obj) {
      var key = obj["label"];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj.value);
      return acc;
    }, {});
  }
  function groupByLabelTranslationKey(
    objectArray: {
      label: string;
      labelTranslationKey: string;
      value: string;
    }[]
  ) {
    return objectArray.reduce(function (acc: any, obj) {
      var key = obj["labelTranslationKey"];
      if (key?.length > 0) {
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj.value);
        return acc;
      }
    }, {});
  }

  function getPieChartData(data: ProgramContentType[], pieChartId: string) {
    const cellList: CellType[][][] = data?.map(
      (each) => each.lines
    ) as CellType[][][];

    const tempData: { [key: string]: { [key: string]: string } } = {};

    cellList
      .flat(2)
      .map((each) => each.content)
      .forEach((element) => {
        if ((element as ElementType).pieChartDataId) {
          const pieChartDataIdList = (
            element as ElementType
          ).pieChartDataId?.split("/");

          pieChartDataIdList?.forEach((pieChartDataId) => {
            if (pieChartDataId?.includes(`${pieChartId}`)) {
              const dataType = pieChartDataId.split(`_`)[1];
              const id = pieChartDataId.split(`_`)[2];

              if (dataType && id) {
                if (!tempData[id]) {
                  tempData[id] = {};
                }

                tempData[id][dataType] = element?.value as string;

                const singleSelection = element as SingleSelectionType;
                if (
                  singleSelection.options !== undefined &&
                  singleSelection.selectedIndex !== undefined
                ) {
                  tempData[id]["labelTranslationKey"] =
                    singleSelection.options[singleSelection.selectedIndex]
                      .translationKey || "";
                }

                tempData[id]["id"] = element?.requiredId as string;
              }
            }
          });
        }
      });

    const rawData = Object.values(tempData).filter(
      (element) => !!element.label
    );

    const groupedData = groupByLabel(
      rawData as {
        label: string;
        labelTranslationKey?: string;
        value: string;
        id: string;
      }[]
    );
    const groupedDataWithTranslationKey = groupByLabelTranslationKey(
      rawData as {
        label: string;
        labelTranslationKey: string;
        value: string;
        id: string;
      }[]
    );

    const pieChartData = Object.entries(groupedData)
      .map(([key, value], index) => ({
        id: `${index}`,
        label: key.length > 15 ? `${key.substring(0, 15)}...` : key,
        ...(groupedDataWithTranslationKey && {
          labelTranslationKey: Object.keys(groupedDataWithTranslationKey)[
            index
          ],
          label: t_common(Object.keys(groupedDataWithTranslationKey)[index]),
        }),
        value: arraySum((value as string[]).map((each) => parseInt(each))),
      }))
      .sort((a, b) => {
        if (a.label === "통제 불가능") return -1;
        if (b.label === "통제 불가능") return 1;
        return 0;
      });

    return { pieChartData };
  }

  const { pieChartData } = getPieChartData(data, pieChartId);

  useEffect(() => {
    if (
      data &&
      data.length > 0 &&
      !_.isEqual(
        (data[blockIndex].lines[lineIndex][cellIndex].content as PieChartType)
          ?.data,
        pieChartData
      )
    ) {
      setData((data) => {
        if (data) {
          const data_temp = _.cloneDeep(data);
          const currentContent = data_temp[blockIndex].lines[lineIndex][
            cellIndex
          ].content as PieChartType;
          data_temp[blockIndex].lines[lineIndex][cellIndex].content = {
            ...currentContent,
            data: pieChartData,
          };

          pieChartData.forEach((each) => {
            const indexofLabel = data_temp[blockIndex].lines.findIndex(
              (element) =>
                element[0].content?.id ===
                `${pieChartId}_${each.labelTranslationKey || each.label}`
            );

            if (indexofLabel === -1) {
              data_temp[blockIndex].lines.push([
                {
                  type: "temp",
                  content: {
                    id: `${pieChartId}_${
                      each.labelTranslationKey || each.label
                    }`,
                    value: `${each.value}`,
                  },
                },
              ]);
            } else {
              data_temp[blockIndex].lines.splice(indexofLabel, 1, [
                {
                  type: "temp",
                  content: {
                    id: `${pieChartId}_${
                      each.labelTranslationKey || each.label
                    }`,
                    value: `${each.value}`,
                  },
                },
              ]);
            }
          });
          return data_temp;
        }
        return data;
      });
    }
  }, [data]);

  const containerRef = useRef<HTMLElement>(null);
  const [legendHeight, setLegendHeight] = useState<number>(0);

  const handleResize = () => {
    var chartContainer = document.getElementById(pieChartId);
    const containerWidth = containerRef.current?.offsetWidth || 0;

    var chart = chartContainer?.childNodes[0]?.childNodes[0] as HTMLElement;
    var chart2 = chartContainer?.childNodes[0] as HTMLElement;
    var legend = chart?.childNodes[3] as HTMLElement;
    if (containerWidth < 500) {
      if (chart) {
        chart.style.overflow = "visible";
      }
      if (chart2) {
        chart2.style.overflow = "visible";
      }

      var legendWidth = 0;
      if (legend) {
        const legendHeight_temp = legend.getBoundingClientRect().height;

        setLegendHeight(legendHeight_temp);
        legendWidth = legend.getBoundingClientRect().width;

        legend.style.transform = `translate(${
          -(containerWidth - legendWidth) / 2
        }px,${125 + legendHeight_temp / 2}px)`;

        legend.style.transform = `translate(${0}px,${
          125 + legendHeight_temp / 2
        }px)`;
      }
    } else {
      if (chart) {
        chart.style.overflow = "hidden";
      }
      if (chart2) {
        chart2.style.overflow = "hidden";
      }

      if (legend) {
        legend.style.transform = `translate(0,0)`;
      }
      setLegendHeight(0);
    }
  };

  useEffect(() => {
    handleResize();
    setTimeout(() => {
      handleResize();
    }, 100);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      id={pieChartId}
      ref={containerRef}
      sx={{ height: `${250 + legendHeight}px` }}
    >
      <PieChart
        margin={{
          left: 0,
          right: (containerRef.current?.offsetWidth || 0) > 500 ? 100 : 0,
          // right: 100,
        }}
        // colors={mangoFusionPalette}

        colors={[
          theme.vars.palette.primary.solid,
          // theme.vars.palette.neutral[100],
          theme.vars.palette.neutral[200],
          theme.vars.palette.neutral[300],
          theme.vars.palette.neutral[400],
          theme.vars.palette.neutral[500],
          // theme.vars.palette.neutral[600],
          theme.vars.palette.neutral[700],
          // theme.vars.palette.neutral[800],
          // theme.vars.palette.neutral[900],
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: "white",
            fontWeight: "bold",
          },
        }}
        series={[
          {
            // arcLabel: (item) => `${item.label} (${item.value})`,
            // arcLabelMinAngle: 0,
            data: pieChartData,
          },
        ]}
        height={250}
      />
    </Box>
  );
}
