import { useState } from "react";
import { Button, Option, Select, Stack } from "@mui/joy";
import TitleWithSubtitle from "components/common/TitleWithSubtitle";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/paths";

export const institutionData = [
  { name: "서울대학교", domain: "snu.ac.kr" },
  { name: "카이스트", domain: "kaist.ac.kr" },
  { name: "오웰헬스", domain: "orwellhealth.org" },
];

export default function InstitutionSelectPage() {
  let navigate = useNavigate();
  const [institution, setInstitution] = useState<string>();

  return (
    <Stack
      direction={"column"}
      alignItems="center"
      justifyContent={"center"}
      sx={{
        px: "20px",
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "calc(var(--vh,1vh) * 100)",
        minHeight: "calc(var(--vh,1vh) * 100)",
        backgroundColor: "background.level1",
      }}
    >
      <Stack spacing={3} sx={{ maxWidth: "400px", width: "100%" }}>
        <TitleWithSubtitle
          title={"소속 학교/기관을 선택해주세요"}
          sx={{ width: "100%", textAlign: "center" }}
        />
        <Select
          className="w-full"
          value={institution}
          onChange={(e) => {
            const selected = (e?.target as HTMLElement)?.innerText;
            setInstitution(selected);
          }}
        >
          {institutionData.map(({ name }, index) => (
            <Option key={name} value={name}>
              {name}
            </Option>
          ))}
        </Select>
        <Stack>
          <Button
            size="lg"
            disabled={!institution}
            onClick={() => {
              navigate(
                `${PATH.emailAuth}?type=${institutionData.findIndex(
                  (element) => element.name === institution
                )}`
              );
            }}
          >
            다음
          </Button>
          <Button
            onClick={() => {
              navigate(PATH.emailAuth);
            }}
            variant={"plain"}
            size="lg"
          >
            {"이미 계정이 있어요"}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
