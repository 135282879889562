import { IconButton, Stack, SvgIcon } from "@mui/joy";
import { NextArrowSvg } from "static/image/common/SvgAssets";

export default function NextButton({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: () => void;
}) {
  return (
    <Stack direction={"row"} justifyContent="end">
      <IconButton disabled={disabled} onClick={onClick}>
        <SvgIcon
          sx={{ width: "14px", height: "14px" }}
          {...(disabled && { opacity: 0.3 })}
          inheritViewBox
        >
          <NextArrowSvg />
        </SvgIcon>
      </IconButton>
    </Stack>
  );
}
