import { ButtonGroup } from "data/CellComponent";
import {
  Instruction,
  InstructionWithContent,
  InstructionWithTextarea,
  InstructionWithImage,
} from "../BlockComponent";
import { LastCardWithTextarea } from "./common";

// 행동활성화랑 맞춤 (v6)
export const Content_0_0_A = (isAiCoach?: boolean) => {
  return [
    Instruction("typography_0_0_0"),
    InstructionWithImage("typography_1_0_0", "v3_0"),
    InstructionWithImage("typography_2_0_0", "v3_1"),
    Instruction("typography_3_0_0"),
    Instruction("typography_4_0_0"),
    InstructionWithImage("typography_5_0_0", "v3_2"),
    Instruction("typography_6_0_0"),
    Instruction("typography_7_0_0"),
    InstructionWithImage("typography_8_0_0", "v3_3"),
    Instruction("typography_9_0_0"),
    Instruction("typography_10_0_0"),
    InstructionWithImage("typography_11_0_0", "v3_4"),
    Instruction("typography_12_0_0"),
    Instruction("typography_13_0_0"),
    Instruction("typography_14_0_0"),
    Instruction("typography_15_0_0"),
    InstructionWithImage("typography_16_0_0", "v3_5"),
    Instruction("typography_17_0_0"),
    InstructionWithImage("typography_18_0_0", "v3_6"),
    InstructionWithImage("typography_19_0_0", "v3_7"),

    Instruction("typography_20_0_0"),
    Instruction("typography_21_0_0"),
    Instruction("typography_22_0_0"),
    Instruction("typography_23_0_0"),

    InstructionWithTextarea("typography_24_0_0", { id: "nickname" }),

    InstructionWithContent("typography_25_0_0", [
      [
        ButtonGroup(
          [
            {
              translationKey: "트위터",
            },
            {
              translationKey: "스레드",
            },
            {
              translationKey: "네이버카페/블로그",
            },
            {
              translationKey: "인스타그램",
            },
            {
              translationKey: "지인 추천",
            },
            {
              translationKey: "기타",
              subjectiveOption: {
                // optional: true,
                id: "acquisition_other",
                placeholder: "어떻게 알고 오셨는지 알려주세요",
              },
            },
          ],
          { id: "acquisition" }
        ),
      ],
    ]),
    Instruction("typography_26_0_0"),
    // ...LastCard,
    ...LastCardWithTextarea,
  ];
};
