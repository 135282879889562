import { Box, Option, Select, Sheet, Typography } from "@mui/joy";
import { useQuery } from "@tanstack/react-query";
import {
  thoughtRecordApi,
  ThoughtRecordType,
  THOUGHTRECORD_DOMAIN,
  THOUGHTRECORD_LIST_ENDPOINT,
} from "api/thoughtRecordApi";
import { DateHeader, ProgramContentType } from "data/BlockComponent";
import * as _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { userAtom } from "recoil/userAtom";

export default function SelectThoughtRecord({
  setThoughtRecordKey,
}: {
  setThoughtRecordKey: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const patientState = useRecoilValue(patientAtom);
  const user = useRecoilValue(userAtom);

  const queryFn = useCallback(
    () => thoughtRecordApi.getList(`${patientState?.patientId}`),
    [patientState]
  );

  useQuery([THOUGHTRECORD_DOMAIN, THOUGHTRECORD_LIST_ENDPOINT], queryFn, {
    enabled: !!patientState?.patientId,
    onSuccess: (data) => {
      if (!data.result || data.result.length === 0) {
      } else {
        const list = data.result;
        list.sort((a, b) => {
          return +new Date(b.date || "") - +new Date(a.date || "");
        });

        setListData(list.filter((element) => element.situation));
      }
    },
  });

  const [listData, setListData] = useState<ThoughtRecordType[]>();

  const handleChange = async (selectedIndex: number) => {
    if (selectedIndex !== null && listData) {
      const selectedThoughtRecordKey = listData[selectedIndex].thoughtRecordKey;
      setThoughtRecordKey(selectedThoughtRecordKey);
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(500);

  useEffect(() => {
    if (containerRef.current && containerRef.current.offsetWidth > 0) {
      setWidth(containerRef.current?.offsetWidth);
    }
  }, []);

  return (
    <Box ref={containerRef} sx={{ width: "100%" }}>
      <Select
        className="w-full flex"
        onChange={(e, index) => {
          handleChange(index as number);
        }}
        sx={{
          width: "100%",
        }}
      >
        {listData?.map((option, index) => (
          <Option
            key={`${option.thoughtRecordKey}`}
            value={index}
            sx={{
              width: width,
            }}
          >
            <Typography
              sx={{
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
            >{`${option.date}/${option.situation}`}</Typography>
          </Option>
        ))}
      </Select>
    </Box>
  );
}
