import { axiosInstance, CustomResponseFormat } from "api";
import { Content_104_1_A } from "data/task/104-1-A";

export default async function get_104_1_A_RawData(
  taskId: string,
  patientId: string,
  isPushNotificationGranted: boolean
) {
  try {
    const response = (await axiosInstance.get("/activity/detail", {
      params: { tk: `${taskId}:${patientId}` },
    })) as CustomResponseFormat;
    if (response.code === 0) {
      const data = response.result;

      const activityId = taskId.split("#")[1];
      const activityStepId = taskId.split("#")[2];
      return {
        taskId: `104-1-A#${activityId}#${activityStepId}`,
        content: Content_104_1_A(
          `${data.name || ""} - ${activityStepId}단계: ${data.step || ""}`,
          isPushNotificationGranted
        ),
      };
    } else {
    }
  } catch (error) {
    // 에러 핸들링
    console.error("Error in get_6_5_A_RawData:", error);
    throw error; // 또는 에러에 대한 처리를 진행할 수 있음
  }
}
