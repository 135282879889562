import { CircularProgress, Stack, Typography } from "@mui/joy";
import useIsInappWebview from "hooks/useIsInappWebview";
import useMixpanelWeb from "hooks/useMixpanelWeb";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import DefaultPage from "./DefaultPage2";
import { useTranslation } from "react-i18next";
import AppleLoginButton from "components/user/AppleLoginButton";
import GoogleLoginButton from "components/user/GoogleLoginButton";
import useDetectIOS from "hooks/useDetectIOS";
import { permanentDataAtom } from "recoil/permanentDataAtom";
import InstitutionStartButton from "components/user/InstitutionStartButton";
import character from "static/image/character/login_character.png";

export default function Login() {
  let navigate = useNavigate();
  const user = useRecoilValue(userAtom);
  const permanantDataState = useRecoilValue(permanentDataAtom);
  const { isIOS } = useDetectIOS();
  const isInappWebview = useIsInappWebview();

  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });

  const [searchParams] = useSearchParams();
  const { state } = useLocation();

  const isOnboarding = searchParams.get("type") === "1"; //신규모객

  useEffect(() => {
    if (!user || !user?.accessToken) {
      if (isOnboarding) {
        setMixPanel_new();
        navigate("/", { replace: true, state: { isOnboarding: true } });
      }
    } else {
      navigate("/", { ...state, replace: true });
    }
  }, [user]);

  const setMixPanel_new = useMixpanelWeb({
    eventName: "0-0-A로 바로 진입",
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const showBothLoginButton = isIOS
    ? (permanantDataState?.appVersion || "1.0.0") >= "1.0.12"
    : true;

  const imageContainer = useRef<HTMLDivElement>(null);
  const [imageContainerHeight, setImageContainerHeight] = useState<number>();
  useEffect(() => {
    if (!imageContainerHeight) {
      setImageContainerHeight(imageContainer.current?.offsetHeight);
    }
  }, []);
  console.log(imageContainerHeight);
  return (
    <DefaultPage
      content={
        !isLoading ? (
          <Stack
            spacing={"40px"}
            sx={{
              width: "100%",
              height: "100%",
              maxHeight: "900px",
              py: "24px",
            }}
            alignItems="center"
          >
            <Stack spacing={"8px"} sx={{ textAlign: "center" }}>
              <Typography sx={{ fontSize: "32px", fontWeight: "900" }}>
                {t_ui("생각과 거리두고\n가치로 나아가세요")}
              </Typography>
              <Typography sx={{ fontSize: "16px", opacity: 0.5 }}>
                {`인지심리학 기반의 멘탈 테라피, 디스턴싱\n효과적으로 마음 문제를 해결합니다.`}
              </Typography>
            </Stack>
            <Stack
              ref={imageContainer}
              sx={{
                flex: 1,
                maxWidth: "300px",
                overflow: "hidden",
              }}
            >
              {imageContainerHeight && (
                <img
                  src={character}
                  alt="empty"
                  style={{
                    height: `${imageContainerHeight}px`,
                    objectFit: "contain",
                  }}
                />
              )}
            </Stack>

            <Stack
              spacing={1}
              sx={{
                width: "100%",
                maxWidth: "500px",
              }}
              alignSelf="center"
            >
              {/* <NewcomerButton /> */}
              {/* <LoginButton /> */}
              {(!isInappWebview || isIOS || showBothLoginButton) && (
                <AppleLoginButton />
              )}
              {(!isInappWebview || !isIOS || showBothLoginButton) && (
                <GoogleLoginButton />
              )}
              {<InstitutionStartButton />}
            </Stack>
          </Stack>
        ) : (
          <Stack alignItems={"center"}>
            <CircularProgress color="neutral" />
          </Stack>
        )
      }
    />
  );
}
