import { ChartsXAxis, ResponsiveChartContainer } from "@mui/x-charts";
import {
  LinePlot,
  MarkPlot,
  MarkElement,
  MarkElementProps,
  LineElementProps,
  LineElement,
} from "@mui/x-charts/LineChart";
import { theme } from "styles/theme";
import { Box, Typography } from "@mui/joy";
import { Fragment } from "react";

export default function LineChart2({
  id,
  data,
  maxScore,
  abnormalScore,
}: {
  id: string;
  data: number[];
  maxScore: number;
  abnormalScore: number;
}) {
  const highlightColor = theme.vars.palette.primary.solid;
  const normalColor = theme.vars.palette.primary.deactivated;
  const CustomMark = ({
    color,
    props,
  }: {
    color?: string;
    props: MarkElementProps;
  }) => {
    const customStyle = { stroke: color, fill: color };
    return <MarkElement {...props} style={customStyle} />;
  };

  const GradientDefs = ({
    highlightList,
    highlightColor,
    normalColor,
    id,
  }: {
    highlightList: boolean[];
    highlightColor: string;
    normalColor: string;
    id: string;
  }) => {
    return (
      <defs>
        <linearGradient id={id}>
          {highlightList
            .filter((element, index) => index < highlightList.length - 1)
            .map((isHighlight, index) => (
              <Fragment key={`${id}_${index}_fragment`}>
                <stop
                  offset={`${Math.floor(
                    index * (100 / (highlightList.length - 1))
                  )}%`}
                  stopColor={isHighlight ? highlightColor : normalColor}
                />
                <stop
                  offset={`${Math.floor(
                    (index + 1) * (100 / (highlightList.length - 1))
                  )}%`}
                  stopColor={isHighlight ? highlightColor : normalColor}
                />
              </Fragment>
            ))}
        </linearGradient>
      </defs>
    );
  };
  const CustomLine = ({ props }: { props: LineElementProps }) => {
    const customStyle = {
      stroke:
        data.length === 2 && data[0] === data[1]
          ? data[0] < abnormalScore
            ? highlightColor
            : normalColor
          : `url(#lineGradient_${id})`,
      // stroke: "#ffffff",
    };
    return <LineElement {...props} style={customStyle} />;
  };

  return (
    <div style={{ position: "relative", height: 200 }}>
      <Box
        sx={{
          position: "absolute",
          width: "1px",
          height: "155px",
          top: "20px",
          backgroundColor: "text.positive",
          opacity: 0.1,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "1px",
          top: `${20 + 155 * (1 - abnormalScore / maxScore)}px`,
          backgroundColor: "text.positive",
          opacity: 0.1,
        }}
      />

      {[0, maxScore].map((each, index) => (
        <Typography
          key={`scoreLabel${each}`}
          level="body-xs"
          sx={{
            position: "absolute",
            ...(index === 1 ? { top: "20px" } : { bottom: "20px" }),
            right: 0,
            opacity: 0.5,
          }}
        >
          {each}
        </Typography>
      ))}

      <ResponsiveChartContainer
        margin={{ top: 30, left: 40, right: 40, bottom: 30 }}
        series={[
          {
            curve: "linear",
            type: "line",
            data: data,
          },
        ]}
        xAxis={[
          {
            scaleType: "point",
            data: data.map((each, index) => `w${index * 2}`),
            id: "bottom-axis-id",
          },
        ]}
        yAxis={[
          {
            min: 0,
            max: maxScore,
            scaleType: "linear",
            data: data,
            id: "right-axis-id",
          },
          {
            scaleType: "point",
            data: data.map((each) => ""),
            id: "left-axis-id",
          },
        ]}
        sx={{
          ".MuiMarkElement-root": {
            strokeWidth: 5,
            scale: "0.6",
          },
          ".MuiChartsAxis-root": {
            // transform: "translateY(0px)",
          },
        }}
        disableAxisListener
      >
        <GradientDefs
          highlightList={data.map(
            (each, index) =>
              index < data.length - 1 &&
              data[index] < abnormalScore &&
              data[index + 1] < abnormalScore
          )}
          highlightColor={highlightColor}
          normalColor={normalColor}
          id={`lineGradient_${id}`}
        />
        <LinePlot
          slots={{
            line: (props) => <CustomLine props={props} />,
          }}
        />
        <MarkPlot
          slots={{
            mark: (props) => (
              <CustomMark
                props={props}
                color={
                  data[props.dataIndex] < abnormalScore
                    ? highlightColor
                    : normalColor
                }
              />
            ),
          }}
        />
        <ChartsXAxis
          position="bottom"
          axisId="bottom-axis-id"
          disableLine
          disableTicks
          tickLabelStyle={{ opacity: 0.5 }}
        />
        {/* <ChartsReferenceLine y={10} lineStyle={{ opacity: 0.2 }} /> */}
        {/* <ChartsYAxis
          position="right"
          axisId="right-axis-id"
          disableLine
          disableTicks
        /> */}
        {/* <ChartsYAxis
          position="left"
          axisId="left-axis-id"
          disableTicks
          tickLabelStyle={{ fontSize: 0 }}
        /> */}
      </ResponsiveChartContainer>
    </div>
  );
}
