import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/joy/Card";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/joy/Button";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  taskApi,
  TaskType,
  TASK_DOMAIN,
  TASK_LISTALL_ENDPOINT,
  TASK_LIST_ENDPOINT,
  TASK_OPENLIST_SAVE_ENDPOINT,
} from "api";
import { useCallback } from "react";
import { getModuleNumber, getTaskIdPrefix } from "logic/logics";
import { IconButton } from "@mui/material";
import {
  Autocomplete,
  Box,
  ListItemButton,
  ListItemContent,
  Stack,
  Typography,
} from "@mui/joy";
import { patientAtom } from "recoil/patientAtom";
import { useRecoilValue } from "recoil";
import { DoneChip, RepeatChip } from "./TaskChip";
import * as _ from "lodash";
import useGetTaskTitleFromTaskKey from "hooks/useGetTaskTitleFromTaskKey";
import { ProgramProgressData } from "data/programData";
import useRefetchTaskList from "hooks/useRefetchTaskList";
import { getTaskTitleFromTaskId } from "logic/logics";

function not(a: readonly TaskType[], b: readonly TaskType[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly TaskType[], b: readonly TaskType[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

// function union(a: readonly TaskType[], b: readonly TaskType[]) {
//   return [...a, ...not(b, a)];
// }

export default function TransferList({
  closeModal,
}: {
  closeModal: () => void;
}) {
  const [checked, setChecked] = React.useState<readonly TaskType[]>([]);
  const [left, setLeft] = React.useState<readonly TaskType[]>([]);
  const [right, setRight] = React.useState<readonly TaskType[]>([]);
  const patientState = useRecoilValue(patientAtom);

  const fetchtempData = useCallback(
    () =>
      taskApi.saveOpenList(
        `${patientState?.patientId}`,
        left.map((element) => element.taskKey)
      ),
    [left, patientState?.patientId]
  );

  const queryClient = useQueryClient();

  const [loadAndSaveNumber, setLoadAndSaveNumber] = useState<number>(0);

  const [isSaved, setIsSaved] = useState<boolean>(false);
  const actionAfterLoadAndSave = () => {
    setLoadAndSaveNumber((loadAndSaveNumber) => loadAndSaveNumber + 1);
  };

  // useEffect(() => {
  //   if (isSaved && loadAndSaveNumber === left.length) {
  //     closeModal();
  //     queryClient.invalidateQueries([TASK_DOMAIN, TASK_LIST_ENDPOINT]);
  //   }
  // }, [closeModal, isSaved, left.length, loadAndSaveNumber, queryClient]);

  const refetchTaskList = useRefetchTaskList();

  const { mutate } = useMutation(
    [TASK_DOMAIN, TASK_OPENLIST_SAVE_ENDPOINT],
    fetchtempData,
    {
      onSuccess: () => {
        setIsSaved(true);
        closeModal();
        refetchTaskList();
      },
    }
  );
  const { data } = useQuery(
    [TASK_DOMAIN, TASK_LISTALL_ENDPOINT],
    () => taskApi.getAllList(`${patientState?.patientId}`),
    {
      onSuccess: (data) => {
        const fetchedData = data.result;
        setLeft(fetchedData?.filter((element) => element.isOpen) || []);
        setRight(fetchedData?.filter((element) => !element.isOpen) || []);
      },
    }
  );

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: TaskType) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  // const numberOfChecked = (items: readonly TaskType[]) =>
  //   intersection(checked, items).length;

  // const handleToggleAll = (items: readonly TaskType[]) => () => {
  //   if (numberOfChecked(items) === items.length) {
  //     setChecked(not(checked, items));
  //   } else {
  //     setChecked(union(checked, items));
  //   }
  // };

  const [isSomethingChanged, setIsSomethingChanged] = useState<boolean>(false);

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    setIsSomethingChanged(true);
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    setIsSomethingChanged(true);
  };

  function TransferListItem({
    value,
    labelId,
    moduleNumber,
  }: {
    value: TaskType;
    labelId: string;
    moduleNumber?: number;
  }) {
    const title = useGetTaskTitleFromTaskKey(value.taskKey);
    return (
      <ListItemButton key={value.taskKey} onClick={handleToggle(value)}>
        <ListItemIcon>
          <Checkbox
            checked={checked.indexOf(value) !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </ListItemIcon>
        <ListItemContent sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            level={"body-xs"}
            fontWeight={600}
            sx={{
              display: "inline",
              opacity: 0.5,
              mr: 1,
            }}
          >
            {/* {`M${value.taskId.split("-")[0]}`} */}
            {moduleNumber !== undefined && `M${moduleNumber}`}
          </Typography>
          <Typography
            level={"body-md"}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {title}
            {/* {value.taskKey} */}
          </Typography>
        </ListItemContent>
        {value.isDone ? (
          <IconButton edge="end" aria-label="delete">
            {value.taskKey?.includes("T") ? <RepeatChip /> : <DoneChip />}
          </IconButton>
        ) : null}
      </ListItemButton>
    );
  }

  const customList = (
    list: { title: string; items: readonly TaskType[] }[]
  ) => (
    <Card
      sx={{
        minWidth: "400px",
        maxWidth: "400px",
        height: "500px",
        overflow: "auto",
      }}
    >
      {list.map(({ title, items }, index) => (
        <div key={title}>
          <div className="flex gap-[10px] px-2">
            <Typography
              id="basic-list-demo"
              level="body-xs"
              textTransform="uppercase"
              fontWeight="lg"
            >
              {title}
            </Typography>{" "}
            <Typography
              id="basic-list-demo"
              level="body-xs"
              textTransform="uppercase"
              fontWeight="lg"
            >
              {items.length}
            </Typography>
          </div>

          <List dense component="div" role="list">
            {[...items]
              .sort((a: TaskType, b: TaskType) => {
                const sequence = ProgramProgressData.map(
                  (each) => each.taskIdPrefixList
                ).flat();
                return (
                  sequence.findIndex(
                    (each) => each === getTaskIdPrefix(a.taskId)
                  ) -
                  sequence.findIndex(
                    (each) => each === getTaskIdPrefix(b.taskId)
                  )
                );
              })
              .map((value: TaskType) => {
                const labelId = `transfer-list-all-item-${value}-label`;

                return (
                  <TransferListItem
                    value={value}
                    labelId={labelId}
                    moduleNumber={getModuleNumber(value.taskId)}
                  />
                );
              })}
          </List>
        </div>
      ))}
    </Card>
  );

  const autocomplete = (placeholder: string, list: readonly TaskType[]) => {
    return (
      <Autocomplete
        placeholder={placeholder}
        options={list.map((each) => ({
          key: each.taskId,
          taskId: each.taskId,
          label: `M${getModuleNumber(each.taskId)} ${getTaskTitleFromTaskId(
            each.taskId
          )}`,
        }))}
        isOptionEqualToValue={(option, value) => {
          return _.isEqual(option, value);
        }}
        noOptionsText="활동이 없어요"
        onChange={(e: React.SyntheticEvent, value) => {
          if (value) {
            const autocompletedTask = list.find(
              (element) => element.taskId === value.taskId
            );
            setChecked((checked) => [
              ...checked,
              autocompletedTask as TaskType,
            ]);
          }
        }}
        sx={{ mb: 1 }}
      />
    );
  };

  return (
    <Stack sx={{ width: "100%" }} spacing="20px" alignItems={"end"}>
      <Stack
        direction="row"
        spacing="10px"
        alignItems={"center"}
        sx={{ width: "100%", height: "100%", overflowX: "scroll" }}
      >
        <Stack sx={{ flex: 1, alignContent: "stretch" }}>
          {autocomplete("다음 할 활동에서 검색", left)}
          {customList([{ title: "다음 할 활동", items: left }])}
        </Stack>
        <Stack direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="solid"
            size="sm"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="solid"
            size="sm"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Stack>
        <Stack sx={{ flex: 1 }}>
          {autocomplete("안 한 활동 / 마친 활동에서 검색", right)}
          {customList([
            {
              title: "안 한 활동",
              items: right.filter((element) => !element.isDone),
            },
            {
              title: "마친 활동",
              items: right.filter((element) => element.isDone),
            },
          ])}
        </Stack>
      </Stack>

      {/* <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>
          {autocomplete("다음 할 활동에서 검색", left)}
          {customList([{ title: "다음 할 활동", items: left }])}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="solid"
              size="sm"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="solid"
              size="sm"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          {autocomplete("안 한 활동 / 마친 활동에서 검색", right)}
          {customList([
            {
              title: "안 한 활동",
              items: right.filter((element) => !element.isDone),
            },
            {
              title: "마친 활동",
              items: right.filter((element) => element.isDone),
            },
          ])}
        </Grid>
      </Grid> */}
      <Button disabled={!isSomethingChanged} onClick={() => mutate()}>
        저장
      </Button>
    </Stack>
  );
}
