import { Avatar, Box, Typography } from "@mui/joy";
import { isDev } from "config";

export default function UserProfile({
  nickname,
  url,
  size,
  locale,
  isAuto,
}: {
  nickname?: string;
  url?: string;
  size?: "sm" | "md";
  locale?: string;
  isAuto?: boolean;
}) {
  const localeWithDefault = locale
    ? locale.startsWith("ko") || locale.startsWith("en")
      ? locale
      : "en"
    : undefined;

  return (
    <Box sx={{ position: "relative" }}>
      <Avatar
        alt={nickname}
        src={url}
        color={"primary"}
        size={size}
        {...(isAuto && {
          sx: {
            background:
              "linear-gradient(45deg, rgba(251,63,63,1) 0%, rgba(124,211,84,1) 71%, rgba(0,110,227,1) 100%)",
          },
        })}
      />

      {localeWithDefault && (
        <Typography
          level="title-lg"
          sx={{ position: "absolute", right: -5, bottom: -5 }}
        >
          {localeWithDefault.startsWith("en") ? "🌏" : ""}
        </Typography>
      )}
    </Box>
  );
}
