import { ButtonGroup, ReferenceLine, Thoughtmap } from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithTextarea,
} from "../BlockComponent";

import { CoreExperienceTemplate, LastCard } from "./common";

export const Content_6_8_A = [
  Instruction(`typography_0_0_0`),
  InstructionWithContent(`typography_1_0_0`, [
    ReferenceLine({ referenceKey: "coreExperienceReferenceList" }),
  ]),

  Header1(`typography_2_0_0`),
  InstructionWithContent(
    `typography_3_0_0`,
    CoreExperienceTemplate({ isRaw: true })
  ),
  InstructionWithContent(`typography_4_0_0`, [
    [
      ButtonGroup([
        {
          translationKey: "네",
          showFromId: "동의",
          hideFromId: "비동의",
        },
        {
          translationKey: "아니요",
          showFromId: "비동의",
          hideFromId: "동의",
        },
      ]),
    ],
  ]),

  InstructionWithTextarea("typography_5_0_0", undefined, {
    blockId: "비동의",
    // hideIfCoach: true,
  }),
  Instruction("typography_6_0_0", {
    noArrow: true,
    isEnd: true,
    // hideIfCoach: true,
  }),

  Instruction(`typography_7_0_0`, {
    blockId: "동의",
  }),
  InstructionWithContent(`typography_7_0_0_1`, [Thoughtmap()]),
  InstructionWithTextarea(`typography_8_0_0`),
  Instruction(`typography_9_0_0`),

  ...LastCard,
];
