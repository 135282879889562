/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  type AxiosInstance,
  AxiosInterceptorManager,
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from "axios";
import { HOST_URL, API_KEY, BUILD_TARGET } from "config";
import mixpanel from "mixpanel-browser";
import { useResetRecoilState } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { userAtom } from "recoil/userAtom";
// import { webviewToast } from "utils";

export type CustomResponseFormat<T = any> = {
  code: number;
  debugMessage?: string;
  message?: string;
  status?: number;
  error?: string;
  data?: T;
  result?: T;
};

interface CustomInstance extends AxiosInstance {
  interceptors: {
    request: AxiosInterceptorManager<InternalAxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse<CustomResponseFormat>>;
  };
  getUri(config?: AxiosRequestConfig): string;
  request<T>(config: AxiosRequestConfig): Promise<T>;
  get<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  delete<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  head<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  options<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
}

const onRequest = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  if (BUILD_TARGET === "dev") {
    console.info(
      "[",
      config.method?.toUpperCase(),
      config.url,
      "]",
      "\nparams: ",
      config.params
    );
  }
  return config;
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  if (BUILD_TARGET === "dev") {
    // console.info(
    //   `[${response.config.method?.toUpperCase()} ${
    //     response.config.url
    //   }]\ndata: ${JSON.stringify(response.data)}`
    // );
    // console.log(response.data);
  }
  return response.data;
};

const axiosInstance: CustomInstance = axios.create({
  withCredentials: true,
  baseURL: HOST_URL,
  headers: {
    // "X-Api-Key": API_KEY,
    // "X-Auth-Token": "",
    // "X-App-Id": "w:123",
  },
});

const onFulfilled = (response: AxiosResponse) => response;

const onRejected = (error: AxiosError<CustomResponseFormat>) => {
  if (error.config) console.log(error);
  if (error.response?.data) {
    const statusCode = error.response?.data.status;

    if (statusCode === 401 || statusCode === 403) {
      localStorage.removeItem("user");
      window.location.href = `${window.location.origin}/login`;
    }
    // webviewToast(
    //   "error: " +
    //     error.response.data.status +
    //     " " +
    //     error.response.data.error +
    //     " " +
    //     error.response.data.debugMessage
    // );
  }

  return Promise.reject(error);
};

axiosInstance.interceptors.request.use(onRequest);
// axiosInstance.interceptors.response.use(onResponse);
axiosInstance.interceptors.response.use(onResponse, onRejected);

export const setAuthHeaders = (accessToken: string, appId?: string) => {
  if (
    axiosInstance.defaults?.headers &&
    axiosInstance.defaults.headers.common
  ) {
    // console.log("current", accessToken, appId);
    // @ts-ignore
    axiosInstance.defaults.headers["X-Auth-Token"] = accessToken;
    if (appId) {
      // @ts-ignore
      axiosInstance.defaults.headers["X-App-Id"] = appId;
    }
  }
};

export const setLocale = (locale: string) => {
  if (
    axiosInstance.defaults?.headers &&
    axiosInstance.defaults.headers.common
  ) {
    // console.log("current", accessToken, appId);
    // @ts-ignore
    axiosInstance.defaults.headers["X-Lang-Code"] = locale;
  }
};

export { axiosInstance };
