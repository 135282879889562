import mixpanel from "mixpanel-browser";
import { useCallback } from "react";

export default function useMixpanelWeb(request: {
  params?: { [key: string]: string };
  eventName: string;
}) {
  const params = request.params ? request.params : {};

  const fetchData = useCallback(async () => {
    try {
      console.log("[mixpanel web]", request.eventName);
      mixpanel.track(request.eventName, params);
    } catch (error) {
      console.error("error", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  return fetchData;
}
