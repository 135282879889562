import * as React from "react";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import TransferList from "components/task-list/TransferList";

export default function EditTaskListButton() {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="neutral"
        onClick={() => {
          setOpen(true);
        }}
        startDecorator={<span className="material-symbols-outlined">edit</span>}
      >
        목록 수정하기
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={{ width: "max-content" }}
        >
          <TransferList closeModal={() => setOpen(false)} />
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
