import { Box, Button, Stack } from "@mui/joy";
import { useMutation } from "@tanstack/react-query";
import { selfDistancingApi } from "api/selfDistancingApi";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IdType } from "./Awareness";
import { Block, BlockType } from "./Block";
import ProgressBar from "./ProgressBar";
import { SummaryType } from "./Summary";

export default function AwarenessWrapup({ uuid }: { uuid: string }) {
  const navigate = useNavigate();
  const qnaRefs = useRef<HTMLDivElement[]>([]);

  const emotion = window.localStorage.getItem("emotion") || "";
  const situation = window.localStorage.getItem("situation") || "";
  const thought = window.localStorage.getItem("thought") || "";
  const thoughtRecord: { [key in IdType]: string } = {
    emotion: emotion,
    situation: situation,
    thought: thought,
  };

  const [lastShownIndex, setLastShownIndex] = useState<number>(0);

  const showNextQuestion = () => {
    setLastShownIndex((lastShownIndex) => lastShownIndex + 1);
  };

  const moveToIndex = (index: number) => {
    qnaRefs.current[index].scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  useEffect(() => {
    if (lastShownIndex > 0) {
      moveToIndex(lastShownIndex);
    }
  }, [lastShownIndex]);

  const wrapupList: {
    type: BlockType;
    question: string;
    summaryData?: SummaryType;
  }[] = [
    {
      type: "summary",
      question:
        "좋아요. 이렇게 감정, 상황, 생각을 구분해서 적어보면 마음 속에 떠오르는 생각을 알아차릴 수 있었어요.",
      summaryData: [
        { label: "감정", text: thoughtRecord.emotion },
        { label: "상황", text: thoughtRecord.situation },
        { label: "생각", text: thoughtRecord.thought },
      ],
    },
    {
      type: "plain",
      question: "이제부터는 생각과 거리를 두기 위한 몇 가지 질문들을 드릴게요.",
    },
  ];

  const submitThoughtRecorQueryFn = useCallback(
    () => selfDistancingApi.submitThoughtRecord(uuid, thoughtRecord),
    [uuid]
  );

  const { mutate: submit } = useMutation(submitThoughtRecorQueryFn, {
    onSuccess: () => {
      navigate("/tool/3");
    },
  });

  return (
    <Stack
      sx={{
        width: "100%",
      }}
      alignItems="center"
    >
      <Stack sx={{ width: "100%", mb: "50px" }} direction="row" spacing={"3px"}>
        {[
          {
            label: "알아차리기",
            percentage: 100,
          },
          { label: "거리두기", percentage: 0 },
          { label: "기꺼이 경험하기", percentage: 0 },
        ].map(({ label, percentage }, index) => (
          <Box key={label} sx={{ flex: 1 }}>
            <ProgressBar label={label} percentage={percentage}></ProgressBar>
          </Box>
        ))}
      </Stack>
      <Stack
        direction={"column"}
        justifyContent="start"
        sx={{ width: "100%", pb: "100px" }}
        spacing="50px"
      >
        {wrapupList.map(({ type, question, summaryData }, index) => (
          <Box sx={{ ...(index > lastShownIndex && { visibility: "hidden" }) }}>
            <Block
              ref={(el) => (qnaRefs.current[index] = el as HTMLDivElement)}
              type={type as BlockType}
              summaryData={summaryData}
              question={question}
              {...(lastShownIndex < 1 && {
                onNextButtonClick: showNextQuestion,
              })}
              isNextButtonAvailable={lastShownIndex === index}
            />
          </Box>
        ))}
      </Stack>
      {lastShownIndex === 1 && (
        <Button
          size="lg"
          sx={{
            backgroundColor: "#232832",
            width: "100%",
            position: "sticky",
            bottom: "20px",
            "&:hover": {
              backgroundColor: `#23283288`,
            },
          }}
          onClick={() => {
            submit();
          }}
        >
          계속 진행하기
        </Button>
      )}
    </Stack>
  );
}
