import { Button, Stack, Typography } from "@mui/joy";
import { useNavigate, useParams } from "react-router-dom";

export default function CoachMatch() {
  const navigate = useNavigate();

  return (
    <Stack
      spacing={2}
      justifyContent={"center"}
      // alignItems="center"
      sx={{ width: "100%" }}
    >
      <Typography level={"h3"}>코치 선생님이 배정되었어요.</Typography>
      <Button sx={{ width: "100%" }} onClick={() => navigate("/onboarding/3")}>
        다음
      </Button>
    </Stack>
  );
}
