import { Stack, Typography } from "@mui/joy";
import LineChart from "./LineChart2";
import { TestResultType } from "api/dashboardApi";
import { selfCheckData, SelfCheckIdType } from "data/selfCheckData";
import { useRecoilValue } from "recoil";
import { taskListAtom } from "recoil/taskListAtom";
import Chip from "components/common/Chip";

function getMaxScoreById(id: SelfCheckIdType) {
  const resultRangeData = selfCheckData[id].result;
  return resultRangeData[resultRangeData.length - 1].range[1];
}

function Score({ score, maxScore }: { score?: number; maxScore: number }) {
  return (
    <Stack spacing={0}>
      <Typography sx={{ fontSize: "14px" }} fontWeight={"xl"}>
        {score !== undefined ? score : "?"}

        <Typography sx={{ fontWeight: 400, opacity: 0.5 }}>
          {` /${maxScore}`}
        </Typography>
      </Typography>
    </Stack>
  );
}

function TestResultById({
  id,
  title,
  scoreList,
}: {
  id: SelfCheckIdType;
  title: string;
  scoreList: number[];
}) {
  const latestScore =
    scoreList && scoreList.length > 0
      ? scoreList[scoreList.length - 1]
      : undefined;
  function getResultLabel(score: number) {
    const resultData = selfCheckData[id].result;
    const result = resultData.find(
      (element) => score >= element.range[0] && score <= element.range[1]
    );
    return result?.label;
  }

  return (
    <Stack
      direction="column"
      flexGrow={1}
      sx={{
        p: "20px",
        borderRadius: "12px",
        backgroundColor: "background.level1",
      }}
    >
      <Stack spacing={2}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Stack direction={"row"} spacing="10px" alignItems={"center"}>
            <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
              {title}
            </Typography>
            {latestScore !== undefined && (
              <Chip
                label={getResultLabel(latestScore) || ""}
                {...(latestScore > selfCheckData[id].abnormalScore && {
                  color: "red",
                })}
              ></Chip>
            )}
          </Stack>
          <Score score={latestScore} maxScore={getMaxScoreById(id)} />
        </Stack>
      </Stack>
      {scoreList.length > 0 && (
        <LineChart
          id={id}
          data={scoreList}
          maxScore={getMaxScoreById(id)}
          abnormalScore={selfCheckData[id].abnormalScore}
        />
      )}
    </Stack>
  );
}
export default function TestResult({
  anxiety,
  depression,
  stress,
  burnout,
}: TestResultType) {
  const taskListState = useRecoilValue(taskListAtom);

  const isBehavioralActivationProgram = !taskListState.find(
    (element) => element.taskId === "0-0-A"
  );

  const data: { id: SelfCheckIdType; scoreList: number[] }[] = [
    {
      id: "depression",
      scoreList: depression?.scoreList || [],
    },
    {
      id: "anxiety",
      scoreList: anxiety?.scoreList || [],
    },
    ...(isBehavioralActivationProgram
      ? []
      : [
          {
            id: "stress" as SelfCheckIdType,
            scoreList: stress?.scoreList || [],
          },
        ]),
  ];

  return (
    <Stack spacing={"16px"}>
      <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
        부정적 반응
      </Typography>
      <Stack direction={"column"} spacing={"12px"}>
        {data.map(({ id, scoreList }, index) => {
          const title = selfCheckData[id as SelfCheckIdType].title;
          return (
            <TestResultById
              key={`testResult_${id}`}
              id={id}
              title={title}
              scoreList={scoreList}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}
