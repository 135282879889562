import { Box } from "@mui/joy";
import PaymentHeader from "./header/PaymentHeader";
import PaymentContent from "./content/PaymentContent2";
import TaskHeader from "./header/TaskHeader";

export default function Payment() {
  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 100,
          // px: { xs: "16px", sm: "24px" },
        }}
      >
        {/* <PaymentHeader /> */}
        <TaskHeader taskKey={"payment"} />
      </Box>

      <div className="w-full h-full overflow-x-hidden">
        <PaymentContent />
      </div>
    </>
  );
}
