import { useEffect, useState } from "react";
import { Input, Stack, Typography } from "@mui/joy";

import TitleWithSubtitle from "components/common/TitleWithSubtitle";
import EmailAuthButton from "components/user/EmailAuthButton";
import { institutionData } from "./InstitutionSelectPage";
import { useSearchParams } from "react-router-dom";

export default function EmailAuthPage() {
  const [searchParams] = useSearchParams();

  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  const institutionType = parseInt(searchParams.get("type") || "");
  useEffect(() => {}, []);

  const [email, setEmail] = useState("");
  // 이메일이 유효한지 검사하는 간단한 함수
  const validateEmail = (email: string) => {
    if (email.length > 0) {
      const regex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/;
      return regex.test(email);
    }
    return true;
  };

  // 이메일 입력 핸들러
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  return (
    <Stack
      direction={"column"}
      alignItems="center"
      justifyContent={"center"}
      sx={{
        px: "20px",
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "calc(var(--vh,1vh) * 100)",
        minHeight: "calc(var(--vh,1vh) * 100)",
        backgroundColor: "background.level1",
      }}
    >
      {isEmailSent ? (
        <Stack sx={{ maxWidth: "400px", width: "100%" }}>
          <TitleWithSubtitle
            title={"받으신 메일의 로그인 링크를 눌러주세요."}
            subtitle={`${email}로 메일을 보내드렸어요. 혹시 메일을 찾을 수 없다면, 스팸메일함도 확인해주세요.`}
            sx={{ width: "100%", textAlign: "center", wordBreak: "keep-all" }}
          />
        </Stack>
      ) : (
        <Stack spacing={3} sx={{ maxWidth: "400px", width: "100%" }}>
          <TitleWithSubtitle
            title={"학교/기관의 메일 주소를 입력해주세요"}
            subtitle={"로그인 링크가 담긴 메일을 보내드려요."}
            sx={{ width: "100%", textAlign: "center" }}
          />
          <Stack>
            <Input
              type="email"
              id="email"
              required
              placeholder={"이메일"}
              value={email}
              onChange={handleEmailChange}
              sx={{
                width: "100%",
                borderWidth: "2px",
                borderColor: "background.level3",
                backgroundColor: "background.level3",
                "--joy-focus-thickness": "0px",
              }}
            ></Input>
            {!validateEmail(email) && (
              <Typography
                fontWeight={"500"}
                textColor={"danger.500"}
                sx={{ mt: "4px" }}
              >
                {`이메일을 정확히 입력해주세요`}
              </Typography>
            )}
          </Stack>

          <EmailAuthButton
            email={email}
            disabled={email.length === 0 || !validateEmail(email)}
            isEmailValid={
              institutionType
                ? email.split("@")[1] ===
                  institutionData[institutionType].domain
                : institutionData
                    .map((element) => element.domain)
                    .includes(email.split("@")[1])
            }
            setIsEmailSent={setIsEmailSent}
          />
        </Stack>
      )}
    </Stack>
  );
}
