import { Box, Stack, Typography } from "@mui/joy";
import { useRecoilValue } from "recoil";
import { conceptNoteAtom } from "recoil/conceptNoteAtom";
import { taskListAtom } from "recoil/taskListAtom";
import Chip from "components/common/Chip";

export default function ConcernAndGoal() {
  const conceptNoteState = useRecoilValue(conceptNoteAtom);
  const taskListState = useRecoilValue(taskListAtom);
  const is1_2_A_done = taskListState.find(
    (element) => element.taskId === "1-2-A"
  )?.lastDoneAt;

  const data = [
    {
      label: "고민",
      text: is1_2_A_done ? conceptNoteState?.concern : null,
      placeholder: "요즈음 나를 괴롭히는 고민은 무엇인가요?",
    },
    {
      label: "목표",
      text: is1_2_A_done ? conceptNoteState?.goal : null,
      placeholder: "내가 만들고 싶은 변화는 무엇인가요?",
    },
  ];

  return (
    <Stack
      sx={{
        p: "20px",
        backgroundColor: "background.level1",
        borderRadius: "12px",
      }}
    >
      {data.map(({ label, text, placeholder }, index) => (
        <Box>
          <Stack spacing={"8px"}>
            {/* <Chip
              color={label === "고민" ? "black" : "primary"}
              label={label}
            ></Chip> */}
            <Typography
              sx={{ fontSize: "14px", fontWeight: 600 }}
              color="primary"
            >
              {label}
            </Typography>
            <Typography sx={{ fontSize: "16px" }}>
              {text || placeholder}
            </Typography>
          </Stack>

          {index < data.length - 1 && (
            <Box
              sx={{
                my: "20px",
                height: "1px",
                width: "100%",
                backgroundColor: "divider",
              }}
            />
          )}
        </Box>
      ))}
    </Stack>
  );
}
