import TitleWithSubtitle from "components/common/TitleWithSubtitle";
import useFetch from "hooks/useFetch";
import { getRedirectPath } from "logic/logics";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userAtom } from "recoil/userAtom";

export default function BeforeNicknameChange() {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);
  const [userDetailData, getUserDetailData] = useFetch({
    url: `/user/detail`,
  });

  useEffect(() => {
    getUserDetailData();
  }, []);

  useEffect(() => {
    if (userDetailData) {
      setUser((user) => ({
        ...user,
        ...userDetailData.result,
      }));

      navigate(
        getRedirectPath({
          ...user,
          ...userDetailData.result,
        }),
        { replace: true }
      );
    }
  }, [userDetailData]);

  return (
    <TitleWithSubtitle
      title={"코치가 메시지를 확인하고 있어요"}
      subtitle={
        "카카오톡으로 닉네임을 보내주셨죠? 코치 선생님이 확인한 뒤에 프로그램을 준비해 드릴 거예요. \n 준비가 완료되면 카카오톡으로 알려드릴게요. 업무 시간이 아니라면 조금 늦어질 수도 있어요. "
      }
    />
  );
}
