import { Box, Stack, Typography } from "@mui/joy";
import { useQuery } from "@tanstack/react-query";
import {
  ABCStatusType,
  ThoughtRecordType,
  toolApi,
  TOOL_THOUGHTRECORD_DETAIL_ENDPOINT,
  TOOL_THOUGHTRECORD_DOMAIN,
} from "api2/toolApi";
import React, { useCallback, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import ThoughtRecordContainer from "./ThoughtRecordContainer";
import dayjs from "dayjs";
import { addAlpha } from "styles/hexOpacity";
import Button from "./Button";
import { usePrevious } from "@uidotdev/usehooks";
import * as _ from "lodash";
import { Lottie } from "components/common/Lottie";
import Loading from "static/lottie/tool/thought-record/conversation_loading.json";
import Breadcrumb from "./Breadcrumb";
import Conversation from "./Conversation";
import Status, { FindingTrapIcon, IsolatingIcon } from "./Status";
import ProgressBar, { ProgressbarStepType } from "./ProgressBar";

export function EmptyCase({
  isLoading,
  placeholder,
}: {
  isLoading?: boolean;
  placeholder: string;
}) {
  return (
    <Stack
      spacing={"16px"}
      sx={{
        px: "24px",
        py: "20px",
        borderRadius: "20px",
        borderWidth: "1px",
        borderColor: addAlpha("#222222", 0.1),
      }}
    >
      {isLoading ? (
        <Box sx={{ height: "24px" }}>
          <Lottie
            src={Loading}
            containerWidth={"32px"}
            originalColor="#FFFFFF"
            newColor="#808080"
          />
        </Box>
      ) : (
        <Typography
          sx={{
            fontSize: "16px",
            color: "#222222",
            opacity: 0.5,
          }}
        >
          {placeholder}
        </Typography>
      )}
    </Stack>
  );
}

const IsolatedElementTitle = ({
  title,
  isLoading,
}: {
  title: string;
  isLoading?: boolean;
}) => {
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      alignItems="center"
      sx={{ px: "10px" }}
    >
      <Typography
        sx={{
          fontSize: "16px",
          color: "#222222",
          fontWeight: 600,
        }}
      >
        {title}
      </Typography>
      {isLoading && (
        <Lottie
          src={Loading}
          containerWidth={"32px"}
          originalColor="#FFFFFF"
          newColor="#808080"
        />
      )}
    </Stack>
  );
};

const CurvedRightArrow = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.25 10.299L12.5 9L10.25 7.70096L10.25 10.299Z"
        fill="#FD5005"
        stroke="#FD5005"
      />
      <path
        d="M4 2V7C4 8.10457 4.89543 9 6 9H11"
        stroke="#FD5005"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const IsolatedElement = ({
  label,
  text,
  isHighlighted,
  tag,
}: {
  label?: string;
  text: string;
  isHighlighted?: boolean;
  tag?: string;
}) => {
  return (
    <Stack spacing={"8px"}>
      <Stack direction="row" spacing={"8px"}>
        {label && (
          <Typography
            sx={{
              fontSize: "16px",
              color: "#222222",
              opacity: 0.5,
              whiteSpace: "nowrap",
            }}
          >
            {label}
          </Typography>
        )}
        <Typography
          sx={{
            fontSize: "16px",
            color: isHighlighted ? "#FD5005" : "#222222",
            wordBreak: "break-word",
          }}
        >
          {text}
        </Typography>
      </Stack>
      {tag && (
        <Stack direction="row" spacing={"4px"} alignItems="center">
          <CurvedRightArrow />

          <Typography
            sx={{
              fontSize: "12px",
              color: "white",
              py: "4px",
              px: "8px",
              backgroundColor: "#FD5005",
              borderRadius: "4px",
            }}
          >
            {tag}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export const ElementContainer = ({
  isHighlighted,
  children,
  onClick,
}: {
  isHighlighted?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}) => {
  return (
    <Stack
      spacing={"16px"}
      sx={{
        px: "24px",
        py: "20px",
        borderRadius: "20px",
        borderColor: isHighlighted ? "#FD5005" : addAlpha("#222222", 0.1),
        borderWidth: "1px",
        backgroundColor: "white",
      }}
      {...(onClick && { onClick: onClick })}
    >
      {children}
    </Stack>
  );
};

interface IsolatedItemType {
  text: string;
  subLabel?: string;
  isChanged?: boolean;
  tag?: string;
  isHighlighted?: boolean;
}

const Situation = (props: IsolatedItemType) => {
  return (
    <ElementContainer>
      <IsolatedElement text={props.text} isHighlighted={props.isChanged} />
    </ElementContainer>
  );
};

const Thoughts = ({ thoughts }: { thoughts: IsolatedItemType[] }) => {
  return (
    <>
      {thoughts.map(({ text, isChanged, isHighlighted, tag }, index) => (
        <ElementContainer
          key={`${text}_${index}`}
          isHighlighted={isHighlighted}
        >
          <IsolatedElement
            text={text}
            isHighlighted={isChanged || isHighlighted}
            tag={tag}
          />
        </ElementContainer>
      ))}
    </>
  );
};
const Reactions = ({ reactions }: { reactions: IsolatedItemType[] }) => {
  return (
    <ElementContainer>
      {reactions.map(({ subLabel, text, isChanged }, index) => (
        <React.Fragment key={`${subLabel}_${index}`}>
          <IsolatedElement
            label={subLabel}
            text={text}
            isHighlighted={isChanged}
          />
          {index < reactions.length - 1 && (
            <Box
              sx={{
                width: "100%",
                height: "1px",
                backgroundColor: addAlpha("#222222", 0.04),
              }}
            />
          )}
        </React.Fragment>
      ))}
    </ElementContainer>
  );
};

const DownArrow = () => {
  return (
    <svg
      width="10"
      height="22"
      viewBox="0 0 10 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4">
        <path
          d="M5 1L5 21"
          stroke="url(#paint0_linear_907_5394)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 17L5 21L9 17"
          stroke="#222222"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_907_5394"
          x1="5.5"
          y1="1"
          x2="5.5"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#222222" stopOpacity="0" />
          <stop offset="1" stopColor="#222222" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default function Isolation() {
  const { ttr } = useParams();
  const [searchParams] = useSearchParams();
  const date = searchParams.get("date") || "";

  const { state } = useLocation();
  const isFromThoughtTrap = state?.isFromThoughtTrap || false;

  const timestamp = ttr ? parseInt(ttr.split(":")[2]) : 0;

  const formatTimestampToMMDD = (timestamp: number) => {
    return timestamp > 0 ? dayjs(timestamp).format("M월 DD일") : "";
  };

  const [formattedDate, setFormattedDate] = useState<string>(
    date ? dayjs(date).format("M월 D일") : "월 일"
  );

  const blankData: ThoughtRecordType = {
    thoughtRecordId: "",
    date: "",
    situation: "",
    thoughtList: [],
    emotionList: [],
    behaviorList: [],
    physicalReactionList: [],
    isProcessing: false,
    status: "in_progress_isolation",
  };

  const [ABCData, setABCData] = useState<ThoughtRecordType>();
  const previousABCData = usePrevious(ABCData);
  const [isConversationOpen, setIsConversationOpen] = useState<boolean>(false);

  function reformatABCData(
    data: ThoughtRecordType,
    previousData?: ThoughtRecordType
  ): { label: string; content?: IsolatedItemType[] }[] {
    return [
      {
        label: "상황",
        content: data.situation
          ? [
              {
                text: data.situation,
                subLabel: undefined,
                isChanged:
                  previousData && data.situation !== previousData.situation,
              },
            ]
          : undefined,
      },
      {
        label: "생각",
        content: data.thoughtList?.map((each, index) => ({
          text: each,
          subLabel: undefined,
          isChanged: previousData && !previousData.thoughtList.includes(each),
          isHighlighted:
            ABCData?.status === "in_progress_finding_thought_trap" &&
            !!data.thoughtTrapList?.find(
              (element) => element.thoughtIndex === index
            )?.thoughtTrap,
          tag:
            ABCData?.status === "completed"
              ? data.thoughtTrapList?.find(
                  (element) => element.thoughtIndex === index
                )?.thoughtTrap
              : undefined,
        })),
      },
      {
        label: "반응",
        content: [
          ...data.emotionList.map((each) => ({
            subLabel: "감정",
            text: each,
            isChanged: previousData && !previousData.emotionList.includes(each),
          })),
          ...data.physicalReactionList.map((each) => ({
            subLabel: "신체",
            text: each,
            isChanged:
              previousData && !previousData.physicalReactionList.includes(each),
          })),
          ...data.behaviorList.map((each) => ({
            subLabel: "행동",
            text: each,
            isChanged:
              previousData && !previousData.behaviorList.includes(each),
          })),
        ],
      },
    ];
  }

  const getDetailQueryFn = useCallback(() => toolApi.getABC(ttr || ""), [ttr]);

  useQuery(
    [TOOL_THOUGHTRECORD_DOMAIN, TOOL_THOUGHTRECORD_DETAIL_ENDPOINT],
    getDetailQueryFn,
    {
      ...((!ABCData || ABCData.isProcessing) && { refetchInterval: 1000 }),
      onSuccess: (data) => {
        if (data.result?.date) {
          setFormattedDate(dayjs(data.result?.date).format("M월 D일"));
        }

        setABCData(data.result);
      },
    }
  );

  const dataByStatus: {
    [key in ABCStatusType]: {
      buttonText: string;
      buttonStartDecorator?: React.ReactNode;
      progressbarSteps: ProgressbarStepType[];
      percentage: number;
    };
  } = {
    in_progress_isolation: {
      buttonText: "더 분리하기",
      buttonStartDecorator: <IsolatingIcon color="#ffffff" />,
      progressbarSteps: [{ threshold: 0, label: "분리하기" }],
      percentage: 0,
    },
    in_progress_finding_thought_trap: {
      buttonText: "생각함정 찾기",
      buttonStartDecorator: <FindingTrapIcon color="#ffffff" />,
      progressbarSteps: [
        { threshold: 0, label: "분리하기" },
        { threshold: 50, label: "생각함정 찾기" },
      ],
      percentage: 50,
    },
    completed: {
      buttonText: "다시 보기",
      progressbarSteps: [
        { threshold: 0, label: "분리하기" },
        { threshold: 50, label: "생각함정 찾기" },
        { threshold: 100, label: "완료" },
      ],
      percentage: 100,
    },
  };

  return (
    <ThoughtRecordContainer>
      <Conversation
        ttr={ttr || ""}
        ABCData={ABCData}
        isConversationOpen={isConversationOpen}
        setIsConversationOpen={setIsConversationOpen}
        isCompleted={ABCData?.status === "completed"}
      />
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          pt: "12px",
          overflow: "hidden",
        }}
      >
        <Box sx={{ width: "100%", px: "24px", mb: "17px" }}>
          <Breadcrumb
            items={
              isFromThoughtTrap
                ? [
                    { iconType: "home", stepBack: 2 },
                    { text: "찾은 생각함정", stepBack: 1 },
                    {
                      text: formattedDate,
                      stepBack: 0,
                    },
                  ]
                : [
                    { iconType: "home", stepBack: 1 },
                    {
                      text: formattedDate,
                      stepBack: 0,
                    },
                  ]
            }
          />
        </Box>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
          sx={{ width: "100%", px: "20px" }}
        >
          <Typography
            sx={{
              fontWeight: 900,
              fontSize: "32px",
              color: "#222222",
            }}
          >
            {/* {formatTimestampToMMDD(timestamp)} */}
            {formattedDate}
          </Typography>
          {ABCData && <Status status={ABCData.status} />}
        </Stack>
        <Box sx={{ width: "100%", px: "20px", mt: "24px" }}>
          <ProgressBar
            steps={[
              { threshold: 0, label: "분리하기" },
              ...(ABCData?.thoughtTrapList &&
              ABCData?.thoughtTrapList?.length > 0
                ? [{ threshold: 50, label: "생각함정 찾기" }]
                : []),
              { threshold: 100, label: "완료" },
            ]}
            percentage={ABCData ? dataByStatus[ABCData.status].percentage : 0}
          />
        </Box>
        <Stack
          sx={{
            width: "100%",
            flex: 1,
            overflow: "scroll",
            px: "20px",
            pt: "20px",
            pb: "112px",
          }}
        >
          {reformatABCData(ABCData || blankData, previousABCData).map(
            ({ label, content }, index) => (
              <React.Fragment key={label}>
                <Stack spacing={"8px"}>
                  <IsolatedElementTitle
                    title={label}
                    isLoading={label === "생각" && ABCData?.isProcessing}
                  />
                  {!content || content.length === 0 ? (
                    <EmptyCase
                      isLoading={!ABCData}
                      placeholder={"아직 구체화되지 않았어요"}
                    />
                  ) : label === "상황" ? (
                    <Situation
                      text={content[0].text}
                      isChanged={content[0].isChanged}
                    />
                  ) : label === "생각" ? (
                    <Thoughts thoughts={content} />
                  ) : label === "반응" ? (
                    <Reactions reactions={content} />
                  ) : (
                    <></>
                  )}
                </Stack>
                {index < 2 && (
                  <Stack
                    sx={{
                      width: "36px",
                      height: "36px",
                      mx: "auto",
                    }}
                    justifyContent="end"
                    alignItems="center"
                  >
                    <DownArrow />
                  </Stack>
                )}
              </React.Fragment>
            )
          )}
          {ABCData && (
            <Box
              sx={{
                position: "fixed",
                bottom: "36px",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <Button
                buttonText={dataByStatus[ABCData.status].buttonText}
                color={
                  ABCData.status !== "completed" ? "gradient-orange" : "black"
                }
                onClick={() => {
                  setIsConversationOpen(true);
                }}
                hasShadow
                startDecorator={<IsolatingIcon color="#ffffff" />}
              />
            </Box>
          )}
        </Stack>
      </Stack>
    </ThoughtRecordContainer>
  );
}
