import { Box, SvgIcon } from "@mui/joy";
import { createSvgIcon } from "@mui/material";

export default function LargeLogo() {
  const LargeLogoIconBackground = createSvgIcon(
    <svg
      width="336"
      height="560"
      viewBox="0 0 336 560"
      fill="currentColor"
      fillOpacity="1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M134.4 0H112H89.6H67.2H44.8V22.4V44.8H22.4H0V67.2V89.6V112V134.4V156.8H22.4H44.8H67.2H89.6H112H134.4H156.8H179.2H201.6V179.2H224V201.4H201.6H179.2H156.8L156.8 179H134.4H112H89.6H67.2H44.8V201.4V223.8H22.4H0V246.2V268.6V291V313.4V335.8H22.4H44.8H67.2H89.6H112H134.4H156.8H179.2H201.6V358.2H224V380.4H201.6H179.2H156.8L156.8 358H134.4H112H89.6H67.2H44.8V380.4V402.8H22.4H0V425.2V447.6V470V492.4V514.8H22.4H44.8H67.2H89.6H112H134.4H156.8H179.2H201.6V537.2H224V559.6H246.4H268.8V537.2V514.8H291.2H313.6H336V492.4V470V447.6V425.2V402.8H313.6H291.2H268.8V380.6V380.4V358.2V335.8H291.2H313.6H336V313.4V291V268.6V246.2V223.8H313.6H291.2H268.8V201.6V201.4V179.2V156.8H291.2H313.6H336V134.4V112V89.6V67.2V44.8H313.6H291.2H268.8V22.4H246.4H224H201.6H179.2H156.8L156.8 0H134.4Z" />
    </svg>,
    "LargeLogoBackground"
  );

  const LargeLogoIconText = createSvgIcon(
    <svg
      width="336"
      height="560"
      viewBox="0 0 336 560"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 140.672V60.9277H15.9053V76.8765H31.8107V92.8253H47.716V108.774H31.8107V92.8253H15.9053V140.672H0ZM63.6213 140.672V92.8253H47.716V76.8765H63.6213V60.9277H79.5266V140.672H63.6213Z" />
      <path d="M113.325 140.672V60.9277H129.231V140.672H113.325Z" />
      <path d="M163.03 140.672V60.9277H178.935V76.8765H194.84V92.8253H210.746V108.774H226.651V60.9277H242.556V140.672H226.651V124.723H210.746V108.774H194.84V92.8253H178.935V140.672H163.03Z" />
      <path d="M272.379 140.672V60.9277H320.095V76.8765H336V124.723H320.095V140.672H272.379ZM288.284 124.723H319.458V76.8765H288.284V124.723Z" />
      <path d="M0 499.072V419.328H15.9053V435.277H31.8107V451.226H47.716V467.175H31.8107V451.226H15.9053V499.072H0ZM63.6213 499.072V451.226H47.716V435.277H63.6213V419.328H79.5266V499.072H63.6213Z" />
      <path d="M113.325 499.072V419.328H129.231V499.072H113.325Z" />
      <path d="M163.03 499.072V419.328H178.935V435.277H194.84V451.226H210.746V467.175H226.651V419.328H242.556V499.072H226.651V483.123H210.746V467.175H194.84V451.226H178.935V499.072H163.03Z" />
      <path d="M272.379 499.072V419.328H320.095V435.277H336V483.123H320.095V499.072H272.379ZM288.284 483.123H319.458V435.277H288.284V483.123Z" />
      <path d="M30.5455 318.079V272.383H15.2727V257.151H0V241.919H15.2727V257.151H30.5455V272.383H45.8182V318.079H30.5455ZM61.0909 257.151V241.919H76.3636V257.151H61.0909ZM45.8182 272.383V257.151H61.0909V272.383H45.8182Z" />
      <path d="M110.727 318.079V302.847H95.4545V257.151H110.727V241.919H141.273V257.151H156.545V302.847H141.273V318.079H110.727ZM110.727 302.238H141.273V257.76H110.727V302.238Z" />
      <path d="M200.455 318.079V302.847H185.182V241.919H200.455V302.847H231V318.079H200.455ZM231 302.847V241.919H246.273V302.847H231Z" />
      <path d="M274.909 318.079V241.919H320.727V257.151H336V272.383H320.727V302.847H336V318.079H320.727V302.847H305.455V287.615H290.182V318.079H274.909ZM290.182 272.383H320.116V257.151H290.182V272.383Z" />
    </svg>,

    "LargeLogoIconText"
  );

  return (
    <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
      <div className="w-full h-full absolute">
        <SvgIcon
          sx={{ width: "100%", height: "100%" }}
          color={"soft"}
          inheritViewBox
        >
          <LargeLogoIconBackground />
        </SvgIcon>
      </div>
      <div className="w-full h-full absolute">
        <SvgIcon
          sx={{ width: "100%", height: "100%" }}
          color={"primary"}
          inheritViewBox
        >
          <LargeLogoIconText />
        </SvgIcon>
      </div>
    </Box>
  );
}
