import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const CONCEPT_DOMAIN = "concept";

export const CONCEPT_DETAIL_ENDPOINT = "detail";
export const CONCEPT_TMPLOAD_ENDPOINT = "tmp/load";
export const CONCEPT_TMPSAVE_ENDPOINT = "tmp/save";
export const CONCEPT_CONFIRM_ENDPOINT = "confirm";

export const CONCEPT_MEMOLOAD_ENDPOINT = "memo/load";
export const CONCEPT_MEMOSAVE_ENDPOINT = "memo/save";

export const CONCEPT_NOTE_DETAIL_ENDPOINT = "note/detail";
export const CONCEPT_NOTE_UPDATE_ENDPOINT = "note/update";
export const CONCEPT_NOTE_AUTOMATICTHOUGHT_ADD_ENDPOINT =
  "note/automatic-thought/add";
export const CONCEPT_NOTE_AUTOMATICTHOUGHT_DELETE_ENDPOINT =
  "note/automatic-thought/delete";
export const CONCEPT_NOTE_CONFIRM_ENDPOINT = "note/confirm";

export const CONCEPT_REFERENCE_DETAIL_ENDPOINT = "reference/detail";
export const CONCEPT_NOTE_REFERENCE_ADD_ENDPOINT = "note/reference/add";

export const CONCEPT_IMPACTSCORE_SUBMIT_ENDPOINT = "impact-score/submit";

export type ConceptKeyType_concernAndGoal = "concern" | "goal";
export type ConceptKeyType_simple = "coreExperience";
export type CoreExperienceReferenceListType =
  `${ConceptKeyType_simple}ReferenceList`;

export type ConceptKeyType_coreBelief = "coreBelief";
export type CoreBeliefKeyType = "coreBelief1" | "coreBelief2" | "coreBelief3";
export type CoreBeliefReferenceListType = `${CoreBeliefKeyType}ReferenceList`;

export type ConceptKeyType_intermediateBelief = "intermediateBelief";
export type IntermediateBeliefKeyType = "rules" | "attitudes" | "assumptions";
export type IntermediateBeliefReferenceListType =
  `${IntermediateBeliefKeyType}ReferenceList`;

export type ConceptKeyType_copingStrategy = "copingStrategy";
export type CopingStrategyKeyType =
  | "surrender"
  | "overcompensation"
  | "avoidance";

export const intermediateBeliefKeyMapping: {
  [key in IntermediateBeliefKeyType]: string;
} = { rules: "규칙", attitudes: "태도", assumptions: "가정" };

export const coreBeliefKeyMapping: {
  [key in CoreBeliefKeyType]: string;
} = {
  coreBelief1: "스스로에 대해",
  coreBelief2: "다른 사람에 대해",
  coreBelief3: "이 세상에 대해",
};

export const conceptKeyMapping: {
  [key in IntermediateBeliefKeyType | CoreBeliefKeyType]: string;
} = { ...intermediateBeliefKeyMapping, ...coreBeliefKeyMapping };

// export type ConceptKeyType_automaticThought =
//   | "automaticThought1"
//   | "automaticThought2"
//   | "automaticThought3";

type NumberToString<N extends number> = `${N}`;
export type automaticThoughtIdType = NumberToString<
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | 30
>;

export const automaticThoughtIdList = Array.from(
  { length: 30 },
  (v, i) => `${i + 1}` as automaticThoughtIdType
);

export type ConceptKeyType_automaticThought =
  | `automaticThought${automaticThoughtIdType}`;

export type ConceptKeyType_automaticThoughtForValue =
  `automaticThoughtForValue${automaticThoughtIdType}`;

type AutomaticThoughtCommonKeyType =
  | "situation"
  | "thought"
  | "emotionalPhysicalResponse"
  | "emotion"
  | "physicalResponse"
  | "behavior";
export type AutomaticThoughtKeyType =
  | AutomaticThoughtCommonKeyType
  | "copingStrategy";

export type AutomaticThoughtForValueKeyType =
  | AutomaticThoughtCommonKeyType
  | "valueDomain"
  | "valueTaskKey"
  | "value";

export type ConceptKeyType_nested =
  | ConceptKeyType_coreBelief
  | ConceptKeyType_intermediateBelief
  | ConceptKeyType_copingStrategy
  | ConceptKeyType_automaticThought
  | ConceptKeyType_automaticThoughtForValue;

type EachStatus<N extends string> = `${N}Status`;
type ConceptItemStatusKey = EachStatus<ConceptKeyType_nested>;
export type ConceptItemStatus = "preparing" | "note_confirmed" | "confirmed";

export type IndividualThoughtKeyType = `thought` | "thoughtTrap";
export type IndividualThoughtType = {
  [key in IndividualThoughtKeyType]: string;
};
export type IndividualThoughtNoteType = {
  [key in IndividualThoughtKeyType]: NoteType;
};

export interface ImpactScoreHistoryListType {
  score: number;
  submittedAt: string;
  taskKey: string;
  automaticThoughtSequence: number;
  thought: string;
}

export type CoreBeliefType = {
  [key in CoreBeliefKeyType]?: string;
};
export type IntermediateBeliefType = {
  [key in IntermediateBeliefKeyType]?: string;
};
export type CopingStrategyType = {
  [key in CopingStrategyKeyType]?: string;
};
export type AutomaticThoughtType = {
  [key in AutomaticThoughtKeyType]: string;
} & {
  impactScore?: number;
  impactScoreChange?: number;
  impactScoreHistoryList?: ImpactScoreHistoryListType[];
  thoughtRecordKey?: string;
  thoughtTrapList?: string[];
  thoughtList?: IndividualThoughtType[];
  isConfirmAvailable?: boolean;
};

export interface ValueBehaviorType {
  taskKey: string;
  valueBehavior: string;
}
export type AutomaticThoughtForValueType = {
  [key in AutomaticThoughtForValueKeyType]: string;
} & {
  valueBehaviorList?: ValueBehaviorType[];
};

export type CoreBeliefNoteType = {
  [key in CoreBeliefKeyType]: NoteType;
} & {
  [key in CoreBeliefReferenceListType]?: ReferenceLinkType[];
};
export type IntermediateBeliefNoteType = {
  [key in IntermediateBeliefKeyType]: NoteType;
} & {
  [key in IntermediateBeliefReferenceListType]?: ReferenceLinkType[];
};
export type CopingStrategyNoteType = {
  [key in CopingStrategyKeyType]?: NoteType;
};

export type AutomaticThoughtNoteType = {
  [key in AutomaticThoughtKeyType]: NoteType;
} & {
  thoughtRecordKey?: string;
  thoughtTrapList?: NoteType[];
  thoughtList?: IndividualThoughtNoteType[];
  isConfirmAvailable?: boolean;
};
export type AutomaticThoughtForValueNoteType = {
  [key in AutomaticThoughtForValueKeyType]: NoteType;
} & {
  thoughtRecordKey?: string;
  isConfirmAvailable?: boolean;
};

export interface NoteType {
  value?: string;
  isHumanCheckRequired: boolean;
  isSaved?: boolean;
}

export interface ReferenceLinkType {
  referenceId: number;
  reference: string;
  thoughtRecordKey: string;
}

export type ConceptKeyType =
  | ConceptKeyType_concernAndGoal
  | ConceptKeyType_simple
  | ConceptKeyType_nested;

export type ConceptType = {
  [key in ConceptKeyType_simple]?: string;
} & {
  [key in ConceptKeyType_coreBelief]?: CoreBeliefType;
} & {
  [key in ConceptKeyType_intermediateBelief]?: IntermediateBeliefType;
} & {
  [key in ConceptKeyType_copingStrategy]?: CopingStrategyType;
} & {
  [key in ConceptKeyType_automaticThought]?: AutomaticThoughtType;
} & {
  [key in ConceptKeyType_automaticThoughtForValue]?: AutomaticThoughtForValueType;
} & {
  [key in ConceptKeyType_concernAndGoal]?: string;
};

export type ConceptNoteType = { isOldVersion?: boolean } & {
  [key in ConceptKeyType_simple]?: NoteType;
} & {
  [key in CoreExperienceReferenceListType]?: ReferenceLinkType[];
} & {
  [key in ConceptKeyType_coreBelief]?: CoreBeliefNoteType;
} & {
  [key in ConceptKeyType_intermediateBelief]?: IntermediateBeliefNoteType;
} & {
  [key in ConceptKeyType_copingStrategy]?: CopingStrategyNoteType;
} & {
  [key in ConceptKeyType_automaticThought]?: AutomaticThoughtNoteType;
} & {
  [key in ConceptKeyType_automaticThoughtForValue]?: AutomaticThoughtForValueNoteType;
} & { [key in ConceptItemStatusKey]?: ConceptItemStatus } & {
  [key in ConceptKeyType_concernAndGoal]?: string;
};

export interface MemoType {
  memo: string;
}

export type ReferenceKeyType =
  | "coreExperienceReferenceList"
  | "coreBeliefReferenceList"
  | "intermediateBeliefReferenceList";
export type ImpactScoreKeyType =
  | "coreBeliefImpactScore"
  | "intermediateBeliefImpactScore";

export type ReferenceListType = {
  [key in ReferenceKeyType]?: string[];
};

export type ImpactScoreType = {
  [key in ImpactScoreKeyType]?: number;
};

export const conceptApi = {
  detail: (pid: string, tk?: string) =>
    axiosInstance.get<CustomResponseFormat<ConceptType>>(
      `${CONCEPT_DOMAIN}/${CONCEPT_DETAIL_ENDPOINT}`,
      { params: { pid: pid, tk: tk } }
    ),
  tmpLoad: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<ConceptType>>(
      `${CONCEPT_DOMAIN}/${CONCEPT_TMPLOAD_ENDPOINT}`,
      { params: { pid: pid } }
    ),
  tmpSave: (pid: string, body: ConceptType) =>
    axiosInstance.post<void>(
      `${CONCEPT_DOMAIN}/${CONCEPT_TMPSAVE_ENDPOINT}`,
      body,
      { params: { pid: pid } }
    ),
  confirm: (pid: string, conceptNoteKey: string) =>
    axiosInstance.post<void>(
      `${CONCEPT_DOMAIN}/${CONCEPT_CONFIRM_ENDPOINT}`,
      { [conceptNoteKey]: true },
      { params: { pid: pid } }
    ),

  noteDetail: (pid: string, tk?: string) =>
    axiosInstance.get<CustomResponseFormat<ConceptNoteType>>(
      `${CONCEPT_DOMAIN}/${CONCEPT_NOTE_DETAIL_ENDPOINT}`,
      { params: { pid: pid, tk: tk } }
    ),
  noteUpdate: (pid: string, body: ConceptType & { reason?: string }) =>
    axiosInstance.post<void>(
      `${CONCEPT_DOMAIN}/${CONCEPT_NOTE_UPDATE_ENDPOINT}`,
      body,
      { params: { pid: pid } }
    ),

  memoLoad: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<MemoType>>(
      `${CONCEPT_DOMAIN}/${CONCEPT_MEMOLOAD_ENDPOINT}`,
      { params: { pid: pid } }
    ),
  memoSave: (pid: string, memo: string) =>
    axiosInstance.post<void>(
      `${CONCEPT_DOMAIN}/${CONCEPT_MEMOSAVE_ENDPOINT}`,
      { memo: memo },
      { params: { pid: pid } }
    ),

  addAutomaticThought: (
    pid: string,
    thoughtRecordKey: string,
    isForValue: boolean
  ) =>
    axiosInstance.post<void>(
      `${CONCEPT_DOMAIN}/${CONCEPT_NOTE_AUTOMATICTHOUGHT_ADD_ENDPOINT}`,
      { thoughtRecordKey: thoughtRecordKey, isForValue: isForValue },
      { params: { pid: pid } }
    ),
  deleteAutomaticThought: (pid: string, automaticThoughtKey: string) =>
    axiosInstance.post<void>(
      `${CONCEPT_DOMAIN}/${CONCEPT_NOTE_AUTOMATICTHOUGHT_DELETE_ENDPOINT}`,
      { automaticThoughtKey: automaticThoughtKey },
      { params: { pid: pid } }
    ),

  confirmConceptNote: (pid: string, conceptNoteKey: string) =>
    axiosInstance.post<void>(
      `${CONCEPT_DOMAIN}/${CONCEPT_NOTE_CONFIRM_ENDPOINT}`,
      { [conceptNoteKey]: true },
      { params: { pid: pid } }
    ),

  getReferenceDetail: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<ReferenceListType>>(
      `${CONCEPT_DOMAIN}/${CONCEPT_REFERENCE_DETAIL_ENDPOINT}`,
      { params: { pid: pid } }
    ),

  addReference: (pid: string, conceptType: string, reference: string) =>
    axiosInstance.post<void>(
      `${CONCEPT_DOMAIN}/${CONCEPT_NOTE_REFERENCE_ADD_ENDPOINT}`,
      { conceptType: conceptType, reference: reference },
      { params: { pid: pid } }
    ),

  submitImpactScore: (body: ImpactScoreType) =>
    axiosInstance.post<void>(
      `${CONCEPT_DOMAIN}/${CONCEPT_IMPACTSCORE_SUBMIT_ENDPOINT}`,
      body
    ),
};
