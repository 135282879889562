import { CircularProgress, Stack } from "@mui/joy";
import { useEffect, useState } from "react";
import useRefreshTokenWeb from "hooks/useRefreshTokenWeb";
import useLogoutWeb from "hooks/useLogoutWeb";
import ThoughtRecordArchive from "./ThoughtRecordArchive";
import ThoughtRecordContainer from "./ThoughtRecordContainer";
import { useRecoilValue } from "recoil";
import { tokenAtom } from "recoil/tokenAtom";
import { setAuthHeaders } from "api2/instance";
import Button from "./Button";
import Login from "./Login";
import { isDev } from "config";

export default function ThoughtRecordTool() {
  const [refreshTokenResult, refreshToken, errorData] = useRefreshTokenWeb();

  const logoutWeb = useLogoutWeb({
    url: `/tool/logout`,
  });

  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined);
  const tokenState = useRecoilValue(tokenAtom);

  useEffect(() => {
    refreshToken();
  }, []);

  function login(accessToken: string) {
    setIsLoggedIn(true);
    setAuthHeaders(accessToken);
  }

  useEffect(() => {
    if (refreshTokenResult || errorData) {
      const accessToken = refreshTokenResult?.accessToken;
      if (accessToken) {
        login(accessToken);
      } else {
        setIsLoggedIn(false);
      }
    }
  }, [refreshTokenResult, errorData]);

  useEffect(() => {
    if (tokenState) {
      if (tokenState?.accessToken) {
        login(tokenState?.accessToken);
      } else {
        setIsLoggedIn(false);
        refreshToken();
      }
    }
  }, [tokenState]);

  return (
    <ThoughtRecordContainer>
      {isLoggedIn === undefined ? (
        <Stack justifyContent={"center"} alignItems="center" sx={{ flex: 1 }}>
          <CircularProgress color="neutral" />
        </Stack>
      ) : !isLoggedIn ? (
        <Login />
      ) : (
        <>
          {/* <Stack
            direction="row"
            justifyContent={"end"}
            sx={{ position: "fixed", right: "20px", top: "20px" }}
          >
            {isDev && (
              <Button
                onClick={() => {
                  logoutWeb();
                }}
                buttonText={"로그아웃"}
                size="sm"
              />
            )}
          </Stack> */}
          <ThoughtRecordArchive />
        </>
      )}
    </ThoughtRecordContainer>
  );
}
