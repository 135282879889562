import { useEffect, useState } from "react";

export default function useIsBeta() {
  const [isBetaHost, setIsBetaHost] = useState(false);

  useEffect(() => {
    const host = window.location.host;
    if (host === "beta-www.distancing.im") {
      setIsBetaHost(true);
    } else {
      setIsBetaHost(false);
    }
  }, []);

  return isBetaHost || true;
}
