import { Box, Stack } from "@mui/joy";
import { useMediaQuery } from "@mui/material";
import { theme } from "styles/theme";
import OverviewItem from "./OverviewItem";

export default function Overview() {
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Stack
      direction={isSmall ? "row" : "column"}
      justifyContent="center"
      sx={{
        width: "100%",
        py: "8px",
      }}
    >
      {["progress", "thoughtmap", "valueCompass"].map((each, index) => (
        <Stack direction={"row"} sx={{ flex: 1 }} alignItems="center">
          <Stack sx={{ flex: 1 }} {...(isSmall && { alignItems: "center" })}>
            <OverviewItem taskKey={each} />
          </Stack>
          {isSmall && index < 2 && (
            <Box
              sx={{ width: "1px", height: "15px", backgroundColor: "divider" }}
            />
          )}
        </Stack>
      ))}
    </Stack>
  );
}
