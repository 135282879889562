import { axiosInstance, type CustomResponseFormat } from "api/instance";
import { TaskType } from "./taskApi";

export const HOME_DOMAIN = "home";

export const HOME_TASK_LIST_ENDPOINT = "task/list";
export const HOME_THOUGHTRECORD_LIST_ENDPOINT = "thought-record/list";
export const HOME_MEDITATION_LIST_ENDPOINT = "meditation/list";

export interface ToolItemType {
  title: string;
  date: string;
  isDone: boolean;
  isOpen: boolean;
  lastDoneAt?: string;
}

export interface ThoughtRecordItemType extends ToolItemType {
  thoughtRecordKey: string;
}
export interface MeditationItemType extends ToolItemType {
  meditationRecordKey: string;
  durationMinutes: number;
  isOldVersion?: boolean;
}

export const homeApi = {
  getList: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<TaskType[]>>(
      `${HOME_DOMAIN}/${HOME_TASK_LIST_ENDPOINT}`,
      { params: { pid: pid } }
    ),
  getThoughtRecordList: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<ThoughtRecordItemType[]>>(
      `${HOME_DOMAIN}/${HOME_THOUGHTRECORD_LIST_ENDPOINT}`,
      { params: { pid: pid } }
    ),
  getMeditationList: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<MeditationItemType[]>>(
      `${HOME_DOMAIN}/${HOME_MEDITATION_LIST_ENDPOINT}`,
      { params: { pid: pid } }
    ),
};
