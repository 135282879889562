import { Stack, Typography } from "@mui/joy";
import Link from "components/common/Link";
import { useState } from "react";
import { addAlpha } from "styles/hexOpacity";

export default function Reviews() {
  const data: {
    text: string;
    from: string;
    store: "appStore" | "playStore";
  }[] = [
    {
      text: "리뷰를 잘 남기지 않는 편이지만, 저에게 무척 도움이 된 프로그램이라 많은 사람들이 도전해볼 수 있게 하고 싶어서 이야기 남겨봅니다. 누구나 자신만의 대나무숲을 바라지만, 사람과의 관계에서 일방적으로 털어놓기만 하는 건 상대방에게도 감정 소모를 요하기 때문에 어렵지요. 하물며 대면으로 마주보고 하는 상담은 말하는 저부터가 힘겨웠습니다. 코치님께서는 제 이야기를 들어주시지만, 저에 대해 판단하지 않습니다. 그게 정말 기뻤고 감사한 부분이에요. 주어진 활동들도 유익하게 느껴지고, 어떤 대목에서는 '아, 내가 그렇구나.'하는 깨달음도 얻을 수 있었습니다. 색다른 용어들도 습득할 수 있고, 생각기록지를 통해 제가 어떤 사고방식을 통해 특정 감정을 느끼는지 객관적으로 바라볼 수 있게 됩니다. 병원에 가서 상담하는 것보다 훨씬 더 좋은 결과를 가질 수 있겠다는 희망이 들었습니다. 저 말고 다른 많은 분들도 해당 프로그램을 통해 편안한 마음을 가질 수 있게 되었으면 좋겠어요. 행복하시길 빌어요♡",
      from: "레****",
      store: "playStore",
    },
    {
      text: "심리적 불안이 심해 일상생활이 힘들고 불편했는데 갑자기 인스타 광고로 떠서 무료체험 해보고 좋아서 결제하고 사용중입니다. 아직 완전히 좋아지기엔 시간이 더 걸리겠지만 일상생활이 좀 더 편안해졌고, 분노,우울,화가 나기 시작하면 그날 하루를 그냥 망쳐버리는 일이 많았는데 감정적인 부분을 이성적으로 판단하고 컨트롤 할 수 있게 되었어요. 피드백도 빨라서 좋습니다.",
      from: "n****",
      store: "playStore",
    },
    {
      text: "지금까지 심리상담 어플을 5가지 이상 설치하고 사용해봤는데요, 그중 디스턴싱 어플이 제일 도움이 많이 되는 것 같습니다! 다른곳에서 3만원가량의 심리 테스트를 해보아도 형식적인 테스트 결과만 받게 되었는데요. 디스턴싱에서 일주일간 무료로 받은 상담체험이 더 큰 도움이 되었습니다. 앞으로 정기구독으로 상담 서비스를 이용해보려고 합니다. 앞으로도 도움이 많이 되면 좋겠습니다..",
      from: "부****",
      store: "playStore",
    },
    {
      text: "한줄기 빛입니다. 이유를 모르게 마음이 혼란할 때가 종종 있어서 괴로운데, 디스턴싱을 하면서 안정을 찾게 되는 것 같아요. 마음의 평화가 필요하긴분들께 적극 추천합니다!!!!",
      from: "H****",
      store: "appStore",
    },
    {
      text: "제발 안망하게 많이 이용해주세요. 이거 망하면 저 패닉와요 진짜.. 나 자신의 불안하거나 우울한 왜곡된 사고를 끊어주고 거리두며 다른 관점으로 볼 수 있게 도와주는.. 이걸 시간과 장소에 구애받지 않고 할 수 있게 해주는.. 최고의 앱, 최고의 서비스입니다..",
      from: "쥐****",
      store: "appStore",
    },
    {
      text: "생각이 많은 편이라 상담을 갔다온 뒤에 아.. 이 말을 했어야하는데 하면서 후회한다거나 즉각적으로 상담사님의 질문에 대답을 잘 못하는게 너무 답답했거든요! 근데 오히려 글이 편한 사람으로써 너무 잘 이용하고 있습니다. 오히려 저의 생각을 잘 정리해서 말할 수 있고, 기록이 남게 되니까 그걸 돌이켜보면서 새롭게 떠오르는 생각도 코치님과 나눌 수 있어서 좋아요.",
      from: "기****",
      store: "appStore",
    },
    {
      text: "너무 도움이 많이 되고있어서 디스턴싱을 알게되서 다행이다라는 생각도 들어요! 디스턴싱 활동을 하는 자체가 저에게 힘이 됩니다ㅎㅎ 감사합니다!!",
      from: "마****",
      store: "playStore",
    },
    {
      text: "심리 치료는 맞는 선생님을 찾아야 하는 과정이 버거웠는데 어느날 저와 비슷한 고민을 가진분의 인스타 광고를 보고 시작하게 되었어요. 이제는 처방받은 약에대한 의존도가 줄어가는게 느껴져요. 코치님 영향인 것 같아요.",
      from: "쯀마****",
      store: "playStore",
    },
    {
      text: "프로그램이 너무 도움이 돼서 추천하고 싶어요. 코치님은 이야기를 들어주시면서도 판단하지 않아서 편안했고, 활동들로 깨달음도 얻었어요. 다른 사람들도 이 프로그램을 통해 편안함을 느낄 수 있었으면 좋겠어요.",
      from: "레****",
      store: "playStore",
    },
  ];

  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <Stack
      direction={"column"}
      spacing={"16px"}
      sx={{ width: "100%" }}
      alignItems="center"
    >
      {data
        .filter((e, i) => (isOpen ? true : i < 3))
        .map(({ text, from, store }) => (
          <Stack
            key={text}
            direction={"column"}
            spacing="12px"
            sx={{
              width: "100%",
              borderRadius: "12px",
              backgroundColor: "primary.100",
              p: "24px",
            }}
          >
            <Typography level="body-md">{text}</Typography>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                sx={{ fontSize: "13px", fontWeight: 900 }}
                endDecorator={
                  <Typography
                    sx={{
                      px: "6px",
                      py: "4px",
                      borderWidth: "1px",
                      borderRadius: "6px",
                      borderColor: addAlpha("#000000", 0.1),
                      fontSize: "10px",
                      color: addAlpha("#000000", 0.6),
                    }}
                  >{`${
                    store === "playStore" ? "Play Store" : "App Store"
                  } 후기`}</Typography>
                }
              >
                {from}
              </Typography>
            </Stack>
          </Stack>
        ))}
      {!isOpen && (
        <Typography
          level="body-md"
          sx={{ opacity: 0.5, cursor: "pointer" }}
          onClick={() => [setIsOpen(true)]}
          endDecorator={
            <svg
              width="9"
              height="5"
              viewBox="0 0 9 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.75 1L4.25 4.5L7.75 1"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
        >
          더 보기
        </Typography>
      )}
    </Stack>
  );
}
