import { atom } from "recoil";

interface LayoutType {
  taskWidth: "full" | "normal";
  isLeftSideBarOpen?: boolean;
  isRightSideBarOpen?: boolean;
  isTaskContainerSmall?: boolean;
  showQuillToolbar?: boolean;
}
export const layoutAtom = atom<LayoutType>({
  key: "layout",
  default: {
    taskWidth: "normal",
    isLeftSideBarOpen: false,
    isRightSideBarOpen: false,
    isTaskContainerSmall: false,
    showQuillToolbar: undefined,
  },
  // effects: [
  //   ({ setSelf, onSet }) => {
  //     const savedData = sessionStorage.getItem("redirect-url");
  //     // setSelf: atom 값을 설정 혹은 재설정
  //     if (savedData) setSelf(savedData);

  //     // atom이 변화가 감지될 때 작동, Storage에 데이터 저장
  //     // setSelf에 의해서는 작동하지 않음
  //     onSet((newValue, _, isReset) => {
  //       isReset
  //         ? sessionStorage.removeItem("redirect-url")
  //         : newValue
  //         ? sessionStorage.setItem("redirect-url", newValue)
  //         : sessionStorage.removeItem("redirect-url");
  //     });
  //   },
  // ],
});
