import { ButtonGroup } from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithImage,
  InstructionWithTextarea,
} from "../BlockComponent";

import {
  AutomaticThoughtTemplate,
  CopingStrategyTemplate,
  LastCard,
} from "./common";

export const Content_6_98_A = [
  Instruction(`typography_0_0_0`),
  Instruction(`typography_1_0_0`),
  Instruction(`typography_2_0_0`),
  Instruction(`typography_3_0_0`),
  Instruction(`typography_4_0_0`),
  Instruction(`typography_5_0_0`),
  Instruction(`typography_6_0_0`),

  Header1(`typography_7_0_0`),
  Instruction(`typography_8_0_0`),
  Instruction(`typography_9_0_0`),
  Instruction(`typography_10_0_0`),
  Instruction(`typography_11_0_0`),
  Instruction(`typography_12_0_0`),
  Instruction(`typography_13_0_0`),
  Instruction(`typography_14_0_0`),

  Header1(`typography_15_0_0`),
  Instruction(`typography_16_0_0`),
  Instruction(`typography_17_0_0`),
  Instruction(`typography_18_0_0`),
  Instruction(`typography_19_0_0`),
  Instruction(`typography_20_0_0`),
  Instruction(`typography_21_0_0`),
  Instruction(`typography_22_0_0`),
  Instruction(`typography_23_0_0`),
  Instruction(`typography_24_0_0`),

  Header1(`typography_25_0_0`),
  Instruction(`typography_26_0_0`),
  Instruction(`typography_27_0_0`),
  Instruction(`typography_28_0_0`),
  Instruction(`typography_29_0_0`),
  Instruction(`typography_30_0_0`),
  Instruction(`typography_31_0_0`),
  Instruction(`typography_32_0_0`),
  Instruction(`typography_33_0_0`),
  Instruction(`typography_34_0_0`),
  Instruction(`typography_35_0_0`),

  Header1(`typography_36_0_0`),
  Instruction(`typography_37_0_0`),
  Instruction(`typography_38_0_0`),
  Instruction(`typography_39_0_0`),
  Instruction(`typography_40_0_0`),
  Instruction(`typography_41_0_0`),
  Instruction(`typography_42_0_0`),
  Instruction(`typography_43_0_0`),

  Header1(`typography_44_0_0`),
  Instruction(`typography_45_0_0`),
  Instruction(`typography_46_0_0`),
  Instruction(`typography_47_0_0`),
  Instruction(`typography_48_0_0`),
  Instruction(`typography_49_0_0`),
  Instruction(`typography_50_0_0`),
  Instruction(`typography_51_0_0`),
  Instruction(`typography_52_0_0`),
  Instruction(`typography_53_0_0`),
  Instruction(`typography_54_0_0`),
  Instruction(`typography_55_0_0`),

  Header1(`typography_56_0_0`),
  InstructionWithImage(`typography_57_0_0`, "0"),
  Instruction(`typography_58_0_0`),
  InstructionWithImage(`typography_59_0_0`, "1"),
  Instruction(`typography_60_0_0`),
  Instruction("typography_61_0_0"),

  InstructionWithImage(`typography_62_0_0`, "2"),
  InstructionWithImage(`typography_63_0_0`, "3"),
  InstructionWithImage(`typography_64_0_0`, "4"),
  Instruction(`typography_65_0_0`),
  Instruction(`typography_66_0_0`),
  Instruction(`typography_67_0_0`),
  Instruction(`typography_68_0_0`),
  Instruction(`typography_69_0_0`),
  Instruction("typography_70_0_0"),
  Instruction(`typography_71_0_0`),

  Header1(`typography_72_0_0`),
  Instruction(`typography_73_0_0`),
  Instruction(`typography_74_0_0`),
  Instruction(`typography_75_0_0`),
  Instruction(`typography_76_0_0`),
  InstructionWithContent(
    `typography_76_0_0`,
    CopingStrategyTemplate({ isRaw: true })
  ),
  InstructionWithContent(`typography_77_0_0`, [
    [
      ButtonGroup([
        {
          translationKey: "네",
          showFromId: "동의",
          hideFromId: "비동의",
        },
        {
          translationKey: "아니요",
          showFromId: "비동의",
          hideFromId: "동의",
        },
      ]),
    ],
  ]),

  InstructionWithTextarea("typography_78_0_0", undefined, {
    blockId: "비동의",
    hideIfCoach: true,
  }),
  Instruction("typography_79_0_0", {
    noArrow: true,
    isEnd: true,
    hideIfCoach: true,
  }),

  InstructionWithTextarea(`typography_80_0_0`, undefined, { blockId: "동의" }),
  Instruction(`typography_81_0_0`),

  ...LastCard,
];
