import { Box, Stack, Typography } from "@mui/joy";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ThoughtTrapItemType,
  toolApi,
  TOOL_THOUGHTRECORD_DOMAIN,
  TOOL_THOUGHTRECORD_TRAP_CHECK_ENDPOINT,
  TOOL_THOUGHTRECORD_TRAP_LIST_ENDPOINT,
} from "api2/toolApi";
import dayjs from "dayjs";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/paths";
import { addAlpha } from "styles/hexOpacity";
import Breadcrumb from "./Breadcrumb";
import { NewThoughtTrapIndicator } from "./HorizontalCalender";
import { ElementContainer, EmptyCase } from "./Isolation";
import ThoughtRecordContainer from "./ThoughtRecordContainer";

function ThoughtTrap({
  thought,
  date,
  isNew,
  onClick,
}: {
  thought: string;
  date: string;
  isNew: boolean;
  onClick: () => void;
}) {
  return (
    <ElementContainer onClick={onClick}>
      <Stack spacing={"8px"}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#222222",
          }}
        >
          {thought}
        </Typography>
        <Stack direction={"row"} spacing="4px" alignItems="center">
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: addAlpha("#222222", 0.6),
            }}
          >
            {dayjs(date).format("M월 D일")}
          </Typography>
          {isNew && <NewThoughtTrapIndicator />}
        </Stack>
      </Stack>
    </ElementContainer>
  );
}

export default function ThoughtTrapList() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [thoughtTrapList, setThoughtTrapList] =
    useState<ThoughtTrapItemType[]>();

  const getThoughtTrapQueryFn = useCallback(
    () => toolApi.getThoughtTrapList(),
    []
  );

  useQuery(
    [TOOL_THOUGHTRECORD_DOMAIN, TOOL_THOUGHTRECORD_TRAP_LIST_ENDPOINT],
    getThoughtTrapQueryFn,
    {
      onSuccess: (data) => {
        if (data.result) {
          setThoughtTrapList(data.result);
        }
      },
    }
  );

  const [selectedThoughtTrap, setSelectedThoughtTrap] =
    useState<ThoughtTrapItemType>();

  const checkThoughtTrapQueryFn = useCallback(
    () => toolApi.checkThoughtTrap(selectedThoughtTrap?.thoughtTrapId || ""),
    [selectedThoughtTrap]
  );

  useQuery(
    [
      TOOL_THOUGHTRECORD_DOMAIN,
      TOOL_THOUGHTRECORD_TRAP_CHECK_ENDPOINT,
      selectedThoughtTrap?.thoughtTrapId,
    ],
    checkThoughtTrapQueryFn,
    {
      enabled: !!selectedThoughtTrap,
      onSuccess: () => {
        const path = PATH.thoughtRecordTool_isolation.replace(
          ":ttr",
          selectedThoughtTrap?.thoughtRecordId || ""
        );
        navigate(path, { state: { isFromThoughtTrap: true } });
        setSelectedThoughtTrap(undefined);
        queryClient.invalidateQueries([
          TOOL_THOUGHTRECORD_DOMAIN,
          TOOL_THOUGHTRECORD_TRAP_LIST_ENDPOINT,
        ]);
      },
    }
  );

  const thoughtTrapDataList = [
    {
      name: "근거 부족",
      placeholder: "균형 있게 따져보지 못하게 되는 생각함정",
    },
    {
      name: "부정 편향",
      placeholder: "부정적인 미래만 바라보게 되는 생각함정",
    },
    {
      name: "책임 과다",
      placeholder: "자신에게 과한 책임을 묻게 되는 생각함정",
    },
    // { name: "평가", placeholder: "가치 평가를 사실로 믿게 되는 생각함정" },
    // { name: "개념화", placeholder: "자신을 정당화하게 되는 생각함정" },
    // { name: "반추", placeholder: "목적 없이 곱씹게 되는 생각함정" },
  ];

  function getListByThoughtTrap(
    list: ThoughtTrapItemType[],
    thoughtTrap: string
  ) {
    return list.filter((element) => element.thoughtTrap === thoughtTrap);
  }

  return (
    <ThoughtRecordContainer>
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          pt: "12px",
          overflow: "hidden",
        }}
      >
        <Box sx={{ width: "100%", px: "24px", mb: "17px" }}>
          <Breadcrumb
            items={[
              { iconType: "home", stepBack: 1 },
              {
                text: "찾은 생각함정",
                stepBack: 0,
              },
            ]}
          />
        </Box>
        <Stack
          sx={{
            width: "100%",
            flex: 1,
            overflow: "scroll",
            px: "20px",
            pt: "20px",
            pb: "112px",
          }}
        >
          <Stack spacing={"36px"}>
            {thoughtTrapDataList.map(({ name, placeholder }) => {
              const currentThoughtList = getListByThoughtTrap(
                thoughtTrapList || [],
                name
              );
              return (
                <Stack key={name} spacing={"12px"}>
                  <Typography
                    sx={{ fontSize: "20px", fontWeight: 600 }}
                  >{`${name} ${currentThoughtList.length}`}</Typography>
                  {currentThoughtList.length === 0 ? (
                    <EmptyCase placeholder={placeholder} />
                  ) : (
                    <Stack spacing={"8px"}>
                      {currentThoughtList.map((item) => (
                        <ThoughtTrap
                          key={item.thoughtTrapId}
                          thought={item.thought}
                          date={item.date}
                          isNew={item.isNew}
                          onClick={() => {
                            setSelectedThoughtTrap(item);
                          }}
                        />
                      ))}
                    </Stack>
                  )}
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </ThoughtRecordContainer>
  );
}
