export const thoughtmapResource = {
  en: {
    "핵심 경험": "Core Experience",
    "핵심 믿음": "Core Belief",
    "중간 믿음": "Intermediate Belief",
    규칙: "Rules",
    태도: "Attitudes",
    가정: "Assumptions",
    "대처 전략": "Coping Strategy",
    "굴복 - 핵심 믿음을 그대로 따르기":
      "Surrender - Following the core beliefs as it is",
    "과잉보상 - 핵심 믿음이 아님을 증명하기":
      "Overcompensation - Proving the core beliefs is not true",
    "회피 - 핵심 믿음을 마주하지 않기":
      "Avoidance - Not facing the core beliefs",
    상황: "Situation",
    생각: "Thought",
    "영향 정도": "Impact level",
    "감정/신체": "Emotion/Body",
    행동: "Behavior",
    "자동적 사고": "Automatic thought",
  },
  ko: {
    "핵심 경험": "핵심 경험",
    "핵심 믿음": "핵심 믿음",
    "중간 믿음": "중간 믿음",
    규칙: "규칙",
    태도: "태도",
    가정: "가정",
    "대처 전략": "대처 전략",
    "굴복 - 핵심 믿음을 그대로 따르기": "굴복 - 핵심 믿음을 그대로 따르기",
    "과잉보상 - 핵심 믿음이 아님을 증명하기":
      "과잉보상 - 핵심 믿음이 아님을 증명하기",
    "회피 - 핵심 믿음을 마주하지 않기": "회피 - 핵심 믿음을 마주하지 않기",
    상황: "상황",
    생각: "생각",
    "영향 정도": "영향 정도",
    "감정/신체": "감정/신체",
    감정: "감정",
    신체: "신체",
    "신체 반응": "신체 반응",
    행동: "행동",
    "행동/충동": "행동/충동",
    "자동적 사고": "자동적 사고",
  },
};
