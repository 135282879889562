import { useEffect, useState } from "react";
import { ProgramType } from "data/programData";
import * as _ from "lodash";
import useFetch from "./useFetch";
import useGetBlankThoughtRecordData from "./useGetBlankThoughtRecordData_v2";
import { setDataWithEditorKeyAndTranslationVersion } from "./useGetBlankTaskData";

export default function useGetThoughtRecordData({
  trk,
  isFirst,
}: {
  trk?: string;
  isFirst?: boolean;
}) {
  const [data, setData] = useState<ProgramType>();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const blankThoughtRecordData = useGetBlankThoughtRecordData({
    trk: trk,
    isFirst: isFirst,
  });

  const [fetchedData, getData] = useFetch({
    url: "/thought-record/data/load",
    params: { trk: trk },
  });

  useEffect(() => {
    if (trk && isFirst !== undefined) {
      getData();
      setIsFetching(true);
    }
  }, [trk]);

  useEffect(() => {
    if (fetchedData) {
      const totalData = (fetchedData?.result || {}).data;
      if (totalData && totalData.content.length > 0) {
        console.log("FETCH: ", totalData);
        setData(setDataWithEditorKeyAndTranslationVersion(totalData));
      } else {
        console.log("RAW: ", blankThoughtRecordData);
        setData(blankThoughtRecordData);
      }
    }
  }, [fetchedData]);

  useEffect(() => {
    if (data) {
      setIsFetching(false);
    }
  }, [data]);

  return { data, refetch: getData, isFetching };
}
