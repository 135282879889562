import { TypographyLine } from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithData,
  InstructionWithImage,
  InstructionWithTextarea,
} from "../BlockComponent";

import { LastCard } from "./common";

// export const Content_4_4_5_A = [
//   Instruction(`typography_0_0_0`),
//   Instruction(`typography_1_0_0`),
//   Instruction(`typography_2_0_0`),
//   Instruction(`typography_3_0_0`),
//   Instruction(`typography_4_0_0`),
//   Instruction(`typography_5_0_0`),

//   Header1(`typography_6_0_0`),
//   Instruction(`typography_7_0_0`),
//   InstructionWithImage(`typography_8_0_0`, "0.png"),
//   Instruction(`typography_9_0_0`),
//   InstructionWithTextarea(`typography_10_0_0`, { id: "개념화 1" }),
//   InstructionWithTextarea(`typography_11_0_0`, {
//     id: "개념화 2",
//   }),
//   InstructionWithTextarea(`typography_12_0_0`, {
//     id: "개념화 3",
//   }),
//   InstructionWithTextarea(`typography_13_0_0`, {
//     id: "개념화 4",
//   }),
//   InstructionWithTextarea(`typography_14_0_0`),
//   Instruction(`typography_15_0_0`),
//   InstructionWithData({ A: { id: "개념화 1" } }, `typographyFromData_16_0_0`),
//   InstructionWithData({ A: { id: "개념화 2" } }, `typographyFromData_17_0_0`),
//   InstructionWithData({ A: { id: "개념화 3" } }, `typographyFromData_18_0_0`),
//   InstructionWithData({ A: { id: "개념화 4" } }, `typographyFromData_19_0_0`),
//   InstructionWithTextarea(`typography_20_0_0`),
//   Instruction(`typography_21_0_0`),
//   Instruction(`typography_22_0_0`),
//   Instruction(`typography_23_0_0`),
//   Instruction(`typography_24_0_0`),
//   Instruction(`typography_25_0_0`),
//   Instruction(`typography_26_0_0`),
//   InstructionWithImage(`typography_27_0_0`, "1.png"),

//   Header1(`typography_28_0_0`),
//   Instruction(`typography_29_0_0`),
//   Instruction(`typography_30_0_0`),
//   Instruction(`typography_31_0_0`),
//   Instruction(`typography_32_0_0`),
//   Instruction(`typography_33_0_0`),
//   Instruction(`typography_34_0_0`),
//   Instruction(`typography_35_0_0`),
//   Instruction(`typography_36_0_0`),
//   Instruction(`typography_37_0_0`),
//   Instruction(`typography_38_0_0`),
//   Instruction(`typography_39_0_0`),
//   Instruction(`typography_40_0_0`),
//   Instruction(`typography_41_0_0`),
//   InstructionWithImage(`typography_42_0_0`, "2.png"),
//   Instruction(`typography_43_0_0`),
//   InstructionWithContent(`typography_44_0_0`, [
//     TypographyLine(`typography_44_2_0`),
//   ]),
//   Instruction(`typography_45_0_0`),
//   Instruction(`typography_46_0_0`),
//   Instruction(`typography_47_0_0`),
//   Instruction(`typography_48_0_0`),
//   Instruction(`typography_49_0_0`),
//   Instruction(`typography_50_0_0`),

//   Header1(`typography_51_0_0`),
//   Instruction(`typography_52_0_0`),
//   InstructionWithImage(`typography_53_0_0`, "3.png"),
//   InstructionWithImage(`typography_54_0_0`, "4.png"),
//   InstructionWithImage(`typography_55_0_0`, "5.png"),
//   Instruction(`typography_56_0_0`),
//   Instruction(`typography_57_0_0`),
//   Instruction(`typography_58_0_0`),
//   Instruction(`typography_59_0_0`),
//   Instruction(`typography_60_0_0`),
//   Instruction(`typography_61_0_0`),
//   Instruction(`typography_62_0_0`),
//   Instruction(`typography_63_0_0`),
//   Instruction(`typography_64_0_0`),
//   Instruction(`typography_65_0_0`),
//   Instruction(`typography_66_0_0`),
//   Instruction(`typography_67_0_0`),
//   Instruction(`typography_68_0_0`),

//   ...LastCard,
// ];

export const Content_4_4_5_A = [
  Instruction(`typography_0`),
  Instruction(`typography_1`),
  Instruction(`typography_2`),
  Instruction(`typography_3`),
  Instruction(`typography_4`),
  Instruction(`typography_5`),

  Header1(`typography_6`),
  Instruction(`typography_7`),
  Instruction(`typography_8`),
  Instruction(`typography_9`),
  InstructionWithTextarea(`typography_10`, { id: "개념화 1" }),
  InstructionWithTextarea(`typography_11`, {
    id: "개념화 2",
  }),
  InstructionWithTextarea(`typography_12`, {
    id: "개념화 3",
  }),
  InstructionWithTextarea(`typography_13`, {
    id: "개념화 4",
  }),
  InstructionWithTextarea(`typography_14`),
  Instruction(`typography_15`),
  InstructionWithData({ A: { id: "개념화 1" } }, `typography_16`),
  InstructionWithData({ A: { id: "개념화 2" } }, `typography_17`),
  InstructionWithData({ A: { id: "개념화 3" } }, `typography_18`),
  InstructionWithData({ A: { id: "개념화 4" } }, `typography_19`),
  InstructionWithTextarea(`typography_20`),
  Instruction(`typography_21`),
  Instruction(`typography_22`),
  Instruction(`typography_23`),
  Instruction(`typography_24`),
  Instruction(`typography_25`),
  Instruction(`typography_26`),
  InstructionWithImage(`typography_27`, "1"),
  Instruction(`typography_28`),

  Header1(`typography_29`),
  Instruction(`typography_30`),
  Instruction(`typography_31`),
  Instruction(`typography_32`),
  Instruction(`typography_33`),
  Instruction(`typography_34`),
  Instruction(`typography_35`),
  Instruction(`typography_36`),
  Instruction(`typography_37`),
  Instruction(`typography_38`),
  Instruction(`typography_39`),
  Instruction(`typography_40`),
  Instruction(`typography_41`),
  Instruction(`typography_42`),
  Instruction(`typography_43`),
  Instruction(`typography_44`),
  Instruction(`typography_45`),

  Header1(`typography_46`),
  Instruction(`typography_47`),
  Instruction(`typography_48`),
  Instruction(`typography_49`),
  Instruction(`typography_50`),
  Instruction(`typography_51`),
  Instruction(`typography_52`),
  Instruction(`typography_53`),
  Instruction(`typography_54`),
  Instruction(`typography_55`),
  Instruction(`typography_56`),
  Instruction(`typography_57`),
  Instruction(`typography_58`),
  Instruction(`typography_59`),

  Header1(`typography_60`),
  Instruction(`typography_61`),
  Instruction(`typography_62`),
  Instruction(`typography_63`),
  Instruction(`typography_64`),
  Instruction(`typography_65`),
  Instruction(`typography_66`),
  Instruction(`typography_67`),
  Instruction(`typography_68`),
  Instruction(`typography_69`),
  Instruction(`typography_70`),
  Instruction(`typography_71`),
  Instruction(`typography_72`),
  Instruction(`typography_73`),
  Instruction(`typography_74`),
  Instruction(`typography_75`),
  Instruction(`typography_76`),
  Instruction(`typography_77`),
  Instruction(`typography_78`),
  Instruction(`typography_79`),

  Header1(`typography_80`),
  InstructionWithImage(`typography_81`, "2.png"),
  InstructionWithContent(`typography_82`, [TypographyLine(`typography_82_1`)]),
  Instruction(`typography_83`),
  Instruction(`typography_84`),
  Instruction(`typography_85`),
  Instruction(`typography_86`),
  Instruction(`typography_87`),
  Instruction(`typography_88`),

  Header1(`typography_89`),
  Instruction(`typography_90`),
  InstructionWithImage(`typography_91`, "3.png"),
  InstructionWithImage(`typography_92`, "4.png"),
  InstructionWithImage(`typography_93`, "5.png"),
  Instruction(`typography_94`),
  Instruction(`typography_95`),
  Instruction(`typography_96`),
  Instruction(`typography_97`),
  Instruction(`typography_98`),
  Instruction(`typography_99`),
  Instruction(`typography_100`),
  Instruction(`typography_101`),
  Instruction(`typography_102`),
  Instruction(`typography_103`),
  Instruction(`typography_104`),
  Instruction(`typography_105`),
  Instruction(`typography_106`),

  ...LastCard,
];
