import { useCallback, useEffect } from "react";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import {
  getHighlightedBlockNumber,
  getLastShownIndex,
  getReferenceData,
  getTaskIdFromTaskKey,
} from "logic/logics";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { taskApi } from "api";
import { ProgramContentType } from "data/BlockComponent";
import { usePrevious } from "@uidotdev/usehooks";
import * as _ from "lodash";
import {
  thoughtRecordApi,
  THOUGHTRECORD_DOMAIN,
  THOUGHTRECORD_LIST_ENDPOINT,
} from "api/thoughtRecordApi";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { userAtom } from "recoil/userAtom";
import { programDataAtom } from "recoil/programDataAtom";
import useGetTaskTitleFromTaskKey from "hooks/useGetTaskTitleFromTaskKey";
import {
  activityRecordApi,
  ACTIVITYRECORD_DOMAIN,
  ACTIVITYRECORD_LIST_ENDPOINT,
} from "api/activityRecordApi";
import {
  meditationRecordApi,
  MEDITATION_RECORD_DOMAIN,
  MEDITATION_RECORD_LIST_ENDPOINT,
} from "api/meditationRecordApi";

export default function useSaveContentData({
  taskKey,
  data,
  thoughtRecordKey,
  activityRecordKey,
  meditationRecordKey,
  translationVersion,
}: {
  taskKey: string;
  data?: ProgramContentType[];
  thoughtRecordKey?: string;
  activityRecordKey?: string;
  meditationRecordKey?: string;
  translationVersion?: string;
}) {
  const user = useRecoilValue(userAtom);
  const previousData = usePrevious(data);
  const isThoughtRecord = taskKey?.includes("2-2-T") && thoughtRecordKey;
  const isActivityRecord = activityRecordKey !== undefined;
  const isMeditationRecord = meditationRecordKey !== undefined;

  const patientState = useRecoilValue(patientAtom);

  const patientIdFromTaskKey = isThoughtRecord
    ? thoughtRecordKey.split(":")[0]
    : taskKey.split(":")[1];

  const setProgramDataState = useSetRecoilState(programDataAtom);

  const taskId = getTaskIdFromTaskKey(taskKey);
  const automaticThoughtId = taskId.includes("_")
    ? taskId.split("_")[1]
    : undefined;

  const title = useGetTaskTitleFromTaskKey(taskKey);

  const queryClient = useQueryClient();

  const saveActivityQueryFn = useCallback(
    () =>
      taskApi.saveData(`${taskKey}`, {
        taskId: getTaskIdFromTaskKey(`${taskKey}`) || "",
        title: title,
        content: data || [],
        translationVersion: translationVersion,
      }),
    [data]
  );

  const { mutate: saveActivityData } = useMutation(saveActivityQueryFn);

  const saveThoughtRecordQueryFn = useCallback(
    (isRefetchList: boolean) =>
      thoughtRecordApi.saveData(
        `${thoughtRecordKey}`,
        `${patientState?.patientId}`,
        {
          taskId: getTaskIdFromTaskKey(`${taskKey}`) || "",
          title: title,
          content: data || [],
          translationVersion: translationVersion,
          thoughtRecordKey: thoughtRecordKey,
        }
      ),
    [data]
  );

  const { mutate: saveThoughtRecordData } = useMutation(
    saveThoughtRecordQueryFn,
    {
      onSuccess: (_, isRefetchList) => {
        if (isRefetchList) {
          queryClient.invalidateQueries([
            THOUGHTRECORD_DOMAIN,
            THOUGHTRECORD_LIST_ENDPOINT,
          ]);
        }
      },
    }
  );
  const saveActivityRecordQueryFn = useCallback(
    () =>
      activityRecordApi.saveData(
        `${activityRecordKey}`,
        `${patientState?.patientId}`,
        {
          taskId: getTaskIdFromTaskKey(`${taskKey}`) || "",
          title: title,
          content: data || [],
          translationVersion: translationVersion,
        }
      ),
    [data]
  );

  const { mutate: saveActivityRecordData } = useMutation(
    saveActivityRecordQueryFn,
    {
      onSuccess: () => {
        if (
          !_.isEqual(
            getReferenceData(previousData || [], "date"),
            getReferenceData(data || [], "date")
          ) ||
          !_.isEqual(
            getReferenceData(previousData || [], "time"),
            getReferenceData(data || [], "time")
          ) ||
          !_.isEqual(
            getReferenceData(previousData || [], "activity"),
            getReferenceData(data || [], "activity")
          ) ||
          !_.isEqual(
            getReferenceData(previousData || [], "activityRecordKey"),
            getReferenceData(data || [], "activityRecordKey")
          ) ||
          getHighlightedBlockNumber(data || []) !==
            getHighlightedBlockNumber(previousData || [])
        ) {
          queryClient.invalidateQueries([
            ACTIVITYRECORD_DOMAIN,
            ACTIVITYRECORD_LIST_ENDPOINT,
          ]);
        }
      },
    }
  );
  const saveMeditationRecordQueryFn = useCallback(
    (isRefetchList: boolean) =>
      meditationRecordApi.saveData(
        `${meditationRecordKey}`,
        `${patientState?.patientId}`,
        {
          taskId: getTaskIdFromTaskKey(`${taskKey}`) || "",
          title: title,
          content: data || [],
          // translationVersion: translationVersion,
          translationVersion: "v1",
        }
      ),
    [data]
  );

  const { mutate: saveMeditationRecordData } = useMutation(
    saveMeditationRecordQueryFn,
    {
      onSuccess: (_, isRefetchList) => {
        if (isRefetchList) {
          queryClient.invalidateQueries([
            MEDITATION_RECORD_DOMAIN,
            MEDITATION_RECORD_LIST_ENDPOINT,
          ]);
        }
      },
    }
  );

  function save(data: ProgramContentType[]) {
    if (
      user?.accessToken &&
      patientState?.patientId &&
      patientState.patientId === patientIdFromTaskKey
    ) {
      if (isThoughtRecord) {
        if (
          getReferenceData(data || [], "thoughtRecordKey")?.value ===
            thoughtRecordKey &&
          !_.isEqual(previousData, data)
        ) {
          const previousTrk = getReferenceData(
            previousData || [],
            "thoughtRecordKey"
          )?.value;
          const trk = getReferenceData(data || [], "thoughtRecordKey")?.value;
          const isRefetchList =
            previousTrk === trk &&
            ([
              "sentiment",
              "date",
              "situation",
              "situation_indirect",
              "situation_direct",
            ].some(
              (id) =>
                !_.isEqual(
                  getReferenceData(previousData || [], id)?.value,
                  getReferenceData(data || [], id)?.value
                )
            ) ||
              getHighlightedBlockNumber(data || []) !==
                getHighlightedBlockNumber(previousData || []));
          saveThoughtRecordData(isRefetchList);
          if (((data[0] || []).lines[3] || [])[0]?.content?.id === "고민") {
            saveActivityData();
          }
        }
      } else if (isActivityRecord) {
        if (
          getReferenceData(data || [], "activityRecordKey")?.value ===
            activityRecordKey &&
          !_.isEqual(previousData, data)
        ) {
          saveActivityRecordData();
        }
      } else if (isMeditationRecord) {
        if (
          getReferenceData(data || [], "meditationRecordKey")?.value ===
            meditationRecordKey &&
          !_.isEqual(previousData, data)
        ) {
          const previousMrk = getReferenceData(
            previousData || [],
            "thoughtRecordKey"
          )?.value;
          const mrk = getReferenceData(data || [], "thoughtRecordKey")?.value;
          const isRefetchList =
            previousMrk === mrk &&
            (["coping_or_routine"].some(
              (id) =>
                !_.isEqual(
                  getReferenceData(previousData || [], id)?.value,
                  getReferenceData(data || [], id)?.value
                )
            ) ||
              getHighlightedBlockNumber(data || []) !==
                getHighlightedBlockNumber(previousData || []));

          saveMeditationRecordData(isRefetchList);
        }
      } else {
        saveActivityData();
      }
    } else {
      // setProgramDataState({
      //   [getTaskIdFromTaskKey(taskKey) as string]: {
      //     taskId: getTaskIdFromTaskKey(taskKey),
      //     title: title,
      //     content: data || [],
      //     translationVersion: translationVersion,
      //   },
      // });
    }
  }

  useEffect(() => {
    if (data && data.length > 0) {
      if (previousData) {
        const changedIndex = data.findIndex(
          (element, index) => !_.isEqual(element, previousData[index])
        );
        // console.log(
        //   "달라진거: ",
        //   data[changedIndex],
        //   previousData[changedIndex]
        // );
      }
      if (!_.isEqual(previousData, data)) {
        save(data);
      }
    }
  }, [data]);
}
