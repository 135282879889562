import { IconButton } from "@mui/joy";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { taskApi, TASK_DOMAIN, TASK_PRESET_DETAIL_ENDPOINT } from "api";
import ButtonWithModal from "components/common/ButtonWithModal";
import { ProgramType } from "data/programData";
import useGetBlankTaskData from "hooks/useGetBlankTaskData";
import { useEffect } from "react";

export default function InitializeButton({
  taskKey,
  setData,
  isInitialized,
}: {
  taskKey: string;
  setData?: React.Dispatch<React.SetStateAction<ProgramType | undefined>>;
  isInitialized?: boolean;
}) {
  const queryClient = useQueryClient();
  const blankTaskData = useGetBlankTaskData({ taskKey: taskKey });

  const { mutate: activityReset } = useMutation(
    () => taskApi.reset(`${taskKey}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          TASK_DOMAIN,
          TASK_PRESET_DETAIL_ENDPOINT,
          taskKey,
        ]);
      },
    }
  );

  useEffect(() => {
    if (setData && isInitialized) {
      setData(blankTaskData);
    }
  }, [isInitialized]);

  return blankTaskData ? (
    <ButtonWithModal
      buttonText={"초기화하기"}
      title={"정말로 초기화할까요?"}
      actionText={"초기화 하기"}
      actionButtonColor={"danger"}
      action={() => {
        if (setData) {
          setData(undefined);
        }
        activityReset();
      }}
      render={
        <IconButton size="sm" variant="plain" color="neutral">
          <span className="material-symbols-rounded">restart_alt</span>
        </IconButton>
      }
    />
  ) : (
    <></>
  );
}
