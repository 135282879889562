export const translation_6_4_A = {
  en: {
    v1: {
      typography_0_0_0: "Let's dive a bit deeper now.",
      typography_1_0_0: "Ever wondered where automatic thoughts come from?",
      typography_2_0_0: "Are they just random reactions to our environment?",
      typography_3_0_0:
        "Not really. If that were the case, people in similar environments would all think alike.",
      typography_4_0_0:
        "But we know this isn't true. Especially, people with depression or anxiety tend to have more frequent and easily triggered negative automatic thoughts than others.",
      typography_5_0_0:
        "From an early age, people develop certain beliefs about 'themselves,' 'others,' and 'the world.'",
      typography_6_0_0:
        "These central beliefs are so fundamental that we often don't even explain them to ourselves. They're just taken for granted.",
      typography_7_0_0:
        "These deep, intrinsic beliefs about oneself, others, and the world are what we call 'core beliefs.'",
      typography_8_0_0:
        "Core beliefs often form from experiences in childhood, but adult experiences can influence them too.",
      typography_9_0_0:
        "Of course, beliefs don't just spring from experiences alone. Innate temperament plays a big role.",
      typography_10_0_0:
        "Still, when we look at various studies, it's clear that 'core experiences' in childhood or adulthood play a crucial role in forming specific beliefs.",
      typography_11_0_0:
        "Think about someone who has had successful experiences from a young age and received support and encouragement from their parents and others.",
      typography_12_0_0: `Such a person is likely to develop beliefs like "I am quite capable," "I am successful," or "I have potential."`,
      typography_13_0_0:
        "These beliefs can form a positive and deep-rooted identity.",
      typography_14_0_0:
        "But what about someone who grew up always being compared to a more accomplished sibling?",
      typography_15_0_0: `They might develop beliefs like "I am incompetent," "I am lacking," or "I am inferior."`,
      typography_16_0_0:
        "And these beliefs can deeply erode their sense of self.",
      typography_17_0_0: "Automatic thoughts and core beliefs",
      typography_18_0_0: `Core beliefs are like the kernels at the bottom of a popcorn machine.`,
      typography_19_0_0: `They stay hidden deep down and slowly create popcorn.`,
      typography_20_0_0:
        "Core beliefs are the producers of automatic thoughts.",
      typography_21_0_0:
        "For example, imagine someone whose long-term project failed.",
      typography_22_0_0: `If they have the core belief "I am quite capable,"`,
      typography_23_0_0: `they might think, "I did my best, but it's unfortunate," "There's something to learn from failure," or "It's a pity this time."`,
      typography_24_0_0: `But what about someone with the core belief "I am lacking"?`,
      typography_25_0_0: `If they use surrender as a coping strategy, they might think, "Typical of me," "It was a difficult task anyway," or "Why did I even try when I knew it wouldn't work?"`,
      typography_26_0_0: `If they use overcompensation, they might think, "This should never have happened," "Something must be wrong," and express anger towards themselves and everyone involved in the project.`,
      typography_27_0_0:
        "If avoidance is their strategy, they might avoid challenging tasks altogether, focusing only on reducing the chances of failure.",
      typography_28_0_0: `Distancing from beliefs`,
      typography_29_0_0: `Do you ever think, "What can I do? Aren't these things decided in childhood?"`,
      typography_30_0_0:
        "Recognize this as just your automatic thought and consider the following.",
      typography_31_0_0:
        "Fortunately, it's possible to distance oneself from beliefs.",
      typography_32_0_0: `We'll identify our own beliefs, understand how they lead to automatic thoughts, and practice distancing from them.`,
      typography_33_0_0:
        "It's a longer process than dealing with automatic thoughts.",
      typography_34_0_0: "They are more central and fundamental, after all.",
      typography_35_0_0:
        "But with effort, you'll come to understand that 'beliefs are just beliefs, not who I am,' just like 'thoughts are just thoughts, not who I am.'",
      typography_36_0_0: `Remember, a belief is just a thought that has lingered a bit longer at this moment in time; it doesn't define everything about you.`,
      typography_37_0_0:
        "Now, shall we start by exploring what core beliefs you hold?",
    },
    v2: {
      typography_0_0_0: "Let's dive a bit deeper now.",
      typography_1_0_0:
        "Ever wondered where automatic thoughts and intermediate beliefs come from?",
      typography_2_0_0: "Are they just random reactions to our environment?",
      typography_3_0_0:
        "Not really. If that were the case, people in similar environments would all think alike.",
      typography_4_0_0:
        "But we know this isn't true. Especially, people with depression or anxiety tend to have more frequent and easily triggered negative automatic thoughts than others.",
      typography_5_0_0:
        "From an early age, people develop certain beliefs about 'themselves,' 'others,' and 'the world.'",
      typography_6_0_0:
        "These central beliefs are so fundamental that we often don't even explain them to ourselves. They're just taken for granted.",
      typography_7_0_0:
        "These deep, intrinsic beliefs about oneself, others, and the world are what we call 'core beliefs.'",
      typography_8_0_0:
        "Core beliefs often form from experiences in childhood, but adult experiences can influence them too.",
      typography_9_0_0:
        "Of course, beliefs don't just spring from experiences alone. Innate temperament plays a big role.",
      typography_10_0_0:
        "Still, when we look at various studies, it's clear that 'core experiences' in childhood or adulthood play a crucial role in forming specific beliefs.",
      typography_11_0_0:
        "Think about someone who has had successful experiences from a young age and received support and encouragement from their parents and others.",
      typography_12_0_0: `Such a person is likely to develop beliefs like "I am quite capable," "I am successful," or "I have potential."`,
      typography_13_0_0:
        "These beliefs can form a positive and deep-rooted identity.",
      typography_14_0_0:
        "But what about someone who grew up always being compared to a more accomplished sibling?",
      typography_15_0_0: `They might develop beliefs like "I am incompetent," "I am lacking," or "I am inferior."`,
      typography_16_0_0:
        "And these beliefs can deeply erode their sense of self.",
      typography_17_0_0: "Automatic thoughts and core beliefs",
      typography_18_0_0: `Core beliefs are like the kernels at the bottom of a popcorn machine.`,
      typography_19_0_0: `They stay hidden deep down and slowly create popcorn.`,
      typography_20_0_0:
        "Core beliefs are the producers of automatic thoughts and intermediate beliefs.",
      typography_21_0_0:
        "For example, imagine someone whose long-term project failed.",
      typography_22_0_0: `If they have the core belief "I am quite capable,"`,
      typography_23_0_0: `They might have intermediate beliefs like:"`,
      typography_23_0_0_1: `I must always take on new challenges`,
      typography_23_0_0_2: `Every failure is just a step in the journey`,
      typography_23_0_0_3: `If I learn from what went wrong, then bigger victories are ahead`,
      typography_24_0_0: `So, facing project failure, they're likely to think "Gave it my all, shame it didn't work", "There's a lesson in every loss", "Tough break this time".`,
      typography_25_0_0: `But what about someone with the core belief "I am lacking"?`,
      typography_26_0_0: `They might have intermediate beliefs like:`,
      typography_26_0_0_1: `I must never fail`,
      typography_26_0_0_2: `Failure's the end of the world`,
      typography_26_0_0_3: `If I make even one mistake, everything will come crashing down`,
      typography_27_0_0: `Facing the same project flop, they might think "Just my luck", "Why did I even try?".`,
      typography_28_0_0: `And end up reinforcing the belief: "Yep, I'm just not enough".`,
      typography_29_0_0: `Distancing from beliefs`,
      typography_30_0_0: `Do you ever think, "What can I do? Aren't these things decided in childhood?"`,
      typography_31_0_0:
        "Recognize this as just your automatic thought and consider the following.",
      typography_32_0_0:
        "Fortunately, it's possible to distance oneself from beliefs.",
      typography_33_0_0: `We'll identify our own beliefs, understand how they lead to automatic thoughts, and practice distancing from them.`,
      typography_34_0_0:
        "It's a longer process than dealing with automatic thoughts.",
      typography_35_0_0: "They are more central and fundamental, after all.",
      typography_36_0_0:
        "But with effort, you'll come to understand that 'beliefs are just beliefs, not who I am,' just like 'thoughts are just thoughts, not who I am.'",
      typography_37_0_0: `Remember, a belief is just a thought that has lingered a bit longer at this moment in time; it doesn't define everything about you.`,
      typography_38_0_0:
        "Now, shall we start by exploring what core beliefs you hold?",
    },
  },
  ko: {
    v1: {
      typography_0_0_0: "자, 이제 조금 더 깊은 이야기로 나아가볼게요.",
      typography_1_0_0: "자동적 사고는 대체 어디서 오는 것일까요?",
      typography_2_0_0: "그저 환경에 반응해 무작위적으로 튀어나오는 것일까요?",
      typography_3_0_0:
        "그렇지 않아요. 만약 그렇다면 비슷한 환경에 놓인 사람들이라면 모두 유사한 생각을 하고 있을 테니까요.",
      typography_4_0_0:
        "하지만 분명히 우리는 특정한 사람들, 특히 우울이나 불안에 빠진 사람들이 다른 사람들보다 부정적인 자동적 사고를 더 쉽게 자주 떠올린다는 것을 알고 있어요.",
      typography_5_0_0:
        "사람들은 어린 시절부터 '자신'과 '다른 사람' 그리고 '이 세상'에 대한 특정한 생각을 발전시켜 나가는데요.",
      typography_6_0_0:
        "이 같은 중심적인 믿음은 너무나 근본적이기 때문에 자신에게조차 설명하지 않아요. 당연한 사실인 거죠.",
      typography_7_0_0:
        "이와 같이 나, 타인, 그리고 자신에 대한 가장 근원적이고 본질적인 믿음을 ‘핵심 믿음’이라고 불러요.",
      typography_8_0_0:
        "핵심 믿음은 주로 어릴 적 경험으로부터 형성될 때가 많아요. 하지만 경우에 따라서는 성인기의 경험이 영향을 미치기도 해요.",
      typography_9_0_0:
        "물론 믿음이 단순히 경험으로부터 생겨나는 것은 아니에요. 타고난 기질도 많은 영향을 미치죠.",
      typography_10_0_0:
        "하지만 그럼에도 수많은 연구 결과들을 종합해보면, 어릴 적 혹은 성인기에 발생한 '핵심 경험'이 특정한 믿음을 형성하는 데에 결정적인 역할을 한다는 사실은 틀림없어 보입니다.",
      typography_11_0_0:
        "어릴 적부터 조금씩 무언가를 성공하는 경험을 했고, 이에 대해 부모님을 포함한 주변 사람들이 지지와 응원을 보내주었던 경험이 있는 사람을 떠올려볼까요?",
      typography_12_0_0:
        "이러한 사람은 발달 과정에서 “나는 제법 유능하다”, “나는 성공적이다”, “나는 잠재력이 있다”와 같은 믿음을 가질 가능성이 높아요.",
      typography_13_0_0:
        "그러한 믿음은 긍정적이고 뿌리깊은 정체성이 되기도 하죠.",
      typography_14_0_0:
        "반면 어릴 적 뛰어난 형제와 항상 비교 당하고 지냈던 사람은 어떨까요?",
      typography_15_0_0: `이러한 사람은 “나는 무능하다”, “나는 부족하다”, “나는 열등하다”와 같은 믿음을 가질 가능성이 높아요.`,
      typography_16_0_0:
        "그리고 그러한 믿음은 나를 갉아먹는 원천으로 내 마음속 깊은 곳에 자리잡죠.",
      typography_17_0_0: "자동적 사고와 핵심 믿음",
      typography_18_0_0: `핵심 믿음은 팝콘 기계의 가장 아래쪽에 있는 옥수수 알갱이와도 같아요.`,
      typography_19_0_0: `아주 깊은 곳에서 숨어서 서서히 팝콘을 만들어내죠.`,
      typography_20_0_0: "핵심 믿음이 자동적 사고를 만들어낸는 거예요.",
      typography_21_0_0:
        "예를 들어볼게요. 어떠한 사람이 오랫동안 공을 들여 시도했던 프로젝트가 실패했다고 생각해볼게요.",
      typography_22_0_0: `이때 "나는 제법 유능하다"라는 핵심 믿음을 가지고 있는 사람은 어떨까요?`,
      typography_23_0_0: `"최선을 다했는데 아쉽다", "실패도 도움이 될 수 있을 것이다", "이번엔 아쉽게 되었다"라고 생각할 거예요.`,
      typography_24_0_0: `반면 "나는 부족하다"라는 핵심 믿음을 가지고 있는 사람이 있다고 생각해볼까요?`,
      typography_25_0_0: `만약 핵심 믿음에 대한 대처 전략으로 굴복을 활용한다면, "내가 그렇지", "역시 어려운 일이었다", "안 될 걸 알면서도 왜 한 걸까"와 같은 생각을 할 거예요.`,
      typography_26_0_0: `만약 과잉보상을 대처 전략으로 활용한다면, "이건 있어서는 안 될 일이다", "뭔가 잘못된 게 틀림없다"라고 생각하며 자신과 프로젝트에 참여한 모든 사람들에게 분노를 표출할 거예요.`,
      typography_27_0_0:
        "만약 회피를 대처 전략으로 활용한다면, 이후에 어려운 일들을 전혀 하지 않으며 자신이 실패할 가능성을 낮추는 데에만 급급하게 될 거예요.",
      typography_28_0_0: `믿음으로부터 거리두기`,
      typography_29_0_0:
        "문득 이런 생각이 들지 않나요? “뭘 할 수 있는데? 어차피 어릴 적에 결정된다며?”",
      typography_30_0_0:
        "이는 여러분의 자동적 사고일 뿐임을 인지하며 다음을 이해해보세요.",
      typography_31_0_0: `다행히 믿음도 거리두기가 가능합니다.`,
      typography_32_0_0: `앞으로 우리는 나만의 믿음을 파악하고, 그것이 자동적 사고를 만들어내는 과정을 이해하며 그로부터 거리두기를 연습할 거예요.`,
      typography_33_0_0:
        "물론 자동적 사고보다는 더 오랜 기간이 필요한 일이에요.",
      typography_34_0_0: `자동적 사고보다는 훨씬 중심적이고 근본적인 생각이니까요.`,
      typography_35_0_0:
        "하지만 조금씩 노력하다보면 ‘생각은 생각일 뿐 나 자신이 아닌 것’처럼 ‘믿음은 믿음일 뿐 나 자신은 아니다’는 사실을 알 수 있을 거랍니다.",
      typography_36_0_0: `기억하세요. 믿음은 그저 현재 이 시점에 당신에게 조금 더 오래 머무르고 있는 생각일 뿐, 그것 자체가 당신의 모든 것을 설명해주지는 않는답니다.`,
      typography_37_0_0:
        "자, 그러면 우선 나에게는 어떤 핵심 믿음이 있는지 살펴볼까요?",
    },
    v2: {
      typography_0_0_0: "자, 이제 조금 더 깊은 이야기로 나아가볼게요.",
      typography_1_0_0: "자동적 사고와 중간 믿음은 대체 어디서 오는 것일까요?",
      typography_2_0_0: "그저 환경에 반응해 무작위적으로 튀어나오는 것일까요?",
      typography_3_0_0:
        "그렇지 않아요. 만약 그렇다면 비슷한 환경에 놓인 사람들이라면 모두 유사한 생각을 하고 있을 테니까요.",
      typography_4_0_0:
        "하지만 분명히 우리는 특정한 사람들, 특히 우울이나 불안에 빠진 사람들이 다른 사람들보다 부정적인 자동적 사고를 더 쉽게 자주 떠올린다는 것을 알고 있어요.",
      typography_5_0_0:
        "사람들은 어린 시절부터 '자신'과 '다른 사람' 그리고 '이 세상'에 대한 특정한 생각을 발전시켜 나가는데요.",
      typography_6_0_0:
        "이 같은 중심적인 믿음은 너무나 근본적이기 때문에 자신에게조차 설명하지 않아요. 당연한 사실인 거죠.",
      typography_7_0_0:
        "이와 같이 나, 타인, 그리고 자신에 대한 가장 근원적이고 본질적인 믿음을 ‘핵심 믿음’이라고 불러요.",
      typography_8_0_0:
        "핵심 믿음은 주로 어릴 적 경험으로부터 형성될 때가 많아요. 하지만 경우에 따라서는 성인기의 경험이 영향을 미치기도 해요.",
      typography_9_0_0:
        "물론 믿음이 단순히 경험으로부터 생겨나는 것은 아니에요. 타고난 기질도 많은 영향을 미치죠.",
      typography_10_0_0:
        "하지만 그럼에도 수많은 연구 결과들을 종합해보면, 어릴 적 혹은 성인기에 발생한 '핵심 경험'이 특정한 믿음을 형성하는 데에 결정적인 역할을 한다는 사실은 틀림없어 보입니다.",
      typography_11_0_0:
        "어릴 적부터 조금씩 무언가를 성공하는 경험을 했고, 이에 대해 부모님을 포함한 주변 사람들이 지지와 응원을 보내주었던 경험이 있는 사람을 떠올려볼까요?",
      typography_12_0_0:
        "이러한 사람은 발달 과정에서 “나는 제법 유능하다”, “나는 성공적이다”, “나는 잠재력이 있다”와 같은 믿음을 가질 가능성이 높아요.",
      typography_13_0_0:
        "그러한 믿음은 긍정적이고 뿌리깊은 정체성이 되기도 하죠.",
      typography_14_0_0:
        "반면 어릴 적 뛰어난 형제와 항상 비교 당하고 지냈던 사람은 어떨까요?",
      typography_15_0_0: `이러한 사람은 “나는 무능하다”, “나는 부족하다”, “나는 열등하다”와 같은 믿음을 가질 가능성이 높아요.`,
      typography_16_0_0:
        "그리고 그러한 믿음은 나를 갉아먹는 원천으로 내 마음속 깊은 곳에 자리잡죠.",
      typography_17_0_0: "자동적 사고와 핵심 믿음",
      typography_18_0_0: `핵심 믿음은 팝콘 기계의 가장 아래쪽에 있는 옥수수 알갱이와도 같아요.`,
      typography_19_0_0: `아주 깊은 곳에서 숨어서 서서히 팝콘을 만들어내죠.`,
      typography_20_0_0:
        "핵심 믿음이 중간 믿음과 자동적 사고를 만들어내는 거예요.",
      typography_21_0_0:
        "예를 들어볼게요. 어떠한 사람이 오랫동안 공을 들여 시도했던 프로젝트가 실패했다고 생각해볼게요.",
      typography_22_0_0: `이때 "나는 제법 유능하다"라는 핵심 믿음을 가지고 있는 사람은 어떨까요?`,
      typography_23_0_0: `그런 사람은 아마 이런 중간 믿음을 갖고 있을 거예요.`,
      typography_23_0_0_1: `도전을 망설이지 않아야 한다`,
      typography_23_0_0_2: `실패는 또 하나의 경험이다`,
      typography_23_0_0_3: `실패의 교훈을 활용하면 더 크게 성공한다`,
      typography_24_0_0: `그럼 프로젝트가 실패한 상황에서 "최선을 다했는데 아쉽다", "실패도 도움이 될 수 있을 것이다", "이번엔 아쉽게 되었다"라고 생각할 거예요.`,
      typography_25_0_0: `반면 "나는 부족하다"라는 핵심 믿음을 가지고 있는 사람이 있다고 생각해볼까요?`,
      typography_26_0_0: `그런 사람은 아마 이런 중간 믿음을 갖고 있을 거예요.`,
      typography_26_0_0_1: `절대 실패해선 안된다`,
      typography_26_0_0_2: `실패하는 건 최악이다`,
      typography_26_0_0_3: `실패하면 무너지고 말 것이다`,
      typography_27_0_0: `그럼 프로젝트가 실패한 상황에서 "내가 그렇지", "안 될 걸 알면서도 왜 한 걸까"와 같은 생각을 할 거예요.`,
      typography_28_0_0: `결국엔 다음과 같이 생각하며 핵심 믿음을 더욱 강화하고 말겠죠. "역시 나는 부족하네."`,
      typography_29_0_0: `믿음으로부터 거리두기`,
      typography_30_0_0:
        "문득 이런 생각이 들지 않나요? “뭘 할 수 있는데? 어차피 어릴 적에 결정된다며?”",
      typography_31_0_0:
        "이는 여러분의 자동적 사고일 뿐임을 인지하며 다음을 이해해보세요.",
      typography_32_0_0: `다행히 믿음도 거리두기가 가능합니다.`,
      typography_33_0_0: `앞으로 우리는 나만의 믿음을 파악하고, 그것이 자동적 사고를 만들어내는 과정을 이해하며 그로부터 거리두기를 연습할 거예요.`,
      typography_34_0_0:
        "물론 자동적 사고보다는 더 오랜 기간이 필요한 일이에요.",
      typography_35_0_0: `자동적 사고보다는 훨씬 중심적이고 근본적인 생각이니까요.`,
      typography_36_0_0:
        "하지만 조금씩 노력하다보면 ‘생각은 생각일 뿐 나 자신이 아닌 것’처럼 ‘믿음은 믿음일 뿐 나 자신은 아니다’는 사실을 알 수 있을 거랍니다.",
      typography_37_0_0: `기억하세요. 믿음은 그저 현재 이 시점에 당신에게 조금 더 오래 머무르고 있는 생각일 뿐, 그것 자체가 당신의 모든 것을 설명해주지는 않는답니다.`,
      typography_38_0_0:
        "자, 그러면 우선 나에게는 어떤 핵심 믿음이 있는지 살펴볼까요?",
    },
  },
};
