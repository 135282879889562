import { translation_selfCheck } from "./selfCheck";
export const translation_102_1_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "오늘부터 프로그램을 진행하며 우리가 얼마만큼 나아지고 있는지를 정기적으로 관찰해 나갈 거예요.",
      typography_1_0_0:
        "2주마다 간단한 검사를 통해 우울감과 불안감을 수치화해 기록해 나갈 건데요.",
      typography_2_0_0:
        "이 점수가 감소하는 것을 통해 간접적으로 무기력으로부터 극복해 나가고 있다는 사실을 알아낼 수 있답니다.",
      typography_3_0_0:
        "하지만 이 검사가 모든 것을 얘기해주는 것은 아니에요. 가장 중요한 변화는 우리 스스로가 느껴야 하니까요.",
      typography_4_0_0:
        "그럼에도 이런 점수를 통해 추이를 관찰하는 것은 큰 도움이 된답니다.",
      typography_5_0_0:
        "오래 걸리지 않으니 바로 시작해볼게요. 1분도 걸리지 않을 거예요.",

      ...translation_selfCheck.ko.v1,
    },
  },
};
