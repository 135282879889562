import { DataMappingType, levelType } from "data/CellComponent";
import { ProgramContentType } from "data/BlockComponent";
import {
  getTaskIdFromTaskKey,
  getTextMappingFromDataMapping,
  setProgramContentData,
} from "logic/logics";
import { useEffect, useState } from "react";
import * as _ from "lodash";
import { useRecoilState, useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import { patientAtom } from "recoil/patientAtom";
import { useTranslation } from "react-i18next";
import TypographyCell from "./TypographyCell";
import { taskDataAtom } from "recoil/taskDataAtom";

export default function TypographyFromDataCell({
  taskKey,
  data,
  setData,
  translationVersion,
  lineIndex,
  cellIndex,
  blockIndex,
  dataMapping,
  initialValue,
  text,
  level,
  color,
  opacity,
}: {
  taskKey: string;
  data: ProgramContentType[];
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  translationVersion?: string;
  lineIndex: number;
  cellIndex: number;
  blockIndex: number;
  dataMapping: DataMappingType;
  initialValue?: string;
  text: string;
  level?: levelType;
  color?: string;
  opacity?: number;
}) {
  const user = useRecoilValue(userAtom);
  const isCoach = user?.role !== "patient";
  const patientState = useRecoilValue(patientAtom);

  const [textMapping, setTextMapping] = useState<
    { [key: string]: string } | undefined
  >();

  const [finalText, setFinalText] = useState("");

  const { t } = useTranslation("translation", {
    keyPrefix: `task.activity.${getTaskIdFromTaskKey(
      taskKey
    )}.${translationVersion}`,
  });

  const dataMappingFromActivity = Object.fromEntries(
    Object.entries(dataMapping).filter(
      ([key, value]) => value.id !== "user_nickname"
    )
  );
  const dataMappingFromPatientState = Object.fromEntries(
    Object.entries(dataMapping).filter(
      ([key, value]) => value.id === "user_nickname"
    )
  );

  //처음에만 가져옴
  useEffect(() => {
    Object.entries(dataMappingFromPatientState).forEach(([key, value]) => {
      // setTextMapping(textMapping=>({...textMapping, [key]:patientState?patientState['nickname']:''}))
      setTextMapping((textMapping) => ({
        ...textMapping,
        [key]: patientState?.nickname?.split("(")[0] || "",
      }));
    });
    const dataMappingFromPreviousActivity = Object.fromEntries(
      Object.entries(dataMappingFromActivity).filter(
        ([key, value]) =>
          value.taskId &&
          !Object.keys(textMapping || {}).includes(value.id as string)
      )
    );
    if (Object.keys(dataMappingFromPreviousActivity).length > 0) {
      getTextMappingFromDataMapping({
        taskKey,
        data,
        dataMapping: dataMappingFromPreviousActivity,
        isCoach,
        accessToken: user?.accessToken || "",
        pid: patientState?.patientId || "",
        t,
        taskDataState,
        setTaskDataState,
      }).then((result) => {
        setTextMapping((textMapping) => ({ ...textMapping, ...result }));
      });
    }
  }, []);

  const [taskDataState, setTaskDataState] = useRecoilState(taskDataAtom);

  useEffect(() => {
    const dataMappingFromCurrentActivity = Object.fromEntries(
      Object.entries(dataMappingFromActivity).filter(
        ([key, value]) => !value.taskId
      )
    );

    getTextMappingFromDataMapping({
      taskKey,
      data,
      dataMapping: dataMappingFromCurrentActivity,
      isCoach,
      accessToken: user?.accessToken || "",
      pid: patientState?.patientId || "",
      t,
      taskDataState,
      setTaskDataState,
    }).then((result) => {
      setTextMapping((textMapping) => ({ ...textMapping, ...result }));
    });

    // const dataMappingFromPreviousActivity = Object.fromEntries(
    //   Object.entries(dataMappingFromActivity).filter(
    //     ([key, value]) =>
    //       value.taskId &&
    //       !Object.keys(textMapping || {}).includes(value.id as string)
    //   )
    // );
    // if (Object.keys(dataMappingFromPreviousActivity).length > 0) {
    //   getTextMappingFromDataMapping({
    //     taskKey,
    //     data,
    //     dataMapping: dataMappingFromPreviousActivity,
    //     isCoach,
    //     accessToken: user?.accessToken || "",
    //     pid: patientState?.patientId || "",
    //     t,
    //     taskDataState,
    //     setTaskDataState,
    //   }).then((result) => {
    //     setTextMapping((textMapping) => ({ ...textMapping, ...result }));
    //   });
    // }
  }, [data]);

  useEffect(() => {
    return () => {
      setTaskDataState({});
    };
  }, []);

  useEffect(() => {
    if (text) {
      const splittedText = text.split("$");
      const finalText = textMapping
        ? splittedText
            .map((each, index) =>
              index % 2 === 0
                ? each
                : each.startsWith("eval")
                ? new Function("A", "B", "C", `return ${each.substring(4)}`)(
                    textMapping["A"],
                    textMapping["B"],
                    textMapping["C"]
                  )
                : textMapping[each]
            )
            .join(``)
        : "";
      setFinalText(finalText);
    }
    // if (
    //   (isCoach ||
    //     (blockIndex > 0 &&
    //       (data[blockIndex - 1].isShown || data[blockIndex].isShown))) &&
    //   textMapping &&
    //   initialValue !== finalText
    // ) {
    //   setProgramContentData({
    //     setData,
    //     blockIndex,
    //     lineIndex,
    //     cellIndex,
    //     newlyAddedData: { value: finalText },
    //   });
    // }
  }, [
    cellIndex,
    data,
    blockIndex,
    isCoach,
    lineIndex,
    setData,
    text,
    textMapping,
    initialValue,
    finalText,
  ]);

  useEffect(() => {
    // console.log(textMapping);
    if (finalText.length > 0 && initialValue !== finalText) {
      setProgramContentData({
        setData,
        blockIndex,
        lineIndex,
        cellIndex,
        newlyAddedData: { value: finalText },
      });
    }
  }, [textMapping, finalText]);

  return (
    <TypographyCell
      level={level}
      text={initialValue || ""}
      color={color}
      opacity={opacity}
    />
  );
}
