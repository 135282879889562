import { Button, Checkbox, Sheet, Stack, Typography } from "@mui/joy";
import TitleWithSubtitle from "components/common/TitleWithSubtitle";
import { useState } from "react";

import { useNavigate } from "react-router-dom";

export default function Checklist() {
  const navigate = useNavigate();

  const [checklist, setChecklist] = useState({
    addChanel: false,
    sendMessage: false,
  });

  return (
    <Stack spacing={2} justifyContent={"center"} alignItems="center">
      <TitleWithSubtitle
        title={"카카오톡 메시지를 확인해주세요."}
        subtitle={"  받은 메시지의 안내를 모두 따른 뒤 다음으로 진행해주세요."}
        sx={{ width: "100%" }}
      />
      {[
        "디스턴싱 코치 채널 친구 추가하기",
        "코치에게 나를 불러줄 닉네임 알려주기",
      ].map((checkpoint, index) => (
        <Sheet
          variant="outlined"
          sx={{
            width: "100%",
            backgroundColor: "#ffffff00",
            px: 2,
            pt: 1.5,
            pb: 1,
            borderRadius: 8,
            borderWidth:
              index ===
              Object.values(checklist).findIndex((element) => !element)
                ? 2
                : 1,
            borderColor:
              index ===
              Object.values(checklist).findIndex((element) => !element)
                ? "primary.solid"
                : "primary.soft",
          }}
        >
          <Checkbox
            label={checkpoint}
            color="primary"
            overlay
            onChange={(e) => {
              if (checkpoint === "디스턴싱 코치 채널 친구 추가하기") {
                setChecklist((checklist) => ({
                  ...checklist,
                  addChanel: e.target.checked,
                }));
              } else {
                setChecklist((checklist) => ({
                  ...checklist,
                  sendMessage: e.target.checked,
                }));
              }
            }}
          />
        </Sheet>
      ))}
      <Button
        sx={{ width: "100%" }}
        disabled={!Object.values(checklist).every((element) => element)}
        onClick={() => navigate("/")}
      >
        다음
      </Button>
    </Stack>
  );
}
