import { CircularProgress, Stack, Typography } from "@mui/joy";
export type SummaryType = {
  label: string;
  text: string;
}[];
export default function Summary({ data }: { data: SummaryType }) {
  return (
    <Stack
      sx={{ backgroundColor: "#F6F9FA", p: "20px", borderRadius: "12px" }}
      direction="column"
      spacing={"16px"}
    >
      {data.map((each) => each.text).some((element) => element.length > 0) ? (
        data.map(
          ({ label, text }, index) =>
            text.length > 0 && (
              <Stack key={label} direction="column" spacing="6px">
                <Typography
                  color="primary"
                  sx={{ fontSize: "14px", fontWeight: 700 }}
                >
                  {label}
                </Typography>
                <Typography sx={{ fontSize: "16px" }}>{text}</Typography>
              </Stack>
            )
        )
      ) : (
        <Stack justifyContent={"center"}>
          <CircularProgress color="neutral" size="sm" />
        </Stack>
      )}
    </Stack>
  );
}
