import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { taskSectionAtom } from "recoil/taskSectionAtom";
import * as _ from "lodash";
import { currentTaskSectionIndexAtom } from "recoil/currentTaskSectionIndexAtom";
import { layoutAtom } from "recoil/layoutAtom";
import { useMediaQuery, useTheme } from "@mui/material";
import useOpenThoughtRecord from "./useOpenThoughtRecord";

export default function useOpenTask(
  taskKey: string,
  disableIfAlreadyOpen?: boolean
) {
  const currentIndex = useRecoilValue(currentTaskSectionIndexAtom);
  const [taskSectionState, setTaskSectionState] =
    useRecoilState(taskSectionAtom);
  const setLayoutState = useSetRecoilState(layoutAtom);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));

  function addToTabAndOpen() {
    if (!taskSectionState || taskSectionState.length === 0) {
      setTaskSectionState([
        { openTaskKeyList: [taskKey], currentTaskKey: taskKey },
      ]);
    } else {
      const { openTaskKeyList, currentTaskKey } =
        taskSectionState[currentIndex];

      setTaskSectionState((taskSectionState) => {
        if (taskSectionState) {
          const copy = _.cloneDeep(taskSectionState);
          copy[currentIndex] = {
            ...taskSectionState[currentIndex],
            // openTaskKeyList: !openTaskKeyList.includes(taskKey)
            //   ? openTaskKeyList.concat(taskKey)
            //   : openTaskKeyList,
            openTaskKeyList: [taskKey],
            currentTaskKey: taskKey,
          };
          return copy;
        }
        return taskSectionState;
      });
    }

    if (isSmall) {
      setLayoutState((state) => ({ ...state, isLeftSideBarOpen: false }));
    }
  }
  return addToTabAndOpen;
}
