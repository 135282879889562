import { ButtonGroup } from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithImage,
} from "../BlockComponent";

import { LastCard, NotificationOnceAndInstructionBlocks } from "./common";

export const Content_8_5_A = (isPushNotificationGranted: boolean) => [
  Instruction(`typography_0_0_0`),
  Instruction("typography_1_0_0"),
  Instruction(`typography_2_0_0`),
  Instruction(`typography_3_0_0`),
  Instruction(`typography_4_0_0`),
  Instruction(`typography_5_0_0`),
  Instruction(`typography_6_0_0`),
  Instruction(`typography_7_0_0`),
  Instruction(`typography_8_0_0`),

  Header1(`typography_9_0_0`),
  InstructionWithImage(`typography_10_0_0`, "1"),
  Instruction(`typography_11_0_0`),
  InstructionWithImage(`typography_12_0_0`, "2"),
  Instruction(`typography_13_0_0`),
  Instruction(`typography_14_0_0`),
  InstructionWithImage(`typography_15_0_0`, "3"),
  Instruction(`typography_16_0_0`),
  Instruction(`typography_17_0_0`),
  Instruction(`typography_18_0_0`),
  Instruction(`typography_19_0_0`),
  Instruction(`typography_20_0_0`),
  Instruction(`typography_21_0_0`),
  Instruction(`typography_22_0_0`),
  Instruction(`typography_23_0_0`),
  Instruction(`typography_24_0_0`),
  Instruction(`typography_25_0_0`),
  Instruction(`typography_26_0_0`),
  InstructionWithImage(`typography_27_0_0`, "4"),
  InstructionWithImage(`typography_28_0_0`, "5"),
  InstructionWithImage(`typography_29_0_0`, "6"),

  Header1(`typography_30_0_0`),
  Instruction(`typography_31_0_0`),
  Instruction(`typography_32_0_0`),
  InstructionWithImage(`typography_33_0_0`, "7"),
  InstructionWithImage(`typography_34_0_0`, "8"),
  Instruction(`typography_35_0_0`),
  InstructionWithImage(`typography_36_0_0`, "9"),
  Instruction(`typography_37_0_0`),
  Instruction(`typography_38_0_0`),
  Instruction(`typography_39_0_0`),
  Instruction(`typography_40_0_0`),
  Instruction(`typography_41_0_0`),
  InstructionWithImage(`typography_42_0_0`, "10"),
  Instruction(`typography_43_0_0`),
  InstructionWithImage(`typography_44_0_0`, "12"),
  Instruction(`typography_45_0_0`),
  Instruction(`typography_46_0_0`),

  Header1(`typography_47_0_0`),
  Instruction(`typography_48_0_0`),
  InstructionWithContent("typography_49_0_0", [
    [
      ButtonGroup([
        {
          text: "생각의 하늘",
          showFromId: "생각의 하늘",
          hideFromId: "생각의 공장",
        },
        {
          text: "생각의 공장",
          showFromId: "생각의 공장",
          hideFromId: "생각의 하늘",
        },
      ]),
    ],
  ]),
  Instruction(`typography_50_0_0`, { blockId: "생각의 하늘" }),
  ...NotificationOnceAndInstructionBlocks(
    isPushNotificationGranted,
    "1",
    "sky_of_thoughts_20min"
  ),
  Instruction(`typography_50_0_0`, { blockId: "생각의 공장" }),
  ...NotificationOnceAndInstructionBlocks(
    isPushNotificationGranted,
    "13",
    "factory_of_thoughts_20min"
  ),

  ...LastCard,
];
