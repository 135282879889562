import { Header1, Instruction, InstructionWithImage } from "../BlockComponent";

import { LastCard, NotificationOnceAndInstructionBlocks } from "./common";

export const Content_8_3_A = (isPushNotificationGranted: boolean) => [
  Instruction(`typography_0_0_0`),
  Instruction("typography_1_0_0"),
  Instruction(`typography_2_0_0`),
  Instruction(`typography_3_0_0`),
  Instruction(`typography_4_0_0`),
  Instruction(`typography_5_0_0`),
  Instruction(`typography_6_0_0`),
  Instruction(`typography_7_0_0`),
  Instruction(`typography_8_0_0`),
  Instruction(`typography_9_0_0`),
  Instruction(`typography_10_0_0`),
  Instruction(`typography_11_0_0`),

  Header1(`typography_12_0_0`),
  Instruction(`typography_13_0_0`),
  InstructionWithImage(`typography_14_0_0`, "1"),
  Instruction(`typography_15_0_0`),
  InstructionWithImage(`typography_16_0_0`, "2"),
  InstructionWithImage(`typography_17_0_0`, "3"),
  Instruction(`typography_18_0_0`),
  Instruction(`typography_19_0_0`),
  Instruction(`typography_20_0_0`),
  Instruction(`typography_21_0_0`),
  Instruction(`typography_22_0_0`),
  Instruction(`typography_23_0_0`),
  Instruction(`typography_24_0_0`),
  InstructionWithImage(`typography_25_0_0`, "4"),
  Instruction(`typography_26_0_0`),
  Instruction(`typography_27_0_0`),

  Header1(`typography_28_0_0`),
  Instruction(`typography_29_0_0`),
  Instruction(`typography_30_0_0`),

  ...NotificationOnceAndInstructionBlocks(
    isPushNotificationGranted,
    "5",
    "stream_of_thoughts_10min"
  ),

  ...LastCard,
];
