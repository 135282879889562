import { Stack, Typography } from "@mui/joy";
import { useRecoilValue } from "recoil";
import { layoutAtom } from "recoil/layoutAtom";
import { debounce } from "lodash";
import useOpenNewMeditationRecord from "hooks/useOpenNewMeditationRecord";
import { isMobile } from "react-device-detect";

function StatisticsIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 9.5V12.7308"
        stroke="#14A191"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M11 6.5V12.7308"
        stroke="#14A191"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M15 4.5V12.7308"
        stroke="#14A191"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M17 15.5H3V4.5"
        stroke="#14A191"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default function OpenStatisticsButton({
  isOpen,
  open,
}: {
  isOpen: boolean;
  open: () => void;
}) {
  const { isTaskContainerSmall } = useRecoilValue(layoutAtom);

  return (
    <Stack
      direction={isTaskContainerSmall ? "column" : "row"}
      spacing={isTaskContainerSmall ? "6px" : "10px"}
      alignItems="center"
      justifyContent={"center"}
      sx={{
        flexShrink: 0,
        cursor: "pointer",
        ...(isMobile
          ? {
              "&:active": {
                opacity: 0.5,
              },
            }
          : {
              "&:hover": {
                opacity: 0.5,
              },
            }),

        ...(isTaskContainerSmall
          ? {
              width: "50px",
              height: "69px",
              ...(isOpen && { backgroundColor: "background.level1" }),
            }
          : { width: "100%", height: "46px" }),
        whiteSpace: "nowrap",
        borderRadius: "6px",
        ...(!isTaskContainerSmall && {
          borderWidth: "1px",
          borderColor: "common.black_10",
        }),
      }}
      onClick={open}
    >
      <StatisticsIcon />
      <Typography
        color="primary"
        sx={{
          fontSize: isTaskContainerSmall ? "14px" : "16px",
          fontWeight: isTaskContainerSmall ? 600 : 700,
        }}
      >
        통계
      </Typography>
    </Stack>
  );
}
