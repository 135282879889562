import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { ReactElement } from "react";
import { findSubstringIndices } from "logic/logics";
import useGetUrls from "hooks/useGetUrls";
import Link from "components/common/Link";
import { userAtom } from "recoil/userAtom";
import { useRecoilValue } from "recoil";

function AccordianTemplate({
  summary,
  details,
}: {
  summary: string;
  details: (string | ReactElement) | (string | ReactElement)[];
  noBorder?: boolean;
}) {
  return (
    <Accordion
      sx={{
        px: "20px",
        py: "16px",
        borderWidth: 0,
        borderRadius: "12px",
        backgroundColor: "background.level1",
      }}
    >
      <AccordionSummary sx={{ p: 0, m: 0 }}>
        <Typography level="body-md" sx={{ fontWeight: 700 }}>
          {summary}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{details}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export function TypographyWithLinkSubstring({
  mainString,
  linkSubstrings,
  linkColor,
  linkOpenType,
}: {
  mainString: string;
  linkSubstrings: { linkString: string; url: string }[];
  linkColor?: string;
  linkOpenType?: "modal" | "link";
}) {
  const linkIndices = linkSubstrings.map((each) => ({
    ...each,
    ...findSubstringIndices(mainString, each.linkString),
  }));
  const splittedText = [mainString.substring(0, linkIndices[0].startIndex)];
  linkIndices.forEach(({ startIndex, endIndex }, index) => {
    if (index > 0) {
      splittedText.push(
        mainString.substring(linkIndices[index - 1].endIndex + 1, startIndex)
      );
    }
    splittedText.push(mainString.substring(startIndex, endIndex + 1));
    if (index === linkIndices.length - 1) {
      splittedText.push(mainString.substring(endIndex + 1));
    }
  });

  return (
    <Box>
      {splittedText.map((each, index) =>
        linkSubstrings.map((each) => each.linkString).includes(each) ? (
          <span key={`${each}_${index}`} style={{ display: "inline" }}>
            <Link
              key={`${each}_${index}`}
              level={"body-sm"}
              text={each}
              url={
                linkSubstrings.find(({ linkString }) => linkString === each)
                  ?.url || ""
              }
              opacity={linkColor ? 1 : 0.6}
              textColor={linkColor}
              linkOpenType={linkOpenType}
            />
          </span>
        ) : (
          <span key={`${each}${index}`} style={{ display: "inline" }}>
            <Typography key={`${each}${index}`}>{each}</Typography>
          </span>
        )
      )}
    </Box>
  );
}
export default function QnA() {
  const userState = useRecoilValue(userAtom);
  const isAiCoachProduct = userState?.isAiCoachProduct;

  return (
    <AccordionGroup sx={{ display: "flex" }}>
      <Stack gap="10px">
        <AccordianTemplate
          summary={"전체 기간은 얼마나 되나요?"}
          details={
            "프로그램을 마치기까지 보통 3개월 정도 걸려요. 사람마다 다를 수는 있어요."
          }
        />
        <AccordianTemplate
          summary={
            isAiCoachProduct
              ? "AI 코치가 도와준다고요?"
              : "코치 선생님이 도와준다고요?"
          }
          details={
            isAiCoachProduct
              ? "코치는 매일 해야할 활동을 안내하고 프로그램을 리드해 나갈 거예요. 코치는 디스턴싱 코칭 과정을 학습한 AI로 구성되어 있어요. 코치와는 익명으로 프로그램 진행이 가능해요."
              : "코치는 매일 해야할 활동을 안내하고 프로그램을 리드해 나갈 거예요. 코치는 전문 수련 과정을 마친 심리치료 전문가로 구성되어 있어요. 코치와는 익명으로 프로그램 진행이 가능해요."
          }
        />
        <AccordianTemplate
          summary={"누가 도움 받을 수 있나요?"}
          details={[
            "우울함, 수치심, 무기력, 폭식을 비롯한 식이장애, 불안감, 강박적인 걱정, 공황장애, 사회공포증, 발표불안, 자기 파괴적 완벽주의, 업무에 대한 부담감, 수면 문제 그리고 이외 복합적인 정서 문제에 도움이 될 수 있어요.",
            <br />,
            "환청, 환각, 자살사고 등 시급한 의료적 접근이 필요한 분들의 경우 당장의 도움이 될 수 없어요. 단, 의료적 접근을 통해 위 문제들이 안정화가 된 상태에서는 보다 장기적이고 지속적인 변화를 위해 디스턴싱을 시작해봐도 괜찮아요.",
          ]}
        />
        <AccordianTemplate
          summary={"이런 경우 환불해드려요"}
          details={[
            "결제 후 서비스를 이용하지 않은 경우 언제든 환불이 가능해요. 결제 후 1회 이상 서비스를 이용한 경우 환불 금액은 판매자에게 문의해주세요.",
            <br />,
            "서버 문제, 개발 오류 등 회사의 사정으로 인해 일주일을 초과하는 기간 동안 서비스가 원활하게 전개되지 않는 경우에는, 문제 발생 21일 전부터 문제 발생 시점까지 진행된 결제 건에 대해 모두 환불해드려요.",
          ]}
        />
      </Stack>
    </AccordionGroup>
  );
}
