import { Box, Stack } from "@mui/joy";
import React from "react";
import Link from "components/common/Link";
import { useTranslation } from "react-i18next";
import useGetUrls from "hooks/useGetUrls";
import logo from "static/image/common/distancing_logo.png";

export default function DefaultPage({ content }: { content: React.ReactNode }) {
  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });
  const { companyInfoUrl, privacyPolicyUrl, servicePolicyUrl } = useGetUrls();
  return (
    <Stack
      direction={"column"}
      alignItems="center"
      sx={{
        zIndex: 1000,
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "calc(var(--vh,1vh) * 100)",
        minHeight: "calc(var(--vh,1vh) * 100)",
        backgroundColor: "background.level1",
        py: "40px",
      }}
    >
      <Box>
        <img src={logo} alt={"empty"} style={{ width: "132px" }} />
      </Box>
      <Stack
        flexGrow={1}
        justifyContent="center"
        sx={{ width: "100%", px: "20px" }}
      >
        {content}
      </Stack>
      <Stack
        direction={"row"}
        spacing={1}
        sx={{ pt: 1, wordBreak: "keep-all" }}
      >
        <Link text={t_ui("사업자 정보")} url={companyInfoUrl} />
        <Link text={t_ui("(대) 개인정보 처리방침")} url={privacyPolicyUrl} />
        <Link text={t_ui("(대) 서비스 이용약관")} url={servicePolicyUrl} />
      </Stack>
    </Stack>
  );
}
