import { Box, Button, Stack } from "@mui/joy";
import { useMutation } from "@tanstack/react-query";
import { selfDistancingApi } from "api/selfDistancingApi";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Block, BlockType } from "./Block";
import ProgressBar from "./ProgressBar";

export type IdType = "emotion" | "situation" | "thought";

export default function Acceptance({ uuid }: { uuid: string }) {
  const navigate = useNavigate();
  const qnaRefs = useRef<HTMLDivElement[]>([]);

  const [lastShownIndex, setLastShownIndex] = useState<number>(0);

  const showNextQuestion = () => {
    setLastShownIndex((lastShownIndex) => lastShownIndex + 1);
  };

  const moveToIndex = (index: number) => {
    qnaRefs.current[index].scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  useEffect(() => {
    if (lastShownIndex > 0) {
      moveToIndex(lastShownIndex);
    }
  }, [lastShownIndex]);

  const [questionList, setQuestionList] = useState<
    { type: BlockType; question: string; answer?: string }[]
  >([
    {
      type: "audio",
      question:
        "‘생각의 강’에서 나의 생각을 그대로 바라보며 잠시 그 느낌에 머물러 보아요.",
    },
    {
      type: "textarea",
      question: `이제 나의 생각이 어떻게 느껴지는지 적어 볼까요?`,
    },
  ]);

  const doneQueryFn = useCallback(
    () => selfDistancingApi.meditationDone(uuid, questionList[1].answer || ""),
    [uuid, questionList]
  );

  const { mutate: done } = useMutation(doneQueryFn, {
    onSuccess: () => {
      navigate("/tool/6");
    },
  });

  return (
    <Stack
      sx={{
        width: "100%",
      }}
      alignItems="center"
    >
      <Stack sx={{ width: "100%", mb: "50px" }} direction="row" spacing={"3px"}>
        {[
          {
            label: "알아차리기",
            percentage: 100,
          },
          { label: "거리두기", percentage: 100 },
          {
            label: "기꺼이 경험하기",
            percentage: ((lastShownIndex + 1) / questionList.length) * 100,
          },
        ].map(({ label, percentage }, index) => (
          <Box sx={{ flex: 1 }}>
            <ProgressBar label={label} percentage={percentage}></ProgressBar>
          </Box>
        ))}
      </Stack>
      <Stack
        direction={"column"}
        justifyContent="start"
        sx={{ width: "100%", pb: "100px" }}
        spacing="50px"
      >
        {questionList.map(({ type, question }, index) => (
          <Box sx={{ ...(index > lastShownIndex && { visibility: "hidden" }) }}>
            <Block
              ref={(el) => (qnaRefs.current[index] = el as HTMLDivElement)}
              type={type}
              question={question}
              {...(index < questionList.length - 1 && {
                onNextButtonClick: showNextQuestion,
              })}
              {...(type === "audio" && {
                onAudioEnded: () => {
                  setQuestionList((questionList) => {
                    return questionList.map((item, i) => {
                      if (i === 0) {
                        return { ...item, answer: "done" };
                      }
                      return item;
                    });
                  });
                },
              })}
              {...(type === "textarea" && {
                handleChange: (value: string) => {
                  setQuestionList((questionList) => {
                    return questionList.map((item, i) => {
                      if (i === index) {
                        return { ...item, answer: value };
                      }
                      return item;
                    });
                  });
                },
              })}
              isNextButtonAvailable={
                lastShownIndex === index &&
                (questionList[lastShownIndex].answer || "").trim().length > 0
              }
            />
          </Box>
        ))}
      </Stack>
      {(questionList[1].answer || "").trim().length > 0 && (
        <Button
          size="lg"
          sx={{
            backgroundColor: "#232832",
            width: "100%",
            position: "sticky",
            bottom: "20px",
            "&:hover": {
              backgroundColor: `#23283288`,
            },
          }}
          onClick={() => {
            done();
          }}
        >
          계속 진행하기
        </Button>
      )}
    </Stack>
  );
}
