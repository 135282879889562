import { Box, Button, Stack, Typography } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import ProgressBar from "./ProgressBar";

export default function Wrapup({ uuid }: { uuid: string }) {
  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        width: "100%",
      }}
      alignItems="center"
    >
      <Stack sx={{ width: "100%", mb: "50px" }} direction="row" spacing={"3px"}>
        {[
          {
            label: "알아차리기",
            percentage: 100,
          },
          { label: "거리두기", percentage: 100 },
          { label: "기꺼이 경험하기", percentage: 100 },
        ].map(({ label, percentage }, index) => (
          <Box sx={{ flex: 1 }}>
            <ProgressBar label={label} percentage={percentage}></ProgressBar>
          </Box>
        ))}
      </Stack>
      <Stack
        direction={"column"}
        justifyContent="start"
        sx={{ width: "100%", pb: "100px" }}
        spacing="20px"
      >
        <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
          {"디스턴싱을 마쳤어요!"}
        </Typography>
        <Typography sx={{ fontSize: "16px" }}>
          {
            "마음속에 떠오르는 생각을 거리두고\n기꺼이 바라보면,\n그것에 반응하지 않을 수 있답니다.\n아직 어려울 수 있지만, \n꾸준히 해보아요."
          }
        </Typography>
      </Stack>

      <Button
        size="lg"
        sx={{
          backgroundColor: "#232832",
          width: "100%",
          position: "sticky",
          bottom: "20px",
          "&:hover": {
            backgroundColor: `#23283288`,
          },
        }}
        onClick={() => {
          navigate("/tool/1", { replace: true });
        }}
      >
        마치기
      </Button>
    </Stack>
  );
}
