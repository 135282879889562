import {
  ButtonGroup,
  GuideTypographyLine,
  LineWithSolidLabel,
  TypographyLine,
} from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithImage,
  InstructionWithTextarea,
} from "../BlockComponent";

import { LastCard } from "./common";

export const Content_4_4_4_A = [
  Instruction(`typography_0`),
  Instruction(`typography_1`),
  Instruction(`typography_2`),
  Instruction(`typography_3`),

  Header1(`typography_4`),
  Instruction(`typography_5`),
  InstructionWithContent(
    `typography_6`,
    [`typography_6_1`, `typography_6_2`].flatMap((each, index) => [
      ...LineWithSolidLabel(`예시`, TypographyLine(each), {
        labelIndex: index + 1,
      }),
    ])
  ),
  Instruction(`typography_7`),
  Instruction(`typography_8`),
  InstructionWithContent(
    `typography_9`,
    [`typography_9_1`, `typography_9_2`].flatMap((each, index) => [
      ...LineWithSolidLabel(`예시`, TypographyLine(each), {
        labelIndex: index + 1,
      }),
    ])
  ),
  Instruction(`typography_10`),
  Instruction(`typography_11`),
  InstructionWithImage(`typography_12`, "1"),
  Instruction(`typography_13`),
  Instruction(`typography_14`),

  Header1(`typography_15`),
  Instruction(`typography_16`),
  InstructionWithContent(`typography_17`, [
    [
      ButtonGroup(
        [
          {
            text: "평가",
          },
          {
            text: "사실",
          },
        ],
        { id: "quiz1" }
      ),
    ],

    GuideTypographyLine("typography_quiz_incorrect", {
      answerList: [{ id: "quiz1", answerIndex: 0 }],
    }),
  ]),

  InstructionWithContent(`typography_18`, [
    [
      ButtonGroup(
        [
          {
            text: "평가",
          },
          {
            text: "사실",
          },
        ],
        { id: "quiz2" }
      ),
    ],

    GuideTypographyLine("typography_quiz_incorrect", {
      answerList: [{ id: "quiz2", answerIndex: 1 }],
    }),
  ]),

  InstructionWithTextarea(`typography_19`),
  Instruction(`typography_20`),
  InstructionWithTextarea(`typography_21`),
  Instruction(`typography_22`),
  Instruction(`typography_23`),
  Instruction(`typography_24`),

  InstructionWithContent(`typography_25`, [
    [
      ButtonGroup(
        [
          {
            text: "평가",
          },
          {
            text: "사실",
          },
        ],
        { id: "quiz3" }
      ),
    ],

    GuideTypographyLine("typography_quiz_incorrect", {
      answerList: [{ id: "quiz3", answerIndex: 1 }],
    }),
  ]),
  InstructionWithContent(`typography_26`, [
    [
      ButtonGroup(
        [
          {
            text: "평가",
          },
          {
            text: "사실",
          },
        ],
        { id: "quiz4" }
      ),
    ],

    GuideTypographyLine("typography_quiz_incorrect", {
      answerList: [{ id: "quiz4", answerIndex: 0 }],
    }),
  ]),
  Instruction(`typography_27`),
  Instruction(`typography_28`),

  Header1(`typography_29`),
  Instruction(`typography_30`),
  Instruction(`typography_31`),
  Instruction(`typography_32`),
  Instruction(`typography_33`),
  Instruction(`typography_34`),
  Instruction(`typography_35`),
  Instruction(`typography_36`),
  Instruction(`typography_37`),
  Instruction(`typography_38`),
  Instruction(`typography_39`),
  Instruction(`typography_40`),
  Instruction(`typography_41`),

  Header1(`typography_42`),
  Instruction(`typography_43`),
  InstructionWithImage(`typography_44`, "3"),
  InstructionWithImage(`typography_45`, "4"),
  InstructionWithImage(`typography_46`, "5"),
  Instruction(`typography_47`),
  Instruction(`typography_48`),
  Instruction(`typography_49`),
  Instruction(`typography_50`),
  Instruction(`typography_51`),
  Instruction(`typography_52`),
  Instruction(`typography_53`),
  Instruction(`typography_54`),

  ...LastCard,
];
