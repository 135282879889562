import { Box, Option, Select, Typography } from "@mui/joy";
import { useQuery } from "@tanstack/react-query";
import {
  conceptApi,
  CONCEPT_DETAIL_ENDPOINT,
  CONCEPT_DOMAIN,
} from "api/conceptApi";
import { ProgramContentType } from "data/BlockComponent";
import * as _ from "lodash";
import { setProgramContentData } from "logic/logics";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";

export default function SelectIntermediateBeliefCell({
  selectedIndex,
  blockIndex,
  setData,
  lineIndex,
  cellIndex,
  readOnly,
}: {
  selectedIndex?: number;
  blockIndex: number;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
  readOnly?: boolean;
}) {
  const patientState = useRecoilValue(patientAtom);
  const [values, setValues] = useState<string[]>();

  //코치 프리셋
  useEffect(() => {
    if (values && selectedIndex !== undefined && selectedIndex > -1) {
      const selected = values[selectedIndex];

      handleChange(selected, selectedIndex);
    }
  }, [values, selectedIndex]);

  const queryFn = useCallback(
    () => conceptApi.detail(`${patientState?.patientId}`),
    [patientState]
  );

  useQuery([CONCEPT_DOMAIN, CONCEPT_DETAIL_ENDPOINT], queryFn, {
    enabled: !!patientState?.patientId,
    onSuccess: (fetchData) => {
      if (fetchData && fetchData.result) {
        const list = fetchData.result;
        setValues(Object.values(list.intermediateBelief || {}));
      }
    },
  });

  const handleChange = (selected: string, selectedIndex: number) => {
    setProgramContentData({
      setData,
      blockIndex,
      lineIndex,
      cellIndex,
      newlyAddedData: { value: selected, selectedIndex: selectedIndex },
    });
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(500);

  useEffect(() => {
    if (containerRef.current && containerRef.current.offsetWidth > 0) {
      setWidth(containerRef.current?.offsetWidth);
    }
  }, []);

  return (
    <Box
      ref={containerRef}
      className={`w-full ${readOnly && "pointer-events-none"}`}
    >
      {values && (
        <Select
          className="w-full"
          defaultValue={
            selectedIndex !== undefined ? values[selectedIndex] : ""
          }
          onChange={(e) => {
            const selected = (e?.target as HTMLElement)?.innerText;

            const selectedIndex = values.findIndex(
              (element) => element === selected
            );

            if (selected && selectedIndex > -1) {
              handleChange(selected, selectedIndex);
            }
          }}
          renderValue={(value) => (
            <Typography
              level={"body-md"}
              textAlign={"left"}
              className="w-full overflow-hidden whitespace-normal"
            >
              {selectedIndex !== undefined ? values[selectedIndex] : ""}
            </Typography>
          )}
        >
          {values.map((option, optionIndex) => (
            <Option
              key={`select_option_${blockIndex}_${lineIndex}_${cellIndex}_${optionIndex}`}
              value={option}
              sx={{
                width: width,
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "3",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {option}
              </Typography>
            </Option>
          ))}
        </Select>
      )}
    </Box>
  );
}
