import { automaticThoughtIdList } from "api/conceptApi";
import { seqPostfix } from "data/prefixData";

export const translation6_9_A_common = {
  en: {
    v1: {
      part1: {
        part1_typography_0_0_0_automaticThought1:
          "Here's the first automatic thought.",
        part1_typography_0_0_0_automaticThought2: "Now, the second one.",
        part1_typography_0_0_0_automaticThought3: "And the third.",
        part1_typography_1_0_0:
          "Earlier, we identified this thought falls under $A$.",
        part1_typography_2_0_0:
          "Do these traps encourage accepting only the thoughts that align with my core beliefs? Are they filtering out contradictory information? If so, how?",
      },
      part2: {
        part2_typography_0_0_0_surrender: "Surrender:",
        part2_typography_0_0_0_overcompensation: "Overcompensation:",
        part2_typography_0_0_0_avoidance: "Avoidance:",
        part2_typography_1_0_0_surrender:
          "What benefits have I gained from surrendering in my life?",
        part2_typography_1_0_0_overcompensation:
          "What benefits have I gained from overcompensating in my life?",
        part2_typography_1_0_0_avoidance:
          "What benefits have I gained from avoiding in my life?",
        part2_typography_2_0_0: "What outcomes resulted from these benefits?",
        part2_typography_3_0_0:
          "The advantages likely helped me avoid confronting situations that challenged my core beliefs, ",
        part2_typography_4_0_0:
          "effectively dodging the associated emotional pain.",
        part2_typography_5_0_0: "But there must have been downsides too.",
        part2_typography_6_0_0_surrender:
          "What losses have I experienced due to surrender?",
        part2_typography_6_0_0_overcompensation:
          "What losses have I experienced due to overcompensation?",
        part2_typography_6_0_0_avoidance:
          "What losses have I experienced due to avoidance?",
        part2_typography_7_0_0:
          "What have been the consequences of these losses?",
        part2_typography_8_0_0:
          "How have these outcomes influenced my perception of myself, others, and the world?",
      },
      part3: {
        part3_typography_0_0_0_surrender:
          'Surrendering to my core beliefs, gaining benefits like "$A$".',
        part3_typography_0_0_0_overcompensation:
          'Overcompensating my core beliefs, enjoying advantages like "$A$".',
        part3_typography_0_0_0_avoidance:
          'Avoiding my core beliefs, receiving benefits like "$A$".',
      },
      part4: {
        part4_typography_0_0_0_surrender:
          'Surrendering to my core beliefs, I face losses like "$A$",',
        part4_typography_0_0_0_overcompensation:
          'Overcompensating my core beliefs, I encounter losses like "$A$",',
        part4_typography_0_0_0_avoidance:
          'Avoiding my core beliefs, I experience losses like "$A$",',
        part4_typography_1_0_0_surrender: 'leading to outcomes like "$A$".',
        part4_typography_1_0_0_overcompensation:
          'resulting in outcomes like "$A$".',
        part4_typography_1_0_0_avoidance: 'leading to outcomes like "$A$".',
      },
    },
    v2: {
      part1: {
        ...automaticThoughtIdList.reduce((accumulator, value, index) => {
          return {
            ...accumulator,
            [`part1_typography_0_0_0_automaticThought${value}`]: `Here's the ${seqPostfix[value].en} automatic thought. `,
          };
        }, {}),
        part1_typography_1_0_0:
          "Earlier, we identified this thought falls under $A$.",
        part1_typography_2_0_0:
          "Do these traps encourage accepting only the thoughts that align with my core beliefs? Are they filtering out contradictory information? If so, how?",
      },
      part2: {
        part2_typography_0_0_0: "Understanding $A$",
        part2_typography_1_0_0:
          "We found these $A$ in the intermediate beliefs.",
        part2_typography_2_0_0: "Let's see what good they've done for me.",
        part2_typography_3_0_0:
          "What good things seem to have come from these $A$ in my life?",
        part2_typography_4_0_0: "What were the outcomes from these benefits?",
        part2_typography_5_0_0:
          "They might have kept me from feeling the hurt from those core beliefs.",
        part2_typography_6_0_0: "But, there were downsides too.",
        part2_typography_7_0_0:
          "What downsides seem to have come from these $A$ in my life?",
        part2_typography_8_0_0: "What happened because of these downsides?",
        part2_typography_9_0_0:
          "In the end, what do these results make me think about myself, others, and the world?",
      },
      part3: {
        part3_typography_0_0_0:
          'With these $A$, I enjoyed benefits like "$B$".',
      },
      part4: {
        part4_typography_0_0_0: 'With these $A$, I faced downsides like "$B$".',
        part4_typography_1_0_0: 'This led to results like "$A$".',
        part4_typography_2_0_0:
          'In the end, I started thinking about me, others, and the world as "$A$".',
      },
    },
  },
  ko: {
    v1: {
      part1: {
        part1_typography_0_0_0_automaticThought1: "첫 번째 자동적 사고예요.",
        part1_typography_0_0_0_automaticThought2: "두 번째 자동적 사고예요.",
        part1_typography_0_0_0_automaticThought3: "세 번째 자동적 사고예요.",
        part1_typography_1_0_0:
          "앞서 우리는 위의 생각이 $A$ 생각함정에 해당한다는 것을 알아냈어요.",
        part1_typography_2_0_0:
          "어떤가요? 이 생각함정들은 핵심 믿음에 일치하는 생각만 받아들이도록 하고 있나요? 그와 반하는 정보는 색안경처럼 걸러내고 있나요? 만약에 그렇다면 어떻게 작용하고 있는 걸까요?",
      },
      part2: {
        part2_typography_0_0_0_surrender:
          "굴복이 내게 어떤 장점을 주는지 살펴볼게요.",
        part2_typography_0_0_0_overcompensation:
          "과잉보상이 내게 어떤 장점을 주는지 살펴볼게요.",
        part2_typography_0_0_0_avoidance:
          "회피가 내게 어떤 장점을 주는지 살펴볼게요.",
        part2_typography_1_0_0_surrender:
          "굴복하는 대처 전략이 지난 삶에서 내게 주었던 이점은 무엇인 것 같나요?",
        part2_typography_1_0_0_overcompensation:
          "과잉보상하는 대처 전략이 지난 삶에서 내게 주었던 이점은 무엇인 것 같나요?",
        part2_typography_1_0_0_avoidance:
          "회피하는 대처 전략이 지난 삶에서 내게 주었던 이점은 무엇인 것 같나요?",
        part2_typography_2_0_0: "그러한 장점으로 발생한 결과는 무엇이었나요?",
        part2_typography_3_0_0:
          "아마 장점으로 인해 핵심 믿음에 맞서 고군분투하는 상황을 피할 수 있었을 거예요.",
        part2_typography_4_0_0:
          "그로 인해 핵심 믿음이 주는 고통스러운 감정을 잘 피해왔을 거예요. 매우 효과적으로 말이죠.",
        part2_typography_5_0_0: "하지만 분명 단점도 있었을 텐데요.",
        part2_typography_6_0_0_surrender:
          "굴복 대처 전략이 지난 삶에서 내게 주었던 손해는 무엇인 것 같나요?",
        part2_typography_6_0_0_overcompensation:
          "과잉보상 대처 전략이 지난 삶에서 내게 주었던 손해는 무엇인 것 같나요?",
        part2_typography_6_0_0_avoidance:
          "회피 대처 전략이 지난 삶에서 내게 주었던 손해는 무엇인 것 같나요?",
        part2_typography_7_0_0:
          "그러한 손해로 인해 발생한 결과는 무엇이었나요?",
        part2_typography_8_0_0:
          "그 결과는 결국 나, 타인, 세상에 대해 어떠한 결론을 내리도록 만드나요?",
      },
      part3: {
        part3_typography_0_0_0_surrender:
          '핵심 믿음에 굴복하며, "$A$"(와)과 같은 이점을 얻었어요.',
        part3_typography_0_0_0_overcompensation:
          '핵심 믿음에 과잉보상하며, "$A$"(와)과 같은 이점을 얻었어요.',
        part3_typography_0_0_0_avoidance:
          '핵심 믿음에 회피하며, "$A$"(와)과 같은 이점을 얻었어요.',
      },
      part4: {
        part4_typography_0_0_0_surrender:
          '핵심 믿음에 굴복하며, "$A$"(와)과 같은 손해을 얻고 있어요.',
        part4_typography_0_0_0_overcompensation:
          '핵심 믿음에 과잉보상하며, "$A$"(와)과 같은 손해을 얻고 있어요.',
        part4_typography_0_0_0_avoidance:
          '핵심 믿음에 회피하며, "$A$"(와)과 같은 손해을 얻고 있어요.',
        part4_typography_1_0_0_surrender:
          '그 결과로 "$A$"(와)과 같은 결과에 이르렀죠.',
        part4_typography_1_0_0_overcompensation:
          '그 결과로 "$A$"(와)과 같은 결과에 이르렀죠.',
        part4_typography_1_0_0_avoidance:
          '그 결과로 "$A$"(와)과 같은 결과에 이르렀죠.',
        part4_typography_2_0_0:
          '결국 나는 나와 타인과 세상에 대해 "$A$"(와)과 같은 생각을 갖게 되었네요.',
      },
    },
    v2: {
      part1: {
        ...automaticThoughtIdList.reduce((accumulator, value, index) => {
          return {
            ...accumulator,
            [`part1_typography_0_0_0_automaticThought${value}`]: `${seqPostfix[value].ko} 번째 자동적 사고예요.`,
          };
        }, {}),
        part1_typography_1_0_0:
          "앞서 우리는 위의 생각이 $A$ 생각함정에 해당한다는 것을 알아냈어요.",
        part1_typography_2_0_0:
          "어떤가요? 이 생각함정들은 핵심 믿음에 일치하는 생각만 받아들이도록 하고 있나요? 그와 반하는 정보는 색안경처럼 걸러내고 있나요? 만약에 그렇다면 어떻게 작용하고 있는 걸까요?",
      },
      part2: {
        part2_typography_0_0_0: "$A$ 이해하기",
        part2_typography_1_0_0:
          "함께 찾은 중간 믿음에는 이런 $A$이(가) 있었어요.",
        part2_typography_2_0_0: "이것이 내게 어떤 이점을 주는지 살펴볼게요.",
        part2_typography_3_0_0:
          "이 $A$이(가) 지난 삶에서 내게 주었던 이점은 무엇인 것 같나요?",
        part2_typography_4_0_0: "이러한 이점으로 발생한 결과는 무엇이었나요?",
        part2_typography_5_0_0:
          "아마 핵심 믿음이 주는 고통스러운 감정을 피할 수 있었을 거예요.",
        part2_typography_6_0_0: "하지만 분명 손해도 있었을 텐데요.",
        part2_typography_7_0_0:
          "이 $A$이(가) 지난 삶에서 내게 주었던 손해는 무엇인 것 같나요?",
        part2_typography_8_0_0: "이러한 손해로 발생한 결과는 무엇이었나요?",
        part2_typography_9_0_0:
          "그 결과는 결국 나, 타인, 세상에 대해 어떠한 결론을 내리도록 만드나요?",
      },
      part3: {
        part3_typography_0_0_0:
          '위와 같은 $A$(으)로 "$B$"(와)과 같은 이점을 얻었어요.',
      },
      part4: {
        part4_typography_0_0_0:
          '위와 같은 $A$(으)로 "$B$"(와)과 같은 손해를 얻었어요.',
        part4_typography_1_0_0: '그로 인해 "$A$"(와)과 같은 결과에 이르렀죠.',
        part4_typography_2_0_0:
          '결국 나는 나와 타인과 세상에 대해 "$A$"(와)과 같은 생각을 갖게 되었네요.',
      },
    },
  },
};
export const translation_6_9_A = {
  en: {
    v1: {
      typography_0_0_0:
        "Great job! You've finally completed your thought map. It must have been a challenging task, and you've done well.",
      typography_1_0_0:
        "Today, let's take some time to understand this map of thoughts we've created.",
      typography_2_0_0:
        "Integrating all the information we've gathered so far might take a while.",
      typography_3_0_0:
        "But it's a crucial task, so let's take it step by step.",
      typography_4_0_0:
        "Often, after completing a thought map, we find ourselves asking questions like:",
      typography_5_0_0: `"Why have I been living with such a map in my mind all this time?"`,
      typography_6_0_0: `"So, what should I do moving forward?"`,
      typography_7_0_0:
        "Don't worry. By gradually understanding and practicing distancing from these thoughts, you'll start to see changes.",
      typography_8_0_0: "First, let's revisit my thought map.",
      typography_9_0_0:
        "It’s important to understand that the thinking traps and coping strategies reinforce my core beliefs.",
      typography_10_0_0:
        "Let's check if you can see this pattern in your thought map.",
      typography_11_0_0: "Role of thinking traps",
      typography_12_0_0:
        "Thinking traps accept only the information that aligns with our core beliefs and reject anything that contradicts them.",
      typography_13_0_0: `For instance, someone with a core belief "I am inadequate"`,
      typography_14_0_0:
        "might react to a failure with the 'Extra Blame' trap, feeling as though everything is their fault, amplifying their sense of inadequacy.",
      typography_15_0_0: `The 'Little Evidence' trap might lead them to dismiss their achievements as trivial, hiding their competencies.`,
      typography_16_0_0:
        "The 'Negative Bias' trap could amplify anxiety, making them feel anxious and uncertain despite their abilities.",
      typography_17_0_0: `Meanwhile, the 'Judgment' trap focuses more on negative self-assessment like "I am lacking,"`,
      typography_18_0_0: `and the 'Conceptualization' trap turns their perceived inadequacies into a part of their identity.`,
      typography_19_0_0: `The 'Rumination' trap constantly reminds them of their inadequacies.`,
      typography_20_0_0:
        "Falling into these traps means accepting only the information that matches our core beliefs",
      typography_21_0_0:
        "while skillfully filtering out anything that might prove those beliefs wrong.",
      typography_22_0_0: `It's like wearing tinted glasses.`,
      typography_23_0_0: `Let's examine my automatic thoughts one by one:`,
      ...translation6_9_A_common.en.v1.part1,

      typography_24_0_0:
        "Combining these insights, do you think these thinking traps are strengthening my core beliefs?",
      typography_25_0_0: `Now, let's delve deeper into coping strategies.`,
      typography_26_0_0: "Role of coping strategies",

      typography_27_0_0:
        "Coping strategies are responses to the threat posed by core beliefs.",
      typography_28_0_0: `Reflect on the experiences that formed your core belief.`,
      typography_29_0_0:
        "What emotions did you feel? If it's hard to recall, consider the most painful emotion if the core belief were entirely true.",
      typography_30_0_0:
        "Coping strategies are ways to survive the emotional distress caused by core beliefs.",
      typography_31_0_0: `They are not wrong or foolish.`,
      typography_32_0_0: `In fact, they might have been quite effective in managing pain in the past.`,
      typography_33_0_0: "Let's explore them further:",
      ...translation6_9_A_common.en.v1.part2,

      typography_34_0_0: `Many people realize at this point that`,
      typography_35_0_0: `the coping strategies designed to avoid core beliefs are actually reinforcing them.`,
      typography_36_0_0: `This is natural.`,
      typography_37_0_0:
        "The strategies were valid and helpful during the initial core experiences.",
      typography_38_0_0: `But now, they may not be as appropriate, hence the problem.`,
      typography_39_0_0: "Puzzle solved",
      typography_40_0_0: `I hold certain core beliefs.`,
      typography_41_0_0: "These beliefs were shaped by specific experiences.",
      typography_42_0_0: `Especially feelings like "$A$" made my core beliefs uncomfortable and painful.`,
      typography_43_0_0: "So, I developed coping strategies like:",
      ...translation6_9_A_common.en.v1.part3,
      typography_44_0_0: "However, as time passed, my situation changed.",
      typography_45_0_0: `Despite being a different person now, I continue to use the same coping strategies.`,
      typography_46_0_0:
        "Consequently, these strategies have also led to downsides:",
      ...translation6_9_A_common.en.v1.part4,
      typography_48_0_0: `The strategies meant to cope with my core beliefs are reinforcing them, creating a cycle of repetition.`,
      typography_49_0_0: `It's been a tough journey, but there were valid reasons for these thoughts and strategies.`,
      typography_50_0_0: `It's not because of a fault or foolishness on my part.`,
      typography_51_0_0: `Finding freedom`,
      typography_52_0_0: `Let's circle back to the beginning. Thoughts are like popcorn.`,
      typography_53_0_0: `They just pop into our mind.`,
      typography_54_0_0: `Forcing or changing these thoughts is futile.`,
      typography_55_0_0: `Our minds don't operate that way.`,
      typography_56_0_0: `Similarly, we won't try to forcefully change our thought map.`,
      typography_57_0_0: `It's a way of thinking that has slowly settled in our minds, perhaps even becoming a part of our identity.`,
      typography_58_0_0: `We can't erase such an identity.`,
      typography_59_0_0: `But, there's an important truth:`,
      typography_60_0_0: `Just as we managed to distance ourselves from individual thoughts, we can distance ourselves from the thought map.`,
      typography_61_0_0: `In cognitive therapy, there's a saying:`,
      typography_62_0_0: `"Thoughts aren't free, but we become free the moment we understand and grasp them."`,
      typography_63_0_0: `We've identified and understood the thought map that's been causing anxiety or depression.`,
      typography_64_0_0: `Now, we can choose not to react to it in our daily lives.`,
      typography_65_0_0: `While immediate change is unlikely, persistent effort will bring about fundamental changes.`,
      typography_66_0_0: `From today, after recording thoughts in <Thought Log>, let's examine which core beliefs and coping strategies they stem from.`,
      typography_67_0_0: `This process will create a space where we don't immediately react to emerging thoughts.`,
      typography_68_0_0: `We'll fill this space with new changes.`,
      typography_69_0_0: `Remember, our minds don't consist solely of this thought map.`,
      typography_70_0_0: `As we work together, you'll discover 'other thought maps' you'd rather respond to more often.`,
      typography_71_0_0: `Now, it's time to move in a new direction in life.`,
      typography_72_0_0: `Today's activity was quite extensive, but you've done well. Shall we wrap up for today?`,
    },
    v2: {
      규칙: "Rules",
      태도: "Attitudes",
      가정: "Assumptions",
      typography_0_0_0:
        "Great job! You've finally completed your thought map. It must have been a challenging task, and you've done well.",
      typography_1_0_0:
        "Today, let's take some time to understand this map of thoughts we've created.",
      typography_2_0_0:
        "Integrating all the information we've gathered so far might take a while.",
      typography_3_0_0:
        "But it's a crucial task, so let's take it step by step.",
      typography_4_0_0:
        "Often, after completing a thought map, we find ourselves asking questions like:",
      typography_5_0_0: `"Why have I been living with such a map in my mind all this time?"`,
      typography_6_0_0: `"So, what should I do moving forward?"`,
      typography_7_0_0:
        "Don't worry. By gradually understanding and practicing distancing from these thoughts, you'll start to see changes.",
      typography_8_0_0: "First, let's revisit my thought map.",
      typography_9_0_0:
        "It’s important to understand that the thinking traps and coping strategies reinforce my core beliefs.",
      typography_10_0_0:
        "Let's check if you can see this pattern in your thought map.",
      typography_11_0_0: "Role of thinking traps",
      typography_12_0_0:
        "Thinking traps accept only the information that aligns with our core beliefs and reject anything that contradicts them.",
      typography_13_0_0: `For instance, someone with a core belief "I am inadequate"`,
      typography_14_0_0:
        "might react to a failure with the 'Extra Blame' trap, feeling as though everything is their fault, amplifying their sense of inadequacy.",
      typography_15_0_0: `The 'Little Evidence' trap might lead them to dismiss their achievements as trivial, hiding their competencies.`,
      typography_16_0_0:
        "The 'Negative Bias' trap could amplify anxiety, making them feel anxious and uncertain despite their abilities.",
      typography_17_0_0: `Meanwhile, the 'Judgment' trap focuses more on negative self-assessment like "I am lacking,"`,
      typography_18_0_0: `and the 'Conceptualization' trap turns their perceived inadequacies into a part of their identity.`,
      typography_19_0_0: `The 'Rumination' trap constantly reminds them of their inadequacies.`,
      typography_20_0_0:
        "Falling into these traps means accepting only the information that matches our core beliefs",
      typography_21_0_0:
        "while skillfully filtering out anything that might prove those beliefs wrong.",
      typography_22_0_0: `It's like wearing tinted glasses.`,
      typography_23_0_0: `Let's examine my automatic thoughts one by one:`,
      ...translation6_9_A_common.en.v2.part1,

      typography_24_0_0:
        "Let's sum up. Do the traps seem to make our core beliefs stronger?",
      typography_25_0_0: `Now, onto a deeper issue: intermediate beliefs.`,
      typography_26_0_0: "Intermediate Beliefs' Role",

      typography_27_0_0:
        "As mentioned, intermediate beliefs are rules, attitudes, or assumptions we make to deal with threats to our core beliefs.",
      typography_28_0_0: `Reflect on the experiences that formed your core belief.`,
      typography_29_0_0:
        "What emotions did you feel? If it's hard to recall, consider the most painful emotion if the core belief were entirely true.",
      typography_30_0_0:
        "Intermediate beliefs are ways to survive those tough emotions from our core beliefs.",
      typography_31_0_0: `They are not wrong or foolish.`,
      typography_32_0_0: `These rules, attitudes, and assumptions helped us get by until now.`,
      typography_33_0_0: "Let's explore them further:",
      ...translation6_9_A_common.en.v2.part2,

      typography_34_0_0: `Many people realize at this point that`,
      typography_35_0_0: `"The intermediate beliefs and automatic thoughts I created to handle my core beliefs actually make those core beliefs stronger."`,
      typography_36_0_0: `This is natural.`,
      typography_37_0_0:
        "Back when I had those core experiences, my intermediate beliefs and automatic thoughts were really helpful and made sense.",
      typography_38_0_0: `But now, they may not be as appropriate, hence the problem.`,
      typography_39_0_0: "Puzzle solved",
      typography_40_0_0: `I have these core beliefs.`,
      typography_41_0_0: "These beliefs were shaped by specific experiences.",
      typography_42_0_0: `Especially feelings like "$A$" made my core beliefs uncomfortable and painful.`,
      typography_43_0_0:
        "So, to deal with it, I kept these intermediate beliefs in my mind.",
      ...translation6_9_A_common.en.v2.part3,
      typography_44_0_0: "However, as time passed, my situation changed.",
      typography_45_0_0: `Even though I've changed, I was still dealing with my mind using the same intermediate beliefs.`,
      typography_46_0_0: "So, I started facing downsides.",
      ...translation6_9_A_common.en.v2.part4,
      typography_48_0_0: `So, the intermediate beliefs meant to handle my core beliefs end up making those core beliefs stronger, and the cycle continues.`,
      typography_49_0_0: `You've worked hard. But there was a good reason for these thoughts; they made sense.`,
      typography_50_0_0: `It's not because of a fault or foolishness on my part.`,
      typography_51_0_0: `Finding freedom`,
      typography_52_0_0: `Let's circle back to the beginning. Thoughts are like popcorn.`,
      typography_53_0_0: `They just pop into our mind.`,
      typography_54_0_0: `Forcing or changing these thoughts is futile.`,
      typography_55_0_0: `Our minds don't operate that way.`,
      typography_56_0_0: `Similarly, we won't try to forcefully change our thought map.`,
      typography_57_0_0: `It's a way of thinking that has slowly settled in our minds, perhaps even becoming a part of our identity.`,
      typography_58_0_0: `We can't erase such an identity.`,
      typography_59_0_0: `But, there's an important truth:`,
      typography_60_0_0: `Just as we managed to distance ourselves from individual thoughts, we can distance ourselves from the thought map.`,
      typography_61_0_0: `In cognitive therapy, there's a saying:`,
      typography_62_0_0: `"Thoughts aren't free, but we become free the moment we understand and grasp them."`,
      typography_63_0_0: `We've identified and understood the thought map that's been causing anxiety or depression.`,
      typography_64_0_0: `Now, we just need to catch when it happens and choose not to react.`,
      typography_65_0_0: `While immediate change is unlikely, persistent effort will bring about fundamental changes.`,
      typography_66_0_0: `From today, after writing down thoughts in <Thought Record>, we'll look at what core and intermediate beliefs they come from.`,
      typography_67_0_0: `This process will create a space where we don't immediately react to emerging thoughts.`,
      typography_68_0_0: `We'll fill this space with new changes.`,
      typography_69_0_0: `Remember, our minds don't consist solely of this thought map.`,
      typography_70_0_0: `As we work together, you'll discover 'other thought maps' you'd rather respond to more often.`,
      typography_71_0_0: `Now, it's time to move in a new direction in life.`,
      typography_72_0_0: `Today's activity was quite extensive, but you've done well. Shall we wrap up for today?`,
    },
  },
  ko: {
    v1: {
      typography_0_0_0:
        "좋아요. 드디어 생각 지도를 다 그렸네요. 힘들었을 텐데 고생 많았어요.",
      typography_1_0_0:
        "오늘은 나의 생각 지도를 이해하는 시간을 가져보려고 해요.",
      typography_2_0_0:
        "지금까지 찾았던 정보를 통합하는 작업이라 제법 활동이 길 수도 있어요.",
      typography_3_0_0:
        "하지만 중요한 작업이니 하나씩 천천히 진행해보도록 해요.",
      typography_4_0_0:
        "흔히 생각 지도를 다 그리고 나면 다음과 같이 생각하곤 해요.",
      typography_5_0_0: `"나는 왜 여태 이런 지도를 가지고 살아왔던 걸까?"`,
      typography_6_0_0: `"그래서 앞으로는 어떻게 해야 하는 거지?"`,
      typography_7_0_0:
        "걱정하지 말아요. 하나씩 이해하고 거리두는 연습을 하다 보면 조금씩 변화를 만들어낼 수 있을 거예요.",
      typography_8_0_0: "자, 우선 나의 생각 지도를 다시 살펴볼까요?",
      typography_9_0_0:
        "생각 지도에서 가장 먼저 이해해야 할 것은 생각함정과 대처 전략들이 다시 핵심 믿음을 강화한다는 거예요.",
      typography_10_0_0:
        "나의 생각 지도에서도 그런 모습이 발견되는지 하나씩 살펴볼게요.",
      typography_11_0_0: "생각함정의 역할",
      typography_12_0_0:
        "생각함정은 내가 가진 핵심 믿음에 부합하는 정보만 수용하고, 반하는 정보는 차단하게 해요.",
      typography_13_0_0: `예를 들어볼게요. "나는 부족하다"라는 핵심 믿음을 가지고 있는 사람이 있어요.`,
      typography_14_0_0:
        "만약 어떠한 일을 실패하고 '책임 과다' 생각함정이 작용한다면, 그 모든 게 자신의 잘못인 것처럼 느끼게 될 거예요. 스스로가 더 부족해 보이기만 하죠.",
      typography_15_0_0: `'근거 부족' 생각함정은 그 사람이 이뤄낸 성과를 별일 아닌 것으로 치부할 수도 있어요. 충분히 유능한 구석을 가려버리는 것이죠.`,
      typography_16_0_0:
        "이따금 '부정 편향' 생각함정이 작용하여 불안감을 증폭시킬 수도 있겠죠. 잘 할 수 있음에도 스스로가 부족하다는 생각에 사로잡혀 초조하고 불안에 떨게 되는 거예요.",
      typography_17_0_0: `한편 '평가' 생각함정은 "나는 모자르다"와 같은 부정적인 평가에 더욱 집중하도록 하고,`,
      typography_18_0_0: `'개념화' 생각함정은 자신이 부족한 이유를 대며 그것을 자신의 정체성으로 만들어버리죠.`,
      typography_19_0_0: `'반추' 생각함정은 나의 부족함을 끊임없이 상기시키게 해요.`,
      typography_20_0_0:
        "이처럼 생각함정에 빠지게 되면 나의 핵심 믿음에 일치하는 정보만 받아들이게 돼요.",
      typography_21_0_0:
        "핵심 믿음이 틀릴 수도 있다는 정보는 모두 교묘히 거르게 되고요.",
      typography_22_0_0: `마치 색안경을 쓴 것처럼요.`,
      typography_23_0_0: `나의 경우에는 어떤지 살펴볼까요? 함께 찾은 자동적 사고를 하나씩 살펴볼게요.`,
      ...translation6_9_A_common.ko.v1.part1,

      typography_24_0_0:
        "위 내용을 종합해보죠. 생각함정들은 나의 핵심 믿음을 더욱 강화하고 있는 것 같나요?",
      typography_25_0_0: `좋아요. 이번엔 조금 더 깊은 문제인 대처 전략을 살펴보죠.`,
      typography_26_0_0: "대처 전략의 역할",

      typography_27_0_0:
        "앞서 이야기한 것처럼 대처 전략은 '핵심 믿음의 위협에 대처하기 위해 보이는 반응'이에요.",
      typography_28_0_0: `핵심 믿음과 그것을 만들었던 경험을 다시 한번 읽어보세요.`,
      typography_29_0_0:
        "이 경험 속에서 나는 어떤 감정을 느꼈던 것 같나요? 잘 기억이 나지 않는다면 핵심 믿음이 100% 사실이라고 가정했을 때, 지금 느껴지는 가장 고통스러운 감정은 무엇인가요?",
      typography_30_0_0:
        "대처 전략은 핵심 믿음이 주는 위와 같은 감정으로부터 살아남기 위한 방법이에요.",
      typography_31_0_0: `잘못되거나 어리석은 게 아니랍니다.`,
      typography_32_0_0: `그렇게 잘 대처해왔기 때문에 지금까지 이렇게 지내올 수 있었던 걸지도 몰라요.`,
      typography_33_0_0: "정말로 그런지 하나씩 살펴볼까요?",
      ...translation6_9_A_common.ko.v1.part2,

      typography_34_0_0: `많은 사람들이 이 지점에서 다음과 같은 사실을 발견해요.`,
      typography_35_0_0: `"핵심 믿음을 피하기 위해서 고안해낸 대처 전략이 오히려 핵심 믿음을 강화하고 있다"`,
      typography_36_0_0: `사실 이건 자연스러운 이야기예요.`,
      typography_37_0_0:
        "핵심 경험이 있었던 당시에는 나의 대처 전략이 매우 유용하고 도움이 되고 타당한 방법이었기 때문이에요.",
      typography_38_0_0: `하지만 지금 이 순간에는 타당한 방법이 아니기 때문에 문제가 되는 거랍니다.`,
      typography_39_0_0: "맞춰진 퍼즐",
      typography_40_0_0: `나는 다음과 같은 핵심 믿음을 가지고 있어요.`,
      typography_41_0_0:
        "이 믿음은 다음과 같은 경험을 통해 나의 마음속에 자리 잡았죠.",
      typography_42_0_0: `나는 특히 "$A$"(와)과 같은 감정이 들며 나의 핵심 믿음이 불편하고 고통스러웠어요.`,
      typography_43_0_0: "그래서 나는 다음과 같은 대처 전략을 만들었어요.",
      ...translation6_9_A_common.ko.v1.part3,
      typography_44_0_0: "하지만 시간이 지남에 따라 상황은 변했어요.",
      typography_45_0_0: `나는 과거와는 다른 사람이 되었음에도 불구하고, 여전히 동일한 대처 전략을 지속하게 되었죠.`,
      typography_46_0_0: "그래서 이제는 대처 전략의 손해도 발생하게 되었어요.",
      ...translation6_9_A_common.ko.v1.part4,
      typography_48_0_0: `이렇듯 핵심 믿음에 대해 대처하려고 했던 전략이 오히려 핵심 믿음을 강화하며, 악순환이 반복되고 있네요.`,
      typography_49_0_0: `고생 많았어요. 하지만 그것도 다 그럴만한 이유가 있었고, 충분히 타당했던 생각이었답니다.`,
      typography_50_0_0: `내가 무언가를 잘못해서, 내가 어리석어서 그런 생각을 가지게 된 건 아니에요.`,
      typography_51_0_0: `자유를 찾아`,
      typography_52_0_0: `자, 다시 원점으로 돌아가볼게요. 생각은 팝콘과도 같아요.`,
      typography_53_0_0: `그저 마음속에 '탁'하고 떠오를 뿐이죠.`,
      typography_54_0_0: `마음속에 떠오르는 생각을 억지로 거부하거나 바꾸려 하는 건 소용없어요.`,
      typography_55_0_0: `애초에 우리 마음은 그렇게 자유롭게 작동하는 게 아니기 때문이죠.`,
      typography_56_0_0: `마찬가지로 우리는 생각 지도를 억지로 바꾸려 노력하지 않을 거예요.`,
      typography_57_0_0: `생각 지도는 오래 전부터 조금씩 우리 마음속에 자리 잡은 하나의 생각 방식이에요. 어쩌면 나의 중요한 정체성 중 하나일 수도 있죠.`,
      typography_58_0_0: `그러한 정체성을 없앨 수는 없어요.`,
      typography_59_0_0: `하지만 중요한 사실이 있어요.`,
      typography_60_0_0: `우리가 개별 생각으로부터 거리두기가 가능했던 것처럼, 생각 지도로부터도 거리두기가 가능하다는 사실이에요.`,
      typography_61_0_0: `인지치료 분야에서는 다음과 같은 격언이 있어요.`,
      typography_62_0_0: `"생각은 자유롭지 않지만, 우리가 그것을 파악하고 이해하는 순간 우리는 비로소 자유로워질 수 있다."`,
      typography_63_0_0: `지금까지 우리는 나를 불안하게 하거나 우울하게 만드는 생각 지도를 찾아 이해했답니다.`,
      typography_64_0_0: `그렇다면 우리는 일상 속에서 그러한 생각 지도가 작동하는 순간을 포착한 뒤, 그에 대해 반응하지 않기로 선택할 수 있어요.`,
      typography_65_0_0: `물론 단기간 내에 바꿀 수는 없겠지만 조금씩 노력하다 보면, 분명 근원적인 부분에서부터의 변화를 만들어낼 수 있을 거예요.`,
      typography_66_0_0: `오늘부터는 <생각기록지>에 생각을 기록하고 나면, 그 생각이 어떤 핵심 믿음과 대처 전략으로부터 기인했는지를 살펴볼 거예요.`,
      typography_67_0_0: `그 과정에서 조금씩 마음속에 떠오른 생각에 곧바로 반응하지 않을 공간이 생길 거예요.`,
      typography_68_0_0: `우리는 그 공간을 새로운 변화로 채워넣을 거랍니다.`,
      typography_69_0_0: `사실 우리 마음속에는 위와 같은 생각 지도만 있는 건 아니랍니다.`,
      typography_70_0_0: `함께 노력하다 보면 분명 내가 더 자주 반응하기로 선택할 '또 다른 생각 지도'도 있다는 걸 알게 될 거예요.`,
      typography_71_0_0: `자, 이제 새로운 삶의 방향으로 나아갈 시간이에요.`,
      typography_72_0_0: `이번 활동은 제법 길었는데 오늘도 고생 많았어요. 오늘은 여기까지 할까요?`,
    },
    v2: {
      규칙: "규칙",
      태도: "태도",
      가정: "가정",
      typography_0_0_0:
        "좋아요. 드디어 생각 지도를 다 그렸네요. 힘들었을 텐데 고생 많았어요.",
      typography_1_0_0:
        "오늘은 나의 생각 지도를 이해하는 시간을 가져보려고 해요.",
      typography_2_0_0:
        "지금까지 찾았던 정보를 통합하는 작업이라 제법 활동이 길 수도 있어요.",
      typography_3_0_0:
        "하지만 중요한 작업이니 하나씩 천천히 진행해보도록 해요.",
      typography_4_0_0:
        "흔히 생각 지도를 다 그리고 나면 다음과 같이 생각하곤 해요.",
      typography_5_0_0: `"나는 왜 여태 이런 지도를 가지고 살아왔던 걸까?"`,
      typography_6_0_0: `"그래서 앞으로는 어떻게 해야 하는 거지?"`,
      typography_7_0_0:
        "걱정하지 말아요. 하나씩 이해하고 거리두는 연습을 하다 보면 조금씩 변화를 만들어낼 수 있을 거예요.",
      typography_8_0_0: "자, 우선 나의 생각 지도를 다시 살펴볼까요?",
      typography_9_0_0:
        "생각 지도에서 가장 먼저 이해해야 할 것은 생각함정과 중간 믿음들이 다시 핵심 믿음을 강화한다는 거예요.",
      typography_10_0_0:
        "나의 생각 지도에서도 그런 모습이 발견되는지 하나씩 살펴볼게요.",
      typography_11_0_0: "생각함정의 역할",
      typography_12_0_0:
        "생각함정은 내가 가진 핵심 믿음에 부합하는 정보만 수용하고, 반하는 정보는 차단하게 해요.",
      typography_13_0_0: `예를 들어볼게요. "나는 부족하다"라는 핵심 믿음을 가지고 있는 사람이 있어요.`,
      typography_14_0_0:
        "만약 어떠한 일을 실패하고 '책임 과다' 생각함정이 작용한다면, 그 모든 게 자신의 잘못인 것처럼 느끼게 될 거예요. 스스로가 더 부족해 보이기만 하죠.",
      typography_15_0_0: `'근거 부족' 생각함정은 그 사람이 이뤄낸 성과를 별일 아닌 것으로 치부할 수도 있어요. 충분히 유능한 구석을 가려버리는 것이죠.`,
      typography_16_0_0:
        "이따금 '부정 편향' 생각함정이 작용하여 불안감을 증폭시킬 수도 있겠죠. 잘 할 수 있음에도 스스로가 부족하다는 생각에 사로잡혀 초조하고 불안에 떨게 되는 거예요.",
      typography_17_0_0: `한편 '평가' 생각함정은 "나는 모자르다"와 같은 부정적인 평가에 더욱 집중하도록 하고,`,
      typography_18_0_0: `'개념화' 생각함정은 자신이 부족한 이유를 대며 그것을 자신의 정체성으로 만들어버리죠.`,
      typography_19_0_0: `'반추' 생각함정은 나의 부족함을 끊임없이 상기시키게 해요.`,
      typography_20_0_0:
        "이처럼 생각함정에 빠지게 되면 나의 핵심 믿음에 일치하는 정보만 받아들이게 돼요.",
      typography_21_0_0:
        "핵심 믿음이 틀릴 수도 있다는 정보는 모두 교묘히 거르게 되고요.",
      typography_22_0_0: `마치 색안경을 쓴 것처럼요.`,
      typography_23_0_0: `나의 경우에는 어떤지 살펴볼까요? 함께 찾은 자동적 사고를 하나씩 살펴볼게요.`,
      ...translation6_9_A_common.ko.v2.part1,

      typography_24_0_0:
        "위 내용을 종합해보죠. 생각함정들은 나의 핵심 믿음을 더욱 강화하고 있는 것 같나요?",
      typography_25_0_0: `좋아요. 이번엔 조금 더 깊은 문제인 중간 믿음을 살펴보죠.`,
      typography_26_0_0: "중간 믿음의 역할",

      typography_27_0_0:
        "앞서 이야기한 것처럼 중간 믿음은 '핵심 믿음의 위협에 대처하기 위해 만든 규칙, 태도, 가정'이에요.",
      typography_28_0_0: `핵심 믿음과 그것을 만들었던 경험을 다시 한번 읽어보세요.`,
      typography_29_0_0:
        "이 경험 속에서 나는 어떤 감정을 느꼈던 것 같나요? 잘 기억이 나지 않는다면 핵심 믿음이 100% 사실이라고 가정했을 때, 지금 느껴지는 가장 고통스러운 감정은 무엇인가요?",
      typography_30_0_0:
        "중간 믿음은 핵심 믿음이 주는 위와 같은 감정으로부터 살아남기 위한 방법이에요.",
      typography_31_0_0: `잘못되거나 어리석은 게 아니랍니다.`,
      typography_32_0_0: `그런 규칙, 태도, 가정을 세워서 잘 대처해왔기 때문에 지금까지 이렇게 지내올 수 있었던 걸지도 몰라요.`,
      typography_33_0_0: "정말로 그런지 하나씩 살펴볼까요?",
      ...translation6_9_A_common.ko.v2.part2,

      typography_34_0_0: `많은 사람들이 이 지점에서 다음과 같은 사실을 발견해요.`,
      typography_35_0_0: `"핵심 믿음에 대처하기 위해서 고안해낸 중간 믿음과 그로부터 만들어진 자동적 사고들이 오히려 핵심 믿음을 강화하고 있다"`,
      typography_36_0_0: `사실 이건 자연스러운 이야기예요.`,
      typography_37_0_0:
        "핵심 경험이 있었던 당시에는 나의 중간 믿음과 자동적 사고가 매우 유용하고 도움이 되고 타당한 방법이었기 때문이에요.",
      typography_38_0_0: `하지만 지금 이 순간에는 타당한 방법이 아니기 때문에 문제가 되는 거랍니다.`,
      typography_39_0_0: "맞춰진 퍼즐",
      typography_40_0_0: `나는 다음과 같은 핵심 믿음을 가지고 있어요.`,
      typography_41_0_0:
        "이 믿음은 다음과 같은 경험을 통해 나의 마음속에 자리 잡았죠.",
      typography_42_0_0: `나는 특히 "$A$"(와)과 같은 감정이 들며 나의 핵심 믿음이 불편하고 고통스러웠어요.`,
      typography_43_0_0:
        "그래서 나는 다음과 같은 대처하기 위해 다음과 같은 중간 믿음을 만들어 마음속에 간직했어요.",
      ...translation6_9_A_common.ko.v2.part3,
      typography_44_0_0: "하지만 시간이 지남에 따라 상황은 변했어요.",
      typography_45_0_0: `나는 과거와는 다른 사람이 되었음에도 불구하고, 여전히 동일한 중간 믿음으로 나의 마음에 대처했죠.`,
      typography_46_0_0: "그래서 이제는 손해도 발생하게 되었어요.",
      ...translation6_9_A_common.ko.v2.part4,
      typography_48_0_0: `이렇듯 핵심 믿음에 대해 대처하고자 만들었던 중간 믿음들이 오히려 핵심 믿음을 강화하며, 악순환이 반복되고 있네요.`,
      typography_49_0_0: `고생 많았어요. 하지만 그것도 다 그럴만한 이유가 있었고, 충분히 타당했던 생각이었답니다.`,
      typography_50_0_0: `내가 무언가를 잘못해서, 내가 어리석어서 그런 생각을 가지게 된 건 아니에요.`,
      typography_51_0_0: `자유를 찾아`,
      typography_52_0_0: `자, 다시 원점으로 돌아가볼게요. 생각은 팝콘과도 같아요.`,
      typography_53_0_0: `그저 마음속에 '탁'하고 떠오를 뿐이죠.`,
      typography_54_0_0: `마음속에 떠오르는 생각을 억지로 거부하거나 바꾸려 하는 건 소용없어요.`,
      typography_55_0_0: `애초에 우리 마음은 그렇게 자유롭게 작동하는 게 아니기 때문이죠.`,
      typography_56_0_0: `마찬가지로 우리는 생각 지도를 억지로 바꾸려 노력하지 않을 거예요.`,
      typography_57_0_0: `생각 지도는 오래 전부터 조금씩 우리 마음속에 자리 잡은 하나의 생각 방식이에요. 어쩌면 나의 중요한 정체성 중 하나일 수도 있죠.`,
      typography_58_0_0: `그러한 정체성을 없앨 수는 없어요.`,
      typography_59_0_0: `하지만 중요한 사실이 있어요.`,
      typography_60_0_0: `우리가 개별 생각으로부터 거리두기가 가능했던 것처럼, 생각 지도로부터도 거리두기가 가능하다는 사실이에요.`,
      typography_61_0_0: `인지치료 분야에서는 다음과 같은 격언이 있어요.`,
      typography_62_0_0: `"생각은 자유롭지 않지만, 우리가 그것을 파악하고 이해하는 순간 우리는 비로소 자유로워질 수 있다."`,
      typography_63_0_0: `지금까지 우리는 나를 불안하게 하거나 우울하게 만드는 생각 지도를 찾아 이해했답니다.`,
      typography_64_0_0: `이제부터는 그러한 생각 지도가 작동하는 순간을 포착한 뒤, 그에 대해 반응하지 않기로 선택하면 되는 것이죠.`,
      typography_65_0_0: `물론 단기간 내에 바꿀 수는 없겠지만 조금씩 노력하다 보면, 분명 근원적인 부분에서부터의 변화를 만들어낼 수 있을 거예요.`,
      typography_66_0_0: `오늘부터는 <생각기록지>에 생각을 기록하고 나면, 그 생각이 어떤 핵심 믿음과 중간 믿음으로부터 기인했는지를 살펴볼 거예요.`,
      typography_67_0_0: `그 과정에서 조금씩 마음속에 떠오른 생각에 곧바로 반응하지 않을 공간이 생길 거예요.`,
      typography_68_0_0: `우리는 그 공간을 새로운 변화로 채워넣을 거랍니다.`,
      typography_69_0_0: `사실 우리 마음속에는 위와 같은 생각 지도만 있는 건 아니랍니다.`,
      typography_70_0_0: `함께 노력하다 보면 분명 내가 더 자주 반응하기로 선택할 '또 다른 생각 지도'도 있다는 걸 알게 될 거예요.`,
      typography_71_0_0: `자, 이제 새로운 삶의 방향으로 나아갈 시간이에요.`,
      typography_72_0_0: `이번 활동은 제법 길었는데 오늘도 고생 많았어요. 오늘은 여기까지 할까요?`,
    },
  },
};
