import { useRecoilValue } from "recoil";
import { Box, Stack } from "@mui/joy";
import React, { useCallback, useEffect, useState } from "react";
import TestResult from "components/dashboard/TestResult";
import { patientAtom } from "recoil/patientAtom";
import {
  dashboardApi,
  DashboardType,
  DASHBOARD_DETAIL_ENDPOINT,
  DASHBOARD_DOMAIN,
} from "api/dashboardApi";
import { useQuery } from "@tanstack/react-query";
import ContentTemplate from "./ContentTemplate";
import PaymentModule from "components/sidebar/PaymentModule";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";
import { useTranslation } from "react-i18next";
import { userAtom } from "recoil/userAtom";
import BehavioralActivationProgress from "components/dashboard/BehaviorActivationProgress";

function Section({ content }: { content: React.ReactNode }) {
  return <Box sx={{ height: "100%" }}>{content}</Box>;
}

export default function MyProgressContentBehaviorActivation() {
  const patientState = useRecoilValue(patientAtom);
  const userState = useRecoilValue(userAtom);
  const isPatient = userState?.accessToken && userState?.role === "patient";

  const { t: t_payment } = useTranslation("translation", {
    keyPrefix: "payment",
  });
  const { isSubscribing, isNotStarted } = useSubscriptionStatus();

  const queryFn = useCallback(
    () => dashboardApi.load(`${patientState?.patientId}`),
    [patientState]
  );
  const [dashboardData, setDashboardData] = useState<DashboardType>();
  useQuery([DASHBOARD_DOMAIN, DASHBOARD_DETAIL_ENDPOINT], queryFn, {
    enabled: !!patientState?.patientId,
    onSuccess: (data) => {
      console.log(data.result);
      setDashboardData(data.result);
    },
  });

  useEffect(() => {
    if (!patientState?.patientId) {
      setDashboardData({
        concept: {},
        history: {},
        selfCheckResult: {},
      });
    }
  }, [patientState]);

  const showPaymentModule = isPatient && !isSubscribing;

  return (
    <ContentTemplate>
      {dashboardData && (
        <Stack spacing={2}>
          {showPaymentModule && (
            <PaymentModule
              title={t_payment("나만의 프로그램을 구성하세요")}
              subtitle={t_payment(
                isNotStarted ? "7일 무료 체험" : "월별 결제, 언제든 취소 가능"
              )}
              size={"lg"}
            />
          )}

          <BehavioralActivationProgress />
          {showPaymentModule && (
            <PaymentModule
              title={t_payment("변화를 꾸준히 트래킹하세요")}
              subtitle={t_payment(
                isNotStarted ? "7일 무료 체험" : "월별 결제, 언제든 취소 가능"
              )}
              size={"lg"}
            />
          )}
          <Section
            content={<TestResult {...dashboardData.selfCheckResult} />}
          />
        </Stack>
      )}
    </ContentTemplate>
  );
}
