import { axiosInstance } from "api";
import { useEffect } from "react";

export default function SelfCheckWebflow() {
  const evaluation = {
    option0: { label: "전혀", value: 0 },
    option1: { label: "며칠간", value: 1 },
    option2: { label: "7일이상", value: 2 },
    option3: { label: "거의매일", value: 3 },
  };
  const evaluation2 = {
    //전혀/며칠간/7일이상/거의매일
    option0: { label: "전혀", value: 3 },
    option1: { label: "며칠간", value: 2 },
    option2: { label: "7일이상", value: 1 },
    option3: { label: "거의매일", value: 0 },
  };

  const data = [
    {
      question: "일이나 여가 활동에 흥미가 없고 기쁘지 않아요.",
      evaluation: evaluation,
    },
    // {
    //   question: "기분이 가라앉거나, 우울하거나, 희망이 없어요.",
    //   evaluation: evaluation,
    // },
    // {
    //   question: "잠들기 어렵거나, 자주 잠에 깨거나, 잠을 너무 많이 자요.",
    //   evaluation: evaluation,
    // },
    // { question: "피곤하거나 기운이 없어요.", evaluation: evaluation },
    // { question: "입맛이 없거나 과식을 해요.", evaluation: evaluation },
    // {
    //   question: "나에게 부정적이에요. 실패자 같고, 나와 내 가족을 실망시켜요.",
    //   evaluation: evaluation,
    // },
    // {
    //   question: "신문이나 TV 등을 볼 때 집중이 어려워요.",
    //   evaluation: evaluation,
    // },
    // {
    //   question:
    //     "말과 행동이 너무 느려 다른 사람이 알아차릴 수 있어요. 또는 반대로, 너무 안절부절못해 평소보다 훨씬 많이 움직여요.",
    //   evaluation: evaluation,
    // },
    // {
    //   question: "내가 죽는 게 낫다고 생각해요. 또는 스스로를 해칠 것 같아요.",
    //   evaluation: evaluation,
    // },
  ];

  const data2 = [
    {
      question: "아침에 늦잠을 잤다! 어떻게 할까?",
      optoins: [
        {
          text: "지금부터 2분 안에 씻고, 4분 안에 역으로 뛰어가면 2분 지각.",
          type: "J",
        },
        { text: "일단 옷부터 입고 뛰어나간다.", type: "P" },
      ],
    },
    {
      question: "출근 길 예상치 못한 교통 체증이 생겨버렸다!",
      optoins: [
        {
          text: "이전에 이쪽 길로 갔을 때 빨리 갔던 경험이 있어.",
          type: "S",
        },
        { text: "원래 가던길이 붐비네, 한산한 길로 가보자", type: "N" },
      ],
    },
    {
      question: "아침 회의에 지각해서 분위기가 냉랭하다. 이 때 나는? ",
      optoins: [
        {
          text: "직접적으로 이유를 설명하고 사과한다.",
          type: "T",
        },
        { text: "분위기를 풀기 위해 유머나 사과로 접근한다.", type: "F" },
      ],
    },
    {
      question: "전체 회의에서 주제가 산으로 가고 있는 것 같다.",
      optoins: [
        {
          text: "시간을 효율적으로 써야지.. 다시 한 번 주제를 상기시킨다.",
          type: "J",
        },
        {
          text: "새로운 주제에서 또 다른 인사이트를 얻을 수 있을것 같아서 경청한다.",
          type: "P",
        },
      ],
    },
    {
      question: "배가 고픈데 회의가 길어져 점심시간이 늦어지고 있다.",
      optoins: [
        {
          text: "점심 시간이 줄어들고 있으니 어서 회의를 끝내자고 제안한다.",
          type: "T",
        },
        {
          text: "모두 배고플 테니 점심시간을 갖자고 제안한다.",
          type: "F",
        },
      ],
    },
    {
      question: "앗, 인턴이 업무를 실수해서 팀장이 화가났다.",
      optoins: [
        {
          text: "직접 상사에게 다가가 상황을 설명하고 인턴을 변호한다.",
          type: "E",
        },
        {
          text: "상황을 지켜보다가 조용히 인턴에게 조언을 해준다.",
          type: "I",
        },
      ],
    },
    {
      question: "퇴근 후 친구와의 약속 시간에 늦어버렸다.",
      optoins: [
        {
          text: "다음엔 지금보다 더 일찍 나와서 늦지 않기로 다짐하고 반성한다.",
          type: "J",
        },
        {
          text: "이해해 주겠지.. 어쩔 수 없는 상황을 받아들인다.",
          type: "P",
        },
      ],
    },
    {
      question: "흠..친구가 나에게 뭔가 불만이 있는 것 같다. ",
      optoins: [
        {
          text: '“아까 어떤일이 있었냐면..." 늦게된 이유를 설명하고 상황을 이해 시키려 노력한다.',
          type: "T",
        },
        {
          text: "“진짜 미안해.. 많이 속상했지?” 속상한 친구를 달래본다.",
          type: "F",
        },
      ],
    },
    {
      question: "저녁을 먹으러 식당에 도착했는데 웨이팅이 있다.",
      optoins: [
        {
          text: "주변 사람들에게 맛집인지 물어보고 결정한다.",
          type: "E",
        },
        {
          text: "음식점 리뷰를 찾아보고 결정한다.",
          type: "I",
        },
      ],
    },
    {
      question: "피곤한 하루 집에 도착했을 때 업무 메신저로 연락이 와있다.",
      optoins: [
        {
          text: "지금 처리할 수 있는 구체적인 방법을 찾아 바로 답장한다.",
          type: "S",
        },
        {
          text: "내일 아침에 더 좋은 해결책을 생각해 답장한다.",
          type: "N",
        },
      ],
    },
    {
      question: "으악 지갑이없다. 아까 식당에서 두고 온 걸까?",
      optoins: [
        {
          text: "식당에 바로 전화해서 확인해본다.",
          type: "E",
        },
        {
          text: "다시 식당에 돌아가서 직접 찾아본다.",
          type: "I",
        },
      ],
    },
    {
      question: "잠자리에 들기 전 내일 중요한 발표가 있다는 것을 깨달았다.",
      optoins: [
        {
          text: "지난 발표 자료와 문서를 검토하면서 준비한다.",
          type: "S",
        },
        {
          text: "더 효과적인 방식은 없을까? 최신 트렌드를 찾아보며 준비한다.",
          type: "N",
        },
      ],
    },
  ];

  const resultData = [
    {
      range: [0, 4],
      resultPageId: "test-result-normal",
      oneLink: "https://www.google.com/1",
    },
    {
      range: [5, 9],
      resultPageId: "test-result-mild",
      oneLink: "https://www.google.com/2",
    },
    {
      range: [10, 14],
      resultPageId: "test-result-moderate",
      oneLink: "https://www.google.com/3",
    },
    {
      range: [15, 19],
      resultPageId: "test-result-severe",
      oneLink: "https://www.google.com/4",
    },
    {
      range: [20, 29],
      resultPageId: "test-result-extreme",
      oneLink: "https://www.google.com/5",
    },
  ];
  const resultData2 = [
    {
      type: 1,
      title: "🚶가끔 생각의 영향을 받지만 금방 회복할 수 있어요.",
    },
    {
      type: 2,
      title: "💭부정적인 생각과 거리두기가 필요한 상태네요.",
    },
    {
      type: 3,
      title: "🏥 전문 기관의 직접적인 관찰이 필요한 상태예요.",
    },
  ];

  const thoughtTrapData = [
    {
      id: "thought-trap_1",
      helpId: "thought-trap-help_1",
      name: "책임 과다",
    },
    {
      id: "thought-trap_2",
      helpId: "thought-trap-help_2",
      name: "근거 부족",
    },
    {
      id: "thought-trap_3",
      helpId: "thought-trap-help_3",
      name: "부정 편향",
    },
    {
      id: "thought-trap_4",
      helpId: "thought-trap-help_4",
      name: "평가",
    },
    {
      id: "thought-trap_5",
      helpId: "thought-trap-help_5",
      name: "개념화",
    },
    {
      id: "thought-trap_6",
      helpId: "thought-trap-help_6",
      name: "반추",
    },
  ];

  useEffect(() => {
    const selectedValues = {};
    let currentQuestionIndex = 0;
    let score = 0;
    const radioButtons = document.querySelectorAll(
      'input[type="radio"][name="depression"]'
    );

    function sumArray(arr) {
      // 배열의 각 요소를 누적하면서 합계를 계산
      return arr.reduce((acc, curr) => acc + curr, 0); // 초기값은 0
    }

    function hideElement(elementId) {
      const element = document.getElementById(elementId);
      element.style.display = "none";
    }

    function showElement(elementId) {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = "block";
      } else {
        console.error(`Element with id '${elementId}' not found`);
      }
    }

    function setRadioButton(index) {
      Object.values(data[index].evaluation).forEach((evaluation, index) => {
        const radioButton = document.getElementById(`option${index}`);
        const label = document.getElementById(`label${index}`);

        radioButton.value = evaluation.value;
        label.textContent = evaluation.label;
        radioButton.checked = false;
      });
      initializeRadioButton();
    }

    function initializeRadioButton(index) {
      radioButtons.forEach(function (radio) {
        radio.checked = false;
      });
    }

    function setQuestion(index) {
      setProgressbar(index);
      const element = document.getElementById("questionId");
      if (element) {
        element.innerText = data[index].question;
      } else {
        console.error("Element with id 'questionId' not found");
      }
      setRadioButton(index);
    }

    function setProgressbar(index) {
      const element = document.getElementById("progressbar");
      element.style.width = `${((index + 1) / data.length) * 100}%`;
      element.style.transition = "width 0.3s";
    }

    function getResult(totalScore) {
      const result = resultData.find(
        (element) => totalScore >= element.range[0] && score <= element.range[1]
      );
      return result;
    }

    function setTotalScore(totalScore) {
      console.log("total score: ", totalScore);
      const scoreElements = document.querySelectorAll("#accumulated-value");
      scoreElements.forEach((element) => {
        element.innerText = totalScore;
      });
    }

    function setOneLink(link) {
      ["cta-help2", "cta-floating"].forEach((id) => {
        const buttons = document.querySelectorAll(`#${id}`);
        buttons.forEach((button) => {
          button.href = link;
        });
      });
    }

    function setShareButton() {
      const buttons = document.querySelectorAll(`#share-button`);
      buttons.forEach((button) => {
        button.addEventListener("click", function (event) {
          event.preventDefault();
          if (navigator.share) {
            // navigator.share를 지원하는지 확인
            navigator
              .share({
                title: "디스턴싱 우울증 테스트", // 공유할 제목
                // text: "무료 자가 진단", // 공유할 텍스트
                url: window.location.href, // 현재 페이지 URL
              })
              .then(() => console.log("Successful share"))
              .catch((error) => console.log("Error sharing:", error));
          } else {
            if (navigator.clipboard) {
              // 클립보드 API 지원 확인
              navigator.clipboard
                .writeText(window.location.href)
                .then(() => {
                  console.log("Page URL copied to clipboard!");
                  alert("Page URL copied to clipboard!");
                })
                .catch((err) => {
                  console.error("Failed to copy: ", err);
                  alert("Failed to copy URL");
                });
            } else {
              // 클립보드 API도 지원하지 않는 경우
              console.log("Clipboard API not supported");
              alert("Copying not supported on this browser.");
            }
          }
        });
      });
    }
    function setText(id, text) {
      const element = document.getElementById(id);
      element.innerText = text;
    }
    function findThoughtTrapDataByName(name) {
      return thoughtTrapData.find((element) => element.name === name);
    }

    function getResultDetail(uuid) {
      axiosInstance
        .get("https://dev-api.distancing.im/self-check/v2/detail", {
          params: { uuid: uuid },
        })
        .then(function (response) {
          const result = response.data.result;
          console.log("get result", result);
          Object.entries(result).forEach(([key, value]) => {
            if (key === "type") {
              if (value === 3) {
                showElement("cant-help");
                hideElement("can-help");
              } else {
                showElement("can-help");
                hideElement("cant-help");
              }
            } else if (key === "thoughtTrapSection") {
              const thoughtTrapData = findThoughtTrapDataByName(
                value.thoughtTrap
              );
              showElement(thoughtTrapData.id);
              showElement(thoughtTrapData.helpId);
              Object.values(value).forEach(([key2, value2]) => {
                if (
                  ["title1", "description1", "title2", "description2"].includes(
                    key2
                  )
                ) {
                  setText(`${thoughtTrapData.id}_${key2}`, value2);
                }
              });
            } else if (
              [
                "concernAndExpectationSection",
                "professionalTreatmentRecommendation",
                "treatmentEncouragementSection",
                "improvementGoalSection",
              ].includes(key)
            ) {
              if (value) {
                Object.entries(value).forEach(([key2, value2]) => {
                  setText(`${key}_${key2}`, value2);
                });
              }
            }
          });
        })
        .catch(function (error) {
          console.error("API 호출 오류:", error);
        });
    }
    hideElement("test-questions");
    hideElement("test-done");
    resultData.forEach(({ range, resultPageId }) => {
      hideElement(resultPageId);
    });
    setShareButton();

    const startButton = document.getElementById("start-button");
    startButton.addEventListener("click", function () {
      hideElement("test-start");
      showElement("test-questions");
      setQuestion(currentQuestionIndex);
    });

    radioButtons.forEach(function (radio) {
      radio.addEventListener("change", function (event) {
        // 라디오 버튼이 선택되었을 때 콘솔에 출력
        if (event && event.target) {
          const currentEvaluation = data[currentQuestionIndex].evaluation;
          const value = currentEvaluation[event.target.id].value;
          selectedValues[currentQuestionIndex] = value;
          console.log(selectedValues);

          setTimeout(() => {
            currentQuestionIndex += 1;
            if (currentQuestionIndex < data.length) {
              setQuestion(currentQuestionIndex);
            } else {
              score = sumArray(Object.values(selectedValues));
              hideElement("test-questions");
              showElement("test-done");
            }
          }, 400);
        }
      });
    });

    const doneButton = document.getElementById("done-button");
    doneButton.addEventListener("click", function () {
      hideElement("test-done");
      setOneLink(getResult(score).oneLink);
      setTotalScore(score);
      showElement(getResult(score).resultPageId);
    });
  }, []);

  return (
    <div style={{ padding: "10px" }}>
      <div id="test-start">
        <button id="start-button">검사 시작</button>
      </div>

      <div id={"test-questions"} style={{ padding: "10px" }}>
        <div
          id="progressbar-container"
          style={{
            width: "80%",
            height: "3px",
            borderRadius: "3px",
            backgroundColor: "#00000011",
          }}
        >
          <div
            id="progressbar"
            style={{
              width: "50%",
              height: "3px",
              borderRadius: "3px",
              backgroundColor: "#d5a322",
            }}
          />
        </div>
        <div id={"questionId"}>질문</div>
        <div style={{ display: "flex", gap: "10px" }}>
          {Object.values(evaluation).map(({ label, value }, index) => (
            <div>
              <input
                type="radio"
                name="depression"
                id={`option${index}`}
                value={""}
              />
              <label for={`option${index}`} id={`label${index}`}>
                {"label"}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div id={"test-done"}>
        <button id="done-button">검사를 마쳤어요</button>
      </div>

      {resultData.map(({ resultPageId }) => (
        <div id={resultPageId}>
          검사 결과
          <div id={"accumulated-value"}></div>
          <a id="cta-help2" href="https://google.com">
            더 알아보기
          </a>
          <a id="cta-help2" href="https://google.com">
            더 알아보기2
          </a>
          <a id="share-button" href="https://google.com">
            공유하기
          </a>
        </div>
      ))}
    </div>
  );
}
