import { ButtonGroup } from "data/CellComponent";
import {
  Instruction,
  InstructionWithContent,
  InstructionWithImage,
  InstructionWithTextarea,
} from "../BlockComponent";
import { LastCardWithTextarea } from "./common";

export const Content_100_0_A = [
  Instruction("typography_0_0_0"),
  InstructionWithImage("typography_1_0_0", "0"),
  InstructionWithImage("typography_2_0_0", "1"),
  Instruction("typography_3_0_0"),
  Instruction("typography_4_0_0"),

  InstructionWithImage(`typography_5_0_0`, "2"),
  Instruction("typography_6_0_0"),
  Instruction("typography_7_0_0"),
  InstructionWithImage("typography_8_0_0", "3"),
  Instruction("typography_9_0_0"),
  Instruction("typography_10_0_0"),
  InstructionWithImage("typography_11_0_0", "4"),

  Instruction(`typography_12_0_0`),
  Instruction("typography_13_0_0"),
  Instruction("typography_14_0_0"),
  Instruction("typography_15_0_0"),

  InstructionWithImage("typography_16_0_0", "5"),
  Instruction("typography_17_0_0"),
  InstructionWithImage("typography_18_0_0", "6"),
  InstructionWithImage("typography_19_0_0", "7"),
  Instruction("typography_20_0_0"),
  Instruction("typography_21_0_0"),
  Instruction("typography_22_0_0"),
  Instruction(`typography_23_0_0`),

  InstructionWithTextarea("typography_24_0_0", { id: "nickname" }),
  InstructionWithContent("typography_25_0_0", [
    [
      ButtonGroup(
        [
          {
            translationKey: "트위터",
          },
          {
            translationKey: "네이버",
          },
          {
            translationKey: "인스타그램",
          },
          {
            translationKey: "지인 추천",
          },
          {
            translationKey: "기타",
            subjectiveOption: {
              optional: true,
              id: "acquisition_other",
              placeholder: "어떻게 알고 오셨는지 알려주세요",
            },
          },
        ],
        { id: "acquisition" }
      ),
    ],
  ]),
  Instruction("typography_26_0_0"),
  ...LastCardWithTextarea,
];
