import { Instruction, InstructionWithImage, Header1 } from "../BlockComponent";
import { LastCard } from "./common";

export const Content_3_1_A = [
  Instruction("생각은 어떻게 생겼을까요? 잠시 재밌는 생각을 해볼게요."),
  Instruction(
    "거미는 여덟 개의 다리를 가집니다. 집게발도 있죠. 날개를 가지고 있는 거미는 없습니다."
  ),
  InstructionWithImage(
    "각각의 모습은 조금씩 다를 수 있지만 기본적으로 거미는 모두 그 모습이 동일하죠.",
    "0.png"
  ),
  InstructionWithImage("거미는 방사형 형태의 거미집도 짓습니다.", "1.png"),
  Instruction(
    "재밌는 사실은 아무도 거미에게 집을 그렇게 짓도록 가르쳐주지 않다는 것이에요."
  ),
  Instruction(
    "엄마 거미가 알려준 것도, 다른 거미가 하는 것을 보고 따라한 것도 아니죠."
  ),
  Instruction(
    "무엇이 거미로 하여금 방사형 형태의 거미집을 짓도록 만들었을까요?"
  ),
  Instruction(
    "바로 DNA입니다. DNA는 생명체의 모습뿐만 아니라 그들이 어떻게 생각하거나 행동하는지, 그 형태를 결정하기도 하죠. 심지어는 거미집의 모양까지도요."
  ),

  Header1("인간의 생각에도 패턴이 있다"),
  Instruction(
    "DNA에 담긴 정보에 의해 인간은 두 다리, 두 팔, 그리고 하나의 뇌를 가지죠."
  ),
  Instruction(
    "그렇다면 생각은 어떨까요? 거미집에도 일정한 모양이 있었던 것처럼 우리가 생각하는 방식에도 특정한 형태가 있을까요?"
  ),
  Instruction(
    "많은 연구들은 우리가 생각하는 방식에도 특정한 틀이 있다는 것을 시사하고 있답니다."
  ),
  Instruction(
    "우리는 그 생각의 틀을 '생각 지도'라고 불러요. 생각 지도는 우리 머릿속에서 생각이 작동하는 방식을 보여주는 틀이랍니다."
  ),
  InstructionWithImage(
    "생각 지도에서는 그 생각이 얼마나 근원적인가에 따라 생각을 세 가지로 구분하죠. 하나씩 살펴볼게요.",
    "2.png"
  ),

  Header1("자동적 사고는 가장 표면적인 생각"),
  Instruction(
    "자동적 사고는 '특정한 상황에서 마음속에 자동적이고 무의식적으로 떠오르는 생각'을 뜻합니다."
  ),
  Instruction(
    "예를 들어볼게요. 민수라는 친구가 있습니다. 민수는 길을 걷다 건너편에 있는 회사 선배를 발견했죠."
  ),
  Instruction(
    "민수는 반가운 마음에 인사를 건넸습니다. 하지만 선배는 인사를 받지 않고 지나갔죠."
  ),
  InstructionWithImage(
    "순간 민수의 마음에는 다음과 같은 자동적 사고가 떠올랐어요. '선배가 나를 싫어하나? 내가 만든 자료가 마음에 안 들었나?'",
    "3.png"
  ),
  Instruction(
    "민수는 이내 침울해졌죠(=굴복). 다음에 자료를 만들 때에는 '어떻게든 밤을 새든 무슨 수를 쓰든' 더 열심히 만들어야겠다고 생각했죠(=과잉보상)."
  ),
  Instruction(
    "이처럼 특정한 상황에서 자동적으로 떠오른 생각에 우리는 강하게 반응하곤 합니다."
  ),
  Instruction(
    "그런데 이런 자동적 사고는 왜 발생한 걸까요? 이를 이해하기 위해서는 더 깊은 내면을 살펴봐야 합니다."
  ),

  Header1("중간 믿음은 '세상에 대한 나의 태도, 규칙 또는 가정'을 뜻해요."),
  Instruction(
    "민수는 '일을 제대로 하지 않으면 사람들은 나를 싫어할 거야'라는 가정을 가지고 있어요."
  ),
  InstructionWithImage(
    "비슷한 맥락에서 '부족한 건 끔찍하다'라는 태도, 그리고 '모든 일은 완벽하게 해내야 한다'라는 규칙을 가지고 있죠.",
    "4.png"
  ),
  Instruction(
    "그러한 가정, 태도, 규칙을 가지고 있으니 선배가 인사를 받지 않았을 때 '내가 일을 제대로 하지 않아 선배가 나를 싫어하는 게 아닐까?'라는 생각이 떠올랐던 것이죠."
  ),
  Instruction(
    "그렇다면 이러한 가정, 태도, 규칙은 대체 어떻게 만들어진 것일까요?"
  ),

  Header1("핵심 믿음은 마음속 가장 근원적인 생각"),
  Instruction(
    `핵심 믿음은 '자신, 타인, 그리고 세상에 대한 가장 중심적이며 무조건적인 생각'을 뜻해요.`
  ),
  Instruction(
    `무조건적이라는 뜻은 주로 특정한 상황이나 조건에 적용되는 중간 믿음과 달리 핵심 믿음은 더 보편적이고 광범위하게 적용된다는 것을 의미한답니다.`
  ),
  Instruction(
    `이 믿음은 너무 근본적이고 깊어서 사람들은 종종 자신에게 어떠한 믿음이 있는지 쉽게 알지 못하죠.`
  ),
  Instruction(
    `그저 그 믿음을 자신도 모르게 '있는 그대로의 절대적 진리'로 받아들일 뿐입니다.`
  ),
  Instruction(
    `핵심 믿음은 주로 어릴 적 경험에서부터 형성될 때가 많아요. 그러한 믿음이 자신에게 나름의 이점을 가져다줬기 때문이죠. 예를 들어볼까요?`
  ),
  Instruction(
    `민수는 어릴 적부터 높은 기준을 가지고 훈육하는 부모님 밑에서 자랐습니다.`
  ),
  Instruction(
    `항상 남들보다 더 잘 해야 한다는 부모님의 암묵적인 압박을 느껴왔죠.`
  ),
  Instruction(
    `민수의 마음에는 자연스럽게 '나는 충분하지 않아. 나는 부족해'라는 생각이 자리잡게 됩니다.`
  ),
  Instruction(
    `그 생각은 부모님의 압박을 개인적인 모욕으로 느끼지 않도록, 그리고 스스로에게 더 강력한 성취 욕구를 자극할 수 있도록 도와주었죠.`
  ),
  InstructionWithImage(
    `하지만 성인이 되어서 그 핵심적인 믿음은 나도 모르게 내 마음속에 떠올라 나를 괴롭히곤 하죠.`,
    "5.png"
  ),
  InstructionWithImage(
    `자, 이제 어떻게 하다가 선배가 인사를 받지 않은 상황에서 민수의 마음속에 아주 자동적이고 반사적으로 "내가 실수한 게 있나?"라는 생각이 떠올랐는지 이해가 되시나요?`,
    "6.png"
  ),
  Instruction(
    `생각과의 거리가 매우 가까웠던 민수는 마음속에 떠오른 생각에 대해 다양하게 대처해보기도 했답니다.`
  ),
  Instruction(
    `때론 무기력함과 우울감을 느끼며 그러한 생각에 '굴복'하기도 했고,`
  ),
  Instruction(
    `또 언젠가는 끊임없이 '더 잘 해야 한다'라는 생각으로 스스로를 몰아세우며 '과잉 보상'하기도 했죠. 그러다가 소진되고 말았지만요.`
  ),
  Instruction(
    `아예 자신의 부족함을 마주할 만한 상황(예: 서류 지원, 소개팅 등)을 피하거나, 억지로 그러한 생각을 몰아내려고 애쓰며 생각을 '회피'하기도 했어요.`
  ),

  Header1("생각과의 거리가 더 멀었다면 어땠을까요?"),
  Instruction(
    `민수는 "내가 실수한 게 있나?"라는 생각에 대해 거리를 두고 살펴볼 여유를 가질 수도 있었을 거예요.`
  ),
  InstructionWithImage(
    `한 걸음 떨어져서 "이 생각은 정확한가? 혹은 나에게 도움이 되는가?"와 같이 재빠르게 생각을 살펴보는 거죠.`,
    "7.png"
  ),
  Instruction(
    `그리고 그 생각에 반응하지 않고 자신이 원하는 가치에 맞는 방향으로 삶을 다시 이끌어나갈 수 있을 거예요.`
  ),
  InstructionWithImage(
    `인간 관계에서 포용적이고 유연하길 바랬던 민수는 그 생각에 과하게 반응하지 않고, 사무실로 돌아가 선배를 마주쳤을 때 위축되지 않고 다시 상냥하게 인사를 건넸죠.`,
    "8.png"
  ),

  Header1("거리를 둘 생각 지도를 찾아서"),
  Instruction(
    `자동적 사고에서부터 핵심 믿음으로 갈수록 그 생각으로부터 거리두기는 더 어렵지만, 거리를 두었을 때 얻는 공간감은 더 큽니다.`
  ),
  Instruction(
    `자동적 사고로부터 거리를 두면 당장에 반사적인 반응을 하지 않을 수 있어요.`
  ),
  Instruction(
    `핵심 믿음으로부터 거리를 두면 보다 다양한 부정적인 생각들에 더 유연하게 반응할 수 있죠.`
  ),
  Instruction(
    `더 근원적인 생각으로부터 거리를 둘수록, 우리가 얻는 공간은 더 많아지고, 우리는 그 공간에 다른 것들을 채워넣을 수 있답니다. 내가 원하는 삶의 모습들로 말이죠.`
  ),
  Instruction(
    `물론 그렇게 하기 위해서는 단계적인 접근이 필요합니다. 처음부터 핵심 믿음으로부터 거리를 두기란 어려운 일이기 때문이죠.`
  ),
  Instruction(
    `그러니 우선 나의 자동적 사고는 무엇인지, 중간 믿음은 무엇인지, 핵심 믿음은 무엇인지, 하나씩 살펴보며 생각 지도를 그려나가야겠죠?`
  ),
  Instruction(
    `자, 우리는 지금부터 나만의 생각 지도를 그려나갈 것입니다. 이후에는 생각으로부터 거리두기를 단계적으로 연습해볼 거랍니다.`
  ),
  Instruction(`그러면 나의 첫 번째 생각을 살펴볼까요?`),

  ...LastCard,
];
