import { axiosInstance, CustomResponseFormat } from "api";
import {
  automaticThoughtIdType,
  ConceptType,
  CoreBeliefKeyType,
  IntermediateBeliefKeyType,
} from "api/conceptApi";

export function getAutimaticThoughtIdList(concept?: ConceptType) {
  if (concept) {
    const automaticThoughtKeyList = Object.keys(concept).filter((element) =>
      element.startsWith("automaticThought")
    );
    return automaticThoughtKeyList.map(
      (each) => each.split("automaticThought")[1] as automaticThoughtIdType
    );
  }
}

export function getIntermediateBeliefKeyList(concept?: ConceptType) {
  if (concept) {
    return Object.entries(concept?.intermediateBelief || {})
      .filter(([key, value]) => value?.length > 0)
      .map(([key, value]) => key);
  }
}
export function getCoreBeliefKeyList(concept?: ConceptType) {
  if (concept) {
    return Object.entries(concept?.coreBelief || {})
      .filter(([key, value]) => value?.length > 0)
      .map(([key, value]) => key);
  }
}

export interface ThoughtmapStateType {
  automaticThoughtIdList?: automaticThoughtIdType[];
  intermediateBeliefKeyList?: IntermediateBeliefKeyType[];
  coreBeliefKeyList?: CoreBeliefKeyType[];
}

export default async function getConfirmedThoughtmapState(patientId: string) {
  try {
    const response = (await axiosInstance.get("/concept/detail", {
      params: { pid: patientId },
    })) as CustomResponseFormat;
    if (response.code === 0) {
      const thoughtmapData = response.result;
      return {
        automaticThoughtIdList: getAutimaticThoughtIdList(thoughtmapData),
        intermediateBeliefKeyList: getIntermediateBeliefKeyList(thoughtmapData),
        coreBeliefKeyList: getCoreBeliefKeyList(thoughtmapData),
      } as ThoughtmapStateType;
    }
    return {
      automaticThoughtIdList: [],
      intermediateBeliefKeyList: [],
      coreBeliefKeyList: [],
    } as ThoughtmapStateType;
  } catch (error: any) {}
}
