import { useParams } from "react-router-dom";
import DefaultPage from "./DefaultPage";
import ParticipationInfo from "components/onboarding/ParticipationInfo";
import CoachMatch from "components/onboarding/CoachMatch";
import Checklist from "components/onboarding/Checklist";
import BeforeNicknameChange from "components/onboarding/BeforeNicknameChange";

export default function Onboarding() {
  const { id } = useParams();

  return (
    <DefaultPage
      content={
        id === "1" ? (
          <ParticipationInfo />
        ) : id === "2" ? (
          <CoachMatch />
        ) : id === "3" ? (
          <Checklist />
        ) : (
          <BeforeNicknameChange />
        )
      }
    />
  );
}
