import { Stack } from "@mui/joy";
import { ProgramContentType } from "data/BlockComponent";
import * as _ from "lodash";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { useRecoilValue } from "recoil";
import { localizationAtom } from "recoil/localizationAtom";
import { setProgramContentData } from "logic/logics";

export default function DateSelectCell({
  defaultValue,
  blockIndex,
  setData,
  lineIndex,
  cellIndex,
  editorKey,
}: {
  defaultValue?: string;
  blockIndex: number;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
  editorKey?: string;
}) {
  const locale = useRecoilValue(localizationAtom);
  const handleChange = (value?: Dayjs) => {
    setProgramContentData({
      setData,
      blockIndex,
      lineIndex,
      cellIndex,
      newlyAddedData: { value: dayjs(value).format("YYYY-MM-DD") },
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <Stack>
        <DatePicker
          key={`${editorKey}`}
          defaultValue={dayjs(defaultValue)}
          sx={{ height: "40px" }}
          slotProps={{ textField: { size: "small" } }}
          onChange={(value) => {
            if (value) {
              handleChange(value);
            }
          }}
        />
      </Stack>
    </LocalizationProvider>
  );
}
