import { translation_meditation } from "./meditation";

export const translation_7_9_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0: "그동안 실행했던 가치 행동을 되돌아볼게요.",
      typography_1_0_0: "우리는 다음의 가치를 선택하였어요.",
      typography_2_0_0:
        "그리고 다음과 같은 가치 행동을 계획하여 실천해나갔죠. 그때를 떠올리며 하나씩 읽어볼까요?",
      typography_3_0_0: "$M$단계로 $A$(을)를 해냈어요.",
      typography_4_0_0: `마치고 나선 이런 생각을 했었죠.`,
      typography_5_0_0: "하나씩 해나가는 게 쉽진 않았을 텐데 고생 많았어요.",
      typography_6_0_0:
        "잠시 그동안의 가치 행동을 돌아보며 나의 가치를 되새기는 명상 시간을 가져볼 텐데요.",
      typography_7_0_0: `가치 행동에 수반되는 생각, 감정, 감각을 느껴볼 거예요.`,
      ...translation_meditation.ko.v1,
      typography_8_0_0: `좋아요. 지금까지 다음의 가치를 실천하기 위해 다섯 번의 가치 행동을 실천했는데요.`,
      typography_9_0_0:
        "앞으로 일상에서 스스로 이 가치를 더욱 발전시켜 보는 건 어떨까요?",
      typography_10_0_0:
        "더 다양하고 큰 도전을 해보며 내 삶을 내가 선택한 가치로 꾸려나가는 것이죠.",
      typography_11_0_0: `가치는 내가 선택한 방향이라는 것,`,
      typography_12_0_0: `그리고 가치 행동은 단계적으로 해나가면 된다는 것을 꼭 잊지 마세요.`,
      typography_13_0_0: `자, 그러면 이번 활동은 여기서 마칠게요. 고생 많았어요.`,
    },
  },
};
