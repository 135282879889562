import TaskHeader from "components/task/header/TaskHeader";
import { Box } from "@mui/joy";
import MyProgressContent from "./content/MyProgressContent2";
import MyProgressContentBehaviorActivation from "./content/MyProgressContentBehaviorActivation";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { userAtom } from "recoil/userAtom";
import PaymentModule from "components/sidebar/PaymentModule2";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";

export default function MyProgress() {
  const patientState = useRecoilValue(patientAtom);
  const userState = useRecoilValue(userAtom);
  const isPatient = userState?.accessToken && userState?.role === "patient";
  const { isSubscribing, isNotStarted } = useSubscriptionStatus();

  const isBehavioralActivationProgram =
    userState?.isBehavioralActivationProgram ||
    patientState?.isBehavioralActivationProgram;

  const showPaymentModule = isPatient && !isSubscribing;

  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 100,
        }}
      >
        <TaskHeader taskKey={"progress"} />
        {showPaymentModule && (
          <PaymentModule
            {...(isNotStarted && {
              title: "🫵 괴로운 고민이 있으세요?",
              subtitle: "나만의 프로그램을 구성하세요",
            })}
            type="header"
          />
        )}
      </Box>

      <div className="w-full h-full overflow-x-hidden">
        {isBehavioralActivationProgram ? (
          <MyProgressContentBehaviorActivation />
        ) : (
          <MyProgressContent />
        )}
      </div>
    </>
  );
}
