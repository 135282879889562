import Box from "@mui/joy/Box";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Typography from "@mui/joy/Typography";
import { memo, useState } from "react";
import { useEffect } from "react";
import { Stack, Chip } from "@mui/joy";
import { PatientType } from "api/patientApi";
import PatientChangeNicknameButton from "./PatientChangeNicknameButton";
import { patientAtom } from "recoil/patientAtom";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { getTimeDifference } from "logic/logics";
import UserProfile from "components/user/UserProfile";
import PatientRemindButton from "./PatientRemindButton";
import { userAtom } from "recoil/userAtom";
import PatientRemoveButton from "./PatientRemoveButton";
import useOpenTask from "hooks/useOpenTask";
import { taskSectionAtom } from "recoil/taskSectionAtom";
import { usePrevious } from "@uidotdev/usehooks";
import { SidebarMenuType } from "components/sidebar/SideBar";

function PatientItem({
  title,
  patient,
  selected,
  setSelected,
  setCurrentMenu,
}: {
  title?: string;
  patient: PatientType;
  selected?: string;
  setSelected?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setCurrentMenu?: React.Dispatch<React.SetStateAction<SidebarMenuType>>;
}) {
  const {
    patientId,
    nickname,
    isTaskUpdateRequired,
    isRemindRequired,
    dayNumber,
    doneAt,
    profileImageUrl,
    coachName,
    isHold,
    isChatAvailable,
    unreadMessageCount,
    locale,
    isNew,
    isHumanCheckRequired,
    nextAutoTaskUpdateDateTime,
    isInAutoProcessingStage,
    participationType,
  } = patient;

  const [patientState, setPatientState] = useRecoilState(patientAtom);

  const [contextMenuOpenPatientId, setContextMenuOpenPatientId] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      setContextMenuOpenPatientId(undefined);
    };
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  const resetTaskSection = useResetRecoilState(taskSectionAtom);

  const setSidebarTaskList = () => {
    if (setCurrentMenu) {
      setCurrentMenu("task");
    }
  };

  return (
    <ListItemButton
      sx={{ "--ListItemDecorator-size": "56px" }}
      key={`${title}${patientId}`}
      className="relative"
      onClick={() => {
        if (setSelected) {
          setSelected(`${title}_${patientId}`);

          if (patientId !== patientState?.patientId) {
            // if (
            //   title === "HUMAN CHECK 필요" &&
            //   isHumanCheckRequired
            // ) {
            //   setTaskSectionState([
            //     { openTaskKeyList: ["chat"], currentTaskKey: "chat" },
            //   ]);
            // } else {
            resetTaskSection();
            setSidebarTaskList();
            // }
          } else {
            setSidebarTaskList();
          }
          setPatientState({ ...patient });
        }
      }}
      onContextMenu={(e) => {
        if (
          (title === "리마인드 필요" && isRemindRequired) ||
          title === "전체"
        ) {
          e.preventDefault();
          setContextMenuOpenPatientId(patientId);
        }
      }}
      selected={selected === `${title}_${patientId}`}
      color={selected === `${title}_${patientId}` ? "primary" : undefined}
    >
      {contextMenuOpenPatientId === patientId && (
        <Box className="absolute z-[30] right-0 top-[50%]">
          <List
            sx={{
              borderWidth: 1,
              borderColor: "#background.level3",
              backgroundColor: "background.level2",
              "--List-gap": 0,
              "--ListItem-paddingY": 0,
            }}
          >
            {title === "리마인드 필요" && isRemindRequired && (
              <ListItem>
                <PatientRemindButton
                  patientId={patientId}
                  onSuccess={() => {
                    setContextMenuOpenPatientId(undefined);
                  }}
                />
              </ListItem>
            )}
            {title === "전체" && (
              <>
                {/* <ListItem>
                  <PatientHoldButton
                    patientId={patientId}
                    onClose={() => {
                      setContextMenuOpenPatientId(undefined);
                    }}
                  />
                </ListItem> */}
                <ListItem>
                  <PatientRemoveButton
                    patientId={patientId}
                    onClose={() => {
                      setContextMenuOpenPatientId(undefined);
                    }}
                  />
                </ListItem>
              </>
            )}
          </List>
        </Box>
      )}
      <ListItemDecorator>
        <UserProfile
          nickname={nickname}
          url={profileImageUrl}
          locale={locale}
          isAuto={isInAutoProcessingStage}
        />
      </ListItemDecorator>
      <ListItemContent>
        <Stack direction={"row"}>
          <Box style={{ flex: 1 }}>
            <Stack direction={"row"} spacing="8px" alignItems={"center"}>
              <Typography level="title-sm">{`${nickname} ${
                participationType || ""
              }`}</Typography>

              {isNew && (
                <Chip size="sm" color="danger" variant="solid">
                  NEW
                </Chip>
              )}
            </Stack>
            <Stack
              sx={{ color: "text.primary", opacity: 0.5 }}
              direction={"row"}
              divider={
                <Typography sx={{ px: 0.5 }} level="body-sm">
                  ·
                </Typography>
              }
            >
              {isHold ? (
                <Typography level="body-xs">홀드 중</Typography>
              ) : (
                doneAt && (
                  <Typography level="body-xs">
                    {getTimeDifference(doneAt)} 완료
                  </Typography>
                )
              )}

              <Typography level="body-xs" noWrap>
                {dayNumber}일차
              </Typography>
            </Stack>
          </Box>
          {(unreadMessageCount || 0) > 0 && (
            <Box
              sx={{
                width: "8px",
                height: "8px",
                borderRadius: "10px",
                backgroundColor: "danger.500",
                alignSelf: "center",
              }}
            />
          )}
        </Stack>
      </ListItemContent>
    </ListItemButton>
  );
}

export default memo(PatientItem);
