import { Stack } from "@mui/joy";
import { ProgramContentType } from "data/BlockComponent";
import * as _ from "lodash";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs, { Dayjs } from "dayjs";
import { useRecoilValue } from "recoil";
import { localizationAtom } from "recoil/localizationAtom";
import { setProgramContentData } from "logic/logics";

export default function TimeSelectCell({
  defaultValue,
  blockIndex,
  setData,
  lineIndex,
  cellIndex,
  editorKey,
}: {
  defaultValue?: string;
  blockIndex: number;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
  editorKey?: string;
}) {
  const locale = useRecoilValue(localizationAtom);

  const today = dayjs().format("YYYY-MM-DD");
  const initialValue = `${today}T${defaultValue}`;

  const handleChange = (value?: Dayjs) => {
    setProgramContentData({
      setData,
      blockIndex,
      lineIndex,
      cellIndex,
      newlyAddedData: { value: dayjs(value).format("HH:mm") },
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <Stack>
        <TimePicker
          key={`${editorKey}`}
          name="startTime"
          defaultValue={dayjs(initialValue)}
          onChange={(newValue) => {
            if (newValue) handleChange(newValue);
          }}
          sx={{ height: "40px" }}
          slotProps={{ textField: { size: "small" } }}
          timeSteps={{ hours: 1, minutes: 60, seconds: 5 }}
        />
      </Stack>
    </LocalizationProvider>
  );
}
