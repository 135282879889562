import {
  AddActivityLine,
  DateSelect,
  LineWithSolidLabel,
  Percentage,
  TextareaLine,
  TimeSelect,
  TypographyFromData,
  TypographyFromDataLine,
} from "data/CellComponent";
import {
  Instruction,
  InstructionWithContent,
  InstructionWithTextarea,
  ProgramContentType,
} from "../BlockComponent";
import dayjs from "dayjs";

const today = dayjs().format("YYYY-MM-DD");
const todayWithTime = dayjs().format("HH:00");

export const Content_101_1_3_T = [
  InstructionWithContent(`typography_0_0_0`, [
    ...LineWithSolidLabel("날짜", [DateSelect({ id: "date", value: today })]),
    ...LineWithSolidLabel("시각", [
      TimeSelect({ id: "time", value: todayWithTime }),
    ]),
  ]),
  InstructionWithContent(
    `typography_1_0_0`,
    LineWithSolidLabel("활동", TextareaLine({ id: "activity" }))
  ),

  InstructionWithContent(
    `typography_2_0_0`,
    [AddActivityLine()],

    { hideIfPatient: true }
  ),

  InstructionWithTextarea(`typography_7_0_0`),
  InstructionWithContent(
    `typography_4_0_0`,
    LineWithSolidLabel("감정/생각", TextareaLine({ id: "emotionAndThought" }))
  ),
  InstructionWithContent(
    `typography_5_0_0`,
    LineWithSolidLabel("기분", [
      Percentage({ id: "mood", min: 0, max: 10, step: 1 }),
    ])
  ),
  Instruction("typography_6_0_0"),
];

export const Content_101_1_3_T_fromTask = (
  taskId: string,
  blocksFromTask: ProgramContentType[]
) => {
  const planTaskId = `104-1-A#${taskId.split("#")[1]}#${taskId.split("#")[2]}`;

  return [
    InstructionWithContent(
      `fromtask_typography_0_0_0`,
      [
        ...LineWithSolidLabel(
          "날짜",
          TypographyFromDataLine(
            { A: { id: "date", taskId: planTaskId } },
            "$A$"
          )
        ),
        ...LineWithSolidLabel(
          "시각",
          TypographyFromDataLine(
            { A: { id: "time", taskId: planTaskId } },
            "$A$"
          )
        ),
      ],
      { isShown: true }
    ),
    InstructionWithContent(
      `fromtask_typography_1_0_0`,
      LineWithSolidLabel(
        "활동",
        TypographyFromDataLine({ A: { id: "activity", taskId: taskId } }, "$A$")
      ),
      { isShown: true }
    ),
    ...blocksFromTask,
  ];
};
