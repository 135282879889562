import { automaticThoughtIdType } from "api/conceptApi";
import {
  DateSelect,
  DividerLine,
  LineWithSolidLabel,
  SolidLabelLine,
  TimeSelect,
  TypographyFromDataLine,
} from "data/CellComponent";
import dayjs from "dayjs";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithData,
  InstructionWithDataWithContent,
  InstructionWithDataWithTextarea,
} from "../BlockComponent";
import { LastCard, RawValueTemplate } from "./common";

const today = dayjs().format("YYYY-MM-DD");
const todayWithTime = dayjs().format("HH:00");

export const Content_7_7_A = (
  automaticThoughtId: automaticThoughtIdType,
  experimentStep: number,
  isPushNotificationGranted: boolean
) => [
  InstructionWithContent(
    "typography_0_0_0",
    RawValueTemplate(automaticThoughtId)
  ),
  InstructionWithContent("typography_1_0_0", [
    ...Array.from({ length: 5 }, (v, i) => i + 1).flatMap((each) => [
      SolidLabelLine(`단계`, { prefixLabelIndex: each }),
      TypographyFromDataLine(
        {
          A: {
            id: `step_${each}`,
            taskId: `7-6-A_${automaticThoughtId}`,
          },
        },
        "$A$",
        {
          ...(each === experimentStep && {
            id: `실험내용`,
          }),
        }
      ),
      ...(each < 5 ? [DividerLine()] : []),
    ]),
  ]),
  InstructionWithData(
    { A: { value: `${experimentStep}` } },
    `typography_2_0_0`
  ),
  Header1(`typography_3_0_0`),
  InstructionWithData({ A: { id: "실험내용" } }, `typography_4_0_0`),
  InstructionWithDataWithTextarea(
    { A: { id: "실험내용" } },
    `typography_5_0_0`,
    { id: "누구와" }
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "실험내용" } },
    `typography_6_0_0`,
    { id: "무엇을" }
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "실험내용" } },
    `typography_7_0_0`,
    { id: "어디서" }
  ),

  InstructionWithDataWithContent(
    {
      A: {
        id: `실험내용`,
      },
    },
    `typography_8_0_0`,
    [
      ...LineWithSolidLabel("날짜", [DateSelect({ id: "date", value: today })]),
      ...LineWithSolidLabel("시각", [
        TimeSelect({ id: "time", value: todayWithTime }),
      ]),
    ]
  ),
  InstructionWithDataWithTextarea(
    {
      A: {
        id: `실험내용`,
      },
    },
    "typography_9_0_0",
    { id: "예상 장애물" }
  ),
  InstructionWithDataWithTextarea(
    {
      A: {
        id: `실험내용`,
      },
    },
    "typography_10_0_0",
    { id: "장애물 극복법" }
  ),

  Header1("typography_11_0_0"),
  InstructionWithDataWithContent(
    {
      A: {
        id: `실험내용`,
      },
    },
    `typography_12_0_0`,
    [
      ...LineWithSolidLabel(
        "누구와",
        TypographyFromDataLine({ A: { id: "누구와" } }, "$A$")
      ),
      DividerLine(),
      ...LineWithSolidLabel(
        "무엇을",
        TypographyFromDataLine({ A: { id: "무엇을" } }, "$A$")
      ),
      DividerLine(),
      ...LineWithSolidLabel(
        "어디서",
        TypographyFromDataLine({ A: { id: "어디서" } }, "$A$")
      ),
      DividerLine(),
      SolidLabelLine(`언제`),
      TypographyFromDataLine(
        { A: { id: "date" }, B: { id: "time" } },
        "$A$ $B$",
        { id: "언제" }
      ),
      DividerLine(),
      ...LineWithSolidLabel(
        "예상 장애물",
        TypographyFromDataLine({ A: { id: "예상 장애물" } }, "$A$")
      ),
      ...LineWithSolidLabel(
        "장애물 극복법",
        TypographyFromDataLine({ A: { id: "장애물 극복법" } }, "$A$")
      ),
    ]
  ),
  Instruction(`typography_13_0_0`),
  Instruction("typography_14_0_0"),
  ...(!isPushNotificationGranted
    ? [
        InstructionWithContent("typography_15_0_0", [
          [{ type: "pushNotification", content: {} }],
        ]),
      ]
    : []),
  Instruction("typography_16_0_0"),
  ...LastCard,
];
