import { automaticThoughtIdList } from "api/conceptApi";
import { isDev } from "config";
import { ProgramContentType } from "data/BlockComponent";
import { ProgramType, TaskMetaData } from "data/programData";
import { Content_0_0_A } from "data/task/0-0-A3";
import { Content_0_1_A } from "data/task/0-1-A";
import { Content_0_2_A } from "data/task/0-2-A";
import { Content_0_3_A } from "data/task/0-3-A";
import { Content_0_4_A } from "data/task/0-4-A";
import { Content_0_5_A } from "data/task/0-5-A";
import { Content_0_6_A } from "data/task/0-6-A";
import { Content_1_1_A } from "data/task/1-1-A2";
import { Content_1_2_A } from "data/task/1-2-A";
import { Content_1_3_A } from "data/task/1-3-A";
import { Content_1_4_A } from "data/task/1-4-A";
import { Content_100_0_A } from "data/task/100-0-A";
import { Content_100_1_A } from "data/task/100-1-A";
import { Content_101_1_1_A } from "data/task/101-1-1-A";
import { Content_101_1_2_A } from "data/task/101-1-2-A";
import { Content_101_1_3_T } from "data/task/101-1-3-T";
import { Content_101_2_1_A } from "data/task/101-2-1-A";
import { Content_101_2_2_A } from "data/task/101-2-2-A";
import { Content_101_2_3_A } from "data/task/101-2-3-A";
import { Content_101_2_4_A } from "data/task/101-2-4-A";
import { Content_101_2_5_A } from "data/task/101-2-5-A";
import { Content_102_1_A } from "data/task/102-1-A";
import { Content_102_2_A } from "data/task/102-2-A";
import { Content_103_1_A } from "data/task/103-1-A";
import { Content_104_0_A } from "data/task/104-0-A";
import { Content_104_1_A } from "data/task/104-1-A";
import { Content_104_2_A } from "data/task/104-2-A";
import { Content_2_1_A } from "data/task/2-1-A";
import { Content_2_2_T } from "data/task/2-2-T";
import { Content_2_3_A } from "data/task/2-3-A";
import { Content_2_4_A } from "data/task/2-4-A";
import { Content_3_1_A } from "data/task/3-1-A";
import { Content_3_2_A } from "data/task/3-2-A";
import { Content_3_3_A } from "data/task/3-3-A";
import { Content_4_1_A } from "data/task/4-1-A";
import { Content_4_10_A } from "data/task/4-10-A";
import { Content_4_11_A } from "data/task/4-11-A";
import { Content_4_2_A } from "data/task/4-2-A";
import { Content_4_3_1_A } from "data/task/4-3-1-A";
import { Content_4_3_2_A } from "data/task/4-3-2-A";
import { Content_4_4_1_A } from "data/task/4-4-1-A";
import { Content_4_4_2_A } from "data/task/4-4-2-A";
import { Content_4_4_3_A } from "data/task/4-4-3-A";
import { Content_4_4_4_A } from "data/task/4-4-4-A";
import { Content_4_4_5_A } from "data/task/4-4-5-A";
import { Content_4_4_6_A } from "data/task/4-4-6-A";
import { Content_4_5_1_A } from "data/task/4-5-1-A";
import { Content_4_5_2_A } from "data/task/4-5-2-A";
import { Content_4_5_3_A } from "data/task/4-5-3-A";
import { Content_4_5_4_A } from "data/task/4-5-4-A";
import { Content_4_5_5_A } from "data/task/4-5-5-A";
import { Content_4_5_6_A } from "data/task/4-5-6-A";
import { Content_4_6_A } from "data/task/4-6-A";
import { Content_4_7_A } from "data/task/4-7-A";
import { Content_4_8_A } from "data/task/4-8-A";
import { Content_4_9_A } from "data/task/4-9-A";
import { Content_5_1_A } from "data/task/5-1-A";
import { Content_5_2_A } from "data/task/5-2-A";
import { Content_5_3_A } from "data/task/5-3-A";
import { Content_5_4_A } from "data/task/5-4-A";
import { Content_5_5_A } from "data/task/5-5-A";
import { Content_5_6_A } from "data/task/5-6-A";
import { Content_5_7_T } from "data/task/5-7-T";
import { Content_6_1_A } from "data/task/6-1-A";
import { Content_6_2_A } from "data/task/6-2-A";
import { Content_6_3_A } from "data/task/6-3-A";
import { Content_6_4_A } from "data/task/6-4-A";
import { Content_6_5_A } from "data/task/6-5-A";
import { Content_6_6_A } from "data/task/6-6-A";
import { Content_6_7_A } from "data/task/6-7-A";

import { Content_6_8_A } from "data/task/6-8-A";
import { Content_6_9_A } from "data/task/6-9-A";

import { Content_6_97_A } from "data/task/6-97-A";
import { Content_6_98_A } from "data/task/6-98-A";
import { Content_6_99_A } from "data/task/6-99-A";
import { Content_7_1_A } from "data/task/7-1-A";
import { Content_7_3_A } from "data/task/7-3-A";
import { Content_7_4_A } from "data/task/7-4-A";
import { Content_7_5_A } from "data/task/7-5-A";
import { Content_7_6_A } from "data/task/7-6-A";
import { Content_7_7_A } from "data/task/7-7-A";
import { Content_7_8_A } from "data/task/7-8-A";
import { Content_7_9_A } from "data/task/7-9-A";
import { Content_8_1_A } from "data/task/8-1-A";
import { Content_8_10_A } from "data/task/8-10-A";
import { Content_8_2_A } from "data/task/8-2-A";
import { Content_8_3_A } from "data/task/8-3-A";
import { Content_8_4_A } from "data/task/8-4-A";
import { Content_8_5_A } from "data/task/8-5-A";
import { Content_8_6_A } from "data/task/8-6-A";
import { Content_8_8_A } from "data/task/8-8-A";
import { Content_8_9_A } from "data/task/8-9-A";
import { Content_9_1_A } from "data/task/9-1-A";
import { Content_9_10_A } from "data/task/9-10-A";
import { Content_9_12_A } from "data/task/9-12-A";
import { Content_9_13_A } from "data/task/9-13-A";
import { Content_9_2_A } from "data/task/9-2-A";
import { Content_9_3_A } from "data/task/9-3-A";
import { Content_9_4_A } from "data/task/9-4-A";
import { Content_9_6_A } from "data/task/9-6-A";
import { Content_9_8_A } from "data/task/9-8-A";
import { MeditationToolTemplate } from "data/task/meditationTool";
import { getFirstBehaviorExperimentAutomaticId } from "logic/logics";
import { useRecoilValue } from "recoil";
import { conceptNoteAtom } from "recoil/conceptNoteAtom";
import { deviceAtom } from "recoil/deviceAtom";
import { patientAtom } from "recoil/patientAtom";
import { userAtom } from "recoil/userAtom";
import { ThoughtmapStateType } from "./getThoughtMapState";
import useIsInappWebview from "./useIsInappWebview";

export default function useGetProgramData(taskId: string) {
  const userState = useRecoilValue(userAtom);
  const patientState = useRecoilValue(patientAtom);
  const deviceState = useRecoilValue(deviceAtom);
  const conceptNoteState = useRecoilValue(conceptNoteAtom);

  const isCoach = userState?.role !== "patient";

  const hasPraised = isCoach ? patientState?.hasPraised : userState?.hasPraised;

  const isInappWebview = useIsInappWebview();
  const isPushNotificationGranted =
    deviceState.pushNotificationStatus === "granted" || !isInappWebview;

  const programData: ProgramType[] = [
    //0-0-A
    {
      taskId: "0-0-A",
      content: Content_0_0_A(userState?.isAiCoachProduct || false),
    },
    //0-4-A
    {
      taskId: "0-4-A",
      content: Content_0_4_A,
    },
    //0-1-A
    {
      taskId: "0-1-A",
      content: Content_0_1_A,
    },
    //0-2-A
    {
      taskId: "0-2-A",
      content: Content_0_2_A,
    },
    //0-3-A
    {
      taskId: "0-3-A",
      content: Content_0_3_A(!hasPraised),
    },
    //0-5-A
    {
      taskId: "0-5-A",
      content: Content_0_5_A,
    },
    //0-6-A
    {
      taskId: "0-6-A",
      content: Content_0_6_A,
    },
    //1-1-A
    {
      taskId: "1-1-A",
      content: Content_1_1_A,
    },
    //1-2-A
    {
      taskId: "1-2-A",
      content: Content_1_2_A,
    },
    //1-3-A
    {
      taskId: "1-3-A",
      content: Content_1_3_A,
    },
    //1-4-A
    {
      taskId: "1-4-A",
      content: Content_1_4_A,
    },
    //2-1-A
    {
      taskId: "2-1-A",
      content: Content_2_1_A(isPushNotificationGranted),
    },
    // //2-2-T
    // {
    //   taskId: "2-2-T",
    //   content: Content_2_2_T(isPushNotificationGranted),
    // },
    //2-3-A
    {
      taskId: "2-3-A",
      content: Content_2_3_A,
    },
    //2-4-A
    {
      taskId: "2-4-A",
      content: Content_2_4_A,
    },
    //3-1-A
    {
      taskId: "3-1-A",
      content: Content_3_1_A,
    },
    //3-2-A
    {
      taskId: "3-2-A",
      content: Content_3_2_A,
    },
    //3-3-A
    ...automaticThoughtIdList.map((each) => ({
      taskId: `3-3-A_${each}`,
      content: Content_3_3_A(each),
    })),
    //4-1-A
    {
      taskId: "4-1-A",
      content: Content_4_1_A,
    },
    //4-2-A
    ...automaticThoughtIdList.map((each) => ({
      taskId: `4-2-A_${each}`,
      content: Content_4_2_A(each),
    })),
    //4-3-1-A
    {
      taskId: `4-3-1-A`,
      content: Content_4_3_1_A,
    },
    //4-3-2-A
    {
      taskId: `4-3-2-A`,
      content: Content_4_3_2_A,
    },
    //4-4-1-A
    {
      taskId: `4-4-1-A`,
      content: Content_4_4_1_A,
    },
    //4-4-2-A
    {
      taskId: `4-4-2-A`,
      content: Content_4_4_2_A,
    },
    //4-4-3-A
    {
      taskId: `4-4-3-A`,
      content: Content_4_4_3_A,
    },
    //4-4-4-A
    {
      taskId: `4-4-4-A`,
      content: Content_4_4_4_A,
    },
    //4-4-5-A
    {
      taskId: `4-4-5-A`,
      content: Content_4_4_5_A,
    },
    //4-4-6-A
    {
      taskId: `4-4-6-A`,
      content: Content_4_4_6_A,
    },

    ...automaticThoughtIdList.flatMap((each) => [
      //4-5-1-A
      {
        taskId: `4-5-1-A_${each}`,
        content: Content_4_5_1_A(each),
      },
      //4-5-2-A
      {
        taskId: `4-5-2-A_${each}`,
        content: Content_4_5_2_A(each),
      },
      //4-5-3-A
      {
        taskId: `4-5-3-A_${each}`,
        content: Content_4_5_3_A(each),
      },
      //4-5-4-A
      {
        taskId: `4-5-4-A_${each}`,
        content: Content_4_5_4_A(each),
      },
      //4-5-5-A
      {
        taskId: `4-5-5-A_${each}`,
        content: Content_4_5_5_A(each),
      },
      //4-5-6-A
      {
        taskId: `4-5-6-A_${each}`,
        content: Content_4_5_6_A(each),
      },
      //4-8-A
      {
        taskId: `4-8-A_${each}`,
        content: Content_4_8_A(each),
      },
      ...[1, 2, 3, 4, 5].flatMap((n) => [
        {
          taskId: `4-9-A_${each}#${n}`,
          content: Content_4_9_A(each, 5, n),
        },
        {
          taskId: `4-10-A_${each}#${n}`,
          content: Content_4_10_A(each, n),
        },
        {
          taskId: `4-11-A_${each}`,
          content: Content_4_11_A(each, 5),
        },
      ]),
    ]),
    //4-6-A
    {
      taskId: `4-6-A`,
      content: Content_4_6_A(
        getFirstBehaviorExperimentAutomaticId(conceptNoteState)
      ),
    },
    //4-7-A
    {
      taskId: `4-7-A`,
      content: Content_4_7_A,
    },

    //5-1-A
    {
      taskId: `5-1-A`,
      content: Content_5_1_A(isPushNotificationGranted),
    },
    //5-2-A
    {
      taskId: `5-2-A`,
      content: Content_5_2_A(isPushNotificationGranted),
    },
    //5-3-A
    ...automaticThoughtIdList.map((each) => ({
      taskId: `5-3-A_${each}`,
      content: Content_5_3_A(each),
    })),
    //5-4-A
    ...automaticThoughtIdList.map((each) => ({
      taskId: `5-4-A_${each}`,
      content: Content_5_4_A(each),
    })),
    //5-5-A
    ...automaticThoughtIdList.map((each) => ({
      taskId: `5-5-A_${each}`,
      content: Content_5_5_A(each),
    })),
    //5-6-A
    ...automaticThoughtIdList.map((each) => ({
      taskId: `5-6-A_${each}`,
      content: Content_5_6_A(each, isPushNotificationGranted),
    })),
    //5-7-T
    // {
    //   taskId: "5-7-T",
    //   content:
    //     meditationList.length === 0
    //       ? Content_5_7_T(isPushNotificationGranted)
    //       : MeditationTemplate_new(meditationList),
    // },
    //6-1-A
    {
      taskId: `6-1-A`,
      content: Content_6_1_A,
    },
    //6-2-A
    {
      taskId: `6-2-A`,
      content: Content_6_2_A,
    },
    //6-3-A
    {
      taskId: `6-3-A`,
      content: Content_6_3_A,
    },
    //6-4-A
    {
      taskId: `6-4-A`,
      content: Content_6_4_A,
    },
    //6-5-A
    {
      taskId: `6-5-A`,
      content: Content_6_5_A(false, false, false),
    },
    //6-6-A
    {
      taskId: `6-6-A`,
      content: Content_6_6_A,
    },
    //6-7-A
    {
      taskId: `6-7-A`,
      content: Content_6_7_A,
    },
    //6-8-A
    {
      taskId: `6-8-A`,
      content: Content_6_8_A,
    },
    //6-9-A
    {
      taskId: `6-9-A`,
      // content: Content_6_9_A([], []) as ProgramContentType[],
      content: [],
    },
    //6-10-A
    {
      taskId: `6-10-A`,
      content: [],
    },

    //6-97-A
    {
      taskId: `6-97-A`,
      content: Content_6_97_A,
    },
    //6-98-A
    {
      taskId: `6-98-A`,
      content: Content_6_98_A,
    },
    //6-99-A
    {
      taskId: `6-99-A`,
      content: Content_6_99_A,
    },
    {
      taskId: `7-1-A`,
      content: Content_7_1_A,
    },
    {
      taskId: `7-3-A`,
      content: Content_7_3_A,
    },
    {
      taskId: `7-5-A`,
      content: Content_7_5_A,
    },
    ...automaticThoughtIdList.flatMap((each) => [
      //7-4-A
      {
        taskId: `7-4-A_${each}`,
        content: Content_7_4_A(each),
      },
      //7-6-A
      {
        taskId: `7-6-A_${each}`,
        content: Content_7_6_A(each),
      },
      //7-9-A
      {
        taskId: `7-9-A_${each}`,
        content: Content_7_9_A(each, isPushNotificationGranted),
      },
      ...[1, 2, 3, 4, 5].flatMap((n) => [
        //7-7-A
        {
          taskId: `7-7-A_${each}#${n}`,
          content: Content_7_7_A(each, n, isPushNotificationGranted),
        },
        //7-8-A
        {
          taskId: `7-8-A_${each}#${n}`,
          content: Content_7_8_A(each, n),
        },
      ]),
    ]),

    //8-1-A
    {
      taskId: `8-1-A`,
      content: Content_8_1_A,
    },

    //8-2-A
    {
      taskId: `8-2-A`,
      content: Content_8_2_A(isPushNotificationGranted),
    },
    //8-3-A
    {
      taskId: `8-3-A`,
      content: Content_8_3_A(isPushNotificationGranted),
    },
    //8-4-A
    {
      taskId: `8-4-A`,
      content: Content_8_4_A(isPushNotificationGranted),
    },
    //8-5-A
    {
      taskId: `8-5-A`,
      content: Content_8_5_A(isPushNotificationGranted),
    },
    //8-6-A
    {
      taskId: `8-6-A`,
      content: Content_8_6_A(isPushNotificationGranted),
    },
    //8-8-A
    {
      taskId: `8-8-A`,
      content: Content_8_8_A,
    },
    //8-9-A
    {
      taskId: `8-9-A`,
      content: Content_8_9_A,
    },
    //8-10-A
    {
      taskId: `8-10-A`,
      content: Content_8_10_A,
    },
    //9-1-A
    {
      taskId: `9-1-A`,
      content: Content_9_1_A,
    },
    //9-2-A
    {
      taskId: `9-2-A`,
      content: Content_9_2_A,
    },
    //9-3-A
    {
      taskId: `9-3-A`,
      content: Content_9_3_A,
    },
    //9-4-A
    {
      taskId: `9-4-A`,
      content: Content_9_4_A,
    },
    //9-6-A
    {
      taskId: `9-6-A`,
      content: Content_9_6_A,
    },
    //9-8-A
    {
      taskId: `9-8-A`,
      content: Content_9_8_A,
    },
    //9-10-A
    {
      taskId: `9-10-A`,
      content: Content_9_10_A,
    },
    //9-12-A
    {
      taskId: `9-12-A`,
      content: Content_9_12_A(isPushNotificationGranted),
    },
    //9-13-A
    {
      taskId: `9-13-A`,
      content: Content_9_13_A,
    },

    //100-0-A
    {
      taskId: `100-0-A`,
      content: Content_100_0_A,
    },
    //100-1-A
    {
      taskId: `100-1-A`,
      content: Content_100_1_A,
    },

    //101-1-1-A
    {
      taskId: `101-1-1-A`,
      content: Content_101_1_1_A,
    },
    //101-1-2-A
    {
      taskId: `101-1-2-A`,
      content: Content_101_1_2_A(isPushNotificationGranted),
    },
    //101-1-3-T
    {
      taskId: "101-1-3-T",
      content: Content_101_1_3_T,
    },

    //101-2-1-A
    {
      taskId: `101-2-1-A`,
      content: Content_101_2_1_A,
    },
    //101-2-2-A
    {
      taskId: `101-2-2-A`,
      content: Content_101_2_2_A,
    },
    //101-2-3-A
    {
      taskId: `101-2-3-A`,
      content: Content_101_2_3_A,
    },
    //101-2-4-A
    {
      taskId: `101-2-4-A`,
      content: Content_101_2_4_A,
    },
    //101-2-5-A
    {
      taskId: `101-2-5-A`,
      content: Content_101_2_5_A,
    },

    //102-1-A
    {
      taskId: `102-1-A`,
      content: Content_102_1_A,
    },
    //102-2-A
    {
      taskId: `102-2-A`,
      content: Content_102_2_A,
    },

    //103-1-A
    {
      taskId: `103-1-A`,
      content: Content_103_1_A,
    },
    //104-0-A
    ...automaticThoughtIdList.flatMap((N) => [
      {
        taskId: `104-0-A#${N}`,
        content: Content_104_0_A(parseInt(N), false, ["mock"]),
      },
      ...Array.from({ length: 5 }, (v, j) => j + 1).flatMap((M) => [
        {
          taskId: `104-1-A#${N}#${M}`,
          content: Content_104_1_A("", false),
        },
        {
          taskId: `104-2-A#${N}#${M}`,
          content: Content_104_2_A(parseInt(N), M, ""),
        },
      ]),
    ]),

    //tool
    {
      taskId: `meditationTool`,
      content: MeditationToolTemplate,
    },
  ];

  return programData.find((element) => element.taskId === taskId);
}
