import { useRecoilValue } from "recoil";
import { Box } from "@mui/joy";
import React from "react";
import { layoutAtom } from "recoil/layoutAtom";
import { useMediaQuery, useTheme } from "@mui/material";

export default function ContentTemplate({
  children,
}: {
  children: React.ReactNode;
}) {
  const { taskWidth } = useRecoilValue(layoutAtom);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      className={`h-full w-full overflow-y-auto py-[20px] px-[20px] overflow-x-hidden`}
    >
      <Box
        className={`relative w-full  ${
          taskWidth === "normal" && "max-w-[720px] mx-auto"
        } pb-[36px]`}
      >
        {children}
      </Box>
    </div>
  );
}
