import {
  automaticThoughtIdType,
  CoreBeliefKeyType,
  IntermediateBeliefKeyType,
} from "api/conceptApi";
import { DividerLine, TextareaLine, Thoughtmap } from "data/CellComponent";
import {
  Header1,
  Header1WithData,
  Instruction,
  InstructionWithContent,
  InstructionWithData,
  InstructionWithDataWithContent,
  InstructionWithDataWithTextarea,
  InstructionWithTextarea,
  ProgramContentType,
} from "../BlockComponent";

import {
  AutomaticThoughtTemplate,
  IntermediateBeliefTemplate,
  CoreBeliefTemplate,
  CoreExperienceTemplate,
  LastCard,
} from "./common";

export const Part1 = (automaticThoughtIdList?: automaticThoughtIdType[]) =>
  automaticThoughtIdList
    ?.map((automaticThoughtId, index) => [
      InstructionWithContent(
        `part1_typography_0_0_0_automaticThought${automaticThoughtId}`,
        AutomaticThoughtTemplate(automaticThoughtId)
      ),
      InstructionWithData(
        { A: { id: `자동적사고${automaticThoughtId} 생각함정` } },
        `part1_typography_1_0_0`
      ),
      InstructionWithTextarea(`part1_typography_2_0_0`),
    ])
    .flat() || [];

export const Part2 = (
  intermediateBeliefKeyList?: IntermediateBeliefKeyType[]
) =>
  intermediateBeliefKeyList
    ?.map((key) => [
      Header1WithData({ A: { id: `${key}_label` } }, "part2_typography_0_0_0"),
      InstructionWithDataWithContent(
        { A: { id: `${key}_label` } },
        "part2_typography_1_0_0",
        IntermediateBeliefTemplate({ intermediateBeliefKeyList: [key] })
      ),

      Instruction("part2_typography_2_0_0"),
      InstructionWithDataWithTextarea(
        { A: { id: `${key}_label` } },
        "part2_typography_3_0_0",
        { id: `${key}의 이점` }
      ),
      InstructionWithTextarea("part2_typography_4_0_0", {
        id: `${key}의 이점의 결과`,
      }),
      Instruction("part2_typography_5_0_0"),
      Instruction("part2_typography_6_0_0"),
      InstructionWithDataWithTextarea(
        { A: { id: `${key}_label` } },
        "part2_typography_7_0_0",
        {
          id: `${key}의 손해`,
        }
      ),
      InstructionWithTextarea("part2_typography_8_0_0", {
        id: `${key}의 손해의 결과`,
      }),
      InstructionWithTextarea("part2_typography_9_0_0", {
        id: `${key}의 결론`,
      }),
    ])
    .flat() || [];

export const Part3 = (
  intermediateBeliefKeyList?: IntermediateBeliefKeyType[]
) =>
  intermediateBeliefKeyList
    ?.map((key) => [
      InstructionWithData(
        { A: { id: `${key}_label` }, B: { id: `${key}의 이점` } },
        "part3_typography_0_0_0"
      ),
    ])
    .flat() || [];

export const Part4 = (
  intermediateBeliefKeyList?: IntermediateBeliefKeyType[]
) =>
  intermediateBeliefKeyList
    ?.map((key) => [
      InstructionWithData(
        { A: { id: `${key}_label` }, B: { id: `${key}의 손해` } },
        "part4_typography_0_0_0"
      ),
      InstructionWithData(
        { A: { id: `${key}의 손해의 결과` } },
        "part4_typography_1_0_0"
      ),
      InstructionWithData(
        { A: { id: `${key}의 결론` } },
        "part4_typography_2_0_0"
      ),
    ])
    .flat() || [];

export const Content_6_9_A = (
  automaticThoughtIdList?: automaticThoughtIdType[],
  intermediateBeliefKeyList?: IntermediateBeliefKeyType[],
  coreBeliefKeyList?: CoreBeliefKeyType[]
) => {
  console.log(
    automaticThoughtIdList,
    intermediateBeliefKeyList,
    coreBeliefKeyList
  );
  return [
    Instruction(`typography_0_0_0`),
    Instruction(`typography_1_0_0`),
    Instruction(`typography_2_0_0`),
    Instruction(`typography_3_0_0`),
    Instruction(`typography_4_0_0`),
    Instruction(`typography_5_0_0`),
    Instruction(`typography_6_0_0`),
    Instruction(`typography_7_0_0`),
    InstructionWithContent(`typography_8_0_0`, [Thoughtmap()]),
    Instruction(`typography_9_0_0`),
    Instruction(`typography_10_0_0`, { blockId: "common1_end" }),

    Header1(`typography_11_0_0`),
    Instruction(`typography_12_0_0`),
    Instruction(`typography_13_0_0`),
    Instruction(`typography_14_0_0`),
    Instruction(`typography_15_0_0`),
    Instruction(`typography_16_0_0`),
    Instruction(`typography_17_0_0`),
    Instruction(`typography_18_0_0`),
    Instruction(`typography_19_0_0`),
    Instruction(`typography_20_0_0`),
    Instruction(`typography_21_0_0`),
    Instruction(`typography_22_0_0`),
    Instruction(`typography_23_0_0`),
    ...Part1(automaticThoughtIdList),
    InstructionWithContent(`typography_24_0_0`, [
      ...CoreBeliefTemplate({ coreBeliefKeyList: coreBeliefKeyList }),
      DividerLine(),
      TextareaLine(),
    ]),
    Instruction(`typography_25_0_0`),

    Header1(`typography_26_0_0`),
    Instruction(`typography_27_0_0`),
    InstructionWithContent(`typography_28_0_0`, [
      Thoughtmap(undefined, "coreBelief"),
    ]),
    InstructionWithTextarea("typography_29_0_0", { id: "고통스런 감정" }),
    Instruction(`typography_30_0_0`),
    Instruction(`typography_31_0_0`),
    Instruction(`typography_32_0_0`),
    Instruction(`typography_33_0_0`),
    ...Part2(intermediateBeliefKeyList),
    // Instruction(`typography_34_0_0`),
    Instruction(`typography_35_0_0`),
    Instruction(`typography_36_0_0`),
    Instruction(`typography_37_0_0`),
    Instruction(`typography_38_0_0`),
    Header1(`typography_39_0_0`),
    InstructionWithContent(
      `typography_40_0_0`,
      CoreBeliefTemplate({ coreBeliefKeyList: coreBeliefKeyList })
    ),
    InstructionWithContent(`typography_41_0_0`, CoreExperienceTemplate()),
    InstructionWithData({ A: { id: "고통스런 감정" } }, `typography_42_0_0`),
    InstructionWithContent(
      `typography_43_0_0`,
      IntermediateBeliefTemplate({
        intermediateBeliefKeyList: intermediateBeliefKeyList,
      })
    ),
    ...Part3(intermediateBeliefKeyList),
    Instruction(`typography_44_0_0`),
    Instruction(`typography_45_0_0`),
    Instruction(`typography_46_0_0`),
    ...Part4(intermediateBeliefKeyList),
    Instruction(`typography_48_0_0`),
    Instruction(`typography_49_0_0`),
    Instruction(`typography_50_0_0`),

    Header1(`typography_51_0_0`),
    Instruction(`typography_52_0_0`),
    Instruction(`typography_53_0_0`),
    Instruction(`typography_54_0_0`),
    Instruction(`typography_55_0_0`),
    Instruction(`typography_56_0_0`),
    Instruction(`typography_57_0_0`),
    Instruction(`typography_58_0_0`),
    Instruction(`typography_59_0_0`),
    Instruction(`typography_60_0_0`),
    Instruction(`typography_61_0_0`),
    Instruction(`typography_62_0_0`),
    Instruction(`typography_63_0_0`),
    Instruction(`typography_64_0_0`),
    Instruction(`typography_65_0_0`),
    Instruction(`typography_66_0_0`),
    Instruction(`typography_67_0_0`),
    Instruction(`typography_68_0_0`),
    Instruction(`typography_69_0_0`),
    Instruction(`typography_70_0_0`),
    Instruction(`typography_71_0_0`),
    Instruction(`typography_72_0_0`),
    ...LastCard,
  ];
};
