import { Header1, Instruction, InstructionWithImage } from "../BlockComponent";

import { LastCard } from "./common";

export const Content_9_3_A = [
  InstructionWithImage(`typography_0`, "1"),
  Instruction("typography_1"),
  Instruction(`typography_2`),
  Instruction(`typography_3`),
  Instruction(`typography_4`),
  Instruction(`typography_5`),
  Instruction(`typography_6`),
  Instruction(`typography_7`),
  Instruction(`typography_8`),

  Header1(`typography_9`),
  Instruction(`typography_10`),
  InstructionWithImage(`typography_11`, "2"),
  Instruction(`typography_12`),
  Instruction(`typography_13`),
  Instruction(`typography_14`),
  Instruction(`typography_15`),
  Instruction(`typography_16`),
  Instruction(`typography_17`),
  Instruction(`typography_18`),
  Instruction(`typography_19`),
  Instruction(`typography_20`),
  Instruction(`typography_21`),
  InstructionWithImage(`typography_22`, "3"),

  Header1(`typography_23`),
  InstructionWithImage(`typography_24`, "4"),
  Instruction(`typography_25`),
  Instruction(`typography_26`),
  Instruction(`typography_27`),
  Instruction(`typography_28`),
  Instruction(`typography_29`),
  Instruction(`typography_30`),
  Instruction(`typography_31`),
  Instruction(`typography_32`),
  Instruction(`typography_33`),
  Instruction(`typography_34`),
  Instruction(`typography_35`),
  Instruction(`typography_36`),

  Header1(`typography_37`),
  Instruction(`typography_38`),
  Instruction(`typography_39`),
  Instruction(`typography_40`),
  Instruction(`typography_41`),
  Instruction(`typography_42`),
  Instruction(`typography_43`),
  Instruction(`typography_44`),
  Instruction(`typography_45`),
  Instruction(`typography_46`),
  Instruction(`typography_47`),
  Instruction(`typography_48`),
  Instruction(`typography_49`),
  InstructionWithImage(`typography_50`, "5"),
  Instruction(`typography_51`),
  Instruction(`typography_52`),
  Instruction(`typography_53`),
  Instruction(`typography_54`),
  Instruction(`typography_55`),
  Instruction(`typography_56`),
  Instruction(`typography_57`),
  Instruction(`typography_58`),
  Instruction(`typography_59`),

  Header1(`typography_60`),
  Instruction(`typography_61`),
  Instruction(`typography_62`),
  Instruction(`typography_63`),
  Instruction(`typography_64`),
  Instruction(`typography_65`),
  Instruction(`typography_66`),
  Instruction(`typography_67`),
  Instruction(`typography_68`),
  Instruction(`typography_69`),
  InstructionWithImage(`typography_70`, "6"),
  Instruction(`typography_71`),
  Instruction(`typography_72`),
  Instruction(`typography_73`),
  Instruction(`typography_74`),
  Instruction(`typography_75`),
  Instruction(`typography_76`),
  Instruction(`typography_77`),
  Instruction(`typography_78`),
  Instruction(`typography_79`),
  Instruction(`typography_80`),
  Instruction(`typography_81`),
  Instruction(`typography_82`),
  InstructionWithImage(`typography_83`, "7"),
  Instruction(`typography_84`),
  Instruction(`typography_85`),
  Instruction(`typography_86`),
  Instruction(`typography_87`),
  Instruction(`typography_88`),

  Header1(`typography_89`),
  Instruction(`typography_90`),
  Instruction(`typography_91`),
  Instruction(`typography_92`),
  Instruction(`typography_93`),
  InstructionWithImage(`typography_94`, "8"),
  InstructionWithImage(`typography_95`, "9"),
  Instruction(`typography_96`),
  Instruction(`typography_97`),
  Instruction(`typography_98`),

  Header1(`typography_99`),
  Instruction(`typography_100`),
  Instruction(`typography_101`),
  Instruction(`typography_102`),
  Instruction(`typography_103`),
  Instruction(`typography_104`),
  Instruction(`typography_105`),
  Instruction(`typography_106`),
  Instruction(`typography_107`),
  Instruction(`typography_108`),
  Instruction(`typography_109`),
  Instruction(`typography_110`),
  Instruction(`typography_111`),
  Instruction(`typography_112`),
  Instruction(`typography_113`),
  InstructionWithImage(`typography_114`, "10"),
  Instruction(`typography_115`),
  Instruction(`typography_116`),

  ...LastCard,
];
