import { ButtonGroup } from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithData,
  InstructionWithImage,
  InstructionWithTextarea,
} from "../BlockComponent";

import { LastCard, MyConcernLine, MyGoalLine } from "./common";

export const Content_1_2_A = [
  Instruction(`typography_0_0_0`),
  Instruction(`typography_1_0_0`),

  Header1(`typography_2_0_0`),
  Instruction(`typography_3_0_0`),
  InstructionWithImage("typography_4_0_0", "0"),
  InstructionWithImage("typography_5_0_0", "1"),
  InstructionWithImage("typography_6_0_0", "2"),
  InstructionWithImage(`typography_7_0_0`, "3"),
  InstructionWithImage(`typography_8_0_0`, "4"),

  Header1(`typography_9_0_0`),
  InstructionWithContent("typography_10_0_0", [
    ...MyConcernLine(),
    ...MyGoalLine(),
  ]),
  InstructionWithContent(`typography_11_0_0`, [
    [
      ButtonGroup([
        {
          translationKey: "네",
          showFromId: "동의",
          hideFromId: "비동의",
        },
        {
          translationKey: "아니요",
          showFromId: "비동의",
          hideFromId: "동의",
        },
      ]),
    ],
  ]),
  InstructionWithTextarea(`typography_12_0_0`, undefined, {
    blockId: "비동의",
    isHidden: true,
  }),
  Instruction(`typography_13_0_0`, {
    noArrow: true,
    isEnd: true,
    isHidden: true,
  }),
  InstructionWithData({ A: { id: "user_nickname" } }, `typography_14_0_0`, {
    blockId: "동의",
  }),
  InstructionWithImage("typography_15_0_0", "5"),
  Instruction("typography_16_0_0"),
  Instruction("typography_17_0_0"),
  InstructionWithImage("typography_18_0_0", "6"),
  Instruction("typography_19_0_0"),
  Instruction("typography_20_0_0"),
  Instruction(`typography_21_0_0`),
  Instruction(`typography_22_0_0`),
  InstructionWithData({ A: { id: "user_nickname" } }, `typography_23_0_0`),

  Header1("typography_24_0_0"),
  Instruction("typography_25_0_0"),
  Instruction("typography_26_0_0"),
  Instruction("typography_27_0_0"),
  Instruction("typography_28_0_0"),
  Instruction("typography_29_0_0"),
  Instruction("typography_30_0_0"),
  // InstructionWithContent(`typography_31_0_0`, [
  //   [
  //     ButtonGroup([
  //       {
  //         translationKey: "네",
  //         showFromId: "걱정 있음",
  //         hideFromId: "걱정 없음",
  //       },
  //       {
  //         translationKey: "아니요",
  //         showFromId: "걱정 없음",
  //         hideFromId: "걱정 있음",
  //       },
  //     ]),
  //   ],
  // ]),
  // InstructionWithTextarea("typography_31_0_0_1", undefined, {
  //   blockId: "걱정 있음",
  // }),
  Instruction("typography_32_0_0", { blockId: "걱정 없음" }),

  ...LastCard,
];
