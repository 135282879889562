import { Box, Grid, Typography } from "@mui/joy";
import React, { useRef } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import LargeLogo from "static/image/common/LargeLogo";

export default function DefaultPage({ content }: { content: React.ReactNode }) {
  const theme = useTheme();
  const contentRef = useRef<HTMLDivElement>(null);
  return (
    <Grid container className="w-full flex h-full ">
      {useMediaQuery(theme.breakpoints.up("md")) && (
        <Grid
          xs={12}
          md={5}
          sx={{
            position: "relative",
            backgroundColor: "background.level3",
            height: "100%",
            width: "100%",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box sx={{ width: "336px", height: "550px" }}>
            <LargeLogo />
          </Box>
          <Box className="absolute w-full left-0 bottom-[30px]">
            <Typography
              level="body-xs"
              textAlign={"center"}
              sx={{ opacity: 0.2 }}
            >
              주식회사 오웰헬스
              <br /> 대표 홍승주 | 사업자등록번호 378-86-02282 | 통신판매업신고
              제 2022-서울강남-02138
              <br /> 서울특별시 강남구 역삼로 168, 202호 (역삼동, 회성빌딩)
              070-7954-3518
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid
        ref={contentRef}
        xs={12}
        md={7}
        display="flex"
        justifyContent="center"
        alignItems="center"
        // alignItems={
        //   contentRef.current?.scrollHeight === contentRef.current?.offsetHeight
        //     ? "center"
        //     : undefined
        // }
        sx={{
          backgroundColor: "background.level2",
          p: 5,
          px: 5,
          height: "100%",
          overflow: "scroll",
        }}
      >
        <Box sx={{ maxWidth: "420px", width: "100%" }}>{content}</Box>
      </Grid>
    </Grid>
  );
}
