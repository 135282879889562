import { ButtonGroup } from "data/CellComponent";
import {
  Instruction,
  ProgramContentType,
  InstructionWithContent,
  InstructionWithTextarea,
  InstructionWithCoachTextarea,
} from "../BlockComponent";
import { LastCard, MyGoalLine } from "./common";

export const reflectGoalTemplate = [
  InstructionWithContent(
    `reflectGoal_typography_0_0_0`,
    [
      [
        ButtonGroup([
          {
            translationKey: "네",
            showFromId: "동의",
            hideFromId: "비동의",
          },
          {
            translationKey: "아니요",
            showFromId: "비동의",
            hideFromId: "동의",
          },
        ]),
      ],
    ],
    { blockId: "수정 불필요" }
  ),
  InstructionWithTextarea(`reflectGoal_typography_1_0_0`, undefined, {
    blockId: "비동의",
    isHidden: true,
  }),
  Instruction("reflectGoal_typography_2_0_0", {
    noArrow: true,
    isEnd: true,
    isHidden: true,
  }),

  InstructionWithContent(
    `reflectGoal_typography_3_0_0`,
    [
      [
        ButtonGroup([
          {
            translationKey: "아직 많이 멀어요",
            showFromId: "멂",
            hideFromId: "가까움",
          },
          {
            translationKey: "조금 멀게 느껴져요",
            showFromId: "멂",
            hideFromId: "가까움",
          },
          {
            translationKey: "조금 가깝게 느껴져요",
            showFromId: "가까움",
            hideFromId: "멂",
          },
          {
            translationKey: "이제 꽤 가까워요",
            showFromId: "가까움",
            hideFromId: "멂",
          },
        ]),
      ],
    ],
    { blockId: "동의" }
  ),

  InstructionWithTextarea(`reflectGoal_typography_4_0_0`, undefined, {
    blockId: "멂",
    nextBlockId: "공통 질문",
  }),

  InstructionWithTextarea(`reflectGoal_typography_5_0_0`, undefined, {
    blockId: "가까움",
  }),
  InstructionWithContent(`reflectGoal_typography_6_0_0`, [
    [
      ButtonGroup([
        {
          translationKey: "아직 아니에요",
          showFromId: "도움 필요",
          hideFromId: "도움 불필요",
        },
        {
          translationKey: "할 수 있을 것 같아요",
          showFromId: "도움 불필요",
          hideFromId: "도움 필요",
        },
      ]),
    ],
  ]),
  InstructionWithTextarea(`reflectGoal_typography_7_0_0`, undefined, {
    blockId: "도움 필요",
    nextBlockId: "공통 질문",
  }),

  InstructionWithTextarea(`reflectGoal_typography_8_0_0`, undefined, {
    blockId: "도움 불필요",
  }),

  InstructionWithTextarea(`reflectGoal_typography_9_0_0`, undefined, {
    blockId: "공통 질문",
  }),
  InstructionWithTextarea(`reflectGoal_typography_10_0_0`),
  Instruction("reflectGoal_typography_11_0_0"),
];

export const Content_0_5_A: ProgramContentType[] = [
  Instruction("typography_0_0_0"),
  Instruction("typography_1_0_0"),
  Instruction("typography_2_0_0"),
  Instruction("typography_3_0_0"),
  Instruction(`typography_4_0_0`),
  Instruction(`typography_5_0_0`),
  Instruction(`typography_6_0_0`),
  Instruction(`typography_7_0_0`),
  Instruction(`typography_8_0_0`),
  Instruction(`typography_9_0_0`),
  Instruction(`typography_10_0_0`),

  InstructionWithContent("typography_11_0_0", MyGoalLine(), {
    blockId: "현재 목표",
  }),
  InstructionWithContent(
    `typography_12_0_0`,
    [
      [
        ButtonGroup(
          [
            {
              translationKey: "수정 필요",
              blockIdForBranch: "현재 목표",
              showFromId: "수정 필요",
              hideFromId: "수정 불필요",
            },
            {
              translationKey: "수정 불필요 또는 수정 완료",
              blockIdForBranch: "현재 목표",
              showFromId: "수정 불필요",
              hideFromId: "수정 필요",
            },
          ],
          { coach: true }
        ),
      ],
    ],
    { hideIfPatient: true }
  ),
  Instruction(`typography_13_0_0`, { blockId: "수정 필요" }),
  Instruction(`typography_14_0_0`),
  Instruction(`typography_15_0_0`),
  InstructionWithCoachTextarea(`typography_16_0_0`, {
    placeholder: `목표를 어떻게 수정하면 좋을지 방향을 제시해주세요`,
    id: "goal_updated",
  }),
  InstructionWithTextarea("typography_17_0_0"),
  Instruction(`typography_18_0_0`, {
    noArrow: true,
    isEnd: true,
    // isHidden: true,
  }),

  ...reflectGoalTemplate,

  ...LastCard,
];
