import { atom } from "recoil";

export interface TaskSectionType {
  openTaskKeyList: string[];
  currentTaskKey: string;
  currentThoughtRecordKey?: string;
  currentMeditationRecordKey?: string;
}

export const taskSectionAtom = atom<undefined | TaskSectionType[]>({
  key: "task-by-section",
  // default: ["dashboard"],
  default: undefined,
  // effects: [
  //   ({ setSelf, onSet }) => {
  //     const savedData = sessionStorage.getItem("open-taskKey-list");
  //     // setSelf: atom 값을 설정 혹은 재설정
  //     if (savedData) setSelf(JSON.parse(savedData));

  //     // atom이 변화가 감지될 때 작동, Storage에 데이터 저장
  //     // setSelf에 의해서는 작동하지 않음
  //     onSet((newValue, _, isReset) => {
  //       isReset
  //         ? sessionStorage.removeItem("open-taskKey-list")
  //         : sessionStorage.setItem(
  //             "open-taskKey-list",
  //             JSON.stringify(newValue)
  //           );
  //     });
  //   },
  // ],
});
