import { translation_meditation } from "./meditation";
export const translation_5_1_A = {
  en: {
    v1: {
      typography_0_0_0:
        "How did you find the task of identifying and distancing yourself from the hidden traps in automatic thoughts?",
      typography_1_0_0:
        "Now, you're ready to examining your automatic thoughts objectively by yourself using the <Thought Log>.",
      typography_2_0_0:
        "Make sure to actively use the <Thought Log> going forward.",
      typography_3_0_0:
        "When negative thoughts arise, it's crucial to separate the situation, thought, and response, and repeatedly practice distancing from those thoughts.",
      typography_4_0_0:
        "With practice, this process becomes a habit, and you'll be able to distance yourself from thoughts even without the record sheet.",
      typography_5_0_0:
        "If you find it challenging to distance yourself from thoughts on your own, please feel free to reach out to me. I'm here to help.",
      typography_6_0_0: "The challenge of distancing",
      typography_7_0_0:
        "You might wonder how exactly to distance yourself from thoughts in daily life.",
      typography_8_0_0: `You might think, "Okay, I get it. I should try to distance myself from my thoughts. They're just psychological events, after all."`,
      typography_9_0_0: `You understand the importance too. By distancing, you won't react to every arising thought, which could make things less overwhelming.`,
      typography_10_0_0:
        'But you might think, "Darn it, when negative emotions surge, I just become instantly sad, anxious, or angry. What do I do then?"',
      typography_11_0_0: "Don't worry. Most people react that way initially.",
      typography_12_0_0: "Distancing yourself from thoughts isn't easy.",
      typography_13_0_0:
        "Scholars often use the term 'fusion' to describe how people with mental health issues are strongly connected with their thoughts.",
      typography_14_0_0:
        "This 'fusion' indicates the challenge of distancing from thoughts.",
      typography_15_0_0: "Positive news",
      typography_16_0_0: "But there's good news.",
      typography_17_0_0:
        "Our brains are incredibly flexible, capable of quickly unraveling even the strongest connections of thoughts.",
      typography_18_0_0: "Neuroscientists call this 'neuroplasticity'",
      typography_19_0_0: "- the brain's ability to adapt.",
      typography_20_0_0:
        "Let's look at an interesting example. Imagine our brain has cells responsible for perceiving and controlling the body.",
      typography_21_0_0:
        "Suppose there are five cells, like in the picture below, each in charge of a different body part.",
      typography_22_0_0:
        "What if we suddenly lose our left arm in an accident?",
      typography_23_0_0: "The cell responsible for it loses its function.",
      typography_24_0_0: "How does our brain react?",
      typography_25_0_0: "It quickly adapts.",
      typography_26_0_0:
        "The cell that handled the left arm gradually takes on similar functions for adjacent areas.",
      typography_27_0_0:
        "Isn't it fascinating how the brain adapts to new roles?",
      typography_28_0_0:
        "There's more. Thankfully, modern medicine can create prosthetic arms.",
      typography_29_0_0:
        "What happens if the person in our example gets a prosthetic arm and undergoes rehabilitation?",
      typography_30_0_0:
        "Surprisingly, the brain area responsible for the left arm's movement resumes its role.",
      typography_31_0_0:
        "Our brain can change its neural functions based on situations and environments.",
      typography_32_0_0:
        "This adaptability is why humans are such adaptive creatures.",
      typography_33_0_0: "We always adapt to new ways.",
      typography_34_0_0: "Flexible thinking",
      typography_35_0_0: "Now, let's return to our thoughts.",
      typography_36_0_0:
        "Like the brain cells in our example that sensed the body's position,",
      typography_37_0_0:
        "our mind has neural circuits for identity and thought.",
      typography_38_0_0:
        "But people with intense depression and anxiety often have rigid circuits.",
      typography_39_0_0:
        "They are strongly fused with sad memories, anxious thoughts, low self-esteem, and unwanted emotions.",
      typography_40_0_0:
        "They struggle to distance themselves from these thoughts.",
      typography_41_0_0: "Does life just continue this way?",
      typography_42_0_0:
        "Absolutely not. Remember, our brain, with its neuroplasticity, is highly adaptable.",
      typography_43_0_0:
        "Just as the brain adapts to a prosthetic arm through rehabilitation,",
      typography_44_0_0:
        "we can change our circuits for recognizing and accepting ourselves with proper training.",
      typography_45_0_0: "Stream of Thoughts",
      typography_46_0_0:
        "So, how can we undertake brain rehabilitation training?",
      typography_47_0_0:
        "Going forward, we'll practice distancing ourselves from thoughts, emotions, and bodily sensations embedded in our automatic thought patterns.",
      typography_48_0_0:
        "Today, let's learn the basic concept of this entire process.",
      typography_49_0_0:
        "We'll practice viewing our thoughts as psychological events.",
      typography_50_0_0: "This is the 'Stream of Thoughts' training,",
      typography_51_0_0:
        "based on mindfulness meditation and supported by numerous neuroscientific and medical studies.",
      typography_52_0_0: "The method is simple.",
      typography_53_0_0: "Imagine a beautiful, slowly flowing stream.",
      typography_54_0_0: "A stream flowing through a forest dense with trees.",
      typography_55_0_0:
        "Suddenly, a large leaf falls into the water and floats downstream.",
      typography_56_0_0:
        "Imagine yourself sitting by the stream on a bright, warm afternoon, watching the leaf drift away.",
      typography_57_0_0: "Now, be aware of your thoughts.",
      typography_58_0_0:
        "Whenever a thought arises, imagine it written on a leaf.",
      typography_59_0_0:
        "If it's a word, it's written on the leaf; if it's an image, it's drawn on the leaf.",
      typography_60_0_0:
        "Our goal is to remain by the stream, letting the leaves float away.",
      typography_61_0_0:
        "Just let the various leaves appear and flow downstream.",
      typography_62_0_0: "Don't try to speed up or slow down the stream.",
      typography_63_0_0:
        "And don't try to change what's written on the leaves.",
      typography_64_0_0:
        "You might find yourself standing up to examine what's on a leaf more closely,",
      typography_65_0_0:
        "or even climbing onto a leaf to inspect its contents.",
      typography_66_0_0:
        "You could end up swimming frantically in the stream, carried away by your thoughts and feelings.",
      typography_67_0_0:
        "But there will be moments when you realize you're no longer just sitting by the stream.",
      typography_68_0_0:
        "That moment is crucial. Realizing where you are is important.",
      typography_69_0_0:
        "When you realize you're not just sitting by the stream, stop following your thoughts.",
      typography_70_0_0:
        "Return to your spot by the stream and watch the leaves drift away.",
      typography_71_0_0:
        "If a leaf disappears, or your mind wanders, or you find yourself on the stream or a leaf,",
      typography_72_0_0: "gently notice this and return to your place.",
      typography_73_0_0:
        'Remember, your place is not on the leaf or in the stream, but "by the stream" watching the leaves.',
      typography_74_0_0:
        "This exercise isn't effective just by understanding it intellectually.",
      typography_75_0_0: "Shall we try it for about 5 minutes?",
      typography_76_0_0:
        "1. Find a quiet place where you can concentrate. During a break at work, in your room, or on your bed - anywhere is fine.",
      typography_77_0_0:
        "2. Get comfortable. Any relaxed posture is good. Just be aware you might fall asleep if you're lying down.",
      typography_78_0_0:
        "3. Once ready, start the training following the audio guide.",
      typography_79_0_0:
        "How was the training? Please write down any thoughts or challenges you encountered.",
      typography_80_0_0:
        "Remember just one thing today: The Stream of Thoughts training is a practice to view thoughts as mere psychological events, like pieces of popcorn.",
      typography_81_0_0: "Great. That concludes our activity for now.",
    },
  },
  ko: {
    v1: {
      typography_0_0_0:
        "자동적 사고에 숨어있는 생각함정을 찾으며 거리두고 살펴보는 작업은 어떠했나요?",
      typography_1_0_0:
        "이제 <생각기록지>에서 자동적 사고를 객관적으로 따져보는 연습을 혼자서 진행하실 수 있을 거예요.",
      typography_2_0_0: "앞으로 <생각기록지>를 적극 활용해보도록 하세요.",
      typography_3_0_0:
        "부정적인 생각이 들었을 때 상황-생각-반응을 분리하고, 그 생각과 거리두는 연습을 반복적으로 하는 게 중요해요.",
      typography_4_0_0:
        "그 과정이 습관이 되면 일상에서 기록지 없이도 생각과 거리를 둘 수 있을 거예요.",
      typography_5_0_0:
        "혼자서 생각과 거리두기가 어렵다면, 언제든 제게 말씀해주세요. 제가 도와드릴게요.",
      typography_6_0_0: "어려운 거리두기",
      typography_7_0_0:
        "하지만 한편으로 생각과 거리를 두는 것을 일상에서 어떻게 하라는 것인지 아리송하실 수도 있을 거예요.",
      typography_8_0_0:
        '"그래. 무슨 말인지 알겠어. 생각과 거리를 두어 보라는 거지. 그건 마음속에 떠오른 하나의 심리적 사건이니까."',
      typography_9_0_0:
        '"그게 왜 중요한지도 알겠어. 거리를 두면 마음속에 떠오른 모든 생각에 반응하지 않을 수 있다는 거잖아. 그러면 덜 힘들겠지."',
      typography_10_0_0:
        '"그렇지만 제기랄, 부정적인 감정이 치솟을 때 그냥 곧바로 우울해지는걸, 불안해지는걸, 분노가 터져나오는 걸 어떡해"',
      typography_11_0_0:
        "걱정하지 않으셔도 돼요. 이 시점에서는 대부분 그렇게 반응하기 때문이죠.",
      typography_12_0_0: "생각과 거리두는 것이 그리 쉬운 일은 아니거든요.",
      typography_13_0_0:
        "학자들은 종종 정신건강 문제를 겪는 사람들이 생각과 강하게 '융합 되어있다'라는 표현까지도 쓰곤 해요.",
      typography_14_0_0:
        "융합이라는 표현은 그만큼 생각과 거리를 두는 것이 어렵다는 걸 뜻하죠.",
      typography_15_0_0: "긍정적인 소식",
      typography_16_0_0: "하지만 긍정적인 소식이 있어요.",
      typography_17_0_0:
        "우리의 뇌는 아주 유연하기 때문에 매우 강렬해보이는 생각의 연결고리도 금방 풀어낼 수 있답니다.",
      typography_18_0_0:
        "우리 뇌는 뛰어난 유연성을 지니는데요. 뇌과학자들은 이를 신경가소성(neuroplasticity)이라고 불러요.",
      typography_19_0_0: "신경이 아주 유연하게 움직인다는 말이죠.",
      typography_20_0_0:
        "재밌는 예를 들어보죠. 우리 뇌는 신체를 인지하고 조종하는 능력을 갖추고 있어요.",
      typography_21_0_0:
        "그러한 역할을 하는 뇌세포가 아래 사진과 같이 다섯 개 있다고 상상해볼까요? 그리고 각각의 세포는 각각 맡고 있는 신체 부위의 움직임을 담당한다고 생각해보죠.",
      typography_22_0_0:
        "그런데 갑작스럽게 사고가 나서 우리가 왼팔을 잃었다고 생각해볼까요?",
      typography_23_0_0:
        "왼팔이 없으니 그것을 담당하는 뇌세포는 역할을 잃어버렸죠.",
      typography_24_0_0: "이제 우리 뇌는 어떻게 행동할까요?",
      typography_25_0_0: "우리 뇌는 빠르게 이 상황에 적응해나갑니다.",
      typography_26_0_0:
        "왼팔을 담당하던 뇌세포는 점차 인접한 부위와 유사한 기능을 담당하게 되죠.",
      typography_27_0_0:
        "얼마 전까지 왼팔을 담당하던 뇌가 상황이 바뀌니 알아서 다른 역할을 한다니, 신기하지 않나요?",
      typography_28_0_0:
        "더 신기한 일도 있습니다. 다행히 현대 의학은 의수(가짜 팔)를 만들 수 있습니다.",
      typography_29_0_0:
        "그렇다면 위 사례의 사람이 왼팔에 의수를 장착하여 적절한 재활운동을 진행하면 어떻게 될까요?",
      typography_30_0_0:
        "놀랍게도 왼팔의 기능을 담당하던 부위는 다시 왼팔의 움직임을 담당하게 돼요.",
      typography_31_0_0:
        "이처럼 우리 뇌는 상황과 환경에 따라 뇌신경의 역할을 바꾸곤 해요.",
      typography_32_0_0:
        "이것이 인류가 가장 적응적인 동물이 된 결정적인 이유랍니다.",
      typography_33_0_0: "우리는 새로운 방식에 언제나 적응합니다.",
      typography_34_0_0: "유연한 생각",
      typography_35_0_0: "자, 이제 우리의 생각으로 돌아와볼까요?",
      typography_36_0_0:
        "위 예시에서 우리의 뇌는 신체의 위치를 인지하는 고유한 신경세포를 가지고 있었죠.",
      typography_37_0_0:
        "마찬가지로 우리의 머릿속에는 정체성과 사고를 담당하는 신경회로가 있어요.",
      typography_38_0_0:
        "하지만 우울과 불안이 심한 사람들은 그 회로가 매우 경직되어 있죠.",
      typography_39_0_0:
        "그들은 우울한 기억, 불안한 생각, 낮은 자존감, 원치 않는 감정과 생각에 아주 강하게 융합되어 있어요.",
      typography_40_0_0: "그러한 생각들로부터 거리를 두지 못하는 것이죠.",
      typography_41_0_0: "그렇다면 삶은 그대로 그렇게 흘러가는 걸까요?",
      typography_42_0_0:
        "천만의 말씀을요. 우리 뇌는 신경가소성이 있는 아주 유연한 기관임을 기억하세요.",
      typography_43_0_0:
        "의수를 장착한 후 재활훈련을 통해 뇌가 새로운 방식에 적응할 수 있는 것처럼",
      typography_44_0_0:
        "우리는 적절한 훈련을 통해 우리 자신을 인지하고 받아들이는 회로를 새롭게 바꿀 수 있어요.",
      typography_45_0_0: "생각의 강, 뇌의 재활훈련",
      typography_46_0_0: "그러면 뇌의 재활훈련은 어떻게 해볼 수 있을까요?",
      typography_47_0_0:
        "앞으로 나의 자동적 사고 패턴에 담겨있는 생각, 감정, 신체감각으로부터 거리두는 연습을 하게 될 텐데요.",
      typography_48_0_0: "오늘은 그 모든 과정의 기본 개념부터 배워볼게요.",
      typography_49_0_0:
        "우리는 앞으로 마음속에 떠오르는 생각을 하나의 심리적 사건처럼 바라보는 연습을 할 거랍니다.",
      typography_50_0_0: "바로 '생각의 강' 훈련이에요.",
      typography_51_0_0:
        "생각의 강은 마인드풀니스 명상에 기반한 거리두기 연습으로 많은 뇌과학 및 의학 연구들이 그 효과를 지지하고 있어요.",
      typography_52_0_0: "방법은 간단해요.",
      typography_53_0_0: "천천히 흘러가는 아름다운 시냇물을 상상해보세요.",
      typography_54_0_0: "많은 나무로 우거진 숲 사이로 흐르는 시냇물이에요.",
      typography_55_0_0:
        "순간 큰 나뭇잎 하나가 냇물에 떨어져서 냇물을 따라 아래로 떠내려가네요.",
      typography_56_0_0:
        "나는 어느 밝고 따스한 오후, 시냇물 옆에 앉아서 나뭇잎이 떠내려가는 것을 지켜보고 있어요.",
      typography_57_0_0: "자, 이제 나의 생각을 의식해보시길 바랍니다.",
      typography_58_0_0:
        "머릿속에 어떤 한 가지 생각이 떠오를 때마다 그것이 나뭇잎 위에 쓰여 있다고 상상해보세요.",
      typography_59_0_0:
        "그 생각이 언어면 그 언어가 나뭇잎 위에 쓰여 있고, 이미지면 그 이미지가 나뭇잎 위에 그려져 있습니다.",
      typography_60_0_0:
        "우리의 목표는 시냇물 옆에 머물며 나뭇잎이 시냇물을 따라 계속 떠내려가도록 하는 것이에요.",
      typography_61_0_0:
        "그저 다양한 나뭇잎이 나타났다가 시냇물을 따라 아래로 흘러내려가도록 내버려두시길 바랍니다.",
      typography_62_0_0:
        "시냇물이 더 빨리, 또는 더 느리게 흐르도록 하려고 애쓰지 마세요.",
      typography_63_0_0:
        "또한 나뭇잎 위에 담겨있는 내용을 변화시키려고도 애쓰지 마세요.",
      typography_64_0_0:
        "하지만 아마도 당신은 높은 확률로 나뭇잎 위에 적힌 내용이 구체적으로 무엇인지 살펴보려고 자리에서 일어나 나뭇잎을 따라 이동할 겁니다.",
      typography_65_0_0:
        "어쩌면 그 내용이 무엇인지 자세히 살펴보고자 나뭇잎 위에 올라가있을 수도 있죠.",
      typography_66_0_0:
        "나뭇잎과 함께 시냇물로 빠져 정신없이 헤엄치고 있을 수도 있을 거예요. 나의 생각, 감정과 함께 말이죠.",
      typography_67_0_0:
        "그러다가 문득 당신이 지금 시냇물 옆에 가만히 앉아있지 않다는 걸 알아차리는 순간도 있을 겁니다.",
      typography_68_0_0:
        "바로 그 순간이에요. 나의 위치를 알아차리는 그 순간이 중요합니다.",
      typography_69_0_0:
        "나의 위치가 시냇물 옆에 가만히 앉아 머물러있지 않다는 것을 알아차리면, 생각을 정신없이 따라가는 걸 멈추세요.",
      typography_70_0_0:
        "그리고 다시 시냇물 옆으로 자리를 옮겨 나뭇잎이 떠내려가는 모습을 바라보시기 바랍니다.",
      typography_71_0_0:
        "나뭇잎이 사라지거나, 나의 정신이 다른 곳에 가 있거나, 당신 자신이 시냇물이나 나뭇잎 위에 있는 걸 발견한다면",
      typography_72_0_0:
        "이를 가만히 알아차리고 다시 제자리로 돌아오는 거예요.",
      typography_73_0_0:
        '나의 자리는 나뭇잎 위도 아니고, 시냇물 속도 아니고, 나뭇잎이 떠내려오는 "시냇물 옆"이라는 걸 명심하세요.',
      typography_74_0_0:
        "이 연습은 머리로 이해하는 것만으로는 아무런 효과가 없답니다.",
      typography_75_0_0: "우선은 한번 연습해볼까요? 5분 정도 걸릴 거예요.",
      ...translation_meditation.ko.v1,
      typography_76_0_0:
        "1. 먼저 조용하고 집중할 수 있는 장소를 찾으세요. 회사 쉬는 시간, 방 안, 침대 위, 어디든 괜찮아요.",
      typography_77_0_0:
        "2. 편한 자세를 취하세요. 편하게 이완될 수 있는 자세면 어떤 자세든 괜찮아요. 단, 누워서 진행할 경우엔 나도 모르게 잠들 수도 있어요.",
      typography_78_0_0: "3. 준비됐다면 음성 안내에 따라 훈련을 시작하세요.",
      typography_79_0_0:
        "훈련은 어땠나요? 느낀 점이나 어려웠던 점은 없었는지 적어주세요.",
      typography_80_0_0:
        "오늘은 한 가지만 기억하세요. 생각의 강 훈련은 생각을 하나의 심리적 사건처럼, 즉, 생각을 그저 하나의 팝콘 조각처럼 바라보기 위한 연습이라는 것을요.",
      typography_81_0_0: "좋아요. 이번 활동은 여기서 마무리할게요.",
    },
  },
};
