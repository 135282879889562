import { automaticThoughtIdType } from "api/conceptApi";
import {
  DividerLine,
  Label,
  LineWithSolidLabel,
  SolidLabelLine,
  TypographyFromData,
  TypographyFromDataLine,
} from "data/CellComponent";
import { Instruction, InstructionWithContent } from "../BlockComponent";
import { LastCard } from "./common";

export const Content_4_11_A = (
  automaticThoughtId: automaticThoughtIdType,
  numberOfExperiment: number
) => [
  Instruction("typography_0_0_0"),
  InstructionWithContent(`typography_1_0_0`, [
    ...LineWithSolidLabel(
      "실험 제목",
      TypographyFromDataLine(
        { A: { id: "제목", taskId: `4-8-A_${automaticThoughtId}` } },
        "$A$",
        { id: "제목" }
      )
    ),
    DividerLine(),
    ...LineWithSolidLabel(
      "실험 가설",
      TypographyFromDataLine(
        { A: { id: "가설", taskId: `4-8-A_${automaticThoughtId}` } },
        "$A$",
        { id: "가설" }
      )
    ),
    DividerLine(),
    ...Array.from({ length: numberOfExperiment }, (v, i) => i + 1).flatMap(
      (each) => [
        SolidLabelLine(`단계 실험 내용`, { prefixLabelIndex: each }),
        TypographyFromDataLine(
          {
            A: {
              id: `step_${each}`,
              taskId: `4-8-A_${automaticThoughtId}`,
            },
          },
          "$A$"
        ),
        DividerLine(),
      ]
    ),
  ]),
  InstructionWithContent("typography_2_0_0", [
    ...Array.from({ length: numberOfExperiment }, (v, i) => i + 1).flatMap(
      (each) => [
        SolidLabelLine(`단계 실험 결과`, { prefixLabelIndex: each }),
        [
          Label("실험 실행 여부", "dimmed", {
            width: 0.5,
          }),
          Label("가설 일치 여부", "dimmed", {
            width: 0.5,
          }),
        ],
        [
          TypographyFromData(
            {
              A: {
                id: `실험_결과_실행_여부`,
                taskId: `4-10-A_${automaticThoughtId}#${each}`,
              },
            },
            "$A$",
            { width: 0.5 }
          ),
          TypographyFromData(
            {
              A: {
                id: `실험_결과_가설_일치_여부`,
                taskId: `4-10-A_${automaticThoughtId}#${each}`,
              },
            },
            "$A$",
            { width: 0.5 }
          ),
        ],
        DividerLine(),
      ]
    ),
    ...[
      SolidLabelLine(`자동적 사고 영향 정도 변화`),
      TypographyFromDataLine(
        {
          A: {
            id: `영향받는 정도`,
            taskId: `4-2-A_${automaticThoughtId}`,
          },
          B: {
            id: `영향받는 정도`,
            taskId: `4-10-A_${automaticThoughtId}#${numberOfExperiment}`,
          },
        },
        "$A$ → $B$"
      ),
    ],
  ]),
  Instruction(`typography_3_0_0`),
  Instruction(`typography_4_0_0`),
  Instruction(`typography_5_0_0`),
  Instruction(`typography_6_0_0`),
  Instruction(`typography_7_0_0`),
  Instruction(`typography_8_0_0`),
  Instruction(`typography_9_0_0`),
  ...LastCard,
];
