import * as _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { ProgramContentType } from "data/BlockComponent";
import TextareaCell from "./TextareaCell";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { taskApi } from "api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Stack } from "@mui/joy";
import {
  conceptApi,
  CONCEPT_DOMAIN,
  CONCEPT_REFERENCE_DETAIL_ENDPOINT,
  ReferenceKeyType,
  ReferenceListType,
} from "api/conceptApi";
import { setProgramContentData } from "logic/logics";

export default function ReferenceCell({
  taskKey,
  placeholder,
  initialHtmlString,
  initialValue,
  isCoach,
  blockIndex,
  setData,
  lineIndex,
  cellIndex,
  editorKey,
  referenceKey,
}: {
  taskKey: string;
  placeholder?: string;
  initialHtmlString?: string;
  initialValue?: string;
  isCoach?: boolean;
  blockIndex: number;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
  editorKey?: string;
  referenceKey?: ReferenceKeyType;
}) {
  const patientState = useRecoilValue(patientAtom);
  const [presetValue, setPresetValue] = useState<string>();
  const [presetSelectedIndex, setPresetSelectedIndex] = useState<number>();
  const [modificationReason, setModificationReason] = useState<string>("");
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);

  useEffect(() => {
    if (presetValue === initialValue) {
      setShowSaveButton(false);
    } else {
      setShowSaveButton(true);
    }
  }, [presetValue, initialValue, presetSelectedIndex]);

  const referenceDetailqueryFn = useCallback(
    () => conceptApi.getReferenceDetail(`${patientState?.patientId}`),
    [patientState]
  );

  useQuery(
    [CONCEPT_DOMAIN, CONCEPT_REFERENCE_DETAIL_ENDPOINT],
    referenceDetailqueryFn,
    {
      enabled: !!patientState?.patientId,
      onSuccess: (fetchData) => {
        if (fetchData && fetchData.result) {
          console.log("[REFERENCE]:", fetchData.result);
          const data = fetchData.result as ReferenceListType;
          const value = data[referenceKey as ReferenceKeyType]
            ?.map((each) => `- ${each}`)
            ?.join("\n");

          setPresetValue(value);

          if (!initialValue) {
            setProgramContentData({
              setData,
              blockIndex,
              lineIndex,
              cellIndex,
              newlyAddedData: {
                htmlString: value ? `<p>${value}</p>` : undefined,
                value: value,
              },
            });
          }
        }
      },
    }
  );

  const updateReferenceQueryFn = useCallback(
    () =>
      taskApi.updatePreset(taskKey, {
        [referenceKey as ReferenceKeyType]: initialValue,
        reason: modificationReason,
      }),
    [initialValue, modificationReason, referenceKey, taskKey]
  );

  const { mutate: updatePreset } = useMutation(updateReferenceQueryFn, {
    onSuccess: () => {
      setModificationReason("");
      setShowSaveButton(false);
    },
  });

  return (
    <Stack spacing={0.5} alignItems="end">
      <TextareaCell
        placeholder={placeholder}
        initialHtmlString={initialHtmlString}
        initialValue={initialValue}
        isCoachField
        isCoach={isCoach}
        setData={setData}
        lineIndex={lineIndex}
        cellIndex={cellIndex}
        blockIndex={blockIndex}
        editorKey={editorKey}
      />
      {/* {showSaveButton && (
        <SavePresetWithReasonButton
          initialValue={presetValue}
          value={initialValue}
          updateAction={updatePreset}
          modificationReason={modificationReason}
          setModificationReason={setModificationReason}
        />
      )} */}
    </Stack>
  );
}
