import { Box, Grid, Stack } from "@mui/joy";
import useOpenTask from "hooks/useOpenTask";
import {
  ConceptKeyType_automaticThought,
  ConceptType,
  ImpactScoreHistoryListType,
  IndividualThoughtType,
} from "api/conceptApi";
import { getTaskIdFromTaskKey } from "logic/logics";
import { thoughtTrapMapping } from "data/task/4-5-A";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { ThoughtMapText } from "./ThoughtMapText";
import ThoughtMapCard from "./ThoughtMapCard";
import AutomaticThoughtImpactScoreChange from "./AutomaticThoughtImpactScoreChange";
import { useRef, useState } from "react";
import AutomaticThoughtByThoughtTrap from "./AutomaticThoughtByThoughtTrap";

function ThoughtTrapItem({ taskKey }: { taskKey: string }) {
  const { t: t_thinkingTrap } = useTranslation("translation", {
    keyPrefix: "thinkingTrap",
  });

  const openTask = useOpenTask(`${taskKey}`);
  return (
    <Stack
      direction={"row"}
      justifyContent="space-between"
      sx={{ color: "text.positive", cursor: "pointer" }}
      onClick={() => {
        openTask();
      }}
    >
      <ThoughtMapText
        text={t_thinkingTrap(
          thoughtTrapMapping[
            getTaskIdFromTaskKey(taskKey).split("_")[0] as
              | "4-5-1-A"
              | "4-5-2-A"
              | "4-5-3-A"
              | "4-5-4-A"
              | "4-5-5-A"
              | "4-5-6-A"
          ]
            .split("-")[0]
            .trim()
        )}
      />
      <span
        style={{ fontSize: "inherit", opacity: 0.4 }}
        className="material-symbols-rounded"
      >
        north_east
      </span>
    </Stack>
  );
}

function AutomaticThoughtPattern({
  situation,
  thought,
  thoughtList,
  emotionalPhysicalResponse,
  emotion,
  physicalResponse,
  behavior,
  impactScore,
  impactScoreChange,
  impactScoreHistoryList,
  thoughtTrapList,
  onAutomaticThoughtClick,
}: {
  situation?: string;
  thought?: string;
  thoughtList?: IndividualThoughtType[];
  emotionalPhysicalResponse?: string;
  emotion?: string;
  physicalResponse?: string;
  behavior?: string;
  impactScore?: number;
  impactScoreChange?: number;
  impactScoreHistoryList?: ImpactScoreHistoryListType[];
  thoughtTrapList?: string[];
  onAutomaticThoughtClick: () => void;
}) {
  const { t: t_thoughtmap } = useTranslation("translation", {
    keyPrefix: "thoughtmap",
  });
  // console.log("thought:", thought, "thoughtList:", thoughtList);
  return (
    <Stack spacing={2} sx={{ textAlign: "left" }}>
      {[
        { id2: "situation", label: "상황", content: situation },

        {
          id2: "thought",
          label: "자동적 사고",
          content: thoughtList?.map((each) => each.thought),
        },
        // {
        //   id2: "emotionalPhysicalResponse",
        //   label: "감정/신체",
        //   content: emotionalPhysicalResponse,
        // },
        {
          id2: "emotion",
          label: "감정",
          content: emotion,
        },
        {
          id2: "physicalResponse",
          label: "신체",
          content: physicalResponse,
        },
        { id2: "behavior", label: "행동", content: behavior },
      ].map(({ label, content }) => (
        <Stack key={`${label}${content}`} spacing={"8px"}>
          <ThoughtMapText
            text={t_thoughtmap(label)}
            dimmed
            color="primary.solid"
          />
          {typeof content === "string" ? (
            <ThoughtMapText text={content || ""} />
          ) : (
            content?.map((thought) => (
              <ThoughtMapText
                key={thought}
                text={thought || ""}
                // hasLink
                // action={onAutomaticThoughtClick}
              />
            ))
          )}
        </Stack>
      ))}
      {thoughtList && thoughtList.length > 0 && (
        <>
          <Box
            sx={{
              height: "1px",
              width: "100%",
              backgroundColor: "divider",
            }}
          />
          <AutomaticThoughtByThoughtTrap
            impactScoreHistoryList={impactScoreHistoryList}
            thoughtList={thoughtList.filter((element) => element.thought)}
          />
        </>
      )}
      {/* {thoughtTrapList && thoughtTrapList.length > 0 && (
        <>
          <Box
            sx={{
              height: "1px",
              width: "100%",
              backgroundColor: "divider",
            }}
          />
          <Stack spacing={"8px"}>
            <ThoughtMapText
              color={"primary.solid"}
              text={t_thoughtmap("찾은 생각함정")}
            />
            {thoughtTrapList.map((each) => (
              <ThoughtTrapItem key={`thoughtTrap${each}`} taskKey={each} />
            ))}
          </Stack>
        </>
      )}
      {impactScore !== null && (
        <Stack spacing={"8px"}>
          <ThoughtMapText
            color={"primary.solid"}
            text={t_thoughtmap("영향 정도")}
          />

          <Stack direction={"row"} justifyContent="space-between">
            <ThoughtMapText text={`${impactScore}`} />
            {impactScoreChange !== undefined && (
              <ThoughtMapText
                text={`${impactScoreChange <= 0 ? "▼" : "▲"} ${Math.abs(
                  impactScoreChange
                )}`}
                color={"primary.solid"}
              />
            )}
          </Stack>
        </Stack>
      )} */}
    </Stack>
  );
}

export default function AutomaticThought({
  data,
  idList,
  includeAutomaticThoughtDetail,
}: {
  data: ConceptType;
  idList: ConceptKeyType_automaticThought[];
  includeAutomaticThoughtDetail?: boolean;
}) {
  const detailRef = useRef<HTMLDivElement>(null);
  const { t: t_thoughtmap } = useTranslation("translation", {
    keyPrefix: "thoughtmap",
  });

  // const labelPostFixList = ["①", "②", "③", "④", "⑤", "⑥", "⑦", "⑧", "⑨", ];
  const automaticThoughtList = idList?.map((id) => {
    const automaticThoughtNum = parseInt(id.split("automaticThought")[1]);
    return {
      id: id,
      label: "부정 패턴",
      labelPostfix: `${automaticThoughtNum}`,
      content: data[id],
    };
  });

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  return (
    <>
      <Grid container direction={"row"} spacing={2} flexGrow={1}>
        {automaticThoughtList.map(
          ({ id, label, labelPostfix, content }, index) => (
            <Grid
              key={id}
              sm={12 / Math.min(automaticThoughtList.length, 2)}
              xs={12}
              sx={{ display: "flex" }}
            >
              <ThoughtMapCard
                sx={{ flexGrow: 1 }}
                key={id}
                label={label}
                labelPostfix={labelPostfix}
                content={
                  Object.values(content || {}).every(
                    (each) =>
                      !each ||
                      (typeof each === "string" && each.trim().length === 0)
                  ) ? (
                    <ThoughtMapText
                      text={"일상에서 내가 겪는 불편은?"}
                      dimmed
                    />
                  ) : (
                    <AutomaticThoughtPattern
                      {...content}
                      onAutomaticThoughtClick={() => {
                        setSelectedIndex(index);
                        detailRef.current?.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                      }}
                    />
                  )
                }
                dimmed={Object.values(content || {}).every(
                  (each) =>
                    !each ||
                    (typeof each === "string" && each.trim().length === 0)
                )}
              />
            </Grid>
          )
        )}
      </Grid>
      {/* {(
        (includeAutomaticThoughtDetail &&
          automaticThoughtList[0].content?.impactScoreHistoryList) ||
        []
      ).length > 0 && (
        <Box ref={detailRef}>
          <AutomaticThoughtImpactScoreChange
            data={data}
            idList={idList}
            defaultIndex={selectedIndex}
          />
        </Box>
      )} */}
    </>
  );
}
