import Typography from "@mui/joy/Typography";
import { Box, Button, CircularProgress, Stack } from "@mui/joy";
import { MeditationItemType, ThoughtRecordItemType } from "api/homeApi";
import NestedList, { menuKeyType } from "./NestedList";
import { TaskType } from "api";
import useOpenNewThoughtRecord from "hooks/useOpenNewThoughtRecord";
import useOpenNewMeditationRecord from "hooks/useOpenNewMeditationRecord";
import useOpenThoughtRecord from "hooks/useOpenThoughtRecord";
import useOpenMeditationRecord from "hooks/useOpenMeditationRecord";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";

function ThoughtRecordIcon() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 12.5H1.5"
        stroke="white"
        stroke-width="1.3"
        stroke-linecap="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.2172 8.00357C5.26704 7.86482 5.34695 7.7388 5.4512 7.63456L11.8787 1.20717C12.2692 0.816652 12.9024 0.816654 13.2929 1.20718C13.6834 1.5977 13.6834 2.23087 13.2929 2.62139L6.86543 9.04876C6.76117 9.15301 6.63515 9.23292 6.49639 9.28277L5.09891 9.78476C4.86019 9.87051 4.6294 9.63971 4.71516 9.40099L5.2172 8.00357Z"
        fill="white"
      />
    </svg>
  );
}

function MeditationIcon() {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 8.53418L2.43995 8.24414C3.06967 8.04982 3.56536 7.56107 3.76853 6.93415V6.93415C4.03902 6.0995 4.8159 5.53402 5.69326 5.53957C7.33849 5.54998 9.28883 5.57331 10.5805 5.59023C11.4319 5.60138 12.1808 6.15022 12.451 6.95773V6.95773C12.6561 7.57067 13.1441 8.0472 13.7617 8.23778L14.7222 8.53418"
        stroke="white"
        stroke-width="1.3"
        stroke-linecap="round"
      />
      <path
        d="M9.5 3C9.5 3.38042 9.5 5.5 9.5 5.5H6.5C6.5 5.5 6.5 3.82843 6.5 3C6.5 2.17157 7.17157 1.5 8 1.5C8.82843 1.5 9.5 2.17157 9.5 3Z"
        stroke="white"
        stroke-width="1.3"
        stroke-linecap="round"
      />
      <path
        d="M8.11046 12.0556C8.11046 12.0556 6.89168 13.6111 5.38824 13.6111C3.8848 13.6111 2.66602 12.9147 2.66602 12.0556C2.66602 11.1964 3.8848 10.5 5.38824 10.5"
        stroke="white"
        stroke-width="1.3"
        stroke-linecap="round"
      />
      <path
        d="M8.11073 12.0558C8.11073 12.0558 9.32951 10.5002 10.833 10.5002C12.3364 10.5002 13.5552 11.1967 13.5552 12.0558C13.5552 12.9149 12.3364 13.6113 10.833 13.6113"
        stroke="white"
        stroke-width="1.3"
        stroke-linecap="round"
      />
    </svg>
  );
}
function LearningActivityIcon() {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 9V3.5C1 2.39543 1.89543 1.5 3 1.5H10.5C10.7761 1.5 11 1.72386 11 2V10C11 10.2761 10.7761 10.5 10.5 10.5H2.5C1.67157 10.5 1 11.1716 1 12V12C1 12.8284 1.67157 13.5 2.5 13.5H11"
        stroke="white"
        stroke-width="1.3"
        stroke-linecap="round"
      />
      <path
        d="M1 7.5L1 12"
        stroke="white"
        stroke-width="1.3"
        stroke-linecap="round"
      />
      <path
        d="M11 9L11 13.5"
        stroke="white"
        stroke-width="1.3"
        stroke-linecap="round"
      />
    </svg>
  );
}

function RightArrow() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="20" height="20" rx="3" fill="#F6F9FA" />
      <path
        d="M8 16L12 11L8 6"
        stroke="#20242B"
        strokeOpacity="0.3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default function ActivityList({
  title,
  isInitialLoading,
  listData,
}: {
  title: string;
  isInitialLoading: boolean;
  listData?: {
    listTitle: string;
    thoughtRecordList?: ThoughtRecordItemType[];
    meditationList?: MeditationItemType[];
    taskList?: TaskType[];
    menuKey: menuKeyType;
    initiallyOpen?: boolean;
  }[];
}) {
  const user = useRecoilValue(userAtom);
  const isCoach = user?.role !== "patient";

  const openThoughtRecord = useOpenThoughtRecord();
  const openMeditationRecord = useOpenMeditationRecord();
  const openNewThoughtRecord = useOpenNewThoughtRecord();
  const openNewMeditationRecord = useOpenNewMeditationRecord();

  const dataByTitle = [
    {
      title: "생각기록지",
      icon: <ThoughtRecordIcon />,
      onTitleClick: openThoughtRecord,
      buttonText: "+ 기록하기",
      onButtonClick: openNewThoughtRecord,
    },
    {
      title: "명상훈련지",
      icon: <MeditationIcon />,
      onTitleClick: openMeditationRecord,
      buttonText: "+ 훈련하기",
      onButtonClick: openNewMeditationRecord,
    },
    {
      title: "배움활동지",
      icon: <LearningActivityIcon />,
    },
  ];
  const currentData = dataByTitle.find((element) => element.title === title);

  return listData ? (
    <Stack
      sx={{
        width: "100%",
        py: "24px",
        ...(title !== "배움활동지" && {
          borderBottom: "1px solid",
          borderColor: "divider",
        }),
      }}
    >
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ mb: "8px" }}
      >
        <Typography
          level="body-md"
          sx={{ fontWeight: 600, cursor: "pointer" }}
          startDecorator={
            <Stack
              sx={{
                width: "20px",
                height: "20px",
                mr: "4px",
                borderRadius: "3px",
                backgroundColor: "primary.solid",
              }}
              justifyContent="center"
              alignItems={"center"}
            >
              {currentData?.icon}
            </Stack>
          }
          {...(currentData?.onTitleClick && {
            endDecorator: (
              <Box
                sx={{
                  transform: "translateX(-4px)",
                }}
              >
                <RightArrow />
              </Box>
            ),
            onClick: currentData.onTitleClick,
          })}
        >
          {title}
        </Typography>
        {currentData?.buttonText && (
          <Button
            size="sm"
            variant="soft"
            disabled={isCoach}
            onClick={() => {
              if (title === "생각기록지") {
                openNewThoughtRecord();
              } else if (title === "명상훈련지") {
                openNewMeditationRecord();
              }
            }}
          >
            {currentData.buttonText}
          </Button>
        )}
      </Stack>

      {!isInitialLoading ? (
        listData.map(
          (
            {
              listTitle,
              thoughtRecordList,
              meditationList,
              taskList,
              menuKey,
              initiallyOpen,
            },
            index
          ) => (
            <NestedList
              key={listTitle}
              menuKey={menuKey}
              title={listTitle}
              {...(thoughtRecordList && {
                thoughtRecordList: thoughtRecordList,
              })}
              {...(meditationList && { meditationList: meditationList })}
              {...(taskList && { taskList: taskList })}
              initiallyOpen={initiallyOpen}
            />
          )
        )
      ) : (
        <Stack
          justifyContent={"center"}
          alignItems="center"
          sx={{
            flexGrow: 1,
          }}
        >
          <CircularProgress
            sx={{
              mt: "14px",
              mx: "auto",
            }}
            color="neutral"
          />
        </Stack>
      )}
    </Stack>
  ) : (
    <></>
  );
}
