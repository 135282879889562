import { Box, Stack } from "@mui/joy";
import Button from "@mui/joy/Button";
import { ProgramContentType } from "data/BlockComponent";
import { MultipleSelectionType, TextareaLine } from "data/CellComponent";
import * as _ from "lodash";
import { setProgramContentData } from "logic/logics";
import { useEffect, useState } from "react";

export default function ButtonGroupMultipleChoiceInput({
  initialSelectedIndices,
  requiredSelectionCount,
  blockIndex,
  options,
  setData,
  lineIndex,
  cellIndex,
  isCoach,
}: {
  initialSelectedIndices?: number[];
  requiredSelectionCount?: number;
  blockIndex: number;
  options: string[];
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
  isCoach: boolean;
}) {
  const [selectedIndices, setSelectedIndices] = useState<number[]>(
    initialSelectedIndices || []
  );

  const handleChange = (selectedIndices?: number[]) => {
    if (!_.isEqual(initialSelectedIndices, selectedIndices)) {
      setProgramContentData({
        setData,
        blockIndex,
        lineIndex,
        cellIndex,
        newlyAddedData: {
          value: selectedIndices
            ?.map((selectedIndex) => options[selectedIndex])
            .join("&&"),
          selectedIndices: selectedIndices,
        },
      });
      setData((data) => {
        if (data) {
          const originalCurrentContent = (data[blockIndex].lines[lineIndex][
            cellIndex
          ].content || {}) as MultipleSelectionType;

          const data_temp = _.cloneDeep(data);
          const newlySelectedIndex = selectedIndices?.find(
            (index) => !initialSelectedIndices?.includes(index)
          );
          const newlyUnselectedIndex = initialSelectedIndices?.find(
            (index) => !selectedIndices?.includes(index)
          );
          if (
            newlySelectedIndex !== undefined &&
            originalCurrentContent.options
          ) {
            const selectedOption =
              originalCurrentContent.options[newlySelectedIndex];
            const additionalContent = selectedOption.additionalContent;
            if (additionalContent) {
              data_temp[blockIndex].lines.splice(
                data_temp[blockIndex].lines.length,
                0,
                ...additionalContent
              );
            }
          }
          if (
            newlyUnselectedIndex !== undefined &&
            originalCurrentContent.options
          ) {
            const unSelectedOption =
              originalCurrentContent.options[newlyUnselectedIndex];
            const additionalContent = unSelectedOption.additionalContent;
            additionalContent?.forEach((line) => {
              const hideCellId = line[0]?.content.id;
              if (hideCellId) {
                const hideCellIndex = data_temp[blockIndex].lines.findIndex(
                  (line) => line.find((cell) => cell.content.id === hideCellId)
                );
                if (hideCellIndex > -1) {
                  data_temp[blockIndex].lines.splice(hideCellIndex, 1);
                }
              }
            });
          }

          return data_temp;
        }

        return data;
      });
    }
  };

  useEffect(() => {
    handleChange(selectedIndices);
  }, [selectedIndices]);

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      sx={{
        width: "100%",
        whiteSpace: "nowrap",
        flexWrap: "wrap",
        gap: 1,
      }}
    >
      {options.map((option, optionIndex) => (
        <Box
          key={`buttongroup_option_${blockIndex}_${lineIndex}_${cellIndex}_${optionIndex}`}
          sx={{ flexGrow: 1 }}
        >
          <Button
            fullWidth
            variant={
              selectedIndices.includes(optionIndex) ? "solid" : "outlined"
            }
            color="primary"
            value={option}
            onClick={(e) => {
              if (!selectedIndices.includes(optionIndex)) {
                setSelectedIndices((selectedIndices) => {
                  if (selectedIndices.length === requiredSelectionCount) {
                    const selectedIndices_temp = _.cloneDeep(selectedIndices);
                    selectedIndices_temp.splice(
                      selectedIndices.length - 1,
                      1,
                      optionIndex
                    );

                    return selectedIndices_temp;
                  } else {
                    const appendedIndices = [...selectedIndices, optionIndex];
                    // appendedIndices.sort();
                    return appendedIndices;
                  }
                });
              } else {
                setSelectedIndices((selectedIndices) =>
                  selectedIndices.filter((element) => element !== optionIndex)
                );
              }
            }}
          >
            {option}
          </Button>
        </Box>
      ))}
    </Stack>
  );
}
