import Typography from "@mui/joy/Typography";
import useOpenTask from "hooks/useOpenTask";
import { Box, Stack } from "@mui/joy";
import useGetTaskTitleFromTaskKey from "hooks/useGetTaskTitleFromTaskKey";

const ThoughtmapIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.75 13.5V11C16.75 9.89543 15.8546 9 14.75 9H6.75C5.64543 9 4.75 9.89543 4.75 11V13.5"
        stroke="#20242B"
        stroke-width="1.4"
        stroke-linecap="round"
      />
      <path
        d="M10.75 4V16"
        stroke="#20242B"
        stroke-width="1.4"
        stroke-linecap="round"
      />
    </svg>
  );
};

const ValueCompassIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 4.5H16V7.5"
        stroke="#20242B"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 16.5L4 16.5L4 13.5"
        stroke="#20242B"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="10"
        cy="10.5"
        r="3"
        fill="#F6F9FA"
        stroke="#20242B"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ProgressIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25 5L6.98331 7.66323C7.35678 8.02712 7.8576 8.23077 8.37904 8.23077H11.5683C12.0898 8.23077 12.5906 8.43442 12.9641 8.79831L16.25 12"
        stroke="#20242B"
        stroke-width="1.4"
        stroke-linecap="round"
      />
      <path
        d="M3.25 16.5H17.25"
        stroke="#20242B"
        stroke-width="1.4"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default function OverviewItem({ taskKey }: { taskKey: string }) {
  const openTask = useOpenTask(`${taskKey}`);
  const title = useGetTaskTitleFromTaskKey(taskKey);

  return (
    <Stack
      direction="row"
      alignItems={"center"}
      sx={{ cursor: "pointer", py: "12px" }}
      onClick={() => {
        (document.activeElement as HTMLElement)?.blur();
        openTask();
      }}
    >
      <Box
        sx={{
          width: "20px",
          height: "20px",
          mr: "6px",
        }}
      >
        {taskKey === "thoughtmap" ? (
          <ThoughtmapIcon />
        ) : taskKey === "valueCompass" ? (
          <ValueCompassIcon />
        ) : taskKey === "progress" ? (
          <ProgressIcon />
        ) : (
          <></>
        )}
      </Box>
      <Typography sx={{ flex: 1, fontSize: "14px" }} fontWeight={500}>
        {title}
      </Typography>
    </Stack>
  );
}
