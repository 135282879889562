import { useMutation, useQueryClient } from "@tanstack/react-query";
import { taskApi, TASK_DOMAIN, TASK_LIST_ENDPOINT } from "api";
import {
  activityRecordApi,
  ACTIVITYRECORD_DOMAIN,
  ACTIVITYRECORD_LIST_ENDPOINT,
} from "api/activityRecordApi";
import { automaticThoughtIdType } from "api/conceptApi";
import { DASHBOARD_DETAIL_ENDPOINT, DASHBOARD_DOMAIN } from "api/dashboardApi";
import {
  HOME_DOMAIN,
  HOME_MEDITATION_LIST_ENDPOINT,
  HOME_TASK_LIST_ENDPOINT,
} from "api/homeApi";
import {
  meditationRecordApi,
  MEDITATION_RECORD_DOMAIN,
  MEDITATION_RECORD_LIST_ENDPOINT,
} from "api/meditationRecordApi";
import { selfCheckApi } from "api/selfCheckApi";
import {
  thoughtRecordApi,
  THOUGHTRECORD_DOMAIN,
  THOUGHTRECORD_LIST_ENDPOINT,
} from "api/thoughtRecordApi";
import ButtonWithModal from "components/common/ButtonWithModal";
import { ProgramContentType } from "data/BlockComponent";
import { PercentageType } from "data/CellComponent";
import useConfirmThoughtmap from "hooks/useConfirmThoughtmap";
import useRefetchTaskList from "hooks/useRefetchTaskList";
import useSaveMeditationRecordWithRawContent from "hooks/useSaveMeditationRecordWithRawContent";
import useSaveThoughtRecordWithRawContent from "hooks/useSaveThoughtRecordWithRawContent";
import useSubmitImpactScore from "hooks/useSubmitImpactScore";
import {
  getReferenceData,
  getSelfCheckScore,
  getTaskIdFromTaskKey,
  isAllUserFieldFilled,
} from "logic/logics";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { currentTaskSectionIndexAtom } from "recoil/currentTaskSectionIndexAtom";
import { deviceAtom } from "recoil/deviceAtom";
import { layoutAtom } from "recoil/layoutAtom";
import { taskListAtom } from "recoil/taskListAtom";
import { taskSectionAtom } from "recoil/taskSectionAtom";
import { userAtom } from "recoil/userAtom";
import { sendMessage } from "utils/webview";

export default function DoneButton({
  taskKey,
  thoughtRecordKey,
  activityRecordKey,
  meditationRecordKey,
  data,
  doneWithEnter,
  setDoneWithEnter,
  isDone = false,
  size,
}: {
  taskKey: string;
  thoughtRecordKey?: string;
  activityRecordKey?: string;
  meditationRecordKey?: string;
  data?: ProgramContentType[];
  doneWithEnter?: boolean;
  setDoneWithEnter?: React.Dispatch<React.SetStateAction<boolean>>;
  isDone?: boolean;
  size?: "sm" | "lg";
}) {
  const isFirstDone = data?.every((element) => !element.indentation);
  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });
  const isSelfCheck =
    getTaskIdFromTaskKey(`${taskKey}`) === "0-1-A" ||
    getTaskIdFromTaskKey(`${taskKey}`) === "0-2-A" ||
    getTaskIdFromTaskKey(`${taskKey}`) === "102-1-A" ||
    getTaskIdFromTaskKey(`${taskKey}`) === "102-2-A";

  const isThoughtRecord = taskKey?.includes("2-2-T") && thoughtRecordKey;
  const isActivityRecord = activityRecordKey !== undefined;
  const isMeditationRecord =
    taskKey?.includes("5-7-T") && meditationRecordKey !== undefined;

  const taskListState = useRecoilValue(taskListAtom);
  const setLayoutState = useSetRecoilState(layoutAtom);
  const resetTaskSection = useResetRecoilState(taskSectionAtom);

  const taskId = getTaskIdFromTaskKey(taskKey);
  const automaticThoughtId = taskId.includes("_")
    ? (taskId.split("_")[1] as automaticThoughtIdType)
    : undefined;

  const user = useRecoilValue(userAtom);

  const disabled = !(!isDone && isAllUserFieldFilled(data, false, user));

  const isLastDone = taskListState
    .filter((element) => element.taskKey !== taskKey)
    .every((element) => element.isDone);

  const queryClient = useQueryClient();

  const saveSelfCheckQueryFn = useCallback(
    () => selfCheckApi.save(getSelfCheckScore(data)),
    [data]
  );

  const { mutate: saveSelfCheckData } = useMutation(saveSelfCheckQueryFn, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        DASHBOARD_DOMAIN,
        DASHBOARD_DETAIL_ENDPOINT,
      ]);
    },
  });

  const saveNewThoughtRecordDataWithRawRecord =
    useSaveThoughtRecordWithRawContent({
      percentageThought:
        (getReferenceData(data || [], "percentageThought") as PercentageType)
          ?.percentage || 0,
      date: getReferenceData(data || [], "date")?.value || "",
      situation: getReferenceData(data || [], "situation")?.value || "",
      situation_indirect:
        getReferenceData(data || [], "situation_indirect")?.value || "",
      situation_direct:
        getReferenceData(data || [], "situation_direct")?.value || "",
    });

  const saveNewMeditationRecordDataWithRawRecord =
    useSaveMeditationRecordWithRawContent({
      type: getReferenceData(data || [], "meditation_type")?.value || "",
    });

  const submit = useSubmitImpactScore({
    taskKey: taskKey,
    data: data || [],
    automaticThoughtId: automaticThoughtId,
  });
  // const confirmThoughtmapIfChange = useConfirmThoughtmap({
  //   data: data || [],
  //   automaticThoughtId: automaticThoughtId || "",
  // });

  const isModalOpen = isLastDone;

  const [taskSectionState, setTaskSectionState] =
    useRecoilState(taskSectionAtom);

  const [currentTaskSectionIndex, setCurrentTaskSectionIndex] = useRecoilState(
    currentTaskSectionIndexAtom
  );

  const coachMatchTaskId = user?.isBehavioralActivationProgram
    ? "100-1-A"
    : "0-0-A";
  const firstTaskId = user?.isBehavioralActivationProgram ? "100-0-A" : "0-0-A";

  const refetchTaskList = useRefetchTaskList();

  const refetchTaskListAndOpenSidebar = () => {
    refetchTaskList();
    if (!isModalOpen) {
      setLayoutState((state) => ({
        ...state,
        isLeftSideBarOpen: true,
      }));
    }
  };

  const { mutate: activityDone } = useMutation(
    () => taskApi.done(`${taskKey}`),
    {
      onSuccess: () => {
        refetchTaskListAndOpenSidebar();
        // queryClient.invalidateQueries([HOME_DOMAIN, HOME_TASK_LIST_ENDPOINT]);
        if (getTaskIdFromTaskKey(taskKey) === "8-6-A") {
          queryClient.invalidateQueries([
            HOME_DOMAIN,
            HOME_MEDITATION_LIST_ENDPOINT,
          ]);
        }
        if (coachMatchTaskId === getTaskIdFromTaskKey(taskKey)) {
          sendMessage(
            JSON.stringify({
              event: "refetch_chat",
            })
          );
        }
      },
    }
  );

  const { mutate: thoughtRecordDone } = useMutation(
    () => thoughtRecordApi.done(`${thoughtRecordKey}`),
    {
      onSuccess: () => {
        // queryClient.invalidateQueries([USER_DOMAIN, USER_DETAIL_ENDPOINT]);
        queryClient.invalidateQueries([
          THOUGHTRECORD_DOMAIN,
          THOUGHTRECORD_LIST_ENDPOINT,
        ]);
        refetchTaskListAndOpenSidebar();
        if (isFirstDone) {
          saveNewMeditationRecordDataWithRawRecord();
        }
      },
    }
  );
  const { mutate: activityRecordDone } = useMutation(
    () => activityRecordApi.done(`${activityRecordKey}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          ACTIVITYRECORD_DOMAIN,
          ACTIVITYRECORD_LIST_ENDPOINT,
        ]);
        refetchTaskListAndOpenSidebar();
      },
    }
  );
  const { mutate: meditationRecordDone } = useMutation(
    () => meditationRecordApi.done(`${meditationRecordKey}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          MEDITATION_RECORD_DOMAIN,
          MEDITATION_RECORD_LIST_ENDPOINT,
        ]);
        refetchTaskListAndOpenSidebar();
        if (isFirstDone) {
          saveNewThoughtRecordDataWithRawRecord();
        }
      },
    }
  );

  const action = () => {
    if (data) {
      if (isSelfCheck) {
        saveSelfCheckData();
      }
      submit();
      // confirmThoughtmapIfChange();

      if (isThoughtRecord) {
        thoughtRecordDone();
      } else if (isActivityRecord) {
        activityRecordDone();
      } else if (isMeditationRecord) {
        meditationRecordDone();
      } else {
        activityDone();
        if (getTaskIdFromTaskKey(taskKey) === firstTaskId) {
          sendMessage(
            JSON.stringify({
              event: "FBLogEvent",
              params: {
                eventName: "firstactivitydone",
              },
            })
          );
        }
      }
    }
  };
  return (
    <ButtonWithModal
      size={size}
      startDecorator={undefined}
      disabled={disabled}
      buttonText={t_ui("마치기")}
      title={t_ui(
        getTaskIdFromTaskKey(taskKey) === firstTaskId
          ? "첫 번째 활동을 마쳤어요"
          : "주어진 활동을 모두 마쳤어요"
      )}
      subtitle={t_ui(
        getTaskIdFromTaskKey(taskKey) === firstTaskId
          ? "잠시 뒤 코치 선생님이 배정될 거예요."
          : `코치 선생님이 확인 후에 메시지를 드릴 거예요.`
      )}
      actionText={t_ui("확인")}
      onClick={action}
      action={() => {
        if (getTaskIdFromTaskKey(taskKey) === coachMatchTaskId) {
          sendMessage(
            JSON.stringify({
              event: "navigate_tab",
              params: {
                tabName: "코칭",
              },
            })
          );

          resetTaskSection();
        } else {
          setLayoutState((state) => ({
            ...state,
            isLeftSideBarOpen: true,
          }));
        }
      }}
      disableModalOpen={!isModalOpen}
      clickWithEnter={doneWithEnter}
      setClickWithEnter={setDoneWithEnter}
    />
  );
}
