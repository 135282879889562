import { CircularProgress } from "@mui/joy";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import TypographyCell from "./TypographyCell";
import {
  thoughtRecordApi,
  THOUGHTRECORD_CONVERSATION_CONTINUE_ENDPOINT,
  THOUGHTRECORD_CONVERSATION_MESSAGE_DETAIL_ENDPOINT,
  THOUGHTRECORD_CONVERSATION_MESSAGE_LIST_ENDPOINT,
  THOUGHTRECORD_DOMAIN,
} from "api/thoughtRecordApi";
import { setProgramContentData } from "logic/logics";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  ProgramContentType,
} from "data/BlockComponent";
import { isDev } from "config";
import * as _ from "lodash";
import { DividerLine, TextareaLine } from "data/CellComponent";
import { v4 as uuidv4 } from "uuid";

export default function AITypographyCell({
  thoughtRecordKey,
  isShown,
  messageId,
  setData,
  blockIndex,
  lineIndex,
  cellIndex,
  value,
}: {
  thoughtRecordKey: string;
  isShown: boolean;
  messageId?: string;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  blockIndex: number;
  lineIndex: number;
  cellIndex: number;
  value?: string;
}) {
  const getMessageListQueryFn = useCallback(
    () => thoughtRecordApi.getMessageList(thoughtRecordKey),
    [thoughtRecordKey, isShown]
  );

  function AddTextarea() {
    setData((data) => {
      if (
        data &&
        data[blockIndex].lines &&
        data[blockIndex].lines[lineIndex] &&
        data[blockIndex].lines[0][0].type === "aiTypography" &&
        !data[blockIndex].lines
          .flat()
          .find((element) => element.type === "textarea")
      ) {
        const data_temp = _.cloneDeep(data);
        data_temp[blockIndex].lines.push(DividerLine());
        data_temp[blockIndex].lines.push(
          TextareaLine({
            queryKey: [
              THOUGHTRECORD_DOMAIN,
              THOUGHTRECORD_CONVERSATION_CONTINUE_ENDPOINT,
            ],
            editorKey: uuidv4(),
          })
        );

        return data_temp;
      }
      return data;
    });
  }

  useQuery(
    [THOUGHTRECORD_DOMAIN, THOUGHTRECORD_CONVERSATION_MESSAGE_LIST_ENDPOINT],
    getMessageListQueryFn,
    {
      enabled: !value && isShown && !messageId,
      ...(!value && {
        refetchInterval: 1000,
      }),
      onSuccess: (data) => {
        if (data.result) {
          const messageList = data.result;
          if (messageList.length > 0) {
            const message = messageList[0].message;
            setProgramContentData({
              setData,
              blockIndex,
              lineIndex,
              cellIndex,
              newlyAddedData: {
                value: message,
              },
            });
            AddTextarea();
          }
        }
      },
    }
  );

  const getConversationMessageDetailQueryFn = useCallback(
    () =>
      thoughtRecordApi.getConversationMessageDetail(
        thoughtRecordKey,
        messageId || ""
      ),
    [messageId]
  );
  const [isStop, setIsStop] = useState<boolean>(false);
  useQuery(
    [
      THOUGHTRECORD_DOMAIN,
      THOUGHTRECORD_CONVERSATION_MESSAGE_DETAIL_ENDPOINT,
      messageId,
    ],
    getConversationMessageDetailQueryFn,
    {
      enabled: !value && isShown && !!messageId && !isStop,
      ...(!value && !isStop && { refetchInterval: 1000 }),
      onSuccess: (data) => {
        if (data.result?.isIgnored) {
          setIsStop(true);
          setData((data) => {
            if (data) {
              if (
                data[blockIndex].lines[0] &&
                data[blockIndex].lines[0][0].type === "aiTypography" &&
                (data[blockIndex].lines[0][0].content.value || "").length === 0
              ) {
                data.splice(blockIndex, 1);
                data[blockIndex].isShown = true;
              }

              return data;
            }
            return data;
          });
        } else {
          const message = data.result?.message;
          if (message) {
            setIsStop(true);
            console.log("[TEST] message:", message, blockIndex);
            setProgramContentData({
              setData,
              blockIndex,
              lineIndex,
              cellIndex,
              newlyAddedData: {
                value: message,
              },
            });
            AddTextarea();
            if (data.result?.isInitialABCFilled) {
              const isPositive = data.result.sentiment === "positive";
              setData((data) => {
                if (
                  data &&
                  !data.some((element) =>
                    element?.lines
                      .flat()
                      .find(
                        (each) =>
                          each.type === "typography" &&
                          each.content.translationKey ===
                            "ifABCFilled_typography_0"
                      )
                  )
                ) {
                  //현재 질문 블록
                  const currentBlock = _.cloneDeep({
                    ...data[blockIndex],
                    isShown: false,
                  });
                  //긍/부, 구체화 안내 블록 추가
                  data.splice(
                    blockIndex,
                    1,
                    InstructionWithContent(
                      `ifABCFilled_typography_0`,
                      [
                        [
                          {
                            type: "isolation",
                            content: {},
                          },
                        ],
                      ],
                      { isShown: true }
                    ),
                    Instruction(
                      `${
                        isPositive ? "positive" : "negative"
                      }_sentiment_typography_0`
                    ),
                    Header1(`ifABCFilled_typography_1`),
                    Instruction(`ifABCFilled_typography_2`),
                    currentBlock
                  );
                  //긍정기록인 경우 업그레이드 제거
                  if (isPositive) {
                    const upgradeStartIndex = data.findIndex(
                      (element) => element.blockId === "upgrade_start"
                    );
                    if (upgradeStartIndex > -1) {
                      const upgradeEndIndex = data.findIndex(
                        (element) =>
                          element.blockId === "basic_restart" ||
                          element.blockId === "thoughtRecord_last"
                      );
                      data.splice(
                        upgradeStartIndex,
                        upgradeEndIndex - upgradeStartIndex
                      );
                    }
                  }

                  return data;
                }
                return data;
              });
            }
          }
        }
      },
    }
  );

  return value ? (
    <TypographyCell key={messageId} text={`${value}`} />
  ) : (
    <CircularProgress color="neutral" size="sm" />
  );
}
