import { translation_selfCheck } from "./selfCheck";
export const translation_0_2_A = {
  en: {
    v1: {
      typography_0_0_0: "It's been two weeks since the last measurement.",
      typography_1_0_0:
        "As you become more skilled at distancing, you'll be less affected by negative reactions.",
      typography_2_0_0:
        "Let’s see how much my distancing skills have grown over the past two weeks.",
      ...translation_selfCheck.en.v1,
    },
  },
  ko: {
    v1: {
      typography_0_0_0: "마지막 측정으로부터 2주가 지났네요.",
      typography_1_0_0:
        "거리두기에 능숙해질수록 부정적인 반응에 영향을 덜 받게 되는데요.",
      typography_2_0_0:
        "나의 거리두기 능력은 쑥쑥 자라고 있는지 한 번 확인해봐요.",

      ...translation_selfCheck.ko.v1,
    },
  },
};
