import { ButtonGroup } from "data/CellComponent";
import {
  Instruction,
  ProgramContentType,
  InstructionWithContent,
  InstructionWithTextarea,
} from "../BlockComponent";

export const Content_0_3_A = (isNotPraisedYet: boolean) => [
  Instruction("typography_0_0_0"),
  InstructionWithTextarea(`typography_1_0_0`, { id: "satisfiedPart" }),
  InstructionWithTextarea(`typography_2_0_0`, { id: "unsatisfiedPart" }),
  InstructionWithTextarea(`typography_3_0_0`, { id: "suggestion" }),
  InstructionWithContent("typography_4_0_0", [
    [
      ButtonGroup(
        [
          {
            translationKey: "매우 아쉬울 것 같아요",
            ...(isNotPraisedYet && { showFromId: "리뷰 요청" }),
          },
          {
            translationKey: "조금 아쉬울 것 같아요",
            ...(isNotPraisedYet && {
              hideFromId: "리뷰 요청",
              showFromId: "마무리",
            }),
          },
          {
            translationKey: "전혀 아쉽지 않을 것 같아요",
            ...(isNotPraisedYet && {
              hideFromId: "리뷰 요청",
              showFromId: "마무리",
            }),
          },
          {
            translationKey: "지금은 사용하고 있지 않아 해당 사항이 없어요",
            ...(isNotPraisedYet && {
              hideFromId: "리뷰 요청",
              showFromId: "마무리",
            }),
          },
        ],
        { id: "feelingWhenTermination" }
      ),
    ],
  ]),
  ...(isNotPraisedYet
    ? [
        Instruction("typography_4_0_0_1", { blockId: "리뷰 요청" }),
        Instruction("typography_4_0_0_2"),
        InstructionWithContent("typography_4_0_0_3", [
          [
            {
              type: "buttonLink",
              content: {
                text: "더 알아보기",
                url: "https://orwell.distancing.im/review-event",
              },
            },
          ],
        ]),
        InstructionWithContent("typography_4_0_0_4", [
          [{ type: "storeReview", content: {} }],
        ]),
        InstructionWithContent("typography_4_0_0_5", [
          [
            {
              type: "buttonLink",
              content: {
                text: "카카오톡 채널 바로가기",
                url: "http://pf.kakao.com/_xjxfLVG",
              },
            },
          ],
        ]),
      ]
    : []),

  Instruction("typography_5_0_0", { blockId: "마무리" }),
];
