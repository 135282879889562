import { Box } from "@mui/joy";
import { ProgramContentType } from "data/BlockComponent";
import {
  getLastShownIndex,
  hasAiComment,
  isBlockCoachFieldFilled,
} from "logic/logics";
import { useEffect, useState } from "react";

import { useRecoilState, useRecoilValue } from "recoil";
import { permanentDataAtom } from "recoil/permanentDataAtom";
import { userAtom } from "recoil/userAtom";
import ProgressBarHighlight from "./ProgressbarHighlight";

export const rainbowGradient =
  "linear-gradient(90deg, rgba(251,63,63,1) 0%, rgba(124,211,84,1) 71%, rgba(0,110,227,1) 100%)";

export default function TaskProgressBar({
  taskKey,
  data,
  setMoveToIndex,
}: {
  taskKey: string;
  data?: ProgramContentType[];
  setMoveToIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
}) {
  const user = useRecoilValue(userAtom);
  const isCoach = user?.role !== "patient";
  const isManual = isCoach && user?.isCoachAiSupportDisabled;

  const [progress, setProgress] = useState<number>(0);
  const [highlightIndexList, setHighlightIndexList] = useState<
    number[] | undefined
  >(undefined);
  const [coachHighlightIndexList, setCoachHighlightIndexList] = useState<
    number[] | undefined
  >(undefined);
  const [presetHighlightIndexList, setPresetHighlightIndexList] = useState<
    number[] | undefined
  >(undefined);

  const [permanentDataState, setPermanentDataState] =
    useRecoilState(permanentDataAtom);

  const highlightWidth = `${100 / (data || []).length}%`;

  function getPercentage(index: number) {
    if (data) {
      return (index / data.length) * 100;
    }
    return 0;
  }

  useEffect(() => {
    const lastIndex = getLastShownIndex(data);
    setProgress(getPercentage(lastIndex + 1));

    const temp = data?.map(({ lines, isHighlight, isHidden }, index) => ({
      lines: lines,
      index: index,
      isHighlight: isHighlight,
      isHidden: isHidden,
    }));
    const temp_coach = data?.map((each, index) => ({
      index: index,
      isHighlight: !isBlockCoachFieldFilled(each),
      isHidden: each.isHidden,
    }));
    const temp_preset = data?.map((each, index) => ({
      index: index,
      isHighlight: each.lines
        .flat()
        .some((each) => ["preset", "reference"].includes(each.type)),
      isHidden: each.isHidden,
    }));

    setHighlightIndexList(
      temp
        ?.filter((element) => element.isHighlight && !element.isHidden)
        .map((each) => each.index)
    );

    setCoachHighlightIndexList(
      temp_coach
        ?.filter((element) => element.isHighlight && !element.isHidden)
        .map((each) => each.index)
    );

    setPresetHighlightIndexList(
      temp_preset
        ?.filter((element) => element.isHighlight && !element.isHidden)
        .map((each) => each.index)
        .concat(
          temp
            ?.filter((element) => element.isHighlight && hasAiComment(element))
            .map((each) => each.index) || []
        )
    );
  }, [data]);

  const [whileHovering, setWhileHovering] = useState<boolean>(false);

  const showIndicatorAnimation =
    !permanentDataState?.isHighlghtIndicatorClicked;

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "3px",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          width: "100%",
          height: whileHovering ? "16px" : "4px",
          backgroundColor: "background.level2",
          // ...(permanentDataState?.isHighlghtIndicatorClicked && {
          //   "&:hover": {
          //     height: "16px",
          //   },
          // }),
          // "&:hover": {
          //   height: "16px",
          // },
        }}
        onMouseEnter={() => {
          setWhileHovering(true);
        }}
        onMouseLeave={() => {
          setWhileHovering(false);
        }}
        onTouchStart={() => {
          setWhileHovering(true);
        }}
        onTouchEnd={() => {
          setWhileHovering(false);
        }}
        onTouchCancel={() => {
          setWhileHovering(false);
        }}
        className="transition-all"
      >
        {progress > 0 && (
          <Box
            sx={{
              position: "absolute",
              width: `${progress}%`,
              height: "100%",
              backgroundColor: "primary.soft",
              borderRadius: "4px",
            }}
            className="transition-all duration-500"
          ></Box>
        )}
        {highlightIndexList && (
          <ProgressBarHighlight
            taskKey={taskKey}
            data={data}
            highlightIndexList={highlightIndexList}
            highlightColor={"primary.solid"}
            showIndicator
            onClick={(highlightIndex) => {
              setMoveToIndex(highlightIndex);
              setWhileHovering(false);
            }}
          />
        )}

        {isCoach && coachHighlightIndexList && (
          <ProgressBarHighlight
            taskKey={taskKey}
            data={data}
            highlightIndexList={coachHighlightIndexList}
            highlightColor={"danger.500"}
            showIndicator
            onClick={(highlightIndex) => {
              setMoveToIndex(highlightIndex);
              setWhileHovering(false);
            }}
          />
        )}
        {isCoach && presetHighlightIndexList && (
          <ProgressBarHighlight
            taskKey={taskKey}
            data={data}
            highlightIndexList={presetHighlightIndexList}
            highlightGradientColor={rainbowGradient}
            showIndicator
            onClick={(highlightIndex) => {
              setMoveToIndex(highlightIndex);
              setWhileHovering(false);
            }}
          />
        )}
      </Box>
    </Box>
  );
}
