import { SolidLabelLine, TypographyLine } from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithImage,
} from "../BlockComponent";

import { LastCard } from "./common";

export const Content_6_4_A = [
  Instruction(`typography_0_0_0`),
  Instruction(`typography_1_0_0`),
  Instruction(`typography_2_0_0`),
  Instruction(`typography_3_0_0`),
  Instruction(`typography_4_0_0`),
  Instruction(`typography_5_0_0`),
  Instruction(`typography_6_0_0`),
  Instruction(`typography_7_0_0`),
  Instruction(`typography_8_0_0`),
  Instruction(`typography_9_0_0`),
  Instruction(`typography_10_0_0`),
  Instruction(`typography_11_0_0`),
  Instruction(`typography_12_0_0`),
  Instruction(`typography_13_0_0`),
  Instruction(`typography_14_0_0`),
  Instruction(`typography_15_0_0`),
  Instruction(`typography_16_0_0`),

  Header1(`typography_17_0_0`),
  InstructionWithImage(`typography_18_0_0`, "0"),
  InstructionWithImage(`typography_19_0_0`, "1"),
  Instruction(`typography_20_0_0`),
  Instruction(`typography_21_0_0`),
  Instruction(`typography_22_0_0`),
  InstructionWithContent(`typography_23_0_0`, [
    SolidLabelLine("규칙"),
    TypographyLine("typography_23_0_0_1"),
    SolidLabelLine("태도"),
    TypographyLine("typography_23_0_0_2"),
    SolidLabelLine("가정"),
    TypographyLine("typography_23_0_0_3"),
  ]),
  Instruction(`typography_24_0_0`),
  Instruction(`typography_25_0_0`),
  InstructionWithContent(`typography_26_0_0`, [
    SolidLabelLine("규칙"),
    TypographyLine("typography_26_0_0_1"),
    SolidLabelLine("태도"),
    TypographyLine("typography_26_0_0_2"),
    SolidLabelLine("가정"),
    TypographyLine("typography_26_0_0_3"),
  ]),
  Instruction(`typography_27_0_0`),
  Instruction(`typography_28_0_0`),

  Header1(`typography_29_0_0`),
  Instruction(`typography_30_0_0`),
  Instruction(`typography_31_0_0`),
  Instruction(`typography_32_0_0`),
  Instruction(`typography_33_0_0`),
  Instruction(`typography_34_0_0`),
  Instruction(`typography_35_0_0`),
  Instruction(`typography_36_0_0`),
  Instruction(`typography_37_0_0`),
  Instruction(`typography_38_0_0`),
  ...LastCard,
];
