import { IconButton } from "@mui/joy";
import useSidebarToggle from "hooks/useSidebarToggle";

export default function OpenSidebarButton() {
  const { openSidebar } = useSidebarToggle({ direction: "left" });

  return (
    <IconButton onClick={openSidebar} variant="plain" color="neutral" size="sm">
      <svg
        width="30"
        height="20"
        viewBox="0 0 30 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.945 5.9044C6.945 5.58883 7.20081 5.33301 7.51638 5.33301H13.781C14.0966 5.33301 14.3524 5.58883 14.3524 5.9044L14.3524 6.2431C14.3524 6.55867 14.6082 6.81449 14.9238 6.81449L21.1884 6.81449C21.504 6.81449 21.7598 7.07031 21.7598 7.38588V7.72458C21.7598 8.04015 22.0156 8.29597 22.3312 8.29597H25.6329C25.9484 8.29597 26.2043 8.55179 26.2043 8.86736V15.132C26.2043 15.4476 25.9484 15.7034 25.6329 15.7034H22.3312C22.0156 15.7034 21.7598 15.9592 21.7598 16.2748V18.095C21.7598 18.4105 21.504 18.6663 21.1884 18.6663H20.515C20.3635 18.6663 20.2181 18.6061 20.111 18.499L17.4827 15.8707C17.3756 15.7636 17.2302 15.7034 17.0787 15.7034H4.57139C4.25582 15.7034 4 15.4476 4 15.132L4 8.86736C4 8.55179 4.25582 8.29597 4.57139 8.29597L6.37361 8.29597C6.68918 8.29597 6.945 8.04015 6.945 7.72458V5.9044Z"
          fill="#20242B"
        />
      </svg>
    </IconButton>
  );
}
