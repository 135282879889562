import { useRecoilValue } from "recoil";
import { Box, Grid, Stack, Typography } from "@mui/joy";
import React, { useCallback, useEffect, useState } from "react";
import { patientAtom } from "recoil/patientAtom";
import {
  AutomaticThoughtForValueDataType,
  dashboardApi,
  DASHBOARD_DOMAIN,
  DASHBOARD_VALUE_EXPLORATION_DETAIL_ENDPOINT,
  ValueDomainType,
  ValueListByDomainType,
} from "api/dashboardApi";
import { useQuery } from "@tanstack/react-query";
import ContentTemplate from "./ContentTemplate";
import Compass from "components/value/Compass";
import ValueWithBehaviorList from "components/value/ValueWithBehaviorList";
import AutomaticThoughtForValue from "components/value/AutomaticThoughtForValue";
import { ConceptKeyType_automaticThoughtForValue } from "api/conceptApi";

export default function MyValueCompassContent() {
  const patientState = useRecoilValue(patientAtom);

  const getValueExplorationDetailQueryFn = useCallback(
    () => dashboardApi.getValueExploration(`${patientState?.patientId}`),
    [patientState]
  );

  const [currentValueIndex, setCurrentValueIndex] = useState<number>(0);

  const [valueDomainList, setValueDomainList] = useState<
    ValueListByDomainType[] | undefined
  >();
  const [automaticThoughtForValueData, setAutomaticThoughtForValueData] =
    useState<AutomaticThoughtForValueDataType>();
  useQuery(
    [DASHBOARD_DOMAIN, DASHBOARD_VALUE_EXPLORATION_DETAIL_ENDPOINT],
    getValueExplorationDetailQueryFn,
    {
      enabled: !!patientState?.patientId,
      onSuccess: (data) => {
        console.log("dashboard data:", data.result);
        setValueDomainList(data.result?.valueListByDomain);
        const automaticThoughtForValueData = Object.fromEntries(
          Object.entries(data.result || {}).filter(
            ([key, value]) => key !== "valueListByDomain"
          )
        );
        if (Object.keys(automaticThoughtForValueData).length > 0) {
          setAutomaticThoughtForValueData(automaticThoughtForValueData);
        } else {
          setAutomaticThoughtForValueData(undefined);
        }
      },
    }
  );
  useEffect(() => {
    setCurrentValueIndex(0);
  }, [valueDomainList]);

  return (
    <ContentTemplate>
      <Stack sx={{ width: "100%" }} spacing={2} alignItems="center">
        <Compass
          value={(valueDomainList || [])[currentValueIndex || 0]?.domain}
          valueList={valueDomainList?.map((each) => each.domain)}
        />
        {valueDomainList && valueDomainList.length > 0 && (
          <ValueWithBehaviorList
            valueListByDomain={valueDomainList[currentValueIndex]}
            {...((currentValueIndex || 0) < valueDomainList.length - 1 && {
              goNext: () => {
                setCurrentValueIndex((index) => index + 1);
              },
            })}
            {...((currentValueIndex || 0) > 0 && {
              goPrevious: () => {
                setCurrentValueIndex((index) => index - 1);
              },
            })}
          />
        )}
        <Stack sx={{ width: "100%" }}>
          <AutomaticThoughtForValue
            data={
              valueDomainList
                ? Object.fromEntries(
                    Object.entries(automaticThoughtForValueData || {}).filter(
                      ([key, value]) =>
                        value.valueDomain ===
                        valueDomainList[currentValueIndex].domain
                    )
                  )
                : {}
            }
          />
        </Stack>
      </Stack>
    </ContentTemplate>
  );
}
