import { IconButton } from "@mui/joy";
import useSidebarToggle from "hooks/useSidebarToggle";

export default function OpenConceptNoteButton() {
  const { openSidebar } = useSidebarToggle({ direction: "right" });

  return (
    <IconButton onClick={openSidebar} variant="plain" color="neutral" size="sm">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 4C4 3.44772 4.44772 3 5 3H14C15.1046 3 16 3.89543 16 5V15C16 16.1046 15.1046 17 14 17H5C4.44772 17 4 16.5523 4 16V4Z"
          stroke="#20242B"
          stroke-width="1.3"
        />
        <rect
          x="14"
          y="3"
          width="14"
          height="3"
          transform="rotate(90 14 3)"
          fill="#20242B"
        />
      </svg>
    </IconButton>
  );
}
