export const translation_9_10_A = {
  en: {},
  ko: {
    v1: {
      typography_0: `마지막으로 ‘기꺼이 경험하기’를 적용해 볼 부분은 행동이에요.`,
      typography_1: `사건의 결과로 내가 행한 것을 적으면 되니 쉽다고 느낄 때가 많은데요.`,
      typography_2: `하지만 이제부터는 행동도 두 가지로 나눠서 기록해 볼 거랍니다.`,
      typography_3: `충동`,
      typography_4: `첫 번째는 ‘충동'이에요.`,
      typography_5: `충동은 쉽게 파악하기 힘들 때가 많아요.`,
      typography_6: `많은 경우, 양가감정 속에서 어떤 한 행동을 하기로 결정하게 되는데,`,
      typography_7: `이는 아주 미묘하기도 하고, 반사적으로 결정되기도 하기 때문이죠.`,
      typography_8: `하지만 마음을 찬찬히 관찰해보면 생각보다 상충되는 여러 충동들이 공존하고 있다는 걸 발견할 수 있답니다.`,
      typography_9: `친구와 싸울 때 소리를 지르고 싶다가도, 조금 더 살펴보면`,
      typography_10: `“그냥 문제를 복잡하게 만들지 않고 빨리 정리하고 싶다"`,
      typography_11: `“먼저 사과하고 싶기도 한데"`,
      typography_12: `“주먹으로 얼굴을 한 방 때려버릴까"`,
      typography_13: `다양한 충동이 존재하고 있는 걸 발견할 수 있답니다.`,
      typography_14: `충동은 생각처럼 느껴지기도 할 텐데요.`,
      typography_15: `하지만 “어떻게 행동하고 싶다"라는 내용은 행동 파트의 충동에 쓰기로 해요.`,
      typography_16: `그래야 실제 행동과 비교를 하며 더 잘 알아차릴 수 있기 때문이랍니다.`,
      typography_17: `실제 행동`,
      typography_18: `두 번째는 ‘실제 행동'이에요.`,
      typography_19: `말 그대로 실제로 내가 행한 행동을 말한답니다.`,
      typography_20: `앞서 설명했던 ‘얼굴과 몸의 언어적 표현’인 신체 반응과 구분해서, 상황에 대한 결과로 내가 행한 행동을 적는 거예요.`,
      typography_21: `“그래서 나는 이렇게 행동했다"라는 내용을 적는다고 생각해주시면 된답니다.`,
      typography_22: `아마 이 부분은 충동보다는 비교적 작성하기 쉬울 거예요.`,
      typography_23: `실제 나의 행동을 적으면 되니까요.`,
      typography_24: `기꺼이 경험하며 관찰해요`,
      typography_25: `지금까지 <생각기록지>에 더 자세하게 기록하고 분리하는 방법을 배웠는데요.`,
      typography_26: `반복해서 설명한 것처럼 이것이 ‘기꺼이 경험하기’에 속하는 이유는 생각보다 부정적인 경험을 이렇게까지 뜯어보는 과정이 쉽지 않기 때문이에요.`,
      typography_27: `각 요소를 마주하는 과정이 나를 괴롭게 하기도 할 거고요.`,
      typography_28: `하지만 반복적으로 분리하며 기록하고, 명상을 통해 이 모든 요소들을 하나의 심리적 사건으로 바라보는 연습을 이어 나가다 보면,`,
      typography_29: `생각과 거리를 두는 일이 더 수월해질 거랍니다.`,
      typography_30: `물론 때론 마음처럼 안 될 때도 있을 거예요.`,
      typography_31: `그럴 때는 코치 선생님이 조금 더 자세히 다뤄볼 수 있도록 도와드릴 거랍니다.`,
      typography_32: `자, 그러면 오늘 배운 내용을 토대로 기록을 작성해볼까요?`,
    },
  },
};
