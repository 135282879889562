import { Box, Button, Stack, Typography } from "@mui/joy";
import useOpenTask from "hooks/useOpenTask";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";

export default function PaymentModule({
  title,
  subtitle,
  size,
}: {
  title?: string;
  subtitle?: string;
  size?: "lg";
}) {
  const openPayment = useOpenTask(`payment`);
  const user = useRecoilValue(userAtom);
  const subscription = user?.subscription;

  const { t: t_payment } = useTranslation("translation", {
    keyPrefix: "payment",
  });

  const text = {
    canceled: {
      title: t_payment(`n일 뒤 구독이 만료되어요`, {
        expiryDaysLeft: subscription?.expiryDaysLeft,
      }),
      subtitle: t_payment(`구독을 유지하고 프로그램을 이어가세요.`),
    },
    expired: {
      title: t_payment(`구독이 만료되었어요`),
      subtitle: t_payment(`다시 구독하고 프로그램을 이어가세요.`),
    },
    notstarted: {
      title: t_payment(`구독하고 이용해주세요`),
      subtitle: t_payment(`코치 선생님과 프로그램을 시작하세요.`),
    },
  };
  const { isCanceled, isExpired, isNotStarted } = useSubscriptionStatus();
  const isLarge = size === "lg";
  return (
    <Stack
      spacing={1}
      // sx={{ ...(!isLarge && { px: "20px" }), pt: "12px", pb: "20px" }}
    >
      <Box>
        <Typography level={isLarge ? "body-md" : "body-sm"}>
          {title !== undefined
            ? title
            : isCanceled
            ? text["canceled"].title
            : isExpired
            ? text["expired"].title
            : isNotStarted
            ? text["notstarted"].title
            : t_payment(`결제 후 이용해주세요`)}
        </Typography>

        <Typography
          level={isLarge ? "body-sm" : "body-xs"}
          fontWeight={600}
          sx={{ opacity: 0.5 }}
        >
          {subtitle !== undefined
            ? subtitle
            : isCanceled
            ? text["canceled"].subtitle
            : isExpired
            ? text["expired"].subtitle
            : isNotStarted
            ? text["notstarted"].subtitle
            : undefined}
        </Typography>
      </Box>
      <Button
        variant="soft"
        onClick={() => {
          openPayment();
        }}
        sx={{ width: "100%" }}
      >
        {t_payment("더 알아보기")}
      </Button>
    </Stack>
  );
}
