import { Stack, Textarea, Typography } from "@mui/joy";
import { useMutation } from "@tanstack/react-query";
import { toolApi } from "api2/toolApi";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PATH } from "routes/paths";
import ButtonWithPopup from "./ButtonWithPopup";
import ThoughtRecordContainer from "./ThoughtRecordContainer";
import useSpeechToText from "./useSpeechToText";
import VoiceRecording from "static/lottie/tool/thought-record/voice.json";
import { Lottie } from "components/common/Lottie";

const MicIcon = () => {
  return (
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.18555"
        width="9.62963"
        height="15.4074"
        rx="4.81482"
        fill="#222222"
      />
      <path
        d="M1.29688 10.1113V10.6249C1.29688 14.8795 4.74594 18.3286 9.00058 18.3286V18.3286C13.2552 18.3286 16.7043 14.8795 16.7043 10.6249V10.1113M9.00058 22.4373V18.3286"
        stroke="#222222"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default function Recording() {
  const [searchParams] = useSearchParams();
  const date = searchParams.get("date") || "";
  const isFirstRecord = searchParams.get("isFirstRecord") === "true";

  const { transcription, startRecording, stopRecording, isRecording } =
    useSpeechToText();

  const textareaRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    setContent((content) => `${content}${transcription}`);
  }, [transcription]);

  const { mutate: submit, isLoading } = useMutation(
    () => toolApi.submitThoughtRecord(content, date),
    {
      onSuccess: (data) => {
        if (data.result) {
          const thoughtRecordId = data.result.thoughtRecordId;
          console.log(thoughtRecordId);
          const path = PATH.thoughtRecordTool_isolation.replace(
            ":ttr",
            thoughtRecordId
          );
          navigate(`${path}?date=${date}`, { replace: true });
        }
      },
      onError: (data) => {
        console.log(data);
      },
    }
  );

  return (
    <ThoughtRecordContainer>
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          px: "40px",
          py: "36px",
        }}
        spacing="8px"
        alignItems="center"
      >
        <Stack
          sx={{
            width: "100%",
            flex: 1,
            overflow: "hidden",
          }}
          justifyContent="center"
          alignItems={"center"}
          spacing="20px"
        >
          <Typography sx={{ fontSize: "16px", color: "#222222", opacity: 0.5 }}>
            어떤 일이 있었나요?
          </Typography>
          <Stack
            sx={{
              width: "100%",
            }}
          >
            <Textarea
              ref={textareaRef}
              placeholder={
                "그때를 떠올리며 마치 슬로우비디오를 보듯 얘기해주세요"
              }
              value={content}
              onChange={(e) => {
                let newValue = e.target.value.replace("\n", "");
                setContent(newValue);
              }}
              autoFocus
              minRows={1}
              maxRows={10}
              sx={{
                width: "100%",
                minWidth: 0,
                height: "100%",
                overflowY: "scroll",
                padding: 0,
                backgroundColor: "transparent",
                borderWidth: 0,
                boxShadow: "none",
                "--joy-focus-thickness": "0px",
                "--variant-borderWidth": "0px",
                "--Textarea-gap": "0px",
              }}
            ></Textarea>
          </Stack>

          <Stack
            sx={{
              width: "52px",
              height: "52px",
              borderRadius: "50%",
              backgroundColor: "white",
              ...(isRecording && {
                background: "linear-gradient(135deg, #FCA15E 0%, #FD4808 100%)",
              }),
            }}
            justifyContent="center"
            alignItems={"center"}
            onClick={() => {
              if (isRecording) {
                stopRecording();
              } else {
                startRecording();
              }
            }}
          >
            {!isRecording ? (
              <MicIcon />
            ) : (
              <Lottie src={VoiceRecording} containerWidth={"32px"} />
            )}
          </Stack>
        </Stack>
        <ButtonWithPopup
          buttonText={"분리 시작하기"}
          action={() => {
            submit();
          }}
          disabled={content.trim().length === 0}
          hasShadow
          loading={isLoading}
          title={"이제 질문에 답변해주세요"}
          subtitle={
            "떠오르는 대로 편히 답해보는 거예요.\n잘 모르겠으면 그렇다고 해도 돼요."
          }
          cancelText={"취소"}
          actionText={"시작하기"}
          usePopup={isFirstRecord}
        />
      </Stack>
    </ThoughtRecordContainer>
  );
}
