import { Box, Button, Stack } from "@mui/joy";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Block } from "./Block";
import ProgressBar from "./ProgressBar";

export type IdType = "emotion" | "situation" | "thought";

export default function Awareness({ uuid }: { uuid: string }) {
  const navigate = useNavigate();
  const qnaRefs = useRef<HTMLDivElement[]>([]);

  const [thoughtRecord, setThoughtRecord] = useState<{
    [id in IdType]: string;
  }>({ emotion: "", situation: "", thought: "" });

  const [lastShownIndex, setLastShownIndex] = useState<number>(0);

  const showNextQuestion = () => {
    setLastShownIndex((lastShownIndex) => lastShownIndex + 1);
  };

  function handleAnswerChange(id: IdType, value: string) {
    setThoughtRecord((thoughtRecord) => ({
      ...thoughtRecord,
      [id]: value,
    }));
  }

  const moveToIndex = (index: number) => {
    qnaRefs.current[index].scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  useEffect(() => {
    if (lastShownIndex > 0) {
      moveToIndex(lastShownIndex);
    }
  }, [lastShownIndex]);

  const questionList: { id: IdType; question: string; label: string }[] = [
    {
      id: "emotion",
      question: "지금 나에게 강하게 느껴지는 감정은 무엇인가요?",
      label: "감정",
    },
    {
      id: "situation",
      question: `어떤 상황에 '${thoughtRecord.emotion}'의 감정을 느꼈나요?`,
      label: "상황",
    },
    {
      id: "thought",
      question: "그 순간에 어떤 생각이 떠올랐나요?",
      label: "생각",
    },
  ];

  return (
    <Stack
      sx={{
        width: "100%",
      }}
      alignItems="center"
    >
      <Stack sx={{ width: "100%", mb: "50px" }} direction="row" spacing={"3px"}>
        {[
          {
            label: "알아차리기",
            percentage: ((lastShownIndex + 1) / questionList.length) * 100,
          },
          { label: "거리두기", percentage: 0 },
          { label: "기꺼이 경험하기", percentage: 0 },
        ].map(({ label, percentage }, index) => (
          <Box key={label} sx={{ flex: 1 }}>
            <ProgressBar label={label} percentage={percentage}></ProgressBar>
          </Box>
        ))}
      </Stack>
      <Stack
        direction={"column"}
        justifyContent="start"
        sx={{ width: "100%", pb: "100px" }}
        spacing="50px"
      >
        {questionList.map(({ id, question, label }, index) => (
          <Box
            key={id}
            sx={{ ...(index > lastShownIndex && { visibility: "hidden" }) }}
          >
            <Block
              ref={(el) => (qnaRefs.current[index] = el as HTMLDivElement)}
              type="textarea"
              question={question}
              answerLabel={label}
              handleChange={(value: string) => handleAnswerChange(id, value)}
              {...(index < questionList.length - 1 && {
                onNextButtonClick: showNextQuestion,
              })}
              isNextButtonAvailable={
                lastShownIndex === index && thoughtRecord[id].trim().length > 0
              }
            />
          </Box>
        ))}
      </Stack>
      {thoughtRecord.thought.trim().length > 0 && (
        <Button
          size="lg"
          sx={{
            backgroundColor: "#232832",
            width: "100%",
            position: "sticky",
            bottom: "20px",
            "&:hover": {
              backgroundColor: `#23283288`,
            },
          }}
          onClick={() => {
            localStorage.setItem("emotion", thoughtRecord.emotion);
            localStorage.setItem("situation", thoughtRecord.situation);
            localStorage.setItem("thought", thoughtRecord.thought);
            navigate("/tool/2");
          }}
        >
          계속 진행하기
        </Button>
      )}
    </Stack>
  );
}
