import { Percentage } from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithData,
  InstructionWithDataWithTextarea,
  InstructionWithImage,
  InstructionWithTextarea,
} from "../BlockComponent";

import { LastCard } from "./common";

export const Content_8_10_A = [
  Instruction(`typography_1`),
  Instruction(`typography_2`),
  Instruction(`typography_3`),
  Instruction(`typography_4`),

  Header1(`typography_5`),
  InstructionWithImage(`typography_6`, "4"),
  Instruction(`typography_7`),
  InstructionWithTextarea(`typography_8`),
  InstructionWithTextarea(`typography_9`),
  InstructionWithTextarea(`typography_10`),
  Instruction(`typography_11`),
  Instruction(`typography_12`),
  Instruction(`typography_13`),
  Instruction(`typography_14`),
  Instruction(`typography_15`),

  Header1(`typography_16`),
  Instruction(`typography_17`),
  Instruction(`typography_18`),

  Instruction(`typography_19`),
  Instruction(`typography_20`),
  Instruction(`typography_21`),
  Instruction(`typography_22`),
  Instruction(`typography_23`),
  InstructionWithTextarea(`typography_24`),
  Instruction(`typography_25`),
  Instruction(`typography_26`),
  Instruction(`typography_27`),
  Instruction(`typography_28`),

  Header1(`typography_29`),
  InstructionWithTextarea(`typography_30`),
  Instruction(`typography_31`),
  Instruction(`typography_32`),
  Instruction(`typography_33`),
  Instruction(`typography_34`),
  Instruction(`typography_35`),
  Instruction(`typography_36`),
  Instruction(`typography_37`),
  InstructionWithTextarea(`typography_38`, { id: "단어" }),
  InstructionWithContent("typography_39", [
    [Percentage({ id: "영향받는 정도_temp" })],
  ]),
  Instruction(`typography_40`),
  Instruction(`typography_41`),
  InstructionWithData({ A: { id: "단어" } }, `typography_42`),
  Instruction(`typography_43`),
  Instruction(`typography_44`),
  InstructionWithDataWithTextarea({ A: { id: "단어" } }, `typography_45`),
  InstructionWithContent("typography_46", [
    [Percentage({ id: "영향받는 정도_temp" })],
  ]),
  Instruction(`typography_47`),

  Header1(`typography_48`),
  Instruction(`typography_49`),
  Instruction(`typography_50`),
  Instruction(`typography_51`),
  Instruction(`typography_52`),
  Instruction(`typography_53`),
  Instruction(`typography_54`),
  Instruction(`typography_55`),
  Instruction(`typography_56`),
  Instruction(`typography_57`),
  Instruction(`typography_58`),
  Instruction(`typography_59`),
  Instruction(`typography_60`),
  Instruction(`typography_61`),

  ...LastCard,
];
