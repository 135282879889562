import Button from "@mui/joy/Button";
import { isAllCoachFieldFilled, isBlockUserFieldFilled } from "logic/logics";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { taskApi, TASK_DOMAIN, TASK_LIST_ENDPOINT } from "api";
import { ProgramContentType } from "data/BlockComponent";
import {
  thoughtRecordApi,
  THOUGHTRECORD_DOMAIN,
  THOUGHTRECORD_LIST_ENDPOINT,
} from "api/thoughtRecordApi";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import {
  activityRecordApi,
  ACTIVITYRECORD_DOMAIN,
  ACTIVITYRECORD_LIST_ENDPOINT,
} from "api/activityRecordApi";
import {
  meditationRecordApi,
  MEDITATION_RECORD_DOMAIN,
  MEDITATION_RECORD_LIST_ENDPOINT,
} from "api/meditationRecordApi";
import useRefetchTaskList from "hooks/useRefetchTaskList";

export default function UndoneButton({
  taskKey,
  data,
  setData,
  thoughtRecordKey,
  activityRecordKey,
  meditationRecordKey,
  isDone = false,
}: {
  taskKey?: string;
  data?: ProgramContentType[];
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  thoughtRecordKey?: string;
  activityRecordKey?: string;
  meditationRecordKey?: string;
  isDone?: boolean;
}) {
  const isThoughtRecord = taskKey?.includes("2-2-T") && thoughtRecordKey;
  const isActivityRecord = activityRecordKey !== undefined;
  const isMeditationRecord = meditationRecordKey !== undefined;
  const queryClient = useQueryClient();

  const patientState = useRecoilValue(patientAtom);

  const refetchTaskList = useRefetchTaskList();

  const { mutate: activityUndone } = useMutation(
    () => taskApi.undone(`${taskKey}`),
    {
      onSuccess: () => {
        refetchTaskList();
      },
    }
  );
  const { mutate: thoughtRecordUndone } = useMutation(
    () =>
      thoughtRecordApi.undone(
        `${thoughtRecordKey}`,
        `${patientState?.patientId}`
      ),
    {
      onSuccess: () => {
        refetchTaskList();
        queryClient.invalidateQueries([
          THOUGHTRECORD_DOMAIN,
          THOUGHTRECORD_LIST_ENDPOINT,
        ]);
      },
    }
  );
  const { mutate: activityRecordUndone } = useMutation(
    () =>
      activityRecordApi.undone(
        `${activityRecordKey}`,
        `${patientState?.patientId}`
      ),
    {
      onSuccess: () => {
        refetchTaskList();
        queryClient.invalidateQueries([
          ACTIVITYRECORD_DOMAIN,
          ACTIVITYRECORD_LIST_ENDPOINT,
        ]);
      },
    }
  );
  const { mutate: meditationRecordUndone } = useMutation(
    () =>
      meditationRecordApi.undone(
        `${meditationRecordKey}`,
        `${patientState?.patientId}`
      ),
    {
      onSuccess: () => {
        refetchTaskList();
        queryClient.invalidateQueries([
          MEDITATION_RECORD_DOMAIN,
          MEDITATION_RECORD_LIST_ENDPOINT,
        ]);
      },
    }
  );

  return (
    <Button
      size="sm"
      variant="solid"
      color="primary"
      disabled={!(data && isDone && isAllCoachFieldFilled(data))}
      onClick={() => {
        if (isThoughtRecord) {
          thoughtRecordUndone();
        } else if (isActivityRecord) {
          activityRecordUndone();
        } else if (isMeditationRecord) {
          meditationRecordUndone();
        } else {
          activityUndone();
        }
      }}
    >
      다시 주기
    </Button>
  );
}
