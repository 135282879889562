import { Button } from "@mui/joy";
import useOpenTask from "hooks/useOpenTask";
import { useTranslation } from "react-i18next";

export default function GotoPaymentButton({
  disabled,
}: {
  disabled?: boolean;
}) {
  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });
  const openPayment = useOpenTask(`payment`);

  return (
    <Button
      sx={{ width: "100%" }}
      onClick={() => {
        openPayment();
      }}
      disabled={disabled}
    >
      {"무료 체험 시작하고 답변 받기"}
    </Button>
  );
}
