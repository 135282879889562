import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { useEffect } from "react";
import { TaskType } from "api";
import { Stack } from "@mui/joy";
import { useRecoilState, useRecoilValue } from "recoil";
import { menuSelector } from "recoil/menuAtom";
import TaskItem from "./TaskItem";
import { UpArrowSvg } from "static/image/common/SvgAssets";
import { MeditationItemType, ThoughtRecordItemType } from "api/homeApi";
import ToolItem from "./ToolItem";
import { patientAtom } from "recoil/patientAtom";
import { freeTaskList, onboardingTaskId } from "./LearningActivityList2";

export type menuKeyType =
  | "taskList_open"
  | "taskList_close"
  | "thoughtRecordList_open"
  | "thoughtRecordList_close"
  | "meditationList_open"
  | "meditationList_close";

export default function NestedList({
  menuKey,
  title,
  taskList,
  thoughtRecordList,
  meditationList,
  initiallyOpen,
}: {
  menuKey: menuKeyType;
  title: string;
  taskList?: TaskType[];
  thoughtRecordList?: ThoughtRecordItemType[];
  meditationList?: MeditationItemType[];
  initiallyOpen?: boolean;
}) {
  const [isOpen, setIsOpen] = useRecoilState(menuSelector(menuKey));
  const patientState = useRecoilValue(patientAtom);
  const patientId = patientState?.patientId || "";

  useEffect(() => {
    if (initiallyOpen) {
      setIsOpen(true);
    }
  }, [initiallyOpen]);

  return (
    <Box>
      <Stack
        direction="row"
        spacing="8px"
        alignItems="center"
        onClick={() => {
          setIsOpen((isOpen) => !isOpen);
        }}
        sx={{ py: "8px", cursor: "pointer" }}
      >
        <Box
          sx={{
            ...(!isOpen && {
              transform: "rotate(180deg)",
              transformOrigin: "center",
            }),
            transition: "transform 0.2s",
          }}
        >
          <UpArrowSvg />
        </Box>
        <Typography sx={{ fontSize: "14px", opacity: 0.6 }}>{title}</Typography>
      </Stack>

      {isOpen && (
        <Stack sx={{ pl: "15px" }} spacing={"4px"}>
          {taskList ? (
            taskList.map(
              ({
                taskId,
                taskKey,
                isOpen,
                isDone,
                isLocked,
                hasMissingValue,
              }) => (
                <TaskItem
                  key={taskKey}
                  taskKey={taskKey}
                  isOpen={isOpen}
                  isDone={isDone}
                  isLocked={isLocked}
                  hasMissingValue={hasMissingValue}
                  isPreviousTaskUndone={
                    freeTaskList.includes(taskId) &&
                    taskId !== onboardingTaskId &&
                    taskList.find(
                      (element) => element.taskId === onboardingTaskId
                    ) &&
                    !taskList.find(
                      (element) => element.taskId === onboardingTaskId
                    )?.isDone
                  }
                />
              )
            )
          ) : thoughtRecordList ? (
            thoughtRecordList.map(
              ({ thoughtRecordKey, title, date, isOpen, isDone }, index) => (
                <ToolItem
                  key={`thoughtRecord_${index}`}
                  thoughtRecordKey={thoughtRecordKey}
                  title={title}
                  date={date}
                  isOpen={isOpen}
                  isDone={isDone}
                />
              )
            )
          ) : meditationList ? (
            meditationList.map(
              (
                {
                  meditationRecordKey,
                  title,
                  date,
                  durationMinutes,
                  isOpen,
                  isDone,
                  isOldVersion,
                },
                index
              ) => (
                <ToolItem
                  key={`meditationRecord_${index}`}
                  meditationRecordKey={meditationRecordKey}
                  title={title}
                  date={date}
                  durationMinutes={durationMinutes}
                  isOpen={isOpen}
                  isDone={isDone}
                  isOldVersion={isOldVersion}
                />
              )
            )
          ) : (
            <></>
          )}
        </Stack>
      )}
    </Box>
  );
}
