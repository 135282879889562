import dayjs from "dayjs";
import "dayjs/locale/ko";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import {
  SingleSelectionType,
  TextareaType,
  SelfCheckType,
  CellType,
  ElementType,
  DataMappingType,
  AudioType,
  PresetType,
  AiCommentType,
  MultipleSelectionType,
  ContentType,
  PushNotificationType,
  IsolationType,
} from "../data/CellComponent";
import * as _ from "lodash";
import { UserType } from "recoil/userAtom";
import {
  AiCommentWithTextareaInput,
  ProgramContentType,
} from "data/BlockComponent";
import {
  TaskMetaData,
  ProgramProgressData,
  ProgramType,
} from "data/programData";
import axios from "../utils/axios";
import { KeyPrefix, Namespace, TFunction } from "i18next";
import { FallbackNs } from "react-i18next";
import {
  automaticThoughtIdType,
  AutomaticThoughtType,
  ConceptNoteType,
  ConceptType,
} from "api/conceptApi";
import { CommentType } from "api/aiApi";
import { TempTaskDataType } from "recoil/taskDataAtom";

// 바로 다음블록 열어야하는 경우
export function hasBlockUserField(data: ProgramContentType) {
  const elements = data.lines.flat();

  return elements.some(
    (each) =>
      [
        "textarea",
        "select",
        "buttongroup",
        "selfCheck",
        "audio",
        "feedback",
      ].includes(each.type) && !each.content.coach
  );
}

export function isBlockUserFieldFilled(
  data: ProgramContentType,
  totalData?: ProgramContentType[],
  user?: UserType
) {
  const elements = data.lines
    .filter((line) =>
      !totalData ? true : !isLineHidden(totalData, data, line)
    )
    .flat()
    .filter((element) => !element.content.coach && !element.content.optional);

  const isPercentageCorrect = elements
    .filter((element) => element.content?.percentageIdList)
    .every(
      (each) =>
        getPercentageSum(
          totalData || [],
          each.content?.percentageIdList || []
        ) === 100
    );

  const isQuizCorrect = elements
    .filter((element) => element.content?.answerList)
    .every((each) => ifQuizAnswerCorrect(data, each));

  const isAllAudioEnded = elements
    .filter(
      (element) =>
        element.type === "audio" && (element.content as AudioType)?.audioUrl
    )
    .every((each) => (each.content as AudioType).isEnded);

  const isAllPercentageInputFilled = elements
    .filter((element) => element.type === "percentage")
    .every((each) => (each.content as SingleSelectionType)?.value);

  const isAllTextareaFilled = elements
    .filter((element) => element.type === "textarea")
    .every(
      (each) => ((each.content as TextareaType)?.value || "").trim().length > 0
    );

  const isAllSelectFilled = elements
    .filter((element) => element.type === "select")
    .every((each) => (each.content as SingleSelectionType)?.value);

  const isAllButtonGroupClicked = elements
    .filter((element) => element.type === "buttongroup")
    .every((each) => (each.content as SingleSelectionType)?.value);

  const isAllButtonGroupMultipleChoiceClicked = elements
    .filter((element) => element.type === "buttongroup_multipleChoice")
    .every(
      (each) =>
        ((each.content as MultipleSelectionType)?.selectedIndices || [])
          .length > 0 &&
        (!(each.content as MultipleSelectionType)?.requiredSelectionCount ||
          ((each.content as MultipleSelectionType)?.selectedIndices || [])
            .length ===
            (each.content as MultipleSelectionType)?.requiredSelectionCount)
    );

  const isAllRadioSelected = elements
    .filter((element) => element.type === "selfCheck")
    .every(
      (each) => (each.content as SelfCheckType)?.selectedScore !== undefined
    );
  const loginButtonCell = elements.find((element) => element.type === "login");
  const isLoginFilled = !loginButtonCell || !!user?.accessToken;

  const pushNotificationButtonCell = elements.find(
    (element) => element.type === "pushNotification"
  );
  const isPushNotificationFilled =
    !pushNotificationButtonCell ||
    (pushNotificationButtonCell.content as PushNotificationType).clicked;

  const feedback = elements
    .find((element) => element.type === "feedback")
    ?.content.value?.trim();
  const isFeedbackSent = !feedback || feedback.length === 0;

  const hasAiTypography = elements.find(
    (element) => element.type === "aiTypography"
  );
  const aiTypography = elements
    .find((element) => element.type === "aiTypography")
    ?.content.value?.trim();
  const aiTypographySet = !hasAiTypography || (aiTypography || "").length > 0;

  const hasIsolation = elements.find((element) => element.type === "isolation");
  const isolationData = (
    elements.find((element) => element.type === "isolation")
      ?.content as IsolationType
  )?.isolatedData;
  const isolationDataSet = !hasIsolation || isolationData;

  return (
    isLoginFilled &&
    isPushNotificationFilled &&
    isPercentageCorrect &&
    isQuizCorrect &&
    isAllAudioEnded &&
    isAllPercentageInputFilled &&
    isAllTextareaFilled &&
    isAllSelectFilled &&
    isAllButtonGroupClicked &&
    isAllButtonGroupMultipleChoiceClicked &&
    isAllRadioSelected &&
    isFeedbackSent &&
    aiTypographySet &&
    isolationDataSet
  );
}

export function isAllUserFieldFilled(
  data?: ProgramContentType[],
  exceptComment?: boolean,
  user?: UserType
) {
  if (data && data.length > 0) {
    const reversedData = _.cloneDeep(data)?.reverse();
    const reversed = reversedData.findIndex((element) => element.isShown);
    const lastIndex = data.length - 1 - reversed;

    const isTempDone =
      (lastIndex < data.length &&
        data
          .slice(0, lastIndex + 1)
          .some(
            (element) => element.isShown && !element.isHidden && element.isEnd
          )) ||
      false;

    const isLastShown =
      (!data[data.length - 1].isHidden && data[data.length - 1].isShown) ||
      false;

    const lines = data
      .filter((element) => (exceptComment ? !element.isHighlight : true))
      .filter((element) =>
        isTempDone ? element.isShown && !element.isHidden : true
      )
      .slice(0, isTempDone ? lastIndex + 1 : data.length)
      .filter((element) =>
        isLastShown
          ? !element.isAlwaysHidden && element.isShown && !element.isHidden
          : true
      );

    const isAllBlockFieldFilled = lines.every((each) =>
      isBlockUserFieldFilled(each, data, user)
    );
    // console.log(
    //   lines.filter((element) => !isBlockUserFieldFilled(element, data, user))
    // );
    return (isLastShown || isTempDone) && isAllBlockFieldFilled;
  }
  return false;
}

export function isBlockCoachFieldFilled(data: ProgramContentType) {
  const elements = data.lines.flat();
  const coachInputs = elements.filter(
    (element, index) => (element.content as ElementType)?.coach
  );

  const isBlockCoachFieldFilled = coachInputs
    .filter((element) => !element.content?.optional)
    .every(
      (each) => ((each.content as ElementType)?.value || "").trim().length > 0
    );

  return data.hideIfCoach || data.isHidden || isBlockCoachFieldFilled;
}

export function isAllCoachFieldFilled(data: ProgramContentType[]) {
  const isAllCoachTextareaFilled = data.every((each) =>
    isBlockCoachFieldFilled(each)
  );
  return isAllCoachTextareaFilled;
}

function hasMissingValue(data: ProgramType) {
  const content = data.content;

  const elements = content
    .filter((element) => !element.hideIfCoach && !element.isHidden)
    .map((each) => each.lines.flat())
    .flat();
  const coachInputs = elements.filter(
    (element) => (element.content as ElementType)?.coach
  );

  const isAllCoachFieldFilled = coachInputs
    .filter((element) => !element.content?.optional)
    .every((each) => ((each.content as ElementType)?.value || "").length > 0);

  return !isAllCoachFieldFilled;
}

export function getReferenceBlock(data: ProgramContentType[], blockId: string) {
  const referenceBlock = data.find(
    (element) => (element as ProgramContentType)?.blockId === blockId
  );
  return referenceBlock;
}

export function getReferenceBlockWithCellId(
  data: ProgramContentType[],
  cellId: string
) {
  const referenceBlock = data.find(
    (element) =>
      (element as ProgramContentType)?.lines
        .flat(2)
        .findIndex((element) => (element as CellType)?.content.id === cellId) >
      -1
  );
  return referenceBlock;
}

export function getReferenceCell(data: ProgramContentType[], id: string) {
  const cellList: CellType[][][] = data
    ?.filter((element) => element.isShown)
    ?.map((each) => each.lines) as CellType[][][];

  const filteredArray = cellList
    .flat(2)
    .filter((element) => (element as CellType)?.content.id === id);
  // const referenceData = cellList
  //   .flat(2)
  //   .find((element) => (element as CellType)?.content.id === id);
  const referenceData = filteredArray
    ? filteredArray[filteredArray.length - 1]
    : undefined;
  return referenceData;
}

export function setReferenceCellAnswer(
  data: ProgramContentType[],
  quizId: string,
  answerIndex: number
) {
  const cellList: CellType[][][] = data?.map(
    (each) => each.lines
  ) as CellType[][][];

  cellList
    .flat(2)
    .find((element) =>
      (element as CellType)?.content.answerList
        ?.map((each) => each.id)
        .includes(quizId)
    )
    ?.content.answerList?.forEach((each) => {
      if (each.id === quizId) {
        each.answerIndex = answerIndex;
      }
    });
}

export function getReferenceData(data: ProgramContentType[], id: string) {
  return getReferenceCell(data, id)?.content;
}

export const getTextMappingFromDataMapping = async ({
  taskKey,
  data,
  dataMapping,
  isCoach,
  accessToken,
  pid,
  t,
  taskDataState,
  setTaskDataState,
}: {
  taskKey: string;
  data: ProgramContentType[];
  dataMapping: DataMappingType;
  isCoach: boolean;
  accessToken: string;
  pid: string;
  t: TFunction<Namespace, KeyPrefix<FallbackNs<Namespace>>>;
  taskDataState: TempTaskDataType;
  setTaskDataState: React.Dispatch<React.SetStateAction<TempTaskDataType>>;
}) => {
  const textMapping: { [key: string]: string } = {};
  for (const key of Object.keys(dataMapping)) {
    let currentData = data;
    if (dataMapping[key].taskId) {
      let taskId = dataMapping[key].taskId;

      if (taskId?.startsWith("4-2-A_")) {
        //영향정도
        const automaticThoughtId = taskId.split("_")[1];
        try {
          const response = await axios(accessToken).get("/dashboard/detail", {
            params: { pid: `${pid}` },
          });

          if (response.data.code === 0) {
            const data = response.data;
            const thoughtTrapList = data.result.concept[
              `automaticThought${automaticThoughtId}`
            ].thoughtTrapList as string[];
            const currentIndex = (
              data.result.concept[`automaticThought${automaticThoughtId}`]
                .thoughtTrapList as string[]
            ).findIndex((element) => element === taskKey);
            const previousThoughtTrapList =
              currentIndex === -1
                ? thoughtTrapList
                : thoughtTrapList.slice(0, currentIndex);

            if (previousThoughtTrapList && previousThoughtTrapList.length > 0) {
              taskId =
                previousThoughtTrapList[
                  previousThoughtTrapList.length - 1
                ].split(":")[0];
            }
          } else {
          }
        } catch (error: any) {}
      }

      try {
        if (taskDataState[`${taskId}:${pid}`]) {
          //taskDataState에 한 번 저장한 후
          currentData = taskDataState[`${taskId}:${pid}`];
        } else {
          //처음
          const response = await axios(accessToken).get("/task/data/load", {
            params: { tk: `${taskId}:${pid}` },
          });

          if (response.data.code === 0) {
            const data = response.data;
            currentData = data.result.data.content;
            setTaskDataState((state) => ({
              ...state,
              [`${taskId}:${pid}`]: currentData,
            }));
          }
        }
      } catch (error: any) {}
    }
    if (dataMapping[key].value) {
      textMapping[key] = dataMapping[key].value || "";
    } else if (dataMapping[key].id !== undefined) {
      const referenceCell = getReferenceCell(
        currentData,
        dataMapping[key].id as string
      );
      const referenceData = referenceCell?.content;

      if (referenceData) {
        if (
          referenceCell?.type === "select" ||
          referenceCell?.type === "buttongroup" ||
          (referenceCell?.type === "preset" &&
            (referenceCell.content as PresetType).presetKey ===
              "statementSubject")
        ) {
          const options = (referenceData as SingleSelectionType).options || [];
          const selectedIndex = (referenceData as SingleSelectionType)
            .selectedIndex;
          if (selectedIndex !== undefined) {
            textMapping[key] = options[selectedIndex].translationKey
              ? t(
                  (dataMapping[key].additional
                    ? options[selectedIndex].translationKey_additional
                    : options[selectedIndex].translationKey) || ""
                )
              : referenceData?.value ||
                (dataMapping[key].taskId
                  ? `<${getTaskTitleFromTaskId(
                      dataMapping[key].taskId || ""
                    )}>를 먼저 진행해주세요`
                  : "");
          } else {
            textMapping[key] =
              referenceData?.value ||
              (referenceData?.translationKey
                ? t(referenceData?.translationKey || "")
                : "");
          }
        } else if (
          referenceCell?.type === "buttongroup_multipleChoice" &&
          dataMapping[key].index !== undefined
        ) {
          textMapping[key] =
            referenceCell.content.value?.split("&&")[
              dataMapping[key].index || 0
            ] || "";
        } else if (
          referenceCell?.type === "temp" &&
          referenceCell.content.translationKey
        ) {
          textMapping[key] = t(referenceCell.content.translationKey);
        } else if (referenceCell?.type === "typographyFromData") {
          textMapping[key] = referenceCell.content.value || "";
        } else {
          textMapping[key] =
            (isCoach &&
            ["textarea", "preset"].includes(referenceCell?.type || "")
              ? convertHtmlStringToPlainString(
                  (referenceData as TextareaType)?.htmlString || ""
                ).trim()
              : referenceData?.translationKey
              ? t(referenceData?.translationKey)
              : (referenceData?.value || "").trim()) ||
            (dataMapping[key].taskId
              ? `<${getTaskTitleFromTaskId(
                  dataMapping[key].taskId || ""
                )}>를 먼저 진행해주세요`
              : "");
        }
      }
    }
  }

  return textMapping;
};

export function isLineHidden(
  data: ProgramContentType[],
  blockData: ProgramContentType,
  line: CellType[]
) {
  return line.every(
    (each) =>
      each.type === "temp" ||
      (each.content?.requiredId &&
        getReferenceData(data, each.content?.requiredId)?.optional &&
        (getReferenceData(data, each.content?.requiredId)?.value || "").trim()
          .length === 0) ||
      (each.content?.percentageIdList &&
        getPercentageSum(data, each.content?.percentageIdList) === 100) ||
      (each.content?.answerList && !ifIncorrectTextShow(blockData, each))
  );
}
// export function ifIncorrectTextShow(data: ProgramContentType, cell: CellType) {
//   return (
//     cell.content?.answerList?.every(
//       (each) => getReferenceData([data], each.id)?.value
//     ) && !ifQuizAnswerCorrect(data, cell)
//   );
// }

// export function ifQuizAnswerCorrect(data: ProgramContentType, cell: CellType) {
//   return cell.content?.answerList?.every(
//     (each) => getReferenceData([data], each.id)?.value === each.value
//   );
// }
export function ifIncorrectTextShow(data: ProgramContentType, cell: CellType) {
  return (
    cell.content?.answerList?.every(
      (each) =>
        (getReferenceData([data], each.id) as SingleSelectionType)
          ?.selectedIndex !== undefined
    ) && !ifQuizAnswerCorrect(data, cell)
  );
}

export function ifQuizAnswerCorrect(data: ProgramContentType, cell: CellType) {
  return cell.content?.answerList?.every((each) =>
    each.value
      ? (getReferenceData([data], each.id) as SingleSelectionType)?.value ===
        each.value
      : (getReferenceData([data], each.id) as SingleSelectionType)
          ?.selectedIndex === each.answerIndex
  );
}

export function getPercentageSum(
  data: ProgramContentType[],
  percentageIdList: string[]
) {
  return arraySum(
    percentageIdList?.map(
      (each) => parseInt(getReferenceData(data, each)?.value || "") || 0
    ) || []
  );
}

export function arraySum(arr: number[]) {
  return arr.reduce(function add(sum, currValue) {
    return sum + currValue;
  }, 0);
}

function getSelfCheckScoreById(selfCheckId: string, data?: SelfCheckType[]) {
  return arraySum(
    data
      ?.filter((element) => element.selfCheckId === selfCheckId)
      .map((each) => each.selectedScore || 0) || [0]
  );
}

export function getSelfCheckScore(data?: ProgramContentType[]) {
  const elements = data?.map((each) => each.lines.flat()).flat();
  const radioInputs = elements
    ?.filter((element) => element.type === "selfCheck")
    .map((each) => each.content as SelfCheckType);

  return {
    depressionScore: getSelfCheckScoreById("depression", radioInputs),
    anxietyScore: getSelfCheckScoreById("anxiety", radioInputs),
    stressScore: getSelfCheckScoreById("stress", radioInputs),
    burnoutScore: getSelfCheckScoreById("burnout", radioInputs),
  };
}

export function getTaskIdFromTaskKey(taskKey: string) {
  return taskKey.split(":")[0];
}

export function getRawTaskTitleFromTaskId(
  taskIdWithAutomaticThoughtId: string
) {
  const taskId = taskIdWithAutomaticThoughtId.split("_")[0].split("#")[0];
  const step = taskIdWithAutomaticThoughtId.split("_")[0].split("#")[1];
  const title =
    TaskMetaData.find((element) => element.taskId === taskId)?.title || "";
  return title.includes("n") && false ? title.replace("n", step) : title;
}
export function getTaskTitleFromTaskId(taskKey: string) {
  const taskId = getTaskIdFromTaskKey(taskKey);

  const N = taskId.split("#")[1] as automaticThoughtIdType;

  const title =
    taskKey === "dashboard"
      ? "대시보드"
      : taskKey === "thoughtmap"
      ? "생각 지도"
      : taskKey === "valueCompass"
      ? "가치 나침반"
      : taskKey === "progress"
      ? "진행 상황"
      : taskKey === "payment"
      ? "월 이용권"
      : taskKey === "chat"
      ? "채팅"
      : getRawTaskTitleFromTaskId(taskId);
  const postfix = taskId.includes("_")
    ? ` (${taskId.split("_")[1].split("#")[0]})`
    : "";

  return `${(title || "").replace("n", N)}${postfix}`;
}
export function getTimeDifference(completedAt: string) {
  dayjs.extend(relativeTime, {
    thresholds: [
      { l: "s", r: 59, d: "second" },
      { l: "ss", r: 4, d: "minute" },

      { l: "m", r: 9 },
      { l: "mm", r: 59, d: "minute" },

      { l: "h", r: 1 },
      { l: "hh", r: 23, d: "hour" },

      { l: "d", r: 1 },
      { l: "dd", r: 6, d: "day" },

      { l: "w", r: 1 },
      { l: "ww", r: 4, d: "week" },

      { l: "M", r: 1 },
      { l: "MM", r: 11, d: "month" },
      { l: "y" },
      { l: "yy", d: "year" },
    ],
    rounding: Math.floor,
  });

  dayjs.extend(updateLocale);

  dayjs.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "a few seconds",
      ss: "a few seconds",

      m: "a minute",
      mm: "%d minutes",

      h: "an hour",
      hh: "%d hours",

      d: "a day",
      dd: "%d days",

      w: "a week",
      ww: "%d weeks",

      M: "a month",
      MM: "%d months",
      y: "a year",
      yy: "%d years",
    },
  });

  dayjs.updateLocale("ko", {
    relativeTime: {
      future: "%s",
      past: "%s 전",
      s: "방금",
      ss: "방금",

      m: "1분",
      mm: "%d분",

      h: "%d시간",
      hh: "%d시간",

      d: "%d일",
      dd: "%d일",

      w: "%d주",
      ww: "%d주",

      M: "%d개월",
      MM: "%d개월",
      y: "%d년",
      yy: "%d년",
    },
  });
  return dayjs(completedAt).fromNow();
}

export function getRedirectPath(user?: UserType) {
  if (!user || !user?.accessToken) {
    return "/login";
  } else {
    return "/";
  }
}

export function getLastShownIndex(data?: ProgramContentType[]) {
  if (data) {
    const copy = _.cloneDeep(data);
    const reversedData = copy?.reverse();
    const reversed = reversedData.findIndex(
      (element) => element.isShown && !element.isHidden
    );
    const lastIndex = (data.length - 1 - reversed) % data.length;
    return lastIndex;
  } else {
    return 0;
  }
}

export function generateThoughtRecordKey(userId: string) {
  return `${userId}:${Date.now()}`;
}
export function generateActivityRecordKey(userId: string) {
  return `ar:${userId}:${Date.now()}`;
}
export function generateRecordKey(
  userId: string,
  type: "meditation" | "activity"
) {
  return `${
    type === "meditation" ? "mr" : type === "activity" ? "ar" : ""
  }:${userId}:${Date.now()}`;
}

export function getFormettedDate(date: string) {
  return `${dayjs(date).format("MMM D일 ddd요일")}`;
}

export function hasHighlightedBlock(data: ProgramContentType[]) {
  const hasHighlightedBlock = data.some((each) => each.isHighlight);

  return hasHighlightedBlock;
}

export function hasAiRecommendationBlock(data: ProgramContentType[]) {
  const hasAiRecommendation = data.some((element) =>
    element.lines.flat().some((element) => element.type === "aiCoaching")
  );
  return hasAiRecommendation;
}

export function getRemainingTimeInMilliSeconds({
  endAt,
  startedAt,
  durationHour,
}: {
  endAt?: string;
  startedAt?: string;
  durationHour?: number;
}) {
  const endTime = endAt
    ? dayjs(endAt)
    : dayjs(startedAt).add(dayjs.duration({ hours: durationHour }));
  const now = dayjs();
  return endTime.diff(dayjs(now));
}

export function getRemainingTimeInString({
  endAt,
  startedAt,
  durationHour,
}: {
  endAt?: string;
  startedAt?: string;
  durationHour?: number;
}) {
  const duration = dayjs.duration(
    getRemainingTimeInMilliSeconds({
      endAt: endAt,
      startedAt: startedAt,
      durationHour: durationHour,
    })
  );
  return `${
    parseInt(duration.format("D")) > 0 ? `${duration.format("D")}일 ` : ""
  }${parseInt(duration.format("H")) > 0 ? `${duration.format("H")}시간 ` : ""}${
    parseInt(duration.format("m")) > 0 ? `${duration.format("m")}분 ` : ""
  }`;
}

export function getRemainingMilliSecondsWithStartTime(
  startedAt: string,
  durationHour: number
) {
  const endAt = dayjs(startedAt).add(dayjs.duration({ hours: durationHour }));
  const now = dayjs();
  return endAt.diff(dayjs(now));
}

export function getRemainingTimeWithStartTime(
  startedAt: string,
  durationHour: number
) {
  const duration = dayjs.duration(
    getRemainingMilliSecondsWithStartTime(startedAt, durationHour)
  );
  return `${
    parseInt(duration.format("H")) > 0 ? `${duration.format("H")}시간 ` : ""
  }${
    parseInt(duration.format("m")) > 0 ? `${duration.format("m")}분 ` : ""
  }${duration.format("s")}초`;
}

export function parsePaymentAmount(num: number) {
  let arr = [];
  const string = num.toString();

  for (let i = 0; i <= string.length / 3; i++) {
    arr.unshift(string.slice(-3 * (i + 1), i === 0 ? undefined : -3 * i));
  }
  return arr.filter((element) => element.length > 0).join(",");
}

export function getModuleNumber(taskId: string) {
  return ProgramProgressData.find((element) =>
    element.taskIdPrefixList.includes(getTaskIdPrefix(taskId))
  )?.moduleNumber;
}

export function getTaskIdPrefix(taskId: string) {
  return taskId.split("-").splice(0, 2).join("-");
}

export function getLanguageCode(locale: string) {
  return locale.split("-")[0];
}

export function findSubstringIndices(mainString: string, subString: string) {
  const startIndex = mainString.indexOf(subString);

  if (startIndex !== -1) {
    const endIndex = startIndex + subString.length - 1;
    return { startIndex, endIndex };
  } else {
    // 부분 문자열이 포함되어 있지 않은 경우
    return { startIndex: -1, endIndex: -1 };
  }
}

function convertHtmlStringToPlainString(html: string) {
  // Create a new div element
  var tempDivElement = document.createElement("div");

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;

  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || "";
}

export function getHighlightedBlockNumber(data: ProgramContentType[]) {
  return data.filter((element) => element.isHighlight).length;
}

export function getAutomaticThoughtId(taskId: string) {
  const automaticThoughtId = taskId.includes("_")
    ? taskId.split("_")[1]
    : undefined;
  return automaticThoughtId;
}

export function isNegativeAutomaticThought(id: string) {
  return (
    id.startsWith("automaticThought") &&
    !id.startsWith("automaticThoughtForValue")
  );
}
export function isPositiveAutomaticThought(id: string) {
  return id.startsWith("automaticThoughtForValue");
}

export function getAutomaticThoughtIdList(data: ConceptType | ConceptNoteType) {
  return Object.keys(data)
    .filter((element) => isNegativeAutomaticThought(element))
    .map((each) => each.split("automaticThought")[1]);
}
export function getAutomaticThoughtForValueIdList(
  data: ConceptType | ConceptNoteType
) {
  return Object.keys(data)
    .filter((element) => isPositiveAutomaticThought(element))
    .map((each) => each.split("automaticThoughtForValue")[1]);
}

export function automaticThoughtIdListWithBlank(
  data: ConceptType | ConceptNoteType
) {
  const filledAutomaticThoughtIdList = getAutomaticThoughtIdList(data);
  const lastFilledAutomaticThoughtId =
    filledAutomaticThoughtIdList.length > 0
      ? +filledAutomaticThoughtIdList[filledAutomaticThoughtIdList.length - 1]
      : 0;
  return filledAutomaticThoughtIdList.concat([
    (lastFilledAutomaticThoughtId + 1).toString(),
  ]);
}

export function getExtraAutomaticThoughtKey(
  data: ConceptType | ConceptNoteType
) {
  const filledAutomaticThoughtIdList = getAutomaticThoughtIdList(data);
  const lastFilledAutomaticThoughtId =
    filledAutomaticThoughtIdList.length > 0
      ? +filledAutomaticThoughtIdList[filledAutomaticThoughtIdList.length - 1]
      : 0;

  return `automaticThought${lastFilledAutomaticThoughtId + 1}`;
}

export function hasAiComment(data: ProgramContentType) {
  const elements = data.lines.flat();
  const coachInputs = elements.filter(
    (element) => (element.content as ElementType)?.coach
  );

  const hasAiComment = coachInputs.some(
    (each) => (each.content as AiCommentType)?.aiCommentKey
  );

  return hasAiComment;
}

export function getBlockIndexWithEditorKey(
  data: ProgramContentType[],
  editorKey: string
) {
  const index = data.findIndex((element) =>
    (element as ProgramContentType)?.lines
      .flat()
      .find((each) => each.content.editorKey === editorKey)
  );
  return index;
}

export function hasAiCommentKey(
  data: ProgramContentType[],
  aiCommentKey: string
) {
  const cellList: CellType[][][] = data?.map(
    (each) => each.lines
  ) as CellType[][][];

  const referenceData = cellList
    .flat(2)
    .find(
      (element) =>
        ((element as CellType)?.content as AiCommentType).aiCommentKey ===
        aiCommentKey
    );
  return !!referenceData;
}

export function getAiCommentKeyInBlock(blockData: ProgramContentType) {
  const aiCommentKey = (
    blockData.lines.flat().find((element) => element.type === "aiComment")
      ?.content as AiCommentType
  )?.aiCommentKey;
  return aiCommentKey;
}

export function addAiComment(
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >,
  aiCommentList?: CommentType[]
) {
  if ((aiCommentList || []).length > 0) {
    console.log("[COMMENT LIST]", aiCommentList);

    if (aiCommentList && aiCommentList?.length > 0) {
      setData((data) => {
        if (data) {
          const data_temp = _.cloneDeep(data);

          aiCommentList.forEach((each, i) => {
            if (getBlockIndexWithEditorKey(data_temp, each.editorKey) > -1) {
              data_temp.splice(
                getBlockIndexWithEditorKey(data_temp, each.editorKey) + 1,
                0,
                ...each.commentList
                  .filter(
                    ({ aiCommentKey }) =>
                      !hasAiCommentKey(data_temp, aiCommentKey)
                  )
                  .map(({ aiCommentKey, comment }) =>
                    AiCommentWithTextareaInput(aiCommentKey, comment, {
                      isHighlight: true,
                    })
                  )
              );
            }
          });

          return data_temp;
        }
        return data;
      });
    }
  } else {
    console.log("AI COMMENT 없음");
  }
}

export function setProgramContentData({
  setData,
  blockIndex,
  lineIndex,
  cellIndex,
  newlyAddedData,
}: {
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  blockIndex: number;
  lineIndex: number;
  cellIndex: number;
  newlyAddedData: any;
}) {
  setData((data) => {
    if (
      data &&
      data[blockIndex] &&
      data[blockIndex].lines &&
      data[blockIndex].lines[lineIndex] &&
      data[blockIndex].lines[lineIndex][cellIndex]
    ) {
      const data_temp = _.cloneDeep(data);
      const currentContent =
        data_temp[blockIndex].lines[lineIndex][cellIndex].content;

      data_temp[blockIndex].lines[lineIndex][cellIndex].content = {
        ...currentContent,
        ...newlyAddedData,
      };

      return data_temp;
    }
    return data;
  });
}

export function getFirstBehaviorExperimentAutomaticId(
  conceptNote?: ConceptType
) {
  const automaticThoughtKey = (Object.entries(conceptNote || {}).find(
    ([key, value]) =>
      key.startsWith("automaticThought") &&
      (value as AutomaticThoughtType).thoughtList
        ?.map((each) => each.thoughtTrap)
        .some((thoughtTrap) =>
          ["책임 과다", "근거 부족", "부정 편향"].includes(thoughtTrap)
        )
  ) || [])[0];
  return (automaticThoughtKey?.split("automaticThought")[1] ||
    "1") as automaticThoughtIdType;
}

export function removeDuplicate<T>(array?: T[]): T[] {
  if (!array) return [];
  return array
    .reduce<T[]>((acc, current) => {
      if (!acc.includes(current)) {
        acc.push(current);
      }
      return acc;
    }, [])
    .filter((element) => !!element);
}
