import { ButtonGroup, Percentage, ReferenceLine } from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithTextarea,
} from "../BlockComponent";

import { ConceptNoteTemplate, LastCard } from "./common";

export const Content_6_6_A = [
  Instruction(`typography_0_0_0`),
  InstructionWithContent(`typography_1_0_0`, [
    ReferenceLine({ referenceKey: "coreBeliefReferenceList" }),
  ]),
  Header1(`typography_2_0_0`),
  InstructionWithContent(
    `typography_3_0_0`,
    // CoreBeliefTemplate({ isRaw: true, coreBeliefKeyList: coreBeliefKeyList })
    ConceptNoteTemplate({ conceptKey: "coreBelief" })
  ),
  InstructionWithContent(`typography_4_0_0`, [
    [
      ButtonGroup([
        {
          translationKey: "네",
          showFromId: "동의",
          hideFromId: "비동의",
        },
        {
          translationKey: "아니요",
          showFromId: "비동의",
          hideFromId: "동의",
        },
      ]),
    ],
  ]),

  InstructionWithTextarea("typography_5_0_0", undefined, {
    blockId: "비동의",
    // hideIfCoach: true,
  }),
  Instruction("typography_6_0_0", {
    noArrow: true,
    isEnd: true,
    // hideIfCoach: true,
  }),

  Instruction(`typography_7_0_0`, { blockId: "동의" }),

  Instruction(`typography_8_0_0`),
  InstructionWithTextarea(`typography_9_0_0`),
  InstructionWithContent("typography_9_0_0_1", [
    [Percentage({ id: "영향받는 정도_coreBelief" })],
  ]),
  InstructionWithTextarea(`typography_10_0_0`),
  // InstructionWithContent(`typography_11_0_0`, [
  //   [
  //     ButtonGroup([
  //       {
  //         translationKey: "네",
  //         showFromId: "걱정 있음",
  //         hideFromId: "걱정 없음",
  //       },
  //       {
  //         translationKey: "아니요",
  //         showFromId: "걱정 없음",
  //         hideFromId: "걱정 있음",
  //       },
  //     ]),
  //   ],
  // ]),
  // InstructionWithTextarea("typography_11_0_0_1", undefined, {
  //   blockId: "걱정 있음",
  // }),
  Instruction(`typography_12_0_0`, { blockId: "걱정 없음" }),

  ...LastCard,
];
