export const translation_6_3_A = {
  en: {
    v1: {
      네: "Yes",
      아니요: "No",
      typography_0_0_0: `Do you remember how we call rules, attitudes, and assumptions made to handle the threats from our core beliefs "intermediate beliefs"?`,
      typography_1_0_0: "We shared the following in our last activity:",
      typography_2_0_0: "Summarizing",
      typography_3_0_0:
        "Based on the above, our intermediate beliefs might look something like this.",
      typography_4_0_0:
        "How does that sound? Do you think you hold such intermediate beliefs?",
      typography_5_0_0: "Where do you feel this isn't quite right?",
      typography_6_0_0: `Okay, let's stop here for today. I'll review and get back to you soon.`,
      typography_7_0_0:
        "Fantastic. We've identified your intermediate beliefs.",
      typography_8_0_0:
        "You can check out how the thought map is coming together in <My Thought Map>.",
      typography_9_0_0:
        "Do these intermediate beliefs seem to frequently influence your daily life?",
      typography_10_0_0: "How much impact is this thought having on you?",
      typography_11_0_0:
        "Would you like to add anything more about this thought? If there's more you want to explain or add, feel free to share.",
      typography_12_0_0: `We'll be looking more into the thought mentioned above. Any worries about that?`,
      typography_12_0_0_1: `What's on your mind?`,
      typography_13_0_0:
        "Great. Based on our conversation today, we'll tailor the program moving forward. Thanks for your effort today.",
    },
  },
  ko: {
    v1: {
      네: "네",
      아니요: "아니요",
      typography_0_0_0:
        "핵심 믿음의 위협에 대처하기 위해 만든 규칙, 태도, 가정을 '중간 믿음'이라고 부른다는 것, 기억하시나요?",
      typography_1_0_0: "지난 활동에서 다음과 같은 이야기를 나누었어요.",
      typography_2_0_0: "정리하기",
      typography_3_0_0:
        "위의 내용을 토대로 중간 믿음을 정리해보면 다음과 같을 거예요.",
      typography_4_0_0:
        "어떤가요? 나는 위와 같은 중간 믿음을 가지고 있는 것 같나요?",
      typography_5_0_0: "어떤 부분에서 그렇지 않다고 생각하시나요?",
      typography_6_0_0: `좋아요, 그럼 오늘은 여기까지 하도록 해요. 제가 확인 후 다시 말씀드릴게요.`,
      typography_7_0_0: "좋아요. 이제 중간 믿음을 찾았네요.",
      typography_8_0_0:
        "그려지고 있는 생각 지도는 <생각 지도>에서 확인할 수 있답니다.",
      typography_9_0_0:
        "위와 같은 중간 믿음은 나의 일상에서 자주 영향을 주는 것 같나요?",
      typography_10_0_0: "이 생각은 나에게 얼마나 영향을 주고 있나요?",
      typography_11_0_0:
        "위 생각에 대해 더 말씀해주실 부분이 있을까요? 추가로 설명하고 싶거나 보완하고 싶은 부분이 있다면 공유해주세요.",
      typography_12_0_0: `앞으로 위 생각을 조금 더 살펴볼 건데요. 혹시 걱정되는 부분이 있을까요?`,
      typography_12_0_0_1: `어떤 점이 걱정되시나요?`,
      typography_13_0_0:
        "좋아요. 그러면 오늘 이야기한 것에 맞춰 앞으로 프로그램을 진행해볼게요. 오늘도 고생 많았어요.",
    },
  },
};
