import TaskHeader from "components/task/header/TaskHeader";
import { Box } from "@mui/joy";
import MyThoughtMapContent from "./content/MyThoughtMapContent2";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";
import PaymentModule from "components/sidebar/PaymentModule2";

export default function MyThoughtMap() {
  const userState = useRecoilValue(userAtom);
  const isPatient = userState?.accessToken && userState?.role === "patient";
  const { isSubscribing, isNotStarted } = useSubscriptionStatus();

  const showPaymentModule = isPatient && !isSubscribing;
  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 100,
        }}
      >
        <TaskHeader taskKey={"thoughtmap"} />
        {showPaymentModule && (
          <PaymentModule
            {...(isNotStarted && {
              title: "🤔 괴로운 생각은 어디서 비롯될까요?",
              subtitle: "스스로를 깊게 이해해 보세요.",
            })}
            type="header"
          />
        )}
      </Box>

      <div className="w-full h-full overflow-x-hidden">
        <MyThoughtMapContent />
      </div>
    </>
  );
}
