import {
  ProgramContentType,
  CommentWithTextareaInput,
} from "data/BlockComponent";
import * as _ from "lodash";
import { Stack, IconButton } from "@mui/joy";
import React, { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import ButtonWithModal from "components/common/ButtonWithModal";
import { useMutation } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { aiApi } from "api/aiApi";

function CoachMenuBar({
  taskKey,
  thoughtRecordKey,
  setData,
  index,
  isThoughtRecord,
  hasIndentation,
  isRemovable,
  aiCommentKey,
}: {
  taskKey: string;
  thoughtRecordKey?: string;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  index: number;
  isThoughtRecord: boolean;
  hasIndentation?: boolean;
  isRemovable?: boolean;
  aiCommentKey?: string;
}) {
  const patientState = useRecoilValue(patientAtom);
  const addComment = () => {
    setData((data) => {
      if (data) {
        const data_temp = _.cloneDeep(data);
        const data_temp2 = _.cloneDeep(data);
        if (isThoughtRecord) {
          const currentData = data_temp2
            .slice(0, index + 1)
            .reverse()
            .find((element) => !element.indentation);
          const currentDataIndex = data_temp.findIndex((element) =>
            _.isEqual(element, currentData)
          );
          const id = currentData?.lines
            .flat()
            .find((element) => element?.content?.id)?.content?.id;
          const questionId = index - currentDataIndex;
          const uuid = uuidv4().split("-")[0];

          const nextOpenBlockIndex =
            Math.max(
              data_temp
                .slice(index + 1)
                .findIndex((element) => element.isShown && !element.isHidden),
              0
            ) +
            index +
            1;

          data_temp.splice(
            nextOpenBlockIndex,
            0,
            CommentWithTextareaInput(
              undefined,
              `${id}_question_${uuid}`,
              `${id}_answer_${uuid}`,
              {
                isHighlight: data_temp[index].isShown,
                // blockId: data_temp[nextOpenBlockIndex].blockId,
                isShown: data_temp[index].isShown,
              }
            )
          );
        } else {
          const nextOpenBlockIndex =
            Math.max(
              data_temp
                .slice(index + 1)
                .findIndex((element) => element.isShown && !element.isHidden),
              0
            ) +
            index +
            1;
          data_temp.splice(
            nextOpenBlockIndex,
            0,
            CommentWithTextareaInput(undefined, undefined, undefined, {
              isHighlight: data_temp[index].isShown,
              // blockId: data_temp[nextOpenBlockIndex].blockId,
              isShown: data_temp[index].isShown,
            })
          );
        }

        return data_temp;
      }

      return data;
    });
  };

  const removeAiCommentQueryFn = useCallback(
    () =>
      aiApi.deleteComment(
        `${patientState?.patientId}`,
        taskKey,
        {
          aiCommentKey: aiCommentKey || "",
        },
        thoughtRecordKey
      ),
    [patientState?.patientId, taskKey, aiCommentKey, thoughtRecordKey]
  );

  const { mutate: deleteAiComment } = useMutation(removeAiCommentQueryFn, {
    onSuccess: () => {
      removeFromData();
    },
  });

  const removeFromData = () => {
    setData((data) => {
      if (data) {
        const data_temp = _.cloneDeep(data);
        if (data_temp[index].removable) {
          data_temp.splice(index, 1);
          return data_temp;
        }
        return data;
      }
      return data;
    });
  };

  const deleteBlock = () => {
    if (aiCommentKey) {
      deleteAiComment();
    } else {
      removeFromData();
    }
  };
  function MenuButton({
    action,
    icon,
  }: {
    action?: () => void;
    icon: React.ReactNode;
  }) {
    return <IconButton onClick={action}>{icon}</IconButton>;
  }

  return (
    <Stack direction={"row"} justifyContent="end" sx={{ pt: "4px" }}>
      <Stack direction={"row"}>
        <MenuButton
          action={addComment}
          icon={
            hasIndentation ? (
              <span
                style={{ fontSize: "16px" }}
                className="material-symbols-rounded"
              >
                arrow_downward
              </span>
            ) : (
              <span
                style={{ fontSize: "16px" }}
                className="material-symbols-rounded"
              >
                subdirectory_arrow_right
              </span>
            )
          }
        />
      </Stack>
      {isRemovable && (
        // <MenuButton
        //   action={deleteBlock}
        //   icon={
        //     <span
        //       style={{ fontSize: "16px" }}
        //       className="material-symbols-rounded"
        //     >
        //       close
        //     </span>
        //   }
        // />
        <ButtonWithModal
          buttonText={""}
          size="lg"
          title={"정말 삭제할까요?"}
          render={
            <MenuButton
              icon={
                <span
                  style={{ fontSize: "16px" }}
                  className="material-symbols-rounded"
                >
                  close
                </span>
              }
            />
          }
          actionButtonColor={"danger"}
          actionText={"삭제하기"}
          action={deleteBlock}
        />
      )}
    </Stack>
  );
}
export default React.memo(CoachMenuBar);
