import { Box, Stack, Typography } from "@mui/joy";
import { usePrevious } from "@uidotdev/usehooks";
import React, { useEffect, useState } from "react";
import { extractHexColor } from "styles/hexOpacity";
import { theme } from "styles/theme";
import boat from "static/image/graphic/compass_boat.png";

export default function Compass({
  value,
  valueList,
}: {
  value?: string;
  valueList?: string[];
}) {
  const previousValue = usePrevious(value);
  const labels = [
    "부부",
    "건강",
    "사회 기여",
    "종교",
    "여가",
    "배움",
    "경력",
    "연인",
    "친구",
    "부모/자녀",
  ];
  const [rotation, setRotation] = useState(18);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setRotation((prevRotation) => prevRotation + 0.5); // 45도씩 증가
  //   }, 100); // 1초마다
  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    if (value) {
      const index = labels.findIndex((element) => element === value);
      let previousIndex = 0;
      if (previousValue) {
        previousIndex = labels.findIndex(
          (element) => element === previousValue
        );
      }
      const indexDifference = index - Math.max(previousIndex, 0);

      // setRotation((rotation) => rotation - 36); //+:시계방향, -:반시계방향
      setRotation((rotation) =>
        rotation - 36 * indexDifference > 0
          ? rotation - 36 * indexDifference
          : rotation - 36 * indexDifference + 360
      );
    }
  }, [value]);

  const containerRadius = Math.min(240, (window.innerWidth - 40) / 2);
  const containerPadding = 60;
  const containerBorderWidth = 5;
  const radius = containerRadius - containerPadding;

  return (
    <Stack
      justifyContent={"center"}
      alignItems="center"
      sx={{
        position: "relative",
        width: `${containerRadius * 2}px`,
        height: `${containerRadius * 2}px`,
        borderRadius: "50%",
        overflow: "hidden",
        p: `${containerPadding}px`,
      }}
    >
      {/* 호 */}
      {value && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
            background: `conic-gradient(${extractHexColor(
              theme.vars.palette.primary.solid
            )} 0deg 36deg, transparent 36deg 360deg)`,
            transform: "rotate(-18deg)",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: `calc(100% - ${containerBorderWidth * 2}px)`,
              height: `calc(100% - ${containerBorderWidth * 2}px)`,
              borderRadius: "50%",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
              backgroundColor: "white",
            }}
          ></Box>
        </Box>
      )}
      <Box
        sx={{
          position: "relative",
          width: `${radius * 2}px`,
          height: `${radius * 2}px`,
          borderRadius: "50%",
          backgroundColor: "primary.soft",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {Array.from({ length: 4 }, (v, i) => i).map((each) => (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              width: `${20 * (each + 1)}%`,
              height: `${20 * (each + 1)}%`,
              borderRadius: "50%",
              borderWidth: `${Math.min(4 - each, 3)}px`,
              borderColor: "white",
              opacity: 0.4 + 0.2 * (4 - each),
            }}
          />
        ))}
        {value && (
          <img
            src={boat}
            alt={"empty"}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              width: "50%",
              height: "50%",
            }}
          />
        )}

        {labels.map((label, index) => {
          const angle = (360 / labels.length) * (index + 7);
          return (
            <Typography
              key={label}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transformOrigin: "50% 50%",
                transform: `rotate(${angle + rotation}deg) translate(${
                  radius + 30
                }px) rotate(-${angle + rotation}deg) translate(-50%,-50%)`,
                fontSize: "14px",

                ...(valueList?.includes(label)
                  ? { fontWeight: 700, color: "primary.solid" }
                  : { fontWeight: 600, opacity: 0.3 }),
                wordBreak: "keep-all",

                transition: "transform 0.4s",
              }}
            >
              {label}
            </Typography>
          );
        })}
      </Box>
    </Stack>
  );
}
