import { Stack } from "@mui/joy";
import { memo } from "react";
import { useRecoilValue } from "recoil";
import {
  conceptKeyMapping,
  ConceptKeyType,
  CoreBeliefKeyType,
  IntermediateBeliefKeyType,
} from "api/conceptApi";
import * as _ from "lodash";
import { conceptNoteAtom } from "recoil/conceptNoteAtom";
import { ThoughtMapText } from "components/thoughtmap/ThoughtMapText";

function ConceptItemCell({
  taskKey,
  conceptKey,
}: {
  taskKey: string;
  conceptKey: ConceptKeyType;
}) {
  const conceptNoteState = useRecoilValue(conceptNoteAtom);

  return conceptNoteState ? (
    <Stack spacing={1}>
      {Object.entries(conceptNoteState[conceptKey] || {})
        .filter(([key, value]) => (value as string)?.trim().length > 0)
        .map(([key, value]) => (
          <Stack key={`${key}`} spacing={"8px"}>
            <ThoughtMapText
              text={
                conceptKeyMapping[
                  key as CoreBeliefKeyType | IntermediateBeliefKeyType
                ]
              }
              dimmed
              color="primary.solid"
            />
            <ThoughtMapText text={(value as string) || ""} />
          </Stack>
        ))}
    </Stack>
  ) : (
    <></>
  );
}

export default memo(ConceptItemCell);
