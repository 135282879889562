import { Box, useColorScheme } from "@mui/joy";
import { useColorScheme as useMaterialColorTheme } from "@mui/material/styles";
import Activity from "components/task/Activity";
import { useEffect, useRef } from "react";
import logo from "static/logo.png";

export default function MeditationTool() {
  const { setMode } = useColorScheme();
  const { setMode: setMaterialMode } = useMaterialColorTheme();

  useEffect(() => {
    setMode("light");
    setMaterialMode("light");
  }, []);

  useEffect(() => {
    const setThemeColor = (color: string) => {
      let themeColorMetaTag = document.querySelector(
        'meta[name="theme-color"]'
      );
      if (!themeColorMetaTag) {
        themeColorMetaTag = document.createElement("meta");
        themeColorMetaTag.setAttribute("name", "theme-color");
        document.head.appendChild(themeColorMetaTag);
      }
      themeColorMetaTag.setAttribute("content", color);
    };

    // 예제: 컴포넌트가 마운트될 때 노치 색상을 변경
    setThemeColor("#ffffff");

    // 필요한 경우, cleanup 함수를 사용하여 색상을 초기 상태로 되돌릴 수 있음
    return () => setThemeColor("#E2E6E8"); // 기본 색상
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        overflow: "scroll",
        pt: "20px",
        // backgroundColor: "background.level1",
      }}
    >
      <img
        src={logo}
        alt={"logo"}
        className="w-[120px] mb-[30px] mx-auto"
      ></img>
      <Activity taskKey="meditationTool" noHeader />

      {/* <Stack
          spacing={"16px"}
          sx={{
            width: "100%",
            backgroundColor: "#F6F9FA",
            p: "20px",
            borderRadius: "12px",
          }}
        >
          <Typography sx={{ color: "common.black", fontSize: "16px" }}>
            {"지금부터 명상을 진행해 볼게요, 어떤 명상을 진행할까요?"}
          </Typography>
          <Box
            sx={{
              height: "1px",
              width: "100%",
              backgroundColor: "divider",
            }}
          />
          <Stack direction="row" spacing="8px">
            {["생각의 강", "영화관", "하늘 바라보기"].map((each) => (
              <Button
                fullWidth
                size="lg"
                variant={"outlined"}
                color="primary"
                onClick={(e) => {
                  const selectedText = (e?.target as HTMLElement)?.innerText;
                }}
              >
                {each}
              </Button>
            ))}
          </Stack>
        </Stack> */}
    </Box>
  );
}
