import { Instruction, ProgramContentType } from "../BlockComponent";
import { selfCheckTemplate } from "./102-1-A";
import { LastCard } from "./common";

export const Content_102_2_A: ProgramContentType[] = [
  Instruction("typography_0_0_0"),
  Instruction("typography_1_0_0"),

  ...selfCheckTemplate,

  ...LastCard,
];
