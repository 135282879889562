import { IconButton } from "@mui/joy";
import useSidebarToggle from "hooks/useSidebarToggle";

export default function HeaderBackButton() {
  const { openSidebar } = useSidebarToggle({ direction: "left" });

  return window.location.pathname === "/" ? (
    <IconButton onClick={openSidebar} variant="plain" color="neutral" size="sm">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 5.5L5.5 12L12 18.5"
          stroke="#2E2B2B"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </IconButton>
  ) : (
    <></>
  );
}
