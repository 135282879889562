export const translation_0_3_A = {
  en: {
    v1: {
      "매우 아쉬울 것 같아요": "I'd really miss it",
      "조금 아쉬울 것 같아요": "I'd miss it a little",
      "전혀 아쉽지 않을 것 같아요": "I wouldn't miss it",
      "지금은 사용하고 있지 않아 해당 사항이 없어요":
        "I'm not using it right now, so it doesn't matter to me",
      typography_0_0_0:
        "How did this week's program go for you? Let's look back at our week and get ready for the next one.",
      typography_1_0_0:
        "Did something from this week really help you? What was it?",
      typography_2_0_0:
        "Was there something about this week that you didn't like much? What was that?",
      typography_3_0_0:
        "Thinking about next week, is there anything we should focus on more?",
      typography_4_0_0:
        "What if the service stopped today and you couldn't use it from tomorrow? How would you feel?",
      typography_6_0_0:
        "Why not share your thoughts to help others thinking about Distancing?",
      typography_5_0_0: "Okay, let's keep going and do our best next week too!",
    },
  },
  ko: {
    v1: {
      "매우 아쉬울 것 같아요": "매우 아쉬울 것 같아요",
      "조금 아쉬울 것 같아요": "조금 아쉬울 것 같아요",
      "전혀 아쉽지 않을 것 같아요": "전혀 아쉽지 않을 것 같아요",
      "지금은 사용하고 있지 않아 해당 사항이 없어요":
        "지금은 사용하고 있지 않아 해당 사항이 없어요",

      "이번 주 프로그램은 어떠셨나요? 이번 한 주를 돌아보며 다음 주를 준비해 봐요":
        "이번 주 프로그램은 어떠셨나요? 이번 한 주를 돌아보며 다음 주를 준비해 봐요.",
      "이번 주 경험에서 특별히 도움이 되었던 부분이 있나요? 있다면 어떤 부분이 도움이 되었나요?":
        "이번 주 경험에서 특별히 도움이 되었던 부분이 있나요? 있다면 어떤 부분이 도움이 되었나요?",
      "이번 주 경험에서 다소 아쉬웠던 부분이 있었나요? 있다면 어떤 부분이 아쉬웠을까요?":
        "이번 주 경험에서 다소 아쉬웠던 부분이 있었나요? 있다면 어떤 부분이 아쉬웠을까요?",
      "다음 주 프로그램을 진행하며 조금 더 신경 쓰면 좋겠는 점이 있나요?":
        "다음 주 프로그램을 진행하며 조금 더 신경 쓰면 좋겠는 점이 있나요?",
      "만약 지금 서비스가 종료되어 내일부터 이용할 수 없게 된다면 어떤 기분이 들 것 같나요?":
        "만약 지금 서비스가 종료되어 내일부터 이용할 수 없게 된다면 어떤 기분이 들 것 같나요?",
      "좋아요. 우리 다음 주도 힘내서 열심히 해봐요":
        "좋아요. 우리 다음 주도 힘내서 열심히 해봐요.",
      typography_0_0_0:
        "이번 주 프로그램은 어떠셨나요? 이번 한 주를 돌아보며 다음 주를 준비해 봐요.",
      typography_1_0_0:
        "이번 주 프로그램을 진행하며 특별히 도움이 되었던 부분이 있나요? 있다면 어떤 부분이 도움이 되었나요?",
      typography_2_0_0:
        "이번 주 프로그램을 진행하며 다소 아쉬웠던 부분이 있었나요? 있다면 어떤 부분이 아쉬웠을까요?",
      typography_3_0_0:
        "다음 주 프로그램을 진행하며 조금 더 신경 쓰면 좋겠는 점이 있나요?",
      typography_4_0_0:
        "만약 지금 서비스가 종료되어 내일부터 이용할 수 없게 된다면 어떤 기분이 들 것 같나요?",
      typography_6_0_0:
        "디스턴싱을 고민하는 다른 사람들에게도 도움이 될 수 있도록 한 마디 남겨보는 건 어떨까요?",
      typography_5_0_0: "좋아요. 우리 다음 주도 힘내서 열심히 해봐요.",
    },
    v2: {
      "매우 아쉬울 것 같아요": "매우 아쉬울 것 같아요",
      "조금 아쉬울 것 같아요": "조금 아쉬울 것 같아요",
      "전혀 아쉽지 않을 것 같아요": "전혀 아쉽지 않을 것 같아요",
      "지금은 사용하고 있지 않아 해당 사항이 없어요":
        "지금은 사용하고 있지 않아 해당 사항이 없어요",

      "이번 주 프로그램은 어떠셨나요? 이번 한 주를 돌아보며 다음 주를 준비해 봐요":
        "이번 주 프로그램은 어떠셨나요? 이번 한 주를 돌아보며 다음 주를 준비해 봐요.",
      "이번 주 경험에서 특별히 도움이 되었던 부분이 있나요? 있다면 어떤 부분이 도움이 되었나요?":
        "이번 주 경험에서 특별히 도움이 되었던 부분이 있나요? 있다면 어떤 부분이 도움이 되었나요?",
      "이번 주 경험에서 다소 아쉬웠던 부분이 있었나요? 있다면 어떤 부분이 아쉬웠을까요?":
        "이번 주 경험에서 다소 아쉬웠던 부분이 있었나요? 있다면 어떤 부분이 아쉬웠을까요?",
      "다음 주 프로그램을 진행하며 조금 더 신경 쓰면 좋겠는 점이 있나요?":
        "다음 주 프로그램을 진행하며 조금 더 신경 쓰면 좋겠는 점이 있나요?",
      "만약 지금 서비스가 종료되어 내일부터 이용할 수 없게 된다면 어떤 기분이 들 것 같나요?":
        "만약 지금 서비스가 종료되어 내일부터 이용할 수 없게 된다면 어떤 기분이 들 것 같나요?",
      "좋아요. 우리 다음 주도 힘내서 열심히 해봐요":
        "좋아요. 우리 다음 주도 힘내서 열심히 해봐요.",
      typography_0_0_0:
        "이번 주 프로그램은 어떠셨나요? 이번 한 주를 돌아보며 다음 주를 준비해 봐요.",
      typography_1_0_0:
        "이번 주 프로그램을 진행하며 특별히 도움이 되었던 부분이 있나요? 있다면 어떤 부분이 도움이 되었나요?",
      typography_2_0_0:
        "이번 주 프로그램을 진행하며 다소 아쉬웠던 부분이 있었나요? 있다면 어떤 부분이 아쉬웠을까요?",
      typography_3_0_0:
        "다음 주 프로그램을 진행하며 조금 더 신경 쓰면 좋겠는 점이 있나요?",
      typography_4_0_0:
        "만약 지금 서비스가 종료되어 내일부터 이용할 수 없게 된다면 어떤 기분이 들 것 같나요?",
      typography_4_0_0_1: "매우 아쉽다고 말씀 주시니 정말 감사해요.",
      typography_4_0_0_2:
        "앞으로도 디스턴싱이 더 많은 사람들을 도와나갈 수 있도록 짧은 리뷰 한 마디 남겨주시는 건 어떨까요?",
      typography_4_0_0_3:
        "리뷰를 남겨주신 모든 분께 감사한 마음을 담아 1만 원을 보내드리고 있어요.",
      typography_4_0_0_4:
        "그럼 우리 모두가 마음 행복한 세상을 위해 한 마디 부탁드릴게요.",
      typography_4_0_0_5:
        "리뷰를 작성해주셨다면 내용을 캡쳐해 디스턴싱 카카오톡 채널로 보내주세요.",
      typography_5_0_0: "좋아요. 우리 다음 주도 힘내서 열심히 해봐요.",
    },
  },
};
