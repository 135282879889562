import { IconButton, Stack, SvgIcon } from "@mui/joy";
import { motion } from "framer-motion";
import { memo } from "react";
import { NextArrowSvg } from "static/image/common/SvgAssets";

function PatientMenuBar({
  highlight,
  disabled,
  onClick,
}: {
  highlight?: boolean;
  disabled: boolean;
  onClick: () => void;
}) {
  return (
    <Stack
      direction={"row"}
      justifyContent="end"
      sx={{ pt: "4px" }}
      onClick={onClick}
    >
      <motion.div
        style={{
          cursor: "pointer",
        }}
        animate={{
          opacity: highlight && !disabled ? [1, 0, 1] : 1,
        }}
        transition={{
          delay: 1,
          duration: highlight && !disabled ? 1 : 0,
          repeat: highlight && !disabled ? Infinity : 0,
        }}
      >
        <IconButton disabled={disabled}>
          <SvgIcon
            sx={{ width: "14px", height: "14px" }}
            {...(disabled && { opacity: 0.3 })}
            inheritViewBox
          >
            <NextArrowSvg />
          </SvgIcon>
        </IconButton>
      </motion.div>
    </Stack>
  );
}

export default memo(PatientMenuBar);
