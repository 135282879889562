import { useEffect } from "react";
import DefaultPage from "./DefaultPage2";
import { CircularProgress, Stack } from "@mui/joy";
import useLogin from "hooks/useLogin";

export default function AppleLoginRedirect() {
  const hashParams = window.location.hash.substring(1).split("&");
  const idTokenParam = hashParams.find((param) =>
    param.startsWith("id_token=")
  );
  const idToken = idTokenParam ? idTokenParam.split("=")[1] : null;

  const login = useLogin({
    url: `/user/login`,
    requestBody: JSON.stringify({
      idToken: idToken,
      type: "apple",
    }),
  });

  useEffect(() => {
    if (idToken) {
      login();
    }
  }, [idToken]);

  return (
    <DefaultPage
      content={
        <Stack alignItems={"center"}>
          <CircularProgress color="neutral" />
        </Stack>
      }
    />
  );
}
