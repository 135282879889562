import { TypographyType, TextareaType, CellType } from "data/CellComponent";
import { Stack } from "@mui/joy";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import { ProgramContentType } from "data/BlockComponent";
import React, { memo } from "react";
import Cell from "../cell/Cell";

function Line({
  lineStr,
  lineIndex,
  taskKey,
  thoughtRecordKey,
  activityRecordKey,
  data,
  setData,
  blockIndex,
  isLastLine,
  translationVersion,
  readonly,
  isShown,
}: {
  lineStr: string;
  lineIndex: number;
  taskKey: string;
  thoughtRecordKey?: string;
  activityRecordKey?: string;
  data?: ProgramContentType[];
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  blockIndex: number;
  isLastLine: boolean;
  translationVersion?: string;
  readonly: boolean;
  isShown: boolean;
}) {
  const line: CellType[] = JSON.parse(lineStr);
  const user = useRecoilValue(userAtom);
  const isCoach = (user?.accessToken && user?.role !== "patient") || false;

  return (
    <div
      key={`${taskKey}_${blockIndex}_${lineIndex}`}
      className="flex gap-[10px] items-stretch w-full"
    >
      {line.map(
        (cell, cellIndex) =>
          !(!isCoach && cell.type === "aiCoaching") && (
            <Stack
              key={
                (cell.content as TextareaType)?.editorKey
                  ? `${taskKey}_textarea_${
                      (cell.content as TextareaType).editorKey
                    }`
                  : `${taskKey}_${blockIndex}_${lineIndex}_${cellIndex}`
              }
              sx={{
                width: `${
                  ((cell.content as TypographyType)?.width || 1) * 100
                }%`,
                alignSelf: "stretch",

                mb: !isLastLine
                  ? cell.type === "typography" &&
                    (cell.content as TypographyType).isLabel
                    ? "8px"
                    : "16px"
                  : 0,
              }}
              className={`flex justify-center ${
                cell.content.readonly && "pointer-events-none"
              }`}
            >
              <Cell
                type={cell.type}
                contentStr={JSON.stringify(cell.content)}
                blockIndex={blockIndex}
                lineIndex={lineIndex}
                cellIndex={cellIndex}
                taskKey={taskKey}
                thoughtRecordKey={thoughtRecordKey}
                activityRecordKey={activityRecordKey}
                data={data}
                setData={setData}
                translationVersion={translationVersion}
                readonly={readonly}
                isShown={isShown}
              />
            </Stack>
          )
      )}
    </div>
  );
}

export default memo(Line);
