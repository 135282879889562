import { automaticThoughtIdType } from "api/conceptApi";
import {
  Audio,
  ButtonGroup,
  Image,
  SolidLabelLine,
  TypographyFromDataLine,
} from "data/CellComponent";
import {
  Instruction,
  InstructionWithContent,
  InstructionWithDataWithContent,
} from "../BlockComponent";
import { NotificationTimeBlocks } from "./5-1-A";
import { LastCard, RawValueTemplate } from "./common";

export const MeditationNotificationBlocks = (
  isPushNotificationGranted: boolean
) => [
  InstructionWithContent(`notification_0_1`, [
    [
      ButtonGroup([
        {
          text: "가능해요",
          showFromId: "가능",
          hideFromId: "불가능",
        },
        {
          text: "지금은 안돼요",
          showFromId: "불가능",
          hideFromId: "가능",
        },
      ]),
    ],
  ]),
  Instruction("notification_1"),
  ...(!isPushNotificationGranted
    ? [
        InstructionWithContent(
          "notification_2",
          [
            [
              {
                type: "pushNotification",
                content: { showFromIdIfDenied: "알림 미허용" },
              },
            ],
          ],
          { blockId: "불가능" }
        ),
        ...NotificationTimeBlocks,
      ]
    : [...NotificationTimeBlocks]),
  Instruction("notification_5", { blockId: "알림 미허용" }),
];

export const Content_7_9_A = (
  automaticThoughtId: automaticThoughtIdType,
  isPushNotificationGranted: boolean
) => {
  return [
    Instruction("typography_0_0_0"),
    InstructionWithContent(
      "typography_1_0_0",
      RawValueTemplate(automaticThoughtId)
    ),
    Instruction("typography_2_0_0"),
    ...Array.from({ length: 5 }, (v, i) => i + 1)
      .map((step, index) => [
        InstructionWithDataWithContent(
          {
            M: { value: `${step}` },
            A: {
              id: `step_${step}`,
              taskId: `7-6-A_${automaticThoughtId}`,
            },
          },
          `typography_3_0_0`,
          [
            ...[
              "누구와",
              "무엇을",
              "어디서",
              "언제",
              "예상 장애물",
              "장애물 극복법",
            ].flatMap((each) => [
              SolidLabelLine(`${each}`),
              TypographyFromDataLine(
                {
                  A: {
                    id: each,
                    taskId: `7-7-A_${automaticThoughtId}#${step}`,
                  },
                },
                "$A$"
              ),
            ]),
          ]
        ),
        InstructionWithContent(`typography_4_0_0`, [
          ...["느낀 점", "어려웠던 점", "앞으로의 기대"].flatMap((each) => [
            SolidLabelLine(`${each}`),
            TypographyFromDataLine(
              {
                A: {
                  id: each,
                  taskId: `7-8-A_${automaticThoughtId}#${step}`,
                },
              },
              "$A$"
            ),
          ]),
        ]),
      ])
      .flat(),
    Instruction("typography_5_0_0"),
    Instruction("typography_6_0_0"),
    Instruction("typography_7_0_0"),
    ...MeditationNotificationBlocks(isPushNotificationGranted),
    Instruction(`meditation_instruction_0`, { blockId: "가능" }),
    Instruction(`meditation_instruction_1`),

    InstructionWithContent("meditation_instruction_2", [
      Image("1"),
      Audio("value_wrapup"),
    ]),
    InstructionWithContent(
      "typography_8_0_0",
      RawValueTemplate(automaticThoughtId)
    ),
    Instruction("typography_9_0_0"),
    Instruction("typography_10_0_0"),
    Instruction("typography_11_0_0"),
    Instruction("typography_12_0_0"),
    Instruction("typography_13_0_0"),
    ...LastCard,
  ];
};
