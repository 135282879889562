import Typography from "@mui/joy/Typography";
import { Stack, SvgIcon } from "@mui/joy";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

export default function AiText({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) {
  return (
    <Stack direction={"row"} spacing={0.5}>
      <SvgIcon
        sx={{
          width: "20px",
          height: "20px",
          p: "2px",
          alignSelf: "start",
          justifySelf: "start",
          color: "primary.solid",
        }}
      >
        <AutoAwesomeIcon />
      </SvgIcon>
      <Typography
        textColor={"primary.solid"}
        onClick={() => {
          onClick();
        }}
        sx={{
          textDecorationLine: "underline",
          fontWeight: 500,
          cursor: "pointer",
        }}
      >
        {text || ""}
      </Typography>
    </Stack>
  );
}
