import { SvgIcon } from "@mui/joy";
import { createSvgIcon } from "@mui/material";

export default function LogoEmptyPage() {
  const LogoIcon = createSvgIcon(
    <svg
      width="256"
      height="256"
      viewBox="0 0 256 256"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 56H120V72H200V88H248V168H200V200H184L152 168H120H40V56ZM40 168H8V88H40V168Z"
        fill="currentColor"
      />
    </svg>,
    "EmptyPageLogo"
  );

  return (
    <SvgIcon
      sx={{ width: "256px", height: "256px" }}
      color={"background3"}
      inheritViewBox
    >
      <LogoIcon />
    </SvgIcon>
  );
}
