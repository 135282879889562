import { Stack, Typography } from "@mui/joy";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import DefaultPage from "./DefaultPage";

export default function Waiting() {
  const user = useRecoilValue(userAtom);

  return (
    <DefaultPage
      content={
        <Stack spacing={3}>
          <Stack spacing={1}>
            {user && user.coachAssignDueDate && (
              <Typography level="h3">
                예상 시작 가능일은{" "}
                {new Date(user?.coachAssignDueDate).getMonth() + 1}월{" "}
                {new Date(user?.coachAssignDueDate).getDate()}일이에요
              </Typography>
            )}
            <Typography sx={{ opacity: 0.5 }}>
              현재 배정 가능한 코치 선생님이 없어요. 조금 기다려주셔야 해요.
              차례가 되면 카카오톡으로 알려드릴게요.
            </Typography>
          </Stack>
        </Stack>
      }
    />
  );
}
