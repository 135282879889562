import Typography from "@mui/joy/Typography";
import { useCallback, useEffect, useState } from "react";
import { Stack, Textarea } from "@mui/joy";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import {
  conceptApi,
  CONCEPT_DOMAIN,
  CONCEPT_MEMOLOAD_ENDPOINT,
  MemoType,
} from "api/conceptApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import { usePrevious } from "@uidotdev/usehooks";

export default function Memo() {
  const patientState = useRecoilValue(patientAtom);
  const [memo, setMemo] = useState<string>();

  const saveMemoFn = useCallback(
    () => conceptApi.memoSave(`${patientState?.patientId}`, memo || ""),
    [patientState, memo]
  );

  const { mutate: saveMemo } = useMutation(saveMemoFn);
  const queryFn = useCallback(
    () => conceptApi.memoLoad(`${patientState?.patientId}`),
    [patientState]
  );

  const { refetch: loadMemo } = useQuery(
    [CONCEPT_DOMAIN, CONCEPT_MEMOLOAD_ENDPOINT],
    queryFn,
    {
      enabled: !!patientState?.patientId,
      onSuccess: (data) => {
        if (data && data.result) {
          setMemo((data.result as MemoType).memo);
        }
      },
    }
  );

  useEffect(() => {
    loadMemo();
  }, [patientState?.patientId]);

  const previousMemo = usePrevious(memo);

  useEffect(() => {
    if (memo && previousMemo !== memo) {
      saveMemo();
    }
  }, [memo]);

  return (
    <Stack spacing={1}>
      <Stack direction={"row"} justifyContent="space-between">
        <Typography level="body-xs">{"메모"}</Typography>
      </Stack>

      <Textarea
        value={memo || ""}
        onChange={(e) => {
          setMemo(e.target.value);
        }}
        sx={{
          width: "100%",
          borderWidth: "2px",
          borderColor: "background.level2",
          backgroundColor: "background.level2",
          "--joy-focus-thickness": "0px",
        }}
      ></Textarea>
    </Stack>
  );
}
