import Link from "components/common/Link";
import {
  workflowApi,
  WORKFLOW_DOMAIN,
  WORKFLOW_HUMAN_CHECK_DETAIL_ENDPOINT,
} from "api/workflowApi";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Stack } from "@mui/joy";
import TaskLink from "../TaskLink";
import HumanCheckTask from "./HumanCheckTask";

export default function PresetTask({
  taskKey,
  isCompleted,
  workflowId,
  showDoneButton,
  isLast,
  workCompleteAction,
}: {
  taskKey: string;
  isCompleted?: boolean;
  workflowId: string;
  showDoneButton?: boolean;
  isLast?: boolean;
  workCompleteAction: () => void;
}) {
  const queryClient = useQueryClient();

  const patientState = useRecoilValue(patientAtom);

  const presetTaskCompleteQueryFn = useCallback(
    () =>
      workflowApi.presetTaskComplete(
        `${patientState?.patientId}`,
        workflowId,
        taskKey
      ),
    [patientState?.patientId, workflowId, taskKey]
  );

  const { mutate: presetTaskComplete } = useMutation(
    presetTaskCompleteQueryFn,
    {
      onSuccess: () => {
        console.log("COMPLETE PRESET TASK");
        queryClient.invalidateQueries([
          WORKFLOW_DOMAIN,
          WORKFLOW_HUMAN_CHECK_DETAIL_ENDPOINT,
        ]);
        if (isLast) {
          workCompleteAction();
        }
      },
    }
  );

  return (
    // <Stack direction={"row"} spacing={"12px"} justifyContent="space-between">
    //   <TaskLink taskKey={taskKey} />
    //   {showDoneButton && (
    //     <Link
    //       text={isCompleted ? "완료됨" : "완료"}
    //       opacity={1}
    //       textColor={isCompleted ? "primary.deactivated" : "primary.solid"}
    //       disabled={isCompleted}
    //       onClick={presetTaskComplete}
    //     />
    //   )}
    // </Stack>
    <HumanCheckTask
      taskKey={taskKey}
      isCompleted={isCompleted}
      showDoneButton={showDoneButton}
      taskCompleteAction={presetTaskComplete}
    />
  );
}
