import { memo } from "react";
import TaskItemTemplate from "./TaskItemTemplate";
import TaskItemWithModal from "./TaskItemWithModal";

function TaskItem({
  taskKey,
  isOpen,
  isDone,
  isLocked,
  hasMissingValue,
  isPreviousTaskUndone,
}: {
  taskKey: string;
  isOpen: boolean;
  isDone: boolean;
  isLocked?: boolean;
  hasMissingValue?: boolean;
  isPreviousTaskUndone?: boolean;
}) {
  return isPreviousTaskUndone ? (
    <TaskItemWithModal
      key={taskKey}
      taskKey={taskKey}
      isOpen={isOpen}
      isDone={isDone}
      isLocked={isLocked}
      hasMissingValue={hasMissingValue}
    />
  ) : (
    <TaskItemTemplate
      key={taskKey}
      taskKey={taskKey}
      isOpen={isOpen}
      isDone={isDone}
      isLocked={isLocked}
      hasMissingValue={hasMissingValue}
    />
  );
}

export default memo(TaskItem);
