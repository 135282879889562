import { Stack, Typography } from "@mui/joy";
import LineChart from "./LineChart2";
import { TestResultType } from "api/dashboardApi";
import { selfCheckData, SelfCheckIdType } from "data/selfCheckData";
import CustomCard from "components/common/CustomCard";
import { useTranslation } from "react-i18next";
import { isDev } from "config";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { userAtom } from "recoil/userAtom";
import { taskListAtom } from "recoil/taskListAtom";

interface TestResultProps extends TestResultType {
  isSelfCheckVertical?: boolean;
}
function getMaxScoreById(id: SelfCheckIdType) {
  const resultRangeData = selfCheckData[id].result;
  return resultRangeData[resultRangeData.length - 1].range[1];
}

function TestResultById({
  id,
  title,
  scoreList,
}: {
  id: SelfCheckIdType;
  title: string;
  scoreList: number[];
}) {
  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });

  return (
    <CustomCard sx={{ flex: 1 }}>
      <Stack
        direction="column"
        flexGrow={1}
        sx={{
          p: 2,
        }}
      >
        <Stack spacing={2}>
          <Typography
            level="body-md"
            // fontWeight="lg"
            textColor="text.secondary"
            whiteSpace={"nowrap"}
          >
            {title}
          </Typography>
          <Stack spacing={0}>
            {/* <Typography
              level="title-md"
              fontWeight={"xl"}
              textColor="text.secondary"
              whiteSpace={"nowrap"}
            >
              {scoreList && scoreList.length > 0
                ? `${scoreList[scoreList.length - 1]}점`
                : "-"}
              {scoreList && scoreList.length > 0 && (
                <Typography
                  level="body-xs"
                  fontWeight={"xl"}
                  textColor="text.secondary"
                  whiteSpace={"nowrap"}
                  sx={{ opacity: 0.5 }}
                >
                  {` /${getMaxScoreById(id)}점`}
                </Typography>
              )}
            </Typography> */}
            {(!scoreList || scoreList.length === 0) && (
              <Typography
                fontWeight={"md"}
                textColor={"text.secondary"}
                whiteSpace={"nowrap"}
                sx={{ opacity: 0.5 }}
              >
                {t_ui("검사 결과가 없어요.")}
              </Typography>
            )}
          </Stack>
        </Stack>
        {scoreList.length > 0 && (
          <LineChart
            id={id}
            data={scoreList}
            maxScore={getMaxScoreById(id)}
            abnormalScore={selfCheckData[id].abnormalScore}
          />
        )}
        {/* {isDev && (
          <LineChart
            id={`${id}_dev`}
            data={[10, 20, 5, 2, 21]}
            maxScore={getMaxScoreById(id)}
            abnormalScore={selfCheckData[id].abnormalScore}
          />
        )} */}
      </Stack>
    </CustomCard>
  );
}
export default function TestResult({
  anxiety,
  depression,
  stress,
  burnout,
}: TestResultProps) {
  const { t: t_selfCheck } = useTranslation("translation", {
    keyPrefix: "selfCheck",
  });

  const taskListState = useRecoilValue(taskListAtom);

  const isBehavioralActivationProgram = !taskListState.find(
    (element) => element.taskId === "0-0-A"
  );

  const data: { id: SelfCheckIdType; scoreList: number[] }[] = [
    {
      id: "depression",
      scoreList: depression?.scoreList || [],
    },
    {
      id: "anxiety",
      scoreList: anxiety?.scoreList || [],
    },
    ...(isBehavioralActivationProgram
      ? []
      : [
          {
            id: "stress" as SelfCheckIdType,
            scoreList: stress?.scoreList || [],
          },
        ]),
    // { id: "burnout", title: "번아웃", scoreList: burnout?.scoreList || [] },
  ];

  return (
    <Stack direction={"column"} spacing={"12px"}>
      {data.map(({ id, scoreList }, index) => {
        const title = selfCheckData[id as SelfCheckIdType].title;
        const screeningTool =
          selfCheckData[id as SelfCheckIdType].screeningTool;
        return (
          <TestResultById
            key={`testResult_${id}`}
            id={id}
            title={`${t_selfCheck(title)} (${screeningTool})`}
            scoreList={scoreList}
          />
        );
      })}
    </Stack>
  );
}
