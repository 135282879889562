import { Instruction, Header1, InstructionWithImage } from "../BlockComponent";
import { LastCardWithTextarea } from "./common";

export const Content_101_1_1_A = [
  Instruction("typography_0_0_0"),
  InstructionWithImage("typography_1_0_0", "0"),
  Instruction("typography_2_0_0"),
  Instruction("typography_3_0_0"),
  InstructionWithImage("typography_4_0_0", "1"),
  Instruction(`typography_5_0_0`),
  Instruction("typography_6_0_0"),

  Header1("typography_7_0_0"),
  Instruction("typography_8_0_0"),
  Instruction("typography_9_0_0"),
  InstructionWithImage("typography_10_0_0", "2"),
  InstructionWithImage("typography_11_0_0", "3"),
  Instruction(`typography_12_0_0`),
  Instruction("typography_13_0_0"),
  Instruction("typography_14_0_0"),
  Instruction("typography_15_0_0"),
  Instruction("typography_16_0_0"),
  Instruction("typography_17_0_0"),
  InstructionWithImage("typography_18_0_0", "4"),
  Instruction("typography_19_0_0"),

  Header1("typography_20_0_0"),
  Instruction("typography_21_0_0"),
  Instruction("typography_22_0_0"),
  InstructionWithImage(`typography_23_0_0`, "5"),
  InstructionWithImage(`typography_24_0_0`, "6"),
  Instruction(`typography_25_0_0`),
  Instruction(`typography_26_0_0`),
  InstructionWithImage(`typography_27_0_0`, "7"),
  Instruction(`typography_28_0_0`),
  Instruction("typography_29_0_0"),
  Instruction("typography_30_0_0"),
  InstructionWithImage("typography_31_0_0", "8"),
  Instruction("typography_32_0_0"),
  Instruction("typography_33_0_0"),
  Instruction("typography_34_0_0"),
  Instruction("typography_35_0_0"),
  Instruction("typography_36_0_0"),
  ...LastCardWithTextarea,
];
