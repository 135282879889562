import React, { useEffect, useRef, useState } from "react";
import QuillComponent, { ReactQuillProps } from "react-quill";
import "./quill.css";
import { Box } from "@mui/joy";
import { theme } from "styles/theme";
import { useRecoilState, useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import { layoutAtom } from "recoil/layoutAtom";
import * as _ from "lodash";

const CustomToolbar = ({ editorKey }: { editorKey: string }) => (
  <div id={`toolbar_${editorKey}`} className="bg-white flex flex-row">
    <button className="ql-bold"></button>
    {/* <button className="ql-italic"></button> */}
    <button className="ql-underline"></button>
    <select
      className="ql-color"
      defaultValue={`${theme.vars.palette.text.positive}`}
    >
      <option value={`${theme.vars.palette.text.green}`}></option>
      <option value={`${theme.vars.palette.text.blue}`}></option>
      <option value={`${theme.vars.palette.text.orange}`}></option>
      <option value={`${theme.vars.palette.text.positive}`}></option>
    </select>
  </div>
);

const ReactQuill = (
  typeof window === "object" ? require("react-quill") : () => false
) as React.FC<ReactQuillProps & { ref: React.Ref<QuillComponent> }>;

export default function Quill({
  placeholder,
  initialHtmlString,
  initialValue,
  handleChange,
  setIsFocused,
  editorKey,
  disableInlineToolbar,
  readOnly,
}: {
  placeholder?: string;
  initialHtmlString?: string;
  initialValue?: string;
  handleChange: (value: string, htmlString?: string) => void;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  editorKey?: string;
  disableInlineToolbar?: boolean;
  readOnly?: boolean;
}) {
  const user = useRecoilValue(userAtom);
  const isCoach = user?.role !== "patient";
  const [layoutState, setLayoutState] = useRecoilState(layoutAtom);
  const [value, setValue] = useState(initialHtmlString || initialValue);
  const [openToolbar, setOpenToolbar] = useState<boolean>(false);
  const [inlineToolbarPosition, setInlineToolbarPosition] = useState<{
    x: number;
    y: number;
  }>();

  const toolbarWidth = 226;

  useEffect(() => {
    if (
      layoutState.showQuillToolbar !== undefined &&
      !layoutState.showQuillToolbar
    ) {
      setOpenToolbar(false);
      setLayoutState((state) => ({ ...state, showQuillToolbar: undefined }));
    }
  }, [layoutState.showQuillToolbar]);

  useEffect(() => {
    if (readOnly) {
      setValue(initialHtmlString || initialValue);
    }
  }, [initialHtmlString, initialValue]);

  const quillRef = useRef<QuillComponent>(null);

  useEffect(() => {
    const overwriteEnterBinding = () => {
      if (quillRef.current) {
        const keyboard = quillRef.current.getEditor().getModule("keyboard");
        keyboard.bindings[13].unshift({
          key: 13,
          handler: () => {
            quillRef.current?.blur();
            setIsFocused(false);
          },
        });
      }
    };

    if (!isCoach) {
      overwriteEnterBinding();
    }
  }, [quillRef]);

  return (
    <div className="relative w-full">
      <Box
        sx={{
          position: "fixed",
          top: (inlineToolbarPosition?.y || 0) + 30,
          left: Math.min(
            inlineToolbarPosition?.x || 0,
            window.innerWidth - (toolbarWidth + 10)
          ),
          width: "100%",
          display: openToolbar ? "flex" : "none",
        }}
        zIndex={10}
      >
        <CustomToolbar editorKey={`${editorKey}`} />
      </Box>

      <ReactQuill
        ref={quillRef}
        id={`${editorKey}`}
        theme="snow"
        value={value}
        placeholder={!readOnly ? placeholder : undefined}
        onChange={(content, delta, source, editor) => {
          setValue(content);
          handleChange(editor.getText(), content);
        }}
        onChangeSelection={(range, source, editor) => {
          if (range && range?.length > 0) {
            const s = window.getSelection();
            if (s) {
              const oRange = s.getRangeAt(0); //get the text range
              if (oRange) {
                const oRect = oRange.getBoundingClientRect();
                if (oRect.width > 1) {
                  setInlineToolbarPosition({ x: oRect.x, y: oRect.y });
                  if (!disableInlineToolbar) {
                    setOpenToolbar(true);
                  }
                }
              }
            } else {
              setOpenToolbar(false);
            }
          } else {
            setOpenToolbar(false);
          }
        }}
        readOnly={readOnly}
        onFocus={() => {
          if (!readOnly) {
            setIsFocused(true);
          }
        }}
        onBlur={() => {
          setIsFocused(false);
          setOpenToolbar(false);
        }}
        formats={[
          // "header",
          "font",
          // "size",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          // "list",
          // "bullet",
          // "indent",
          // "link",
          // "image",
          "color",
        ]}
        modules={{
          toolbar: {
            container: `#toolbar_${editorKey}`,
          },
          keyboard: {
            bindings: { tab: false },
          },
        }}
      />
    </div>
  );
}
