import { Stack, Typography } from "@mui/joy";
import { theme } from "styles/theme";

export default function RecordListTabs({
  selectedTab,
  data,
}: {
  selectedTab?: string;
  data: { id: string; label: string; onClick: () => void }[];
}) {
  return (
    <Stack
      direction="row"
      spacing="8px"
      sx={{ width: "100%", height: "fit-content" }}
    >
      {data.map(({ id, label, onClick }) => (
        <Stack
          sx={{
            flex: 1,
            py: "8px",
            borderRadius: "6px",
            cursor: "pointer",
            ...(id === selectedTab
              ? {
                  boxShadow: `inset 0 0 0 1.6px ${theme.vars.palette.primary.solid}`,
                }
              : {
                  backgroundColor: "background.level1",
                }),
          }}
          onClick={onClick}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 700,
              ...(id === selectedTab
                ? { color: "primary.solid" }
                : { color: "common.black", opacity: 0.4 }),
              textAlign: "center",
            }}
          >
            {label}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}
