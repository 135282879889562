import { Box, Stack } from "@mui/joy";
import Button from "@mui/joy/Button";
import { ProgramContentType } from "data/BlockComponent";
import { SingleSelectionType, TextareaLine } from "data/CellComponent";
import * as _ from "lodash";
import { useEffect, useState } from "react";

export default function ButtonGroupCell({
  defaultValue,
  selectedIndex,
  blockIndex,
  options,
  setData,
  lineIndex,
  cellIndex,
  isCoach,
}: {
  defaultValue?: string;
  selectedIndex?: number;
  blockIndex: number;
  options: string[];
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
  isCoach?: boolean;
}) {
  const [selectedOption, setSelectedOption] = useState<{
    text?: string;
    index?: number;
  }>({ text: defaultValue, index: selectedIndex });

  useEffect(() => {
    const index =
      selectedIndex !== undefined
        ? selectedIndex
        : options.findIndex((element) => element === defaultValue);

    setSelectedOption({ text: defaultValue, index: index });
  }, [defaultValue, selectedIndex]);

  // useEffect(() => {
  //   if (options.length === 1) {
  //     setSelected(options[0]);
  //     handleChange(options[0], 0);
  //   }
  // }, [options]);

  //직접 클릭한 게 아닌 경우 (생기지에서 명훈지 저장, 명훈지에서 생기지 저장)
  useEffect(() => {
    handleChangeFirst(selectedOption.text, selectedOption.index);
  }, [selectedOption]);

  const handleChangeFirst = (selected?: string, optionIndex?: number) => {
    setData((data) => {
      if (data) {
        const originalCurrentContent = (data[blockIndex].lines[lineIndex][
          cellIndex
        ].content || {}) as SingleSelectionType;

        const data_temp = _.cloneDeep(data);
        if (
          selected &&
          optionIndex !== undefined &&
          originalCurrentContent.options
        ) {
          const selectedOption = originalCurrentContent.options[optionIndex];

          const showBlockIdList = selectedOption?.showBlockIdList;
          const hideBlockIdList = selectedOption?.hideBlockIdList;
          showBlockIdList?.forEach((blockId) => {
            const blockIndex = data_temp.findIndex(
              (element) => element.blockId && element.blockId === blockId
            );
            if (blockIndex > -1) {
              data_temp[blockIndex].isAlwaysHidden = false;
            }
          });
          hideBlockIdList?.forEach((blockId) => {
            const blockIndex = data_temp.findIndex(
              (element) => element.blockId && element.blockId === blockId
            );
            if (blockIndex > -1) {
              data_temp[blockIndex].isAlwaysHidden = true;
            }
          });

          if (selectedOption?.blockIdForBranch) {
            const branchIndex = data_temp.findIndex(
              (element) =>
                element.blockId &&
                element.blockId === selectedOption?.blockIdForBranch
            );
            data_temp[branchIndex].nextBlockId =
              selectedOption?.showFromId || `buttongroup_option_${selected}`;
            data_temp[blockIndex].autoOpenNext = true; //nextBlockId 없어서 바로 안열림. 그래서 수동으로
          } else {
            data_temp[blockIndex].nextBlockId =
              selectedOption?.showFromId || `buttongroup_option_${selected}`;
          }
          const currentContent =
            data_temp[blockIndex].lines[lineIndex][cellIndex].content || {};
          data_temp[blockIndex].lines[lineIndex][cellIndex].content = {
            ...currentContent,
            value: selected,
            selectedIndex: optionIndex,
          };
        }

        return data_temp;
      }

      return data;
    });
  };

  const handleChange = (selected?: string, optionIndex?: number) => {
    setData((data) => {
      if (data) {
        const originalCurrentContent = (data[blockIndex].lines[lineIndex][
          cellIndex
        ].content || {}) as SingleSelectionType;

        const data_temp = _.cloneDeep(data);
        if (
          selected &&
          optionIndex !== undefined &&
          originalCurrentContent.options
        ) {
          const selectedOption = originalCurrentContent.options[optionIndex];
          const subjectiveOption = selectedOption.subjectiveOption;
          if (subjectiveOption) {
            const subjectiveOptionIndex = data_temp[blockIndex].lines.findIndex(
              (line) =>
                line.find((cell) => cell.content.id === subjectiveOption.id)
            );
            data_temp[blockIndex].lines.splice(
              subjectiveOptionIndex > -1
                ? subjectiveOptionIndex
                : data_temp[blockIndex].lines.length,
              subjectiveOptionIndex > -1 ? 1 : 0,
              TextareaLine(selectedOption.subjectiveOption)
            );
          } else {
            const hideCellId = originalCurrentContent.options.find(
              (element) => element.subjectiveOption
            )?.subjectiveOption?.id;
            if (hideCellId) {
              const hideCellIndex = data_temp[blockIndex].lines.findIndex(
                (line) => line.find((cell) => cell.content.id === hideCellId)
              );
              if (hideCellIndex > -1) {
                data_temp[blockIndex].lines.splice(hideCellIndex, 1);
              }
            }
          }

          const showBlockIdList = selectedOption?.showBlockIdList;
          const hideBlockIdList = selectedOption?.hideBlockIdList;
          showBlockIdList?.forEach((blockId) => {
            const blockIndex = data_temp.findIndex(
              (element) => element.blockId && element.blockId === blockId
            );
            if (blockIndex > -1) {
              data_temp[blockIndex].isAlwaysHidden = false;
            }
          });
          hideBlockIdList?.forEach((blockId) => {
            const blockIndex = data_temp.findIndex(
              (element) => element.blockId && element.blockId === blockId
            );
            if (blockIndex > -1) {
              data_temp[blockIndex].isAlwaysHidden = true;
            }
          });

          const showFromIndex = data_temp.findIndex(
            (element) =>
              element.blockId && element.blockId === selectedOption?.showFromId
          );

          const hideFromIndex = data_temp.findIndex(
            (element) =>
              element.blockId && element.blockId === selectedOption?.hideFromId
          );

          if (showFromIndex > -1) {
            data_temp.forEach((e, i) => {
              if (hideFromIndex > -1) {
                const showFromIndex2 = data_temp.findIndex(
                  (element) =>
                    element.blockId &&
                    element.blockId === data_temp[hideFromIndex - 1].nextBlockId
                );
                const hideFromIndex2 = data_temp.findIndex(
                  (element, index) =>
                    index > showFromIndex &&
                    index < hideFromIndex &&
                    element.nextBlockId
                );
                const showFromIndex3 = data_temp.findIndex(
                  (element, index) =>
                    index > showFromIndex && element.nextBlockId
                );
                const showModuleLastBlockShown = data_temp.findIndex(
                  (element) => data_temp[hideFromIndex - 1].isShown
                );
                // if (i >= Math.min(showFromIndex, hideFromIndex)) {
                //   if (
                //     (showFromIndex < hideFromIndex &&
                //       i >= hideFromIndex &&
                //       (showFromIndex2 > -1 && !showModuleLastBlockShown
                //         ? i < showFromIndex2
                //         : true)) ||
                //     (showFromIndex > hideFromIndex &&
                //       (i < showFromIndex ||
                //         i >
                //           data_temp.findIndex(
                //             (element, index) =>
                //               element.nextBlockId && index > showFromIndex
                //           )))
                //   ) {
                //     data_temp[i].isHidden = true;
                //   } else {
                //     data_temp[i].isHidden = false;
                //   }
                // }
                if (i > blockIndex) {
                  if (
                    (showFromIndex < hideFromIndex &&
                      (i < showFromIndex ||
                        i >= hideFromIndex ||
                        (hideFromIndex2 > -1 && i > hideFromIndex2))) ||
                    (showFromIndex > hideFromIndex &&
                      (i < showFromIndex ||
                        (showFromIndex3 > -1 && i >= showFromIndex3)))
                  ) {
                    if (!data_temp[i].isHidden) {
                      data_temp[i].isHidden = true;
                    }
                  } else {
                    data_temp[i].isHidden = false;
                  }
                }
                // if (i > blockIndex) {
                //   if (
                //     (showFromIndex < hideFromIndex &&
                //       (i < showFromIndex ||
                //         i > showFromIndex ||
                //         i >= hideFromIndex)) ||
                //     (showFromIndex > hideFromIndex &&
                //       (i < hideFromIndex ||
                //         i > showFromIndex ||
                //         (i < showFromIndex && i >= hideFromIndex)))
                //   ) {
                //     data_temp[i].isHidden = true;
                //   } else {
                //     data_temp[i].isHidden = false;
                //   }
                // }
                // if (data_temp[hideFromIndex].isShown) {
                //   data_temp[showFromIndex].isShown = true;
                //   data_temp[showFromIndex].isHidden = false;
                // }
              }
            });
          }
          if (selectedOption?.blockIdForBranch) {
            const branchIndex = data_temp.findIndex(
              (element) =>
                element.blockId &&
                element.blockId === selectedOption?.blockIdForBranch
            );
            data_temp[branchIndex].nextBlockId =
              selectedOption?.showFromId || `buttongroup_option_${selected}`;
            data_temp[blockIndex].autoOpenNext = true; //nextBlockId 없어서 바로 안열림. 그래서 수동으로
          } else {
            data_temp[blockIndex].nextBlockId =
              selectedOption?.showFromId || `buttongroup_option_${selected}`;
          }
        } else {
          const hideCellId = originalCurrentContent.options?.find(
            (element) => element.subjectiveOption
          )?.subjectiveOption?.id;
          if (hideCellId) {
            const hideCellIndex = data_temp[blockIndex].lines.findIndex(
              (line) => line.find((cell) => cell.content.id === hideCellId)
            );
            if (hideCellIndex > -1) {
              data_temp[blockIndex].lines.splice(hideCellIndex, 1);
            }
          }

          data_temp.forEach((e, i) => {
            if (i > blockIndex) {
              if (!isCoach) {
                if (!data_temp[i].isHidden) {
                  data_temp[i].isHidden = true;
                }
              }
            }
          });
        }
        const currentContent =
          data_temp[blockIndex].lines[lineIndex][cellIndex].content || {};
        data_temp[blockIndex].lines[lineIndex][cellIndex].content = {
          ...currentContent,
          value: selected,
          selectedIndex: optionIndex,
        };

        return data_temp;
      }

      return data;
    });
  };

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      sx={{
        width: "100%",
        whiteSpace: "nowrap",
        flexWrap: "wrap",
        gap: 1,
      }}
    >
      {options.map((option, optionIndex) => (
        <Box
          key={`buttongroup_option_${blockIndex}_${lineIndex}_${cellIndex}_${optionIndex}`}
          sx={{ flexGrow: 1 }}
        >
          <Button
            fullWidth
            size="md"
            variant={selectedOption.text === option ? "solid" : "outlined"}
            color="primary"
            value={option}
            onClick={(e) => {
              const selectedText = (e?.target as HTMLElement)?.innerText;

              if (selectedOption.text !== selectedText) {
                setSelectedOption({ text: selectedText, index: optionIndex });
                handleChange(selectedText, optionIndex);
              } else {
                setSelectedOption({ text: undefined, index: undefined });
                handleChange(undefined, undefined);
              }
            }}
            // sx={{ fontWeight: 700 }}
          >
            {option}
          </Button>
        </Box>
      ))}
    </Stack>
  );
}
