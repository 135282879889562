import { Button } from "@mui/joy";
import useOpenTask from "hooks/useOpenTask";
import { useRecoilState } from "recoil";
import { patientAtom } from "recoil/patientAtom";

export default function OpenChatButton() {
  const openChat = useOpenTask(`chat`);
  const [patientState, setPatientState] = useRecoilState(patientAtom);
  return (
    <Button
      variant={patientState?.isTaskUpdateRequired ? "solid" : "soft"}
      onClick={() => {
        openChat();

        if (patientState) {
          setPatientState({ ...patientState, unreadMessageCount: 0 });
        }
      }}
      startDecorator={<span className="material-symbols-outlined">chat</span>}
    >
      채팅
    </Button>
  );
}
