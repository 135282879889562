import Button from "@mui/joy/Button";
import { getTaskIdFromTaskKey } from "logic/logics";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { taskApi, TASK_DOMAIN, TASK_LIST_ENDPOINT } from "api";
import { ProgramContentType } from "data/BlockComponent";
import {
  thoughtRecordApi,
  THOUGHTRECORD_DOMAIN,
  THOUGHTRECORD_LIST_ENDPOINT,
} from "api/thoughtRecordApi";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { userAtom } from "recoil/userAtom";
import useGetTaskTitleFromTaskKey from "hooks/useGetTaskTitleFromTaskKey";
import {
  activityRecordApi,
  ACTIVITYRECORD_DOMAIN,
  ACTIVITYRECORD_LIST_ENDPOINT,
} from "api/activityRecordApi";
import {
  meditationRecordApi,
  MEDITATION_RECORD_DOMAIN,
  MEDITATION_RECORD_LIST_ENDPOINT,
} from "api/meditationRecordApi";
import useRemoveTab from "hooks/useRemoveTab";
import useRefetchTaskList from "hooks/useRefetchTaskList";

export default function CloseButton({
  taskKey,
  data,
  thoughtRecordKey,
  activityRecordKey,
  meditationRecordKey,
  isDone = false,
}: {
  taskKey: string;
  data?: ProgramContentType[];
  thoughtRecordKey?: string;
  activityRecordKey?: string;
  meditationRecordKey?: string;
  isDone?: boolean;
}) {
  const user = useRecoilValue(userAtom);
  const isThoughtRecord = taskKey?.includes("2-2-T") && thoughtRecordKey;
  const isActivityRecord = activityRecordKey !== undefined;
  const isMeditationRecord = meditationRecordKey !== undefined;
  const queryClient = useQueryClient();

  const patientState = useRecoilValue(patientAtom);
  const title = useGetTaskTitleFromTaskKey(taskKey);

  const refetchTaskList = useRefetchTaskList();

  const { mutate: saveDataBeforeActivityClose } = useMutation(
    () =>
      taskApi.saveData(`${taskKey}`, {
        taskId: getTaskIdFromTaskKey(`${taskKey}`) || "",
        title: title,
        content: data?.map((each) => ({ ...each, isHighlight: false })) || [],
      }),
    {
      onSuccess: () => {
        refetchTaskList();
      },
    }
  );
  const { mutate: saveDataBeforeThoughtRecordClose } = useMutation(
    () =>
      thoughtRecordApi.saveData(
        `${thoughtRecordKey}`,
        `${patientState?.patientId}`,
        {
          taskId: getTaskIdFromTaskKey(`${taskKey}`) || "",
          title: title,
          content: data?.map((each) => ({ ...each, isHighlight: false })) || [],
        }
      ),

    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          THOUGHTRECORD_DOMAIN,
          THOUGHTRECORD_LIST_ENDPOINT,
        ]);
      },
    }
  );
  const { mutate: saveDataBeforeActivityRecordClose } = useMutation(
    () =>
      activityRecordApi.saveData(
        `${activityRecordKey}`,
        `${patientState?.patientId}`,
        {
          taskId: getTaskIdFromTaskKey(`${taskKey}`) || "",
          title: title,
          content: data?.map((each) => ({ ...each, isHighlight: false })) || [],
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          ACTIVITYRECORD_DOMAIN,
          ACTIVITYRECORD_LIST_ENDPOINT,
        ]);
      },
    }
  );
  const { mutate: saveDataBeforeMeditationRecordClose } = useMutation(
    () =>
      meditationRecordApi.saveData(
        `${meditationRecordKey}`,
        `${patientState?.patientId}`,
        {
          taskId: getTaskIdFromTaskKey(`${taskKey}`) || "",
          title: title,
          content: data?.map((each) => ({ ...each, isHighlight: false })) || [],
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          MEDITATION_RECORD_DOMAIN,
          MEDITATION_RECORD_LIST_ENDPOINT,
        ]);
      },
    }
  );

  const { removeTab } = useRemoveTab({ taskKey });

  const { mutate: activityClose } = useMutation(
    () => taskApi.close(`${taskKey}`),
    {
      onSuccess: () => {
        refetchTaskList();
        removeTab();
      },
    }
  );

  return (
    <Button
      variant="outlined"
      color="primary"
      // disabled={
      //   !(
      //     data &&
      //     isDone &&
      //     isAllCoachFieldFilled(data) &&
      //     isAllUserFieldFilled(data, false, user)
      //   )
      // }
      disabled={!(data && isDone)}
      onClick={() => {
        if (isThoughtRecord) {
          saveDataBeforeThoughtRecordClose();
        } else if (isActivityRecord) {
          saveDataBeforeActivityRecordClose();
        } else if (isMeditationRecord) {
          saveDataBeforeMeditationRecordClose();
        } else {
          saveDataBeforeActivityClose();
          activityClose();
        }
      }}
    >
      마치기
    </Button>
  );
}
