//웹->앱

export const sendMessage = (message: string) => {
  window.ReactNativeWebView?.postMessage(message);
};

//앱->웹

export const getMessageFromApp = (
  setData: React.Dispatch<React.SetStateAction<any | undefined>>
) => {
  const eventFromApp = (e: MessageEvent<any>) => {
    if (typeof e.data === "string") {
      try {
        const parsedMessage = JSON.parse(e.data);
        setData(parsedMessage);
      } catch (error) {}
    }
  };
  // ios
  window.addEventListener("message", eventFromApp, false);
  // android
  document.addEventListener("message", eventFromApp as EventListener, false);
  return () => {
    window.removeEventListener("message", eventFromApp);
    document.removeEventListener("message", eventFromApp as EventListener);
  };
};
