import { Box, Stack, Textarea, Typography } from "@mui/joy";
import { useState } from "react";
import NextButton from "./NextButton";

export default function Answer({
  answerLabel,
  handleChange,
}: {
  answerLabel?: string;
  handleChange?: (value: string) => void;
}) {
  const [answer, setAnswer] = useState<string>("");
  return (
    <Stack direction="column" spacing={"10px"}>
      {answerLabel && (
        <Typography color="primary" sx={{ fontSize: "14px", fontWeight: 700 }}>
          {answerLabel}
        </Typography>
      )}
      <Textarea
        value={answer || ""}
        onChange={(e) => {
          let newValue = e.target.value;
          setAnswer(newValue);
          if (handleChange) {
            handleChange(newValue);
          }
        }}
        minRows={1}
        sx={{
          paddingX: "10px",
          paddingY: "12px",
          minHeight: "24px",
          backgroundColor: "transparent",
          borderWidth: "1.5px",
          boxShadow: "none",
        }}
      ></Textarea>
    </Stack>
  );
}
