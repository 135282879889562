import { Typography } from "@mui/joy";
import { memo, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  AutomaticThoughtForValueKeyType,
  AutomaticThoughtForValueType,
  AutomaticThoughtKeyType,
  AutomaticThoughtType,
  ConceptKeyType,
  CopingStrategyKeyType,
  CopingStrategyType,
  CoreBeliefKeyType,
  CoreBeliefType,
  IntermediateBeliefKeyType,
  IntermediateBeliefType,
} from "api/conceptApi";
import { CellType, levelType } from "data/CellComponent";
import { ProgramContentType } from "data/BlockComponent";
import * as _ from "lodash";
import { thoughtTrapMapping, thoughtTrapMappingKey } from "data/task/4-5-A";
import { useTranslation } from "react-i18next";
import { conceptAtom } from "recoil/conceptAtom";
import { getTaskIdFromTaskKey } from "logic/logics";

function TypographyFromDashboardCell({
  taskKey,
  initialValue,
  setData,
  lineIndex,
  cellIndex,
  blockIndex,
  id1,
  id2,
  level,
}: {
  taskKey: string;
  initialValue?: string;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
  blockIndex: number;
  id1: ConceptKeyType;
  id2?:
    | AutomaticThoughtKeyType
    | AutomaticThoughtForValueKeyType
    | CopingStrategyKeyType
    | IntermediateBeliefKeyType
    | CoreBeliefKeyType;
  level?: levelType;
}) {
  const { t: t_thinkingTrap } = useTranslation("translation", {
    keyPrefix: "thinkingTrap",
  });

  const [value, setValue] = useState<string>();

  const conceptState = useRecoilValue(conceptAtom);

  useEffect(() => {
    if (conceptState) {
      const value = id2
        ? (
            (conceptState[id1] || {}) as AutomaticThoughtType &
              AutomaticThoughtForValueType &
              CopingStrategyType &
              IntermediateBeliefType &
              CoreBeliefType
          )[id2]
        : ((conceptState[id1] || "") as string);
      // console.log(conceptState, id2, value);

      setValue(value);

      setData((data) => {
        if (data) {
          const data_temp = _.cloneDeep(data);

          const isAddThoughtTrapCell =
            getTaskIdFromTaskKey(taskKey) === "6-9-A" &&
            id1.startsWith("automaticThought") &&
            id2 === "behavior";
          if (isAddThoughtTrapCell) {
            const automaticThoughtId = id1.substring("automaticThought".length);

            const cells = data_temp[blockIndex].lines.flat();
            const thinkingTrapLine: CellType[] = [
              {
                type: "temp",
                content: {
                  id: `자동적사고${automaticThoughtId} 생각함정`,
                  value: (
                    conceptState[id1] as AutomaticThoughtType
                  )?.thoughtTrapList
                    ?.map((each) =>
                      t_thinkingTrap(
                        thoughtTrapMapping[
                          each.split("_")[0] as thoughtTrapMappingKey
                        ]
                          .split("-")[0]
                          .trim()
                      )
                    )
                    .join(", "),
                },
              },
              // {
              //   type: "temp",
              //   content: {
              //     id: `대처전략`,
              //     value: (conceptState[id1] as AutomaticThoughtType)
              //       ?.copingStrategy,
              //   },
              // },
            ];

            if (
              !cells.find(
                (element) =>
                  element.type === "temp" &&
                  element.content.id ===
                    `자동적사고${automaticThoughtId} 생각함정`
              )
            ) {
              data_temp[blockIndex].lines.push(thinkingTrapLine);
            }
          }
          const currentContent =
            data_temp[blockIndex].lines[lineIndex][cellIndex].content || {};
          if (isAddThoughtTrapCell || currentContent.value !== value) {
            data_temp[blockIndex].lines[lineIndex][cellIndex].content = {
              ...currentContent,
              htmlString: `<p>${value}</p>`,
              value: value,
            };

            return data_temp;
          }
        }
        return data;
      });
    }
  }, [conceptState]);

  return <Typography level={level || "body-md"}>{value}</Typography>;
}

export default memo(TypographyFromDashboardCell);
