import { axiosInstance, CustomResponseFormat } from "api";
import { Content_104_0_A } from "data/task/104-0-A";

export default async function get_104_0_A_RawData(
  activityId: number,
  patientId: string
) {
  try {
    // getBlocksFromPreviousActivity 함수 호출

    const response = (await axiosInstance.get(
      "/activity/plan/candidate/detail",
      {
        params: { pid: `${patientId}` },
      }
    )) as CustomResponseFormat;
    if (response.code === 0) {
      const data = response.result;

      return {
        taskId: `104-0-A#${activityId}`,
        content: Content_104_0_A(
          activityId,
          data.isFromActivityRecord,
          data.candidateList
        ),
      };
    } else {
    }
  } catch (error) {
    // 에러 핸들링
    console.error("Error in get_6_5_A_RawData:", error);
    throw error; // 또는 에러에 대한 처리를 진행할 수 있음
  }
}
