import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { actionCodeSettings } from "utils/auth_email_link_actioncode_settings";

import ButtonWithModal from "components/common/ButtonWithModal";
import { useState } from "react";

export default function EmailAuthButton({
  email,
  disabled,
  isEmailValid,
  setIsEmailSent,
}: {
  email: string;
  disabled?: boolean;
  isEmailValid?: boolean;
  setIsEmailSent: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  // const setMixPanel = useMixpanelWeb({
  //   eventName: "애플 로그인",
  // });

  const sendEmail = () => {
    setLoading(true);
    const auth = getAuth();
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        setIsEmailSent(true);

        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(errorMessage);
        // ...
      });
  };
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <>
      <ButtonWithModal
        size="lg"
        startDecorator={undefined}
        disabled={disabled}
        buttonText={"이메일 인증"}
        title={"메일 주소를 다시 확인해주세요."}
        subtitle={"디스턴싱과 함께하는 학교/기관이 아닌 것 같아요."}
        onClick={isEmailValid ? sendEmail : undefined}
        disableModalOpen={isEmailValid}
        loading={loading}
      />
      {/* <Button
        disabled={disabled}
        loading={loading}
        size="lg"
        onClick={sendEmail}
      >
        이메일 인증
      </Button> */}
    </>
  );
}
