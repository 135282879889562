import { Box, Stack, Typography } from "@mui/joy";
import { SxProps } from "@mui/joy/styles/types";
import dayjs from "dayjs";
import { findSubstringIndices } from "logic/logics";
import { addAlpha, extractHexColor } from "styles/hexOpacity";
import { theme } from "styles/theme";

function MixedTypography({
  text,
  highlightedText,
  customSx = {},
  highlightedSx = {},
}: {
  text: string;
  highlightedText?: string;
  customSx?: SxProps;
  highlightedSx?: SxProps;
}) {
  return (
    <Typography>
      {(highlightedText
        ? [
            text.substring(
              0,
              findSubstringIndices(text, highlightedText).startIndex
            ),
            highlightedText,
            text.substring(
              findSubstringIndices(text, highlightedText).endIndex + 1
            ),
          ]
        : [text]
      ).map((each) =>
        each === highlightedText ? (
          <Typography level={"body-md"} sx={{ ...highlightedSx }}>
            {each}
          </Typography>
        ) : (
          <Typography level={"body-md"} sx={{ ...customSx }}>
            {each}
          </Typography>
        )
      )}
    </Typography>
  );
}

export default function FreeTrialTimeline() {
  const today = dayjs();
  return (
    <Stack spacing={"12px"} sx={{ pl: "20px" }}>
      <Box sx={{ position: "relative", pb: "25px" }}>
        <Stack
          sx={{
            position: "absolute",
            top: "5px",
            left: "11px",
            width: "3px",
            height: "calc(100% - 5px)",
            background: `linear-gradient(180deg, ${extractHexColor(
              theme.vars.palette.primary.solid
            )} 75%, ${addAlpha(
              extractHexColor(theme.vars.palette.primary.solid),
              0
            )} 100%)`,
            transform: "translateX(-50%)",
          }}
        ></Stack>
        <img
          style={{
            position: "absolute",
            right: "-20px",
            bottom: 0,
            width: "93px",
          }}
          alt={"empty"}
          src={require(`../../static/freetrial_timeline_image.png`)}
        />
        <Stack spacing={"30px"}>
          {[
            {
              title: `오늘 (${today.format("M/D")})`,
              text: "나의 고민 분석, 맞춤 프로그램 구성",
            },
            {
              title: `5일 차 (${today.add(5, "day").format("M/D")})`,
              text: `체험 종료 예정 안내`,
            },
            {
              title: `7일 차 (${today.add(7, "day").format("M/D")})`,
              text: "체험 취소 시 100% 무료",
              boldText: "100% 무료",
            },
          ].map(({ title, text, boldText }, index) => (
            <Stack direction={"row"} spacing="15px">
              <Stack
                sx={{
                  width: "22px",
                  height: "22px",
                  borderRadius: "50%",
                  p: "5px",
                  backgroundColor: addAlpha(
                    extractHexColor(theme.vars.palette.primary.solid),
                    index === 0 ? 0.1 : 0
                  ),
                }}
              >
                <Stack
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    backgroundColor: "primary.solid",
                  }}
                ></Stack>
              </Stack>
              <Stack key={title} spacing={"4px"}>
                <Typography level="body-md" sx={{ fontWeight: 700 }}>
                  {title}
                </Typography>
                <MixedTypography
                  text={text}
                  highlightedText={boldText}
                  highlightedSx={{ fontWeight: 700, color: "primary.solid" }}
                />
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
}
