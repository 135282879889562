import { Button } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/paths";

export default function InstitutionStartButton() {
  // const setMixPanel = useMixpanelWeb({
  //   eventName: "애플 로그인",
  // });
  let navigate = useNavigate();
  const buttonData = {
    backgroundColor: "#ffffff",
    color: "#3A3C40",
    borderColor: "rgba(0,0,0,0.1)",
  };

  return (
    <Button
      size="lg"
      onClick={() => {
        navigate(PATH.institutionSelect);
      }}
      sx={{
        backgroundColor: buttonData.backgroundColor,
        border: `1px solid ${buttonData.borderColor}`,
        color: buttonData.color,
        "&:hover": {
          backgroundColor: `${buttonData.backgroundColor}`,
          color: buttonData.color,
          opacity: 0.6,
        },
        "&:focus": {
          backgroundColor: buttonData.backgroundColor,
        },
      }}
    >
      학교/기관으로 시작하기
    </Button>
  );
}
