import PatientMenuBar from "components/task/content/block/PatientMenuBar";
import * as _ from "lodash";
import { Box, Sheet } from "@mui/joy";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import {
  getAiCommentKeyInBlock,
  getTaskIdFromTaskKey,
  isBlockUserFieldFilled,
  isLineHidden,
} from "logic/logics";
import CoachMenuBar from "./CoachMenuBar";
import { layoutAtom } from "recoil/layoutAtom";
import { ProgramContentType } from "data/BlockComponent";
import React, { memo } from "react";
import DoneButton from "components/task/header/DoneButton";
import Line from "./Line";
import { deviceAtom } from "recoil/deviceAtom";

interface PropsType {
  taskKey: string;
  thoughtRecordKey?: string;
  activityRecordKey?: string;
  meditationRecordKey?: string;
  data: ProgramContentType[];
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  blockDataStr: string;
  index: number;
  complete: (index: number) => void;
  isCurrentIndex: boolean;
  doneWithEnter?: boolean;
  setDoneWithEnter?: React.Dispatch<React.SetStateAction<boolean>>;
  isDone?: boolean;
  isLastIndex: boolean;
  isBeforeNoBorder?: boolean;
  isNextOpen?: boolean;
  translationVersion?: string;
}

const Block = React.forwardRef<HTMLDivElement, PropsType>(
  (
    {
      taskKey,
      thoughtRecordKey,
      activityRecordKey,
      meditationRecordKey,
      data,
      setData,
      blockDataStr,
      index,
      complete,
      isCurrentIndex,
      doneWithEnter,
      setDoneWithEnter,
      isDone,
      isLastIndex,
      isBeforeNoBorder,
      isNextOpen,
      translationVersion,
    }: PropsType,
    ref
  ) => {
    const blockData: ProgramContentType = JSON.parse(blockDataStr);
    const user = useRecoilValue(userAtom);
    const device = useRecoilValue(deviceAtom);
    const isCoach =
      (user?.accessToken &&
        user?.role !== "patient" &&
        !taskKey.includes("Tool")) ||
      false;

    const isThoughtRecord = taskKey.includes("2-2-T");

    const { taskWidth } = useRecoilValue(layoutAtom);

    const highlight = blockData && blockData.isHighlight;

    const isHidden =
      blockData &&
      (blockData.isHidden ||
        blockData.isAlwaysHidden ||
        (isCoach && !blockData.isShown && blockData.hideIfCoach) ||
        (!isCoach && (!blockData.isShown || blockData.hideIfPatient)));

    return blockData ? (
      <div
        ref={ref}
        key={`${taskKey}_${index}`}
        className={`w-full mx-auto ${
          taskWidth === "normal" && "max-w-[720px]"
        } ${isHidden && "hidden"} `}
      >
        {index > 0 && !blockData.noBorder && !isBeforeNoBorder && (
          <Box
            sx={{ backgroundColor: "background.level3" }}
            className={`w-[1px] h-[12px] translate-x-[16px] ${
              !isCoach && blockData.indentation && !isNextOpen && "opacity-0"
            }`}
          />
        )}
        <div
          className={`${
            blockData.indentation && "pl-[16px] flex items-start relative"
          }`}
        >
          {blockData.indentation && (
            <Box>
              {(isCoach || isNextOpen) && (
                <Box
                  sx={{ backgroundColor: "background.level3" }}
                  className="w-[1px] h-full absolute"
                />
              )}
              <Box
                sx={{ backgroundColor: "background.level3" }}
                className="w-[1px] h-[12px] absolute top-[-12px] left-[48px]"
              />
            </Box>
          )}

          {blockData.indentation && <div className="w-[16px]"></div>}

          <Sheet
            sx={{
              position: "relative",
              backgroundColor: blockData.noBorder
                ? "transparent"
                : "card.background",
              ...(blockData.hideIfPatient
                ? {
                    borderStyle: "dashed",
                    borderWidth: "2px",
                  }
                : {
                    borderWidth: blockData.noBorder ? 0 : "0px 1px 1px 0px",
                  }),
              borderColor: "background.level3",
            }}
            className={`relative w-full flex flex-col gap-[1px] ${
              !isCoach && isDone && "pointer-events-none"
            } ${
              !blockData.noBorder
                ? `px-[20px] py-[16px]`
                : `py-[12px] ${index > 0 && "pt-[24px]"} pl-[10px] `
            }`}
          >
            {highlight && (
              <Box
                sx={{
                  backgroundColor: "primary.solid",
                  width: "5px",
                  height: "100%",
                  borderRadius: "10px",
                  position: "absolute",
                }}
                className="top-0 left-0"
              ></Box>
            )}
            {/* <Typography level="body-xs" color="danger">
              {index}
            </Typography> */}

            {blockData.lines?.map(
              (eachLine, lineIndex) =>
                !isLineHidden(data, blockData, eachLine) && (
                  <Line
                    key={`${taskKey}_line_${lineIndex}`}
                    lineStr={JSON.stringify(eachLine)}
                    lineIndex={lineIndex}
                    taskKey={taskKey}
                    thoughtRecordKey={thoughtRecordKey}
                    activityRecordKey={activityRecordKey}
                    data={
                      eachLine.some(
                        (element) =>
                          element.type === "typographyFromData" ||
                          element.type === "pieChart" ||
                          element.type === "percentage" ||
                          element.type === "extractFromThoughtRecord"
                      )
                        ? data
                        : undefined
                    }
                    setData={setData}
                    blockIndex={index}
                    isLastLine={lineIndex === blockData.lines.length - 1}
                    translationVersion={translationVersion}
                    readonly={isDone || false}
                    isShown={blockData.isShown || false}
                  />
                )
            )}
            {!isCoach &&
              (isLastIndex || blockData.isEnd) &&
              !taskKey.includes("Tool") && (
                <Box sx={{ py: "8px" }}>
                  <Box
                    sx={{
                      height: "1px",
                      width: "100%",
                      backgroundColor: "background.level2",
                      mb: "12px",
                    }}
                  />
                  <DoneButton
                    taskKey={taskKey}
                    data={data}
                    doneWithEnter={doneWithEnter}
                    setDoneWithEnter={setDoneWithEnter}
                    thoughtRecordKey={thoughtRecordKey}
                    activityRecordKey={activityRecordKey}
                    meditationRecordKey={meditationRecordKey}
                    isDone={isDone}
                  />
                </Box>
              )}
            {!isCoach &&
              !isLastIndex &&
              !blockData.isEnd &&
              !blockData.noArrow && (
                <PatientMenuBar
                  highlight={
                    getTaskIdFromTaskKey(taskKey) === "0-0-A" && index === 0
                  }
                  disabled={
                    !(
                      isCurrentIndex &&
                      isBlockUserFieldFilled(blockData, data, user)
                    )
                  }
                  onClick={() => {
                    complete(index);
                  }}
                />
              )}
            {isCoach &&
              !isLastIndex &&
              !blockData.isEnd &&
              (!blockData.noBorder || blockData.removable) &&
              !blockData.hideIfPatient && (
                <CoachMenuBar
                  taskKey={taskKey}
                  thoughtRecordKey={thoughtRecordKey}
                  setData={setData}
                  index={index}
                  isThoughtRecord={isThoughtRecord}
                  hasIndentation={blockData.indentation}
                  isRemovable={blockData.removable}
                  aiCommentKey={getAiCommentKeyInBlock(blockData)}
                />
              )}
          </Sheet>
        </div>
      </div>
    ) : (
      <div />
    );
  }
);

export default memo(Block);
