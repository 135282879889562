import {
  Instruction,
  InstructionWithCoachTextarea,
  InstructionWithImage,
} from "../BlockComponent";
import { LastCard } from "./common";

export const Content_101_2_2_A = [
  Instruction("typography_0_0_0"),
  Instruction("typography_1_0_0"),
  InstructionWithCoachTextarea(`typography_2_0_0`, {
    placeholder: `코치가 정리한 상황/사건`,
    id: "상황/사건",
  }),
  InstructionWithCoachTextarea(`typography_3_0_0`, {
    placeholder: `코치가 정리한 행동`,
    id: "행동",
  }),
  InstructionWithCoachTextarea(`typography_4_0_0`, {
    placeholder: `코치가 정리한 단기적 이점`,
    id: "단기적 이점",
  }),
  InstructionWithCoachTextarea(`typography_5_0_0`, {
    placeholder: `코치가 정리한 장기적 단점`,
    id: "장기적 단점",
  }),
  Instruction("typography_6_0_0"),
  Instruction("typography_7_0_0"),
  InstructionWithImage("typography_8_0_0", "0"),
  InstructionWithImage("typography_9_0_0", "1"),
  InstructionWithImage("typography_10_0_0", "2"),
  Instruction("typography_11_0_0"),
  Instruction("typography_12_0_0"),
  InstructionWithImage("typography_13_0_0", "3"),
  Instruction("typography_14_0_0"),
  Instruction("typography_15_0_0"),
  Instruction("typography_16_0_0"),
  Instruction("typography_17_0_0"),
  Instruction("typography_18_0_0"),
  Instruction("typography_19_0_0"),
  Instruction("typography_20_0_0"),
  Instruction("typography_21_0_0"),
  Instruction("typography_22_0_0"),
  Instruction("typography_23_0_0"),

  ...LastCard,
];
