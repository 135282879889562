import { Stack } from "@mui/joy";
import { useEffect, useState } from "react";

export default function SteppaySubscriptionManagerModule({
  storeUuid,
  sessionKey,
}: {
  storeUuid: string;
  sessionKey: string;
}) {
  useEffect(() => {
    // 스크립트 생성 및 속성 설정
    const script = document.createElement("script");
    script.src = "https://cdn.steppay.kr/production-loader.js";
    script.defer = true;

    // 스크립트를 body에 추가
    document.body.appendChild(script);
    // 컴포넌트가 언마운트 될 때 스크립트 제거
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div id="step-shop-embed" data-type="subscription">
      <script type="application/json">
        {JSON.stringify({
          storeUuid: storeUuid,
          session: sessionKey,
        })}
      </script>
    </div>
  );
}
