import { translation_4_5_A } from "./4-5-A";
export const translation_4_5_3_A = {
  en: {
    v1: {
      ...translation_4_5_A.en.v1.start,
      typography_0_0_0: "Negative predictions and the worst scenarios",
      typography_1_0_0: "In situations like the one below,",
      typography_2_0_0:
        "you might have thought of some negative things that popped into your mind.",
      typography_3_0_0:
        "And, you've probably also thought of the worst-case scenarios.",
      typographyFromData_4_0_0:
        'Before we start calculating the odds, how likely do you think it is that something like "$A$" will happen? What percentage would you give it?',
      typography_5_0_0: "Estimating the odds",
      typographyFromData_6_0_0:
        'Alright. How often or for how long did something like "$A$" happen in the past? (e.g., Twice a year)',
      typographyFromData_7_0_0:
        'Considering the frequency, what do you think is the percentage likelihood of a situation like "$A$" occurring?',
      typographyFromData_8_0_0: `Now, let's assume that "$A$" actually happens. There might be possibilities to prevent the situation from getting worse.`,
      typographyFromData_9_0_0:
        'If we assume that "$A$" occurs, what do you think are the chances of it turning into a situation like "$B$"? What percentage of the 100% do you think it would be?',
      typographyFromData_10_0_0: `Let's think of other possibilities.`,
      typographyFromData_11_0_0:
        'If "$A$" happens, what are some different outcomes other than "$B$" that could happen?',
      typography_12_0_0:
        "Out of the total 100%, how likely is each of these different possibilities?",
      typography_12_32_0: "Total probability must add up to 100%",
      typography_13_0_0: "Reviewing the probabilities",
      typography_14_0_0:
        "Great. Now, let's sum up what you've written and think about it.",
      typographyFromData_15_0_0: `First, the probability that "$B$" won't happen at all is $eval(100-A)$%.`,
      typographyFromData_16_0_0: `Conversely, the probability that "$B$" will happen is $A$%.`,
      typographyFromData_17_0_0:
        "Let's break down this $A$% into various possibilities and examine them one by one.",
      typographyFromData_17_5_0: "$eval((parseInt(A)*parseInt(B))/100)$%",
      typographyFromData_17_10_0: "$eval(Math.round((A*(100)*C)/10000))$%",
      typographyFromData_17_15_0: "$eval(Math.round((A*(100)*C)/10000))$%",
      typographyFromData_17_20_0: "$eval(Math.round((A*(100)*C)/10000))$%",
      typographyFromData_17_25_0: "$eval(Math.round((A*(100)*C)/10000))$%",
      typographyFromData_17_30_0: "$eval(Math.round((A*(100)*C)/10000))$%",
      typographyFromData_17_35_0: "$eval(Math.round((A*(100)*C)/10000))$%",
      typographyFromData_18_0_0:
        'After reviewing the results, how likely do you think it is now for "$A$" to occur?',
      typographyFromData_19_0_0:
        "Initially, you said the probability was $A$%. Do you see a difference now? If there is a difference, what do you think about it?",
      typography_20_0_0: "The back up plans",
      typographyFromData_21_0_0:
        'If a situation like "$A$" were to happen, how could you handle it? Can you think of some coping strategies?',
      typography_22_0_0:
        "Then, if the same situation occurs, who or what could you turn to for help? Who/where/what could you ask for assistance?",
      typography_23_0_0:
        "Lastly, if such a situation arises, what are your strengths or advantages that could help you overcome it?",
      typography_24_0_0: "Stepping back and reflecting",
      typography_25_0_0:
        "Alright. Let's summarize what we've discussed. Take your time and read through it again.",
      typographyFromData_26_0_0:
        'Firstly, the likelihood of an event like "$A$" happening is about $B$%.',
      typographyFromData_27_0_0: `Secondly, if that happens, I've got strengths like "$A$" to rely on.`,
      typography_28_0_0: "Thirdly, I can cope in the following ways.",
      typography_29_0_0:
        "Fourthly, I can also seek help from those around me in ways like this.",
      typography_30_0_0:
        "Having reviewed and organized everything like this, what are your thoughts now?",
      ...translation_4_5_A.en.v1.end,
    },
  },
  ko: {
    v1: {
      ...translation_4_5_A.ko.v1.start,
      typography_0_0_0: "부정적 예측과 최악의 시나리오",
      typography_1_0_0: "우선 아래과 같은 상황에서",
      typography_2_0_0: "우리 마음속에 떠올랐던 부정적인 일은 다음과 같겠네요.",
      typography_3_0_0: "또한 다음과 같은 최악의 사태도 떠올랐어요.",
      typographyFromData_4_0_0:
        '본격적으로 확률을 따져보기 전, "$A$"와(과) 같은 일이 발생할 확률은 몇 % 정도 될 거라고 생각하나요?',
      typography_5_0_0: "확률 따져보기",
      typographyFromData_6_0_0:
        '좋아요. 그러면 "$A$"와(과) 같은 일은 과거에 얼마나 자주/오래 발생했었나요? (예: 1년에 2번 정도)',
      typographyFromData_7_0_0:
        '빈도를 생각해보았을 때, "$A$"와(과) 같은 상황이 발생할 확률은 몇 퍼센트 정도가 될 거라고 생각하나요?',
      typographyFromData_8_0_0:
        '이번에는 "$A$"와(과) 같은 일이 실제로 발생했다고 생각해볼게요. 그런 상황에서는 아래와 같이 일이 더 부정적으로 흘러가지 않게끔 무력화시키는 가능성이 있을 거예요.',
      typographyFromData_9_0_0:
        '"$A$"와(과) 같은 상황이 벌어졌다고 가정해 볼게요. 이때 상황이 "$B$"와(과) 같이 흘러갈 확률은 100% 중 얼마나 될까요?',
      typographyFromData_10_0_0: "자, 다른 가능성을 떠올려볼게요.",
      typographyFromData_11_0_0:
        '"$A$"와(과) 같은 상황이 벌어졌다고 했을 때 "$B$" 이외에 상황이 다르게 흘러갈 수 있는 가능성에는 무엇무엇이 있을까요?',
      typography_12_0_0:
        "전체가 100%라고 했을 때, 각각의 다른 가능성이 일어날 확률은 어떻게 될까요?",
      typography_12_32_0: "확률의 합은 100%가 되어야 합니다",
      typography_13_0_0: "확률 검토하기",
      typography_14_0_0:
        "좋아요. 이제 적어주신 것을 합산하여 생각해보도록 해요.",
      typographyFromData_15_0_0:
        '먼저 "$B$"이(가) 아예 일어나지 않을 확률은 $eval(100-A)$%이에요.',
      typographyFromData_16_0_0: '반대로 "$B$"이(가) 일어날 확률은 $A$%이지요.',
      typographyFromData_17_0_0:
        "이때의 $A$%를 이루는 여러 가능성을 계산해서 하나하나 살펴보면 다음과 같아요.",
      typographyFromData_17_5_0: "$eval((parseInt(A)*parseInt(B))/100)$%",
      typographyFromData_17_10_0: "$eval(Math.round((A*(100)*C)/10000))$%",
      typographyFromData_17_15_0: "$eval(Math.round((A*(100)*C)/10000))$%",
      typographyFromData_17_20_0: "$eval(Math.round((A*(100)*C)/10000))$%",
      typographyFromData_17_25_0: "$eval(Math.round((A*(100)*C)/10000))$%",
      typographyFromData_17_30_0: "$eval(Math.round((A*(100)*C)/10000))$%",
      typographyFromData_17_35_0: "$eval(Math.round((A*(100)*C)/10000))$%",
      typographyFromData_18_0_0:
        '위 결과를 살펴본 후 다시 생각해보았을 때 "$A$"이(가) 일어날 확률은 얼마나 된다고 생각하나요?',
      typographyFromData_19_0_0:
        "처음에는 그러한 일이 발생할 확률을 $A$%라고 적어주셨네요. 이전 수치와 차이가 있나요? 만약 차이가 있다면 어떤 생각이 드나요?",
      typography_20_0_0: "대처 전략 마련하기",
      typographyFromData_21_0_0:
        '만약 "$A$"와(과) 같은 상황이 발생했다고 하면 나는 어떻게 대처할 수 있을까요? 나의 대처 전략을 적어볼까요?',
      typography_22_0_0:
        "그렇다면 같은 상황이 발생했을 때 내가 도움을 청할 수 있는 사람이나 대상이 있을까요? 누구/어디/무엇에게 어떻게 도움을 청할 수 있을까요?",
      typography_23_0_0:
        "마지막으로 위와 같은 상황이 발생했을 때 이를 극복해 나갈 수 있는 나의 강점이나 장점이 있다면 무엇일까요?",
      typography_24_0_0: "거리두고 살펴보기",
      typography_25_0_0:
        "좋아요. 이야기 나눴던 것들을 정리해볼게요. 다시 한번 찬찬히 읽어보세요.",
      typographyFromData_26_0_0:
        '첫째, "$A$"와(과) 같은 일이 발생할 확률은 약 $B$% 정도이다.',
      typographyFromData_27_0_0:
        '둘째, 만약 위와 같은 상황이 발생했을 때, "$A$"와(과) 같은 강점이 있는 나는',
      typography_28_0_0: "셋째, 다음과 같이 대처할 수 있을 것이다.",
      typography_29_0_0:
        "넷째, 그리고 다음과 같이 주변에 도움을 청할 수도 있을 것이다.",
      typography_30_0_0: "위와 같이 정리하여 살펴보았을 때 어떤 생각이 드나요?",
      ...translation_4_5_A.ko.v1.end,
    },
  },
};
