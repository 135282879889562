import { Box, Stack } from "@mui/joy";
import { useRecoilValue } from "recoil";
import { layoutAtom } from "recoil/layoutAtom";
import { permanentDataAtom } from "recoil/permanentDataAtom";

export default function FloatingArea({
  children,
}: {
  children: React.ReactNode;
}) {
  const { isLeftSideBarOpen, taskWidth } = useRecoilValue(layoutAtom);
  const permanantDataState = useRecoilValue(permanentDataAtom);
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: isLeftSideBarOpen ? "var(--Sidebar-width)" : 0,
        width: isLeftSideBarOpen
          ? `calc(100% - var(--Sidebar-width, 0))`
          : "100%",
        backgroundColor: "white",
        boxShadow: `0px -1px 0px rgba(0, 0, 0, 0.1)`,
        px: "20px",
        pt: "20px",
        pb: `${permanantDataState?.homeIndicatorHeight || 20}px`,
      }}
    >
      <Stack
        className={` w-full  ${
          taskWidth === "normal" && "max-w-[720px] mx-auto"
        }`}
        spacing="12px"
        alignItems="center"
      >
        {children}
      </Stack>
    </Box>
  );
}
