import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RecoilRoot, useSetRecoilState } from "recoil";
import Router from "routes";
import { theme } from "styles/theme";
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import { GlobalStyles } from "@mui/joy";
import { materialTheme } from "styles/materialTheme";
import "material-symbols";
import mixpanel from "mixpanel-browser";
import { BUILD_TARGET, GOOGLE_CLIENTID } from "config";
import useMixpanelWeb from "hooks/useMixpanelWeb";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

declare global {
  interface Window {
    Kakao: any;
    ReactNativeWebView: {
      postMessage(msg: string): void;
    };
  }
  interface Array<T> {
    findLastIndex(
      predicate: (value: T, index: number, obj: T[]) => unknown,
      thisArg?: any
    ): number;
  }
}

export const firebaseConfig_dev = {
  apiKey: "AIzaSyAX_O-NntYzO4Bmh8M8G7PUpcP8cIwW8XI",
  authDomain: "distancing-dev.firebaseapp.com",
  projectId: "distancing-dev",
  storageBucket: "distancing-dev.appspot.com",
  messagingSenderId: "193897208164",
  appId: "1:193897208164:web:6c796023f17e225257c271",
  measurementId: "G-TXJBESPEG7",
};
export const firebaseConfig_real = {
  apiKey: "AIzaSyBMCcsN-xmAlgj6wnA1qW-S9Xlbuzdk4OI",
  authDomain: "distancing-404509.firebaseapp.com",
  projectId: "distancing-404509",
  storageBucket: "distancing-404509.appspot.com",
  messagingSenderId: "938509636319",
  appId: "1:938509636319:web:5be2e679961d04947e4f6a",
  measurementId: "G-DMCFDDT4V3",
};

function App() {
  const [queryClient] = useState(() => new QueryClient());
  // const materialTheme = materialExtendTheme();
  const body = document.body;
  body.style.position = "fixed";
  body.style.overflow = "hidden";
  const isDev = BUILD_TARGET === "dev";

  const setMixPanel_come = useMixpanelWeb({
    eventName: "진입",
  });

  useEffect(() => {
    setMixPanel_come();
  }, []);

  const projectId = isDev
    ? "306a1663b1fece6e28324733a9aca2ee"
    : "6bb06d65a8dec1d9dec0496adef7c8d0";

  const googleClientid = GOOGLE_CLIENTID;

  // Initialize Firebase
  const app = initializeApp(isDev ? firebaseConfig_dev : firebaseConfig_real);
  const analytics = getAnalytics(app);

  mixpanel.init(projectId, { debug: true });

  mixpanel.set_config({
    // HTTP method for tracking requests
    api_method: "POST",

    // transport for sending requests ('XHR' or 'sendBeacon')
    // NB: sendBeacon should only be used for scenarios such as
    // page unload where a &quot;best-effort&quot; attempt to send is
    // acceptable; the sendBeacon API does not support callbacks
    // or any way to know the result of the request. Mixpanel
    // tracking via sendBeacon will not support any event-
    // batching or retry mechanisms.
    api_transport: "XHR",

    // request-batching/queueing/retry
    batch_requests: false,

    // maximum number of events/updates to send in a single
    // network request
    batch_size: 50,

    // milliseconds to wait between sending batch requests
    batch_flush_interval_ms: 5000,

    // milliseconds to wait for network responses to batch requests
    // before they are considered timed-out and retried
    batch_request_timeout_ms: 90000,

    // override value for cookie domain, only useful for ensuring
    // correct cross-subdomain cookies on unusual domains like
    // subdomain.mainsite.avocat.fr; NB this cannot be used to
    // set cookies on a different domain than the current origin
    cookie_domain: "",

    // super properties cookie expiration (in days)
    cookie_expiration: 365,

    // if true, cookie will be set with SameSite=None; Secure
    // this is only useful in special situations, like embedded
    // 3rd-party iframes that set up a Mixpanel instance
    cross_site_cookie: false,

    // super properties span subdomains
    cross_subdomain_cookie: true,

    // debug mode
    debug: false,

    // if this is true, the mixpanel cookie or localStorage entry
    // will be deleted, and no user persistence will take place
    disable_persistence: false,

    // if this is true, Mixpanel will automatically determine
    // City, Region and Country data using the IP address of
    //the client
    ip: true,

    // opt users out of tracking by this Mixpanel instance by default
    opt_out_tracking_by_default: false,

    // opt users out of browser data storage by this Mixpanel instance by default
    opt_out_persistence_by_default: false,

    // persistence mechanism used by opt-in/opt-out methods - cookie
    // or localStorage - falls back to cookie if localStorage is unavailable
    opt_out_tracking_persistence_type: "localStorage",

    // customize the name of cookie/localStorage set by opt-in/opt-out methods
    opt_out_tracking_cookie_prefix: undefined,

    // type of persistent store for super properties (cookie/
    // localStorage) if set to 'localStorage', any existing
    // mixpanel cookie value with the same persistence_name
    // will be transferred to localStorage and deleted
    persistence: "cookie",

    // name for super properties persistent store
    persistence_name: "",

    // names of properties/superproperties which should never
    // be sent with track() calls
    property_blacklist: [],

    // if this is true, mixpanel cookies will be marked as
    // secure, meaning they will only be transmitted over https
    secure_cookie: false,

    // the amount of time track_links will
    // wait for Mixpanel's servers to respond
    track_links_timeout: 300,

    // if you set upgrade to be true, the library will check for
    // a cookie from our old js library and import super
    // properties from it, then the old cookie is deleted
    // The upgrade config option only works in the initialization,
    // so make sure you set it when you create the library.
    upgrade: false,

    // extra HTTP request headers to set for each API request, in
    // the format {'Header-Name': value}
    xhr_headers: {},

    // whether to ignore or respect the web browser's Do Not Track setting
    ignore_dnt: false,
  });
  // mixpanel.track('Sign up');

  return (
    <GoogleOAuthProvider clientId={googleClientid}>
      <MaterialCssVarsProvider
        defaultMode="system"
        theme={{ [MATERIAL_THEME_ID]: materialTheme }}
      >
        <JoyCssVarsProvider defaultMode="system" theme={theme}>
          <GlobalStyles
            styles={{
              // CSS object styles
              html: {
                // ...
                boxSizing: "border-box",
              },
              body: {
                // ...
              },
            }}
          />
          <RecoilRoot>
            <QueryClientProvider client={queryClient}>
              <Router />
            </QueryClientProvider>
          </RecoilRoot>
        </JoyCssVarsProvider>
      </MaterialCssVarsProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
