import { Box, Stack, Textarea, Typography } from "@mui/joy";
import { useEffect, useRef, useState } from "react";
import ThoughtRecordContainer from "./ThoughtRecordContainer";
import { addAlpha } from "styles/hexOpacity";
import BottomSheet from "components/common/BottomSheet";
import Button from "./Button";
import useIsKeyboardOpen from "hooks/useIsKeyboardOpen";

export default function Test() {
  const contentRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLDivElement>(null);
  const [isConversationOpen, setIsConversationOpen] = useState<boolean>(false);

  const [userMessage, setUserMessage] = useState<string>("");

  const isKeyboardOpen = useIsKeyboardOpen();

  useEffect(() => {
    if (isKeyboardOpen) {
      if (contentRef.current) {
        setTimeout(() => {
          contentRef.current?.scrollTo({
            top: contentRef.current?.scrollHeight,
            behavior: "smooth",
          });
        }, 0);
      }
    }
  }, [isKeyboardOpen]);

  return (
    <ThoughtRecordContainer>
      <Button
        buttonText="열기"
        onClick={() => {
          setIsConversationOpen(true);
        }}
      />
      <BottomSheet
        isVisible={isConversationOpen}
        setIsVisible={setIsConversationOpen}
        backgrounColor="#222222"
      >
        <Box
          ref={contentRef}
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            padding: "20px",
            overflow: "scroll",
          }}
        >
          <Stack spacing="16px" sx={{ pb: "100px" }}>
            <div className="w-full h-[1000px] bg-white" />
            <Textarea
              ref={textareaRef}
              placeholder={"자유롭게 적어주세요"}
              value={userMessage}
              onChange={(e) => {
                let newValue = e.target.value;
                setUserMessage(newValue);
              }}
              minRows={1}
              sx={{
                width: "100%",
                fontSize: "16px",
                color: "white",
                padding: 0,
                backgroundColor: "transparent",
                borderWidth: 0,
                boxShadow: "none",
                "--joy-focus-thickness": "0px",
                "--variant-borderWidth": "0px",
                "--Textarea-gap": "0px",
                minWidth: 0,
                minHeight: "24px",
              }}
            ></Textarea>
          </Stack>
        </Box>
        {true && (
          <Box
            sx={{
              position: "absolute",
              bottom: "32px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <Button
              size="sm"
              isRound
              buttonText="더 분리되었어요"
              color="gradient-orange"
              onClick={() => {
                setIsConversationOpen(false);
              }}
            />
          </Box>
        )}
      </BottomSheet>
    </ThoughtRecordContainer>
  );
}
