import {
  Instruction,
  InstructionWithContent,
  Header1,
} from "../BlockComponent";
import {
  ButtonGroup,
  ButtonGroupMultipleChoice,
  DividerLine,
  GuideTypographyLine,
  LineWithSolidLabel,
  TypographyLine,
} from "../CellComponent";
import { LastCard, MyWorryLines } from "./common";

const NotificationTimeBlock = InstructionWithContent(
  "typography_37_0_0",
  [
    [
      ButtonGroupMultipleChoice(
        [
          {
            translationKey: "저녁 7시",
          },
          {
            translationKey: "저녁 8시",
          },
          {
            translationKey: "저녁 9시",
          },
          {
            translationKey: "밤 10시",
          },
          {
            translationKey: "밤 11시",
          },
          {
            translationKey: "밤 12시",
          },
        ],
        { id: "notification_time" }
      ),
    ],
  ],
  { blockId: "알림 허용" }
);

export const Content_2_1_A = (isPushNotificationGranted: boolean) => [
  Instruction("typography_0_0_0"),
  Instruction("typography_1_0_0"),
  Instruction("typography_2_0_0"),
  Instruction("typography_3_0_0"),
  Instruction("typography_4_0_0"),
  InstructionWithContent(
    `typography_5_0_0`,
    LineWithSolidLabel("상황", TypographyLine("typography_5_3_0"))
  ),
  InstructionWithContent(`typography_6_0_0`, [
    ...LineWithSolidLabel("생각", TypographyLine("typography_6_3_0")),
    ...LineWithSolidLabel("영향 정도", TypographyLine("typography_6_5_0")),
  ]),
  InstructionWithContent(`typography_7_0_0`, [
    ...LineWithSolidLabel("감정", TypographyLine("typography_7_3_0_0")),
    ...LineWithSolidLabel("신체 반응", TypographyLine("typography_7_3_0_1")),
    ...LineWithSolidLabel("행동/충동", TypographyLine("typography_7_5_0")),
  ]),

  Header1("typography_8_0_0"),
  InstructionWithContent(
    `typography_9_0_0`,
    LineWithSolidLabel("상황", TypographyLine("typography_9_3_0"))
  ),
  Instruction("typography_10_0_0"),
  InstructionWithContent(`typography_11_0_0`, [
    ...LineWithSolidLabel("상황", TypographyLine("typography_11_3_0")),
    DividerLine(),
    [
      ButtonGroup(
        [
          {
            translationKey: "잘 적혔어요",
          },
          {
            translationKey: "아니요, 잘못 적혔어요",
          },
        ],
        { id: "quiz1" }
      ),
    ],

    GuideTypographyLine("typography_11_6_0", {
      answerList: [{ id: "quiz1", answerIndex: 1 }],
    }),
  ]),

  Instruction("typography_12_0_0"),
  Instruction("typography_13_0_0"),

  Header1("typography_14_0_0"),
  InstructionWithContent(`typography_15_0_0`, [
    ...LineWithSolidLabel("생각", TypographyLine("typography_15_3_0")),
    ...LineWithSolidLabel("영향 정도", TypographyLine("typography_15_5_0")),
  ]),
  Instruction("typography_16_0_0"),
  Instruction("typography_17_0_0"),
  InstructionWithContent(`typography_18_0_0`, [
    ...LineWithSolidLabel("생각", TypographyLine("typography_18_3_0")),
    ...LineWithSolidLabel("영향 정도", TypographyLine("typography_18_5_0")),
    DividerLine(),
    [
      ButtonGroup(
        [
          {
            translationKey: "잘 적혔어요",
          },
          {
            translationKey: "아니요, 잘못 적혔어요",
          },
        ],
        { id: "quiz2" }
      ),
    ],

    GuideTypographyLine("typography_18_8_0", {
      answerList: [{ id: "quiz2", answerIndex: 1 }],
    }),
  ]),

  Instruction("typography_19_0_0"),
  Instruction("typography_20_0_0"),
  Instruction("typography_21_0_0"),
  Instruction("typography_22_0_0"),
  Instruction("typography_23_0_0"),

  Header1("typography_24_0_0"),
  InstructionWithContent(`typography_25_0_0`, [
    ...LineWithSolidLabel("감정", TypographyLine("typography_25_3_0_0")),
    ...LineWithSolidLabel("신체 반응", TypographyLine("typography_25_3_0_1")),
    ...LineWithSolidLabel("행동/충동", TypographyLine("typography_25_5_0")),
  ]),
  Instruction("typography_26_0_0"),
  Instruction("typography_27_0_0"),

  Instruction("typography_28_0_0"),
  Instruction("typography_28_0_0_1"),
  Instruction("typography_29_0_0"),
  Instruction("typography_29_0_0_1"),
  Instruction("typography_30_0_0"),
  Instruction("typography_31_0_0"),
  Instruction("typography_32_0_0"),
  Instruction("typography_33_0_0"),
  Instruction("typography_34_0_0"),
  Instruction("typography_35_0_0"),
  ...(!isPushNotificationGranted
    ? [
        InstructionWithContent("typography_36_0_0", [
          [
            {
              type: "pushNotification",
              content: { showFromIdIfDenied: "알림 미허용" },
            },
          ],
        ]),
        NotificationTimeBlock,
      ]
    : [NotificationTimeBlock]),
  // InstructionWithContent(`typography_38_0_0`, MyWorryLines(), {
  //   blockId: "알림 미허용",
  // }),
  Instruction(`typography_38_0_0`, {
    blockId: "알림 미허용",
  }),
  Instruction("typography_39_0_0"),

  ...LastCard,
];
